<template>
    <div class="organisation-form">
        <v-tabs v-model="activeTab"
                color="primary"
                slider-color="primary">
            <v-tab :key="0"
                   data-test="organisation-form-tab-general">
                <span>{{ $t('admin.organisation.form.tabs.general') }}</span>
            </v-tab>
            <v-tab :key="1"
                   data-test="organisation-form-tab-billing">
                <span>{{ $t('admin.organisation.form.tabs.billing') }}</span>
            </v-tab>
            <v-tab :key="2">
                <span>{{ $t('admin.organisation.form.tabs.chargesEmail') }}</span>
            </v-tab>
            <v-tab :key="3">
                <span>{{ $t('admin.organisation.form.tabs.features') }}</span>
            </v-tab>
            <v-tab :key="4">
                <span>{{ $t('admin.organisation.form.tabs.products') }}</span>
            </v-tab>
            <v-tab :key="5">
                <span>{{ $t('admin.organisation.form.tabs.grandfathered') }}</span>
            </v-tab>
        </v-tabs>

        <v-window v-model="activeTab">
            <v-window-item :key="0"
                           class="organisation-form__tab-content">
                <v-text-field v-model="selectedOrganisation.name"
                              autocomplete="ow"
                              data-test="organisation-form-input-name"
                              :rules="[rules.onlyAlpha]"
                              :label="`* ${ $t('form.label.name') }`"
                              required />

                <v-text-field v-model="selectedOrganisation.maxStandardUsers"
                              data-test="organisation-form-checkbox-max-standard-users"
                              hide-details
                              :label="$t('admin.organisation.label.maximumStandardAllocatedUsers')"
                              :min="selectedOrganisation.activeStandardUsersCount"
                              type="number" />
                <v-text-field v-model="selectedOrganisation.maxPremiumUsers"
                              data-test="organisation-form-checkbox-max-premium-users"
                              hide-details
                              :label="$t('admin.organisation.label.maximumPremiumAllocatedUsers')"
                              :min="selectedOrganisation.activePremiumUsersCount"
                              type="number" />

                <v-select v-model="selectedOrganisation.industry"
                          :items="industryItems"
                          data-test="organisation-form-dropdown-industry"
                          hide-details
                          :label="`* ${ $t('form.label.industry') }`"
                          required />

                <v-select v-model="selectedOrganisation.status"
                          :items="statusItems"
                          data-test="organisation-form-dropdown-status"
                          hide-details
                          :label="`* ${ $t('form.label.status') }`"
                          required />

                <v-text-field v-if="!isEdit"
                              v-model="selectedOrganisation.sectionName"
                              autocomplete="ow"
                              data-test="organisation-form-input-default-section-name"
                              :label="$t('admin.organisation.label.defaultSectionName')"
                              required />
                <div class="organisation-form__default-section-address body-copy">
                    {{ $t('admin.organisation.label.defaultSectionAddress') }}
                </div>
                <v-text-field v-if="!isEdit"
                              v-model="selectedOrganisation.sectionAddressHouseNumber"
                              autocomplete="ow"
                              data-test="organisation-form-input-default-section-address-house-number"
                              :label="`* ${ $t('admin.organisation.label.defaultSectionHouseBuildingNumber') }`"
                              required />
                <v-text-field v-if="!isEdit"
                              v-model="selectedOrganisation.sectionAddressStreet"
                              autocomplete="ow"
                              data-test="organisation-form-input-default-section-address-street"
                              :label="$t('admin.organisation.label.defaultSectionStreetName')"
                              required />
                <v-text-field v-if="!isEdit"
                              v-model="selectedOrganisation.sectionAddressLocality"
                              autocomplete="ow"
                              data-test="organisation-form-input-default-section-address-locality"
                              :label="$t('admin.organisation.label.defaultSectionLocality')" />
                <v-text-field v-if="!isEdit"
                              v-model="selectedOrganisation.sectionAddressTown"
                              autocomplete="ow"
                              data-test="organisation-form-input-default-section-address-town"
                              :label="$t('admin.organisation.label.defaultSectionTown')" />
                <v-text-field v-if="!isEdit"
                              v-model="selectedOrganisation.sectionAddressPostcode"
                              autocomplete="ow"
                              data-test="organisation-form-input-default-section-address-postcode"
                              :label="$t('admin.organisation.label.defaultSectionPostcode')"
                              required />

                <v-select v-if="isEdit"
                          v-model="selectedOrganisation.defaultSectionId"
                          :items="selectedOrganisation.sections"
                          data-test="organisation-form-dropdown-default-section"
                          item-title="name"
                          item-value="id"
                          :label="$t('admin.organisation.label.defaultSection')" />

                <!-- MZA: temporarly disable regions. Only Copilot is using it and the value is not -->
                <!-- being synced with the user token, which is causing misunderstadings -->
                <!-- <v-select v-if=false -->
                <!--           v-model="selectedOrganisation.regions" -->
                <!--           :items="['UK', 'US', 'Australia', 'Canada']" -->
                <!--           data-test="organisation-form-dropdown-regions" -->
                <!--           multiple -->
                <!--           hint="Select the regions the organisation operates in. This will control region-specific features and data availability." -->
                <!--           :label="`* ${ $t('form.label.regions') }`" -->
                <!--           disabled /> -->
                <!-- <v-select v-model="selectedOrganisation.defaultLocale" -->
                <!--           :items="[ -->
                <!--               { -->
                <!--                   value: 'en-GB', -->
                <!--                   title: 'English (United Kingdom)', -->
                <!--               }, -->
                <!--               { -->
                <!--                   value: 'en-US', -->
                <!--                   title: 'English (United States)', -->
                <!--               }, -->
                <!--               { -->
                <!--                   value: 'en-AU', -->
                <!--                   title: 'English (Australia)', -->
                <!--               }, -->
                <!--               { -->
                <!--                   value: 'en-CA', -->
                <!--                   title: 'English (Canada)', -->
                <!--               }]" -->
                <!--           data-test="organisation-form-input-default-locale" -->
                <!--           :label="`* ${ $t('form.label.defaultLocale') }`" -->
                <!--           hint="Select the default locale for users in the organisation, this controls date formats, grammar, currency, and so on." -->
                <!--           required /> -->
            </v-window-item>

            <v-window-item :key="1"
                           class="organisation-form__tab-content">
                <div>
                    <h4>
                        Orbital Witness
                    </h4>
                    <v-select v-model="selectedOrganisation.pricingModelId"
                              :item-title="getPricingModelItemText"
                              :items="pricingModels"
                              data-test="organisation-form-dropdown-pricing-model"
                              item-value="id"
                              :label="`* ${ $t('admin.organisation.label.pricingModel') }`"
                              required />
                    <v-checkbox v-model="selectedOrganisation.hasTitleSummaryCharge"
                                color="primary"
                                data-test="organisation-form-checkbox-title-summary-charge"
                                hide-details
                                :label="$t('admin.organisation.label.registerPrice')" />
                    <v-text-field v-model="selectedOrganisation.titleSummaryCharge"
                                  :disabled="!selectedOrganisation.hasTitleSummaryCharge"
                                  clearable
                                  data-test="organisation-form-input-title-summary-charge"
                                  :label="$t('admin.organisation.label.titleSummaryCharge')"
                                  min="1"
                                  prefix="£"
                                  type="number" />

                    <div v-if="selectedOrganisation.pricingModelId === pricingPlans.PER_MATTER_PRICING_MODEL"
                         style="margin-bottom:20px">
                        <v-text-field v-model="selectedOrganisation.pmpmMatterCharge"
                                      clearable
                                      data-test="organisation-form-input-pmpm-matter-charge"
                                      :label="$t('admin.organisation.label.pmpm')"
                                      min="1"
                                      prefix="£"
                                      type="number" />
                    </div>

                    <div v-if="selectedOrganisation.pricingModelId === pricingPlans.PAY_PER_CLICK"
                         style="margin-bottom:20px">
                        <v-text-field v-model="selectedOrganisation.ppcStandardCharge"
                                      clearable
                                      data-test="organisation-form-input-ppc-standard-charge"
                                      :label="$t('admin.organisation.label.ppc')"
                                      min="1"
                                      prefix="£"
                                      type="number" />
                    </div>
                </div>
                <div v-if="copilotProductEnabled">
                    <h4>Orbital Copilot</h4>
                    <v-select v-model="selectedOrganisation.copilotPricingModel"
                              :items="copilotPricingPlanNames"
                              data-test="organisation-form-dropdown-pricing-model"
                              :label="`* ${ $t('admin.organisation.label.pricingModel') }`"
                              required />
                </div>
            </v-window-item>

            <v-window-item :key="2"
                           class="organisation-form__tab-content">
                <v-text-field v-model="selectedOrganisation.chargesEmails"
                              autocomplete="ow"
                              :hint="$t('admin.organisation.hint.billingEmail')"
                              :label="$t('admin.organisation.label.billingEmail')"
                              required />
                <v-checkbox v-model="selectedOrganisation.preferChargesCSVWithoutHeader"
                            color="primary"
                            hide-details
                            :label="$t('admin.organisation.label.withoutColumnHeadersCsvCharges')" />
                <v-checkbox v-model="selectedOrganisation.preferChargesLandRegistryCSVFormat"
                            color="primary"
                            hide-details
                            :label="$t('admin.organisation.label.hmlrCsvCharges')" />
                <v-checkbox v-model="selectedOrganisation.preferChargesUserFullNameRatherThanEmail"
                            color="primary"
                            hide-details
                            :label="$t('admin.organisation.label.fullNameInChargesEmail')" />
                <v-checkbox v-if="selectedOrganisation.preferChargesLandRegistryCSVFormat"
                            v-model="selectedOrganisation.preferChargesCSVSectionNameForOffice"
                            color="primary"
                            hide-details
                            :label="$t('admin.organisation.label.officeDefaultSectionName')" />
                <v-text-field v-model="selectedOrganisation.preferChargesCSVMatterCodeSeparator"
                              autocomplete="ow"
                              :hint="$t('admin.organisation.hint.matterCodeSeparator')"
                              :label="$t('admin.organisation.label.matterCodeSeparator')"
                              required />
            </v-window-item>

            <v-window-item :key="3"
                           class="organisation-form__tab-content">
                <v-checkbox v-model="selectedOrganisation.enableFeatureHideableMatterSidePanel"
                            color="primary"
                            hide-details
                            :label="$t('admin.organisation.label.hideableMatterPanel')" />
                <v-checkbox v-model="selectedOrganisation.usesClientCodes"
                            color="primary"
                            hide-details
                            :label="$t('admin.organisation.label.usesClientCodes')" />
                <v-checkbox v-model="selectedOrganisation.mandatoryMatterCodes"
                            color="primary"
                            hide-details
                            :label="$t('admin.organisation.label.mandatoryMatterCodes')" />
                <v-checkbox v-model="selectedOrganisation.canShareMattersWithWholeOrg"
                            color="primary"
                            :label="$t('admin.organisation.label.canShareMattersWithWholeOrg')" />
                <v-text-field v-model="selectedOrganisation.matterCodeFormat"
                              :label="$t('admin.organisation.label.matterCodeFormat')"
                              :rules="[v => v ? v.length <= 100 : true]"
                              :hint="$t('admin.organisation.hint.matterCodeFormat')"
                              :error-messages="lengthErrorMessage(selectedOrganisation.matterCodeFormat)" />
                <v-text-field v-model="selectedOrganisation.matterCodeMask"
                              :label="$t('admin.organisation.label.matterCodeMask')"
                              :rules="[v => v ? v.length <= 100 : true]"
                              :hint="$t('admin.organisation.hint.matterCodeMask')"
                              :error-messages="lengthErrorMessage(selectedOrganisation.matterCodeMask)" />
                <v-text-field v-model="selectedOrganisation.clientCodeFormat"
                              :label="$t('admin.organisation.label.clientCodeFormat')"
                              :rules="[v => v ? v.length <= 100 : true]"
                              :error-messages="lengthErrorMessage(selectedOrganisation.clientCodeFormat)" />
                <v-select v-model="selectedOrganisation.ssoOption"
                          :item-title="getSsoOptionText"
                          :items="ssoItems"
                          item-value="id"
                          :label="$t('admin.organisation.label.ssoOption')"
                          required />
                <v-text-field v-show="!isSsoDisabled"
                              v-model="selectedOrganisation.ssoProviderId"
                              :label="$t('admin.organisation.label.ssoProviderId')"
                              :disabled="isSsoDisabled"
                              :required="isSsoEnabled" />
                <v-combobox v-model="selectedOrganisation.validEmailDomains"
                            hide-selected
                            :hint="$t('admin.organisation.hint.emailDomains')"
                            :label="$t('admin.organisation.label.emailDomains')"
                            multiple
                            closable-chips />
            </v-window-item>
            <v-window-item :key="4"
                           class="organisation-form__tab-content">
                <v-checkbox v-for="product in selectedOrganisation.products"
                            :key="product.id"
                            v-model="product.availableToOrganisation"
                            color="primary"
                            hide-details
                            :label="product.name" />
                <v-checkbox v-if="showEnableNewProductsForUsersCheckbox"
                            v-model="enableNewProductsForUsers"
                            color="primary"
                            hide-details
                            label="Enable the new product(s) for all users in the organisation" />
            </v-window-item>
            <v-window-item :key="5"
                           class="organisation-form__tab-content">
                <v-checkbox v-model="isPlanningGrandfathered"
                            color="primary"
                            hide-details
                            :label="$t('admin.organisation.label.planning')" />
                <v-checkbox v-model="isFindNearbyGrandfathered"
                            color="primary"
                            hide-details
                            :label="$t('admin.organisation.label.findNearby')" />
                <v-checkbox v-model="isShapefileExportGrandfathered"
                            color="primary"
                            hide-details
                            :label="$t('admin.organisation.label.shapefileExport')" />
            </v-window-item>
        </v-window>

        <div class="organisation-errors">
            <p v-for="(error, index) in selectedOrganisationErrors"
               :key="index"
               class="error-text">
                {{
                    error
                }}
            </p>
            <p v-if="selectedOrganisation.error"
               class="error-text">
                {{ $t('admin.organisation.organisationCreateError', { errorMessage: selectedOrganisation.errorMessage }) }}
            </p>
        </div>
        <div class="organisation-form__actions">
            <v-btn color="primary"
                   variant="text"
                   @click="onCancelClick">
                Cancel
            </v-btn>
            <v-btn :disabled="!customValidatorsValid || selectedOrganisationErrors.length > 0 || loading"
                   :loading="loading"
                   color="primary"
                   data-test="organisation-form-button-submit"
                   @click="submitForm">
                {{ isEdit ? $t('action.update') : $t('action.add') }}
            </v-btn>
        </div>
    </div>
</template>

<script>
    import { mapActions,
             mapMutations,
             mapState } from "vuex"

    import { GrandfatheredModules } from "@/components/admin/organisations/grandfathered-modules.enum"
    import { ORGANISATION_INDUSTRIES } from "@/consts/organisation-industries"
    import { ORGANISATION_STATUSES } from "@/consts/organisation-status"
    import {
        copilotPricingPlanNames,
        pricingPlanNames,
        pricingPlans,
    } from "@/consts/pricing-plans.js"
    import { ssoOptionNames,
             ssoOptions } from "@/consts/sso-types"
    import i18n from "@/plugins/i18n"
    import {
        CREATE_ORGANISATION,
        LOAD_LIST,
        LOAD_PRICING_MODELS,
        MUTATE_SELECTED_ORGANISATION,
        RELOAD_PRODUCTS,
        UPDATE_ORGANISATION,
    } from "@/store/modules/organisation/types"
    import { isNullOrEmpty } from "@/utils/array-utils"
    import { isNullOrWhitespace } from "@/utils/string-utils"

    export default {
        name: 'OrganisationForm',

        props: {
            isEdit: {
                type: Boolean,
                required: false,
            },
        },
        emits: ['on-cancel-click', 'after-submit'],

        data() {
            return {
                activeTab: 0,
                loading: false,
                pricingPlans,
                pricingPlanNames,
                copilotPricingPlanNames,
                ssoOptionNames,
                industryItems: ORGANISATION_INDUSTRIES,
                statusItems: ORGANISATION_STATUSES,
                enableNewProductsForUsers: false,
                initialProductIds: [],
                isPlanningGrandfathered: false,
                isFindNearbyGrandfathered: false,
                isShapefileExportGrandfathered: false,
                rules: {
                    onlyAlpha: value => {
                        // NOTE: Regular expression to match alphanumeric characters (including accents), spaces and some special characters
                        // NOTE2: Special characters allowed: . , ; & - ( ) ' [ ]
                        const pattern = /^[\p{L}\p{N} .,;&\-()']+$/u
                        return pattern.test(value) || i18n.global.t('admin.organisation.rules.onlyAlpha')
                    },
                },
            }
        },

        computed: {
            ...mapState({
                selectedOrganisation: state => state.organisation.selectedOrganisation,
                pricingModels: state => state.organisation.pricingModels,
                availableProducts: state => state.organisation.availableProducts,
            }),

            customValidatorsValid() {
                return this.rules.onlyAlpha(this.selectedOrganisation.name) === true
            },

            selectedOrganisationErrors() {
                const errors = []
                if (isNullOrWhitespace(this.selectedOrganisation.name)) {
                    errors.push('Organisation name required')
                }

                if (isNullOrWhitespace(this.selectedOrganisation.industry)) {
                    errors.push('Select the industry the organisation operates in')
                }

                if (isNullOrWhitespace(this.selectedOrganisation.status)) {
                    errors.push('Select the current status of the organisation')
                }

                if (isNullOrWhitespace(this.selectedOrganisation.pricingModelId)) {
                    errors.push('Pricing model is required')
                }

                if (!this.isEdit && isNullOrWhitespace(this.selectedOrganisation.sectionAddressHouseNumber)) {
                    errors.push('Default section house/building number is required')
                }

                if (this.selectedOrganisation.pricingModelId === this.pricingPlans.PAY_PER_CLICK) {
                    if (this.selectedOrganisation.ppcStandardCharge == null) {
                        errors.push('Pay-per-click pricing model but no standard charge specified')
                    }
                }

                if (this.selectedOrganisation.hasTitleSummaryCharge === true && this.selectedOrganisation.titleSummaryCharge == null) {
                    errors.push('If a title summary charge is not required, deselect the option for one.')
                }

                if (this.isSsoEnabled && isNullOrWhitespace(this.selectedOrganisation.ssoProviderId)) {
                    errors.push('You need to enter SSO Provider ID if the SSO Forced option is selected')
                }

                if (this.isSsoEnabled && isNullOrEmpty(this.selectedOrganisation.validEmailDomains)) {
                    errors.push('You need to enter the email domains if the SSO Forced option is selected')
                }

                if(this.selectedOrganisation.regions?.length === 0) {
                    errors.push('You need to select at least one region')
                }

                if(this.selectedOrganisation.maxPremiumUsers < this.selectedOrganisation.activePremiumUsersCount) {
                    errors.push('New maximum premium users must be greater than or equal to the current number of premium users.')
                }

                if(this.selectedOrganisation.maxStandardUsers < this.selectedOrganisation.activeStandardUsersCount) {
                    errors.push('New maximum standard users must be greater than or equal to the current number of standard users.')
                }
                return errors
            },

            isSsoDisabled() {
                return this.selectedOrganisation.ssoOption === ssoOptions.SSO_DISABLED
            },
            isSsoEnabled() {
                return this.selectedOrganisation.ssoOption !== ssoOptions.SSO_DISABLED
            },
            ssoItems() {
                return Object.entries(this.ssoOptionNames).map(([key, value]) => {
                    return {
                        id: Number(key),
                        text: value,
                    }
                })
            },

            showEnableNewProductsForUsersCheckbox() {
                if(!this.isEdit) return false

                // If a product has been enabled, show the option to enable for all users in that organisation.
                return (this.selectedOrganisation.products ?? [])
                    .filter(p => p.availableToOrganisation)
                    .map(p => p.id)
                    .some(id => !this.initialProductIds.includes(id)) ?? false
            },

            copilotProductEnabled() {
                return this.selectedOrganisation.products.some(p => p.name === "Orbital Copilot" && p.availableToOrganisation)
            },
        },

        async mounted() {
            await this.loadPricingModels()
            await this.reloadProducts()
            if(this.isEdit) {
                this.initialProductIds = this.selectedOrganisation.products
                    .filter(p => p.availableToOrganisation)
                    .map(p => p.id)
                this.isPlanningGrandfathered = this.selectedOrganisation.grandfatheredModules.includes(GrandfatheredModules.PLANNING)
                this.isFindNearbyGrandfathered = this.selectedOrganisation.grandfatheredModules.includes(GrandfatheredModules.FIND_NEARBY)
                this.isShapefileExportGrandfathered = this.selectedOrganisation.grandfatheredModules.includes(GrandfatheredModules.SHAPEFILE_EXPORT)
            } else {
                const availableProducts = this.availableProducts.map(x => {
                    return {
                        ...x,
                        availableToOrganisation: false,
                    }
                })
                this.mutateSelectedOrganisation({
                    products: availableProducts,
                })
            }
        },

        methods: {
            ...mapActions('organisation', {
                getOrganisations: LOAD_LIST,
                updateOrganisation: UPDATE_ORGANISATION,
                createOrganisation: CREATE_ORGANISATION,
                loadPricingModels: LOAD_PRICING_MODELS,
                reloadProducts: RELOAD_PRODUCTS,
            }),

            ...mapMutations('organisation', {
                mutateSelectedOrganisation: MUTATE_SELECTED_ORGANISATION,
            }),

            onCancelClick() {
                this.$emit('on-cancel-click')
            },

            getPricingModelItemText(item) {
                return this.pricingPlanNames[item.id]
            },

            getSsoOptionText(item) {
                return this.ssoOptionNames[item.id]
            },

            lengthErrorMessage(val) {
                if (!val || val.length <= 100) {
                    return []
                }

                return ['The value must be less or equal than 100 characters']
            },

            getGrandfatheredModulesForRequest() {
                let grandfatheredModules = []
                if(this.isPlanningGrandfathered){
                    grandfatheredModules.push(GrandfatheredModules[GrandfatheredModules.PLANNING])
                }
                if(this.isFindNearbyGrandfathered){
                    grandfatheredModules.push(GrandfatheredModules[GrandfatheredModules.FIND_NEARBY])
                }
                if(this.isShapefileExportGrandfathered){
                    grandfatheredModules.push(GrandfatheredModules[GrandfatheredModules.SHAPEFILE_EXPORT])
                }
                return grandfatheredModules
            },

            async submitForm() {
                // The valid email domain should not include the "@".
                this.selectedOrganisation.validEmailDomains =
                    this.selectedOrganisation?.validEmailDomains?.map(e => e.replace(/@/g, ""))

                this.loading = true
                this.selectedOrganisation.error = false
                this.selectedOrganisation.errorMessage = null
                const request = {
                    id: this.isEdit ? this.selectedOrganisation.id : undefined,
                    name: this.selectedOrganisation.name.trim(),
                    maxUsers: this.selectedOrganisation.maxUsers,
                    maxStandardUsers: this.selectedOrganisation.maxStandardUsers,
                    maxPremiumUsers: this.selectedOrganisation.maxPremiumUsers,
                    pricingModelId: this.selectedOrganisation.pricingModelId,
                    sectionName: this.isEdit ? undefined : this.selectedOrganisation.sectionName,
                    sectionAddressHouseNumber: this.isEdit ? undefined : this.selectedOrganisation.sectionAddressHouseNumber,
                    sectionAddressStreet: this.isEdit ? undefined : this.selectedOrganisation.sectionAddressStreet,
                    sectionAddressLocality: this.isEdit ? undefined : this.selectedOrganisation.sectionAddressLocality,
                    sectionAddressTown: this.isEdit ? undefined : this.selectedOrganisation.sectionAddressTown,
                    sectionAddressPostcode: this.isEdit ? undefined : this.selectedOrganisation.sectionAddressPostcode,
                    defaultSectionId: this.isEdit ? this.selectedOrganisation.defaultSectionId : undefined,
                    sections: this.isEdit ? this.selectedOrganisation.sections : undefined,
                    chargesEmails: this.selectedOrganisation.chargesEmails,
                    usesClientCodes: this.selectedOrganisation.usesClientCodes,
                    mandatoryMatterCodes: this.selectedOrganisation.mandatoryMatterCodes,
                    preferChargesCSVWithoutHeader: this.selectedOrganisation.preferChargesCSVWithoutHeader,
                    preferChargesLandRegistryCSVFormat: this.selectedOrganisation.preferChargesLandRegistryCSVFormat,
                    preferChargesUserFullNameRatherThanEmail: this.selectedOrganisation.preferChargesUserFullNameRatherThanEmail,
                    preferChargesCSVSectionNameForOffice: this.selectedOrganisation.preferChargesLandRegistryCSVFormat ? this.selectedOrganisation.preferChargesCSVSectionNameForOffice : null,
                    preferChargesCSVMatterCodeSeparator: this.selectedOrganisation.preferChargesCSVMatterCodeSeparator,
                    ppcStandardCharge: this.selectedOrganisation.pricingModelId === this.pricingPlans.PAY_PER_CLICK ? this.selectedOrganisation.ppcStandardCharge : null,
                    pmpmMatterCharge: this.selectedOrganisation.pricingModelId === this.pricingPlans.PER_MATTER_PRICING_MODEL ? this.selectedOrganisation.pmpmMatterCharge : null,
                    titleSummaryCharge: this.selectedOrganisation.hasTitleSummaryCharge ? this.selectedOrganisation.titleSummaryCharge : null,
                    enableFeatureHideableMatterSidePanel: this.selectedOrganisation.enableFeatureHideableMatterSidePanel,
                    ssoOption: this.selectedOrganisation.ssoOption,
                    ssoProviderId: this.selectedOrganisation.ssoProviderId,
                    validEmailDomains: this.selectedOrganisation.validEmailDomains?.filter(d => !isNullOrWhitespace(d)).map(d => d.trim()) ?? [],
                    status: this.selectedOrganisation.status,
                    industry: this.selectedOrganisation.industry,
                    matterCodeFormat: this.selectedOrganisation.matterCodeFormat,
                    matterCodeMask: this.selectedOrganisation.matterCodeMask,
                    clientCodeFormat: this.selectedOrganisation.clientCodeFormat,
                    canShareMattersWithWholeOrg: this.selectedOrganisation.canShareMattersWithWholeOrg,
                    productIds: this.selectedOrganisation.products.filter(x=> x.availableToOrganisation).map(p => p.id),
                    enableNewProductsForUsers: this.enableNewProductsForUsers,
                    regions: this.selectedOrganisation.regions,
                    defaultLocale: this.selectedOrganisation.defaultLocale,
                    grandfatheredModules: this.getGrandfatheredModulesForRequest(),
                }

                const response = this.isEdit ? await this.updateOrganisation(request) : await this.createOrganisation(request)

                if (response?.ok) {
                    await this.getOrganisations()
                    this.$emit('after-submit')
                } else if (response?.data) {
                    this.selectedOrganisation.error = true
                    this.selectedOrganisation.errorMessage = response.data.join('. ')
                } else {
                    this.selectedOrganisation.error = true
                }

                this.loading = false
            },
        },
    }
</script>

<style lang="scss">
@import './organisation-form';
</style>

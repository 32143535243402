// @/stores/asset-monitoring/index.ts
import {
    acceptHMRUpdate,
    defineStore,
} from 'pinia'

import {
    IGetNotificationResponseItem,
    INotificationMessageArguments,
    INotificationSettings,
} from '@/api/notifications.api'
import {ReadStatus} from "@/components/asset-monitoring/read-status.enum"

import amActions from './actions'
import amGetters from './getters'

/**
 * Represents the state of the asset monitoring store.
 */
export interface IAssetMonitoringState {
    isLoading: boolean
    notifications: Array<IGetNotificationResponseItem>
    totalResults: number
    currentPageIndex: number
    pageSize: number
    sortBy: string,
    filterText?: string
    selectedTitleNumbers?: Array<string>,
    selectedNotificationTypes?: Array<string>,
    selectedNotificationSubTypes?: Array<string>,
    notificationSettings: INotificationSettings,
    notificationMessages: Array<INotificationMessageArguments>,
    areNotificationSettingsLoaded: boolean,
    showOnlyUnread?: boolean,
    isLoadingSettings: boolean,
}

export const assetMonitoringOptions = {
    state: (): IAssetMonitoringState => {
        return {
            isLoading: false,
            notifications: [] as Array<IGetNotificationResponseItem>,
            totalResults: 0,
            currentPageIndex: 0,
            pageSize: 100,
            sortBy: null, // Descending date is the default sort order
            filterText: '',
            selectedTitleNumbers: [],
            selectedNotificationTypes: [],
            selectedNotificationSubTypes: [],
            notificationSettings: {
                isActive: false,
                trackDaylist: false,
                trackOcda: false,
                trackOwnership: false,
                trackBoundary: false,
                trackCompaniesHouse: false,
            },
            areNotificationSettingsLoaded: false,
            notificationMessages: [] as Array<INotificationMessageArguments>,
            showOnlyUnread: false,
            isLoadingSettings: false,
        }
    },
    getters: { ...amGetters },
    actions: { ...amActions },
}

/**
 * The `useAssetMonitoringStore` variable is a store created using the `defineStore` function.
 * It is used to manage the state, getters, and actions related to asset monitoring.
 */
export const useAssetMonitoringStore = defineStore('assetMonitoring', assetMonitoringOptions)

// Enables Hot Module Replacement in Pinia
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAssetMonitoringStore, import.meta.hot))
}

<template>
    <div class="schedule-of-lease-record">
        <h3 class="schedule-of-lease-record__header headers-h3-card-heading"
            @click="expanded = !expanded">
            <ow-text-highlight class="schedule-of-lease-record__header--name"
                               :queries="[filter]"
                               @match-count-changed="nameSearchMatchCount = $event">
                {{ $t('titles.sonol.title') }}
            </ow-text-highlight>
            <v-icon v-if="!expanded">
                $chevron-down
            </v-icon>
            <v-icon v-else>
                $chevron-up
            </v-icon>
        </h3>
        <div v-for="(entry, index) in scheduleOfLeases"
             v-show="expanded"
             :key="index"
             class="schedule-of-lease-record__entry">
            <div class="schedule-of-lease-record__entry--code">
                <ow-text-highlight class="body-regular"
                                   :queries="[filter]"
                                   data-test="review-assistant-register-record-entry-code"
                                   @match-count-changed="indexSearchMatchCount[index] = $event">
                    {{ index + 1 }}
                </ow-text-highlight>
            </div>
            <section>
                <schedule-of-lease-record-entry :entry="entry"
                                                :search-filter="filter"
                                                :highlight-dates="highlightDates"
                                                @match-count-changed="entrySearchMatchCount[index] = $event"
                                                @scrolled="selectEntry(entry, index)" />
            </section>
        </div>
    </div>
</template>

<script lang="ts">
    import {              inject,
                          PropType } from 'vue'

    import OwTextHighlight from '@/components/core/ow-text-highlight.vue'
    import ScheduleOfLeaseRecordEntry from '@/components/review-assistant/schedule-of-lease-record-entry.vue'
    import { IScheduleOfLeaseEntry } from '@/interfaces/documents/schedule-of-lease-entry.interface'

    const EVENTS = {
        ENTRY_CLICKED: 'entry-clicked',
        MATCH_COUNT_CHANGED: 'match-count-changed',
    }

    export default {
        components: {
            OwTextHighlight,
            ScheduleOfLeaseRecordEntry,
        },
        props: {
            scheduleOfLeases: {
                type: Array as PropType<Array<IScheduleOfLeaseEntry>>,
                default: () => [],
            },

            highlightDates: {
                type: Boolean,
                required: false,
            },
        },

        emits: Object.values(EVENTS),
        setup() {
            const filterProvider = inject('FILTER_PROVIDER')
            return {
                filterProvider,
            }
        },

        data() {
            return {
                expanded: true,

                nameSearchMatchCount: 0,
                indexSearchMatchCount: new Array(this.scheduleOfLeases.length).fill(0),
                entrySearchMatchCount: new Array(this.scheduleOfLeases.length).fill(0),
            }
        },

        computed: {
            totalSearchMatchCount() {
                return this.nameSearchMatchCount +
                    this.indexSearchMatchCount.reduce((a, b) => a + b, 0) +
                    this.entrySearchMatchCount.reduce((a, b) => a + b, 0)
            },
            filter() {
                return this.filterProvider ?? ''
                    .toLowerCase()
                    .replace(/\W/g, ' ')
            },
        },

        watch: {
            totalSearchMatchCount(value: number) {
                this.$emit(EVENTS.MATCH_COUNT_CHANGED, value)
            },
        },

        methods: {
            selectEntry(entry: IScheduleOfLeaseEntry, index: number) {
                this.$emit(EVENTS.ENTRY_CLICKED, entry, String(index))
            },
        },
    }
</script>

<style lang="scss">
    @import 'schedule-of-lease-record.scss';
</style>

<template>
    <section class="unauthorised">
        <nav class="unauthorised__nav">
            <div class="unauthorised__nav--logo"
                 data-global-nav-item="home"
                 data-test="global-nav-unauthorised-logo"
                 data-track="GLOBAL-NAV - Unauthorised logo">
                <ow-icon-ow-logo data-test="global-nav-unauthorised-selected"
                                 class="colour-change"
                                 height="38"
                                 width="40" />
            </div>
        </nav>
        <section class="unauthorised__content">
            <article>
                <ow-icon-ow-logo data-test="global-nav-unauthorised-selected"
                                 class="colour-change"
                                 height="120"
                                 width="128" />
                <div>
                    <h3 class="headers-display">
                        {{ t('unauthorised.title') }}
                    </h3>
                    <p class="body-regular">
                        {{ t('unauthorised.paragraph1') }}
                    </p>
                    <p class="body-regular">
                        {{ t('unauthorised.paragraph2') }}
                    </p>
                    <div class="unauthorised__content--actions">
                        <ow-button is-primary
                                   data-test="unauthorised-witness-btn"
                                   data-track="Unauthorised Page: Witness button"
                                   @click="onWitnessBtnClick">
                            {{ t('buttons.witness') }}
                        </ow-button>
                        <ow-button is-secondary
                                   data-test="unauthorised-copilot-btn"
                                   data-track="Unauthorised Page: Copilot button"
                                   @click="onCopilotBtnClick">
                            {{ t('buttons.copilot') }}
                        </ow-button>
                        <ow-button is-secondary
                                   data-test="unauthorised-support-btn"
                                   data-track="Unauthorised Page: Support button"
                                   @click="onSupportBtnClick">
                            {{ t('buttons.support') }}
                        </ow-button>
                    </div>
                </div>
            </article>
        </section>
    </section>
</template>

<script setup lang="ts">
    import { useI18n } from 'vue-i18n'
    import {useRouter} from "vue-router"

    import OwIconOwLogo from '@/components/core/icons/ow-icon-ow-logo.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import {Route} from "@/enums/route.enum"

    const { t } = useI18n()
    const router = useRouter()

    const onCopilotBtnClick = () => {
        window.location.replace('https://copilot.orbitalwitness.com')
    }

    const onWitnessBtnClick = async () => {
        await router.push({
            name: Route.Homepage,
        })
    }

    const onSupportBtnClick = () => {
        if (window.Intercom) {
            window.Intercom('showNewMessage')
        }
    }
</script>

<style scoped lang="scss">
    @import './unauthorised';
</style>

<template>
    <div class="side-nav-tab">
        <side-nav-tab-button v-model="disabled"
                             :has-sections="hasSections"
                             :selected="selected"
                             :title="title"
                             :feature-id="featureId"
                             :is-loading="isLoading"
                             class="side-nav-tab__button"
                             tabindex="0"
                             @click="$emit('click')">
            <div class="caption-highlight d-flex align-center text-no-wrap">
                {{ title }}
            </div>
        </side-nav-tab-button>
        <div v-if="!disabled && selected && hasSections"
             class="side-nav-tab__section px-6 py-4 d-flex flex-column">
            <ow-loading-skeleton v-if="isLoading"
                                 border-radius
                                 class="side-nav-tab__section-header w-50" />
            <div v-else
                 class="side-nav-tab__section-header d-flex text-no-wrap label-caps-smallest">
                {{ title }}
            </div>
            <div class="side-nav-tab__content d-flex flex-column mt-2 gr-1">
                <slot />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref } from "vue"

    import OwLoadingSkeleton from "@/components/core/ow-loading-skeleton.vue"
    import SideNavTabButton from "@/components/side-nav/side-nav-tab-button.vue"
    import { FeatureId } from "@/composables/use-licence-controller"

    const disabled = ref()

    const props = defineProps<{
        title?: string
        hasSections: boolean
        featureId?: FeatureId
        isLoading?: boolean
    }>()
    const emits = defineEmits<{
        (e: 'click'): void,
    }>()
    const selected = defineModel<boolean>({
        default: false,
    })
</script>

<style scoped lang="scss">
@import './side-nav-tab';
</style>


export const DocumentType = {
    INVALID: 0,
    REGISTER: 1,
    ABSTRACT: 10,
    AGREEMENT: 20,
    ASSENT: 30,
    ASSIGNMENT: 40,
    CHARGE: 50,
    CONVEYANCE: 60,
    DEED: 70,
    INDENTURE: 80,
    LEASE: 90,
    LICENCE: 100,
    PLAN: 110,
    SUBCHARGE: 120,
    TRANSFER: 130,
    OTHER: 140,
    COMMONHOLD_COMMUNITY_STATEMENT: 150,
    MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION: 160,
    SURRENDER_OF_DEVELOPMENT_RIGHTS: 170,
    TERMINATION_DOCUMENT: 180,
}

/**
 * @param {string} docTypeString
 * @param {string} fallbackDocumentTypeDescription
 */
export const convertStringToDocumentType = (docTypeString, fallbackDocumentTypeDescription = null) => {
    // If the document type is not known, we will try to map it to a known type used for ordering.
    if (DocumentType[docTypeString.toUpperCase()]) {
        return DocumentType[docTypeString.toUpperCase()]
    } else if (fallbackDocumentTypeDescription) {
        for (const key in DocumentType) {
            if (fallbackDocumentTypeDescription.toUpperCase().includes(key.toUpperCase())) {
                return DocumentType[key]
            }
        }
    }
    return DocumentType.INVALID
}

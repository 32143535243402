import {
    computed,
    ref,
} from "vue"
import { useStore } from "vuex"

import SecurityApi from "@/api/security"
import { LicenceType } from "@/enums/licenceType"
import { isNullOrEmpty } from "@/utils/array-utils"

export enum FeatureId {
    Planning = 'planning',
    Nearby = 'nearby',
    ShapefileExport = 'shapefileExport',
    Alerts = 'alerts',
}

export interface LicencedFeature {
    id: FeatureId,
    licenceType: LicenceType,
}
const useLicenceController = (args: {
    debug?: boolean,
} = {
    debug: true,

}) => {
    const { debug } = args
    const store = useStore()
    const supportedLicenceTypes = computed(() => store.state?.config?.featureFlags?.supportedLicenceTypes ?? ["Standard"])
    const licenceTypeOptions = computed(() => {
        return Object.keys(LicenceType).map(key => ({
            label: key,
            id: LicenceType[key],
        })).filter(option => supportedLicenceTypes.value.includes(option.id))
    })
    const active = computed(() => licenceTypeOptions.value.length > 1)
    const licenceType = ref<string>()
    const grandfatheredModules = ref<string[]>()
    const accessTokenData = ref<{
        ow_licence_type: string,
        ow_grandfathered_modules: string[],
    }>()
    const convertLicenceTypeIdToGrandfatheredModule = (id: string) => {
        switch (id) {
            case FeatureId.Planning:
                return 'PLANNING'
            case FeatureId.Nearby:
                return 'FIND_NEARBY'
            case FeatureId.ShapefileExport:
                return 'SHAPEFILE_EXPORT'
            default:
                return null
        }
    }

    const checkLicenceType = (feature: LicencedFeature) => {
        const index = licenceTypeOptions.value.findIndex(item => item.id === feature.licenceType)
        const licenceIndex = licenceTypeOptions.value.findIndex(item => item.id === licenceType.value)
        if (index === -1 || licenceIndex === -1) {
            return false
        }
        if (licenceIndex >= index) {
            if (debug) {
                // eslint-disable-next-line
                console.debug(`User has access to ${ feature.id } as they have ${ licenceType.value }`)
            }
            return true
        }
    }

    const checkGrandfatheredModules = (feature: LicencedFeature) => {
        if (isNullOrEmpty(grandfatheredModules.value)) {
            return false
        }
        const module = convertLicenceTypeIdToGrandfatheredModule(feature.id)
        const hasGrandfatheredAccess = grandfatheredModules.value.includes(module)
        if (hasGrandfatheredAccess) {
            if (debug) {
                // eslint-disable-next-line
                console.debug(`User has access to ${ feature.id } as they have grandfathered access to ${ module }`)
            }
            return true
        }
    }

    const userHasAccess = async (featureId: FeatureId) => {
        const feature = licencedFeatures[featureId]
        if (!feature) {
            trace(`Feature ${ featureId } is not a licenced feature`)
            return false
        }
        // if we have no active licence types, return true
        if (!active.value) {
            trace(`User has access to ${ feature.id } as there are no active licence types`)
            return true
        }

        // get the user access token data
        // if the user access token is provided, use that, otherwise get the user access token from the API
        const accessTokenData = await SecurityApi.getAccessTokenData()
        if (!accessTokenData) {
            trace(`User does not have a valid access token`)
            return false
        }

        // set the licence type and grandfathered modules
        licenceType.value = accessTokenData.licenceType
        grandfatheredModules.value = accessTokenData.grandfatheredModules
        trace(`User has licence type ${ licenceType.value } and grandfathered modules ${ isNullOrEmpty(grandfatheredModules.value) ? 'None' : grandfatheredModules.value }`)

        // if the user has the same licence type or higher, return true
        if (checkLicenceType(feature)) {
            return true
        }

        // if the user has grandfathered access to the module, return true
        if (checkGrandfatheredModules(feature)) {
            return true
        }

        trace(`User does not have access to ${ feature.id }`)
        return false
    }

    const trace = (message: string) => {
        if (debug) {
            // eslint-disable-next-line
            console.debug(message)
        }
    }

    const licencedFeatures = {
        planning: {
            id: FeatureId.Planning,
            licenceType: LicenceType.Premium,
        },
        nearby: {
            id: FeatureId.Nearby,
            licenceType: LicenceType.Premium,
        },
        shapefileExport: {
            id: FeatureId.ShapefileExport,
            licenceType: LicenceType.Premium,
        },
        alerts: {
            id: FeatureId.Alerts,
            licenceType: LicenceType.Premium,
        },
    }

    return {
        active,
        accessTokenData,
        licenceTypeOptions,
        licencedFeatures,
        userHasAccess,
    }
}

export default useLicenceController

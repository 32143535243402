import pluralize from 'pluralize'

import {
    dynamicSort,
    isNullOrEmpty,
    unique,
} from '@/utils/array-utils'
import {
    add,
    differenceInDays,
    differenceInMonths,
    differenceInYears,
} from '@/utils/date-utils'
import { gbpCurrencyFormatter } from '@/utils/number-utils'
import {
    camelCaseToSentenceCase,
    isNullOrWhitespace,
} from '@/utils/string-utils'
import {
    QUALITY_FROM_TENURE,
    TENURE_TYPE_FROM_TENURE,
} from '@/utils/title-enums'

const getTenureType = (title) => {
    if (title.tenureType) {
        // Tenure type may be populated from the register
        return camelCaseToSentenceCase(title.tenureType)
    } else if (title.ccodOcodTitle?.tenure) {
        // Otherwise try CCOD
        return TENURE_TYPE_FROM_TENURE[title.ccodOcodTitle.tenure]
    }
    return undefined
}

const getQualityOfTenure = (title) => {
    if (title.qualityOfTenure) {
        // Tenure type may be populated from the register
        return camelCaseToSentenceCase(title.qualityOfTenure)
    } else if (title.ccodOcodTitle?.tenure) {
        // Otherwise try CCOD
        return QUALITY_FROM_TENURE[title.ccodOcodTitle.tenure]
    }
    return undefined
}

const joinArray = (proprietorNameArray) => {
    if (proprietorNameArray) {
        return proprietorNameArray.join('. ')
    }
    return undefined
}

const getCompanyHousePropertyArray = (title, withNumbering = true) => {
    let result
    if (!isNullOrEmpty(title.companiesHouseData)) {
        result = title.companiesHouseData.map((o, i) => {
            return {
                index: i,
                name: o.companyName,
                status: o.companyStatus,
                address: o.displayAddress,
                natureOfBusiness: o.displayNatureOfBusiness,
                incorporatedOn: o.incorporatedOn,
                companyType: o.companyType,
            }
        })
    }

    let nameResult
    let statusResult
    let addressResult
    let incorporatedOnResult
    let natureOfBusinessResult
    let companyTypeResult

    if (result) {
        result.sort(dynamicSort('name'))
        if (result.length > 1) {
            if (withNumbering) {
                nameResult = result.map(r => `(${ r.index + 1 }) ${ r.name }`)
                statusResult = result.map(r => `(${ r.index + 1 }) ${ r.status }`)
                addressResult = result.map(r => `(${ r.index + 1 }) ${ r.address }`)
                incorporatedOnResult = result.map(r => `(${ r.index + 1 }) ${ r.incorporatedOn }`)
                natureOfBusinessResult = result.map(r => `(${ r.index + 1 }) ${ r.natureOfBusiness }`)
                companyTypeResult = result.map(r => `(${ r.index + 1 }) ${ r.companyType }`)
            } else {
                nameResult = result.map(r => r.name)
                statusResult = result.map(r => r.status)
                addressResult = result.map(r => r.address)
                incorporatedOnResult = result.map(r => r.incorporatedOn)
                natureOfBusinessResult = result.map(r => r.natureOfBusiness)
                companyTypeResult = result.map(r => r.companyType)
            }
        } else {
            nameResult = [result[0].name]
            statusResult = [result[0].status]
            addressResult = [result[0].address]
            incorporatedOnResult = [result[0].incorporatedOn]
            natureOfBusinessResult = [result[0].natureOfBusiness]
            companyTypeResult = [result[0].companyType]
        }
    }

    return {
        names: nameResult,
        statuses: statusResult,
        addresses: addressResult,
        incorporatedOns: incorporatedOnResult,
        naturesOfBusinesses: natureOfBusinessResult,
        companyTypes: companyTypeResult,
    }
}

const getProprietorNameArray = (title, withNumbering = true) => {
    let result
    if (title?.proprietorshipParties?.length > 0) {
        result = title?.proprietorshipParties?.map(p => {
            return {
                index: p.index,
                name: p.displayName,
            }
        })
    } else if (title?.ccodOcodOwners?.length > 0) {
        result = title.ccodOcodOwners.map((o, i) => {
            return {
                index: i,
                name: o.proprietorName,
            }
        })
    }
    if (result) {
        result.sort(dynamicSort('name'))
        if (result.length > 1) {
            if (withNumbering) {
                result = result.map(r => `(${ r.index + 1 }) ${ r.name }`)
            } else {
                result = result.map(r => r.name)
            }
        } else {
            return [result[0].name]
        }
    }

    return result
}

const getTradingAsNameArray = (title, withNumbering = true, distinctValues = true) => {
    let result = title?.proprietorshipParties?.filter(x => x.tradingName != null)?.map((o, i) => {
        return {
            index: i,
            name: o.tradingName,
        }
    })
    if(!result || result.length <= 0){
        return undefined
    }
    if (distinctValues){
        const distinctResult = Array.from(new Set(result.map(o => o.name)))
            .map(name => {
                return result.find(o => o.name === name)
            })
        result = distinctResult
    }
    result.sort(dynamicSort('name'))
    if (result.length > 1) {
        if(result)
            if (withNumbering) {
                result = result.map(r => `(${ r.index + 1 }) ${ r.name }`)
            } else {
                result = result.map(r => r.name)
            }
    } else {
        return [result[0].name]
    }

    return result
}

const getRegisterOwnerName = (title) => {
    if (!isNullOrEmpty(title?.proprietorshipParties)) {
        return title?.proprietorshipParties?.map(p => p.displayName)
    }
    return undefined
}

const getCcodOcodOwnerName = (title) => {
    if (!isNullOrEmpty(title?.ccodOcodOwners)) {
        return title.ccodOcodOwners.map(o => o.proprietorName)
    }
    return undefined
}

const getCcodOcodMatchesOwner = (title) => {
    let registerNames = getRegisterOwnerName(title)
    const ccodOcodNames = getCcodOcodOwnerName(title)
    if (!isNullOrEmpty(registerNames) && !isNullOrEmpty(ccodOcodNames)) {
        registerNames = registerNames.map(n => n && n.toUpperCase())
        const titleIsFoundOnCcodOcod = ccodOcodNames.filter(co => co !== undefined)
        if (isNullOrEmpty(titleIsFoundOnCcodOcod)) {
            return undefined
        }
        const matchingNames = ccodOcodNames.filter(o => registerNames.includes(o))
        return matchingNames.length === ccodOcodNames.length
    }
    return undefined
}

const getProprietorAddress = (proprietorAddressArray) => {
    if (proprietorAddressArray) {
        return proprietorAddressArray.join('. ')
    }
    return undefined
}

const getProprietorAddressArray = (title) => {
    const addresses = []

    if (title?.proprietorshipParties?.length > 0) {
        if (title.proprietorshipParties.some(p => p.proprietorshipPartyAddresses)) {
            title.proprietorshipParties.forEach(party => {
                if (party.proprietorshipPartyAddresses) {
                    party.proprietorshipPartyAddresses.forEach(partyAddress => {
                        let address = partyAddress.addressLines.join(', ')
                        if (partyAddress.postcodeZone) {
                            address += `, ${ partyAddress.postcodeZone }`
                        }
                        const existingAddress = addresses.find(a => a.address == address)
                        if (existingAddress) {
                            existingAddress.partyIndexes.push(partyAddress.proprietorshipPartyIndex)
                        } else {
                            addresses.push({
                                partyIndexes: [partyAddress.proprietorshipPartyIndex],
                                address,
                            })
                        }
                    })
                }
            })

            const prefixAddressesWithPartyIndex = title.proprietorshipParties.length > 1 && addresses.length > 1

            return addresses.map(a => {
                if (prefixAddressesWithPartyIndex) {
                    return `(${ a.partyIndexes.map(i => i + 1).join(', ') }) ${ a.address }`
                } else {
                    return a.address
                }
            })
        }
    } else if (title?.ccodOcodOwners?.length > 0) {
        if (title.ccodOcodOwners.some(c => c.proprietorAddress1)) {
            title.ccodOcodOwners.forEach(owner => {
                const ccodAddresses = []
                if (owner.proprietorAddress1) {
                    ccodAddresses.push(owner.proprietorAddress1)
                }

                if (owner.proprietorAddress2) {
                    ccodAddresses.push(owner.proprietorAddress2)
                }

                if (owner.proprietorAddress3) {
                    ccodAddresses.push(owner.proprietorAddress3)
                }
                addresses.push(ccodAddresses.join('. '))
            })
            return unique(addresses)
        }
    }
    return undefined
}

const getRestrictions = (title, type) => {
    if (title?.restrictionDetails) {
        let restrictions
        if (type === undefined) {
            restrictions = title.restrictionDetails.map(d => {
                return d.entryText?.replace('RESTRICTION:', `${ camelCaseToSentenceCase(d.typeCode) }:`)
            })
        } else {
            restrictions = title.restrictionDetails.filter(d => d.typeCode === type)
                .map(d => d.entryText?.replace('RESTRICTION:', ''))
        }
        return restrictions.join(', ')
    }
    return undefined
}

const getRestrictionsCount = (title, type) => {
    if (title?.restrictionDetails && title?.editionDate) {
        if (type === undefined) {
            return title.restrictionDetails.length
        } else {
            return title.restrictionDetails.filter(d => d.typeCode === type).length
        }
    }
    return undefined
}

const getEntryText = (data) => {
    if (data) {
        const results = data.map(d => d.entryText)
        return results.join(', ')
    }
    return undefined
}

const getEntryNumberAndText = (data) => {
    if (data) {
        const results = data.map(d => `${ d.entryNumber } - ${ d.entryText }`)
        return results.join('\n')
    }
    return undefined
}

const getPricePaid = (data) => {
    if (data?.pricePaid !== null) {
        return gbpCurrencyFormatter.format(data.pricePaid)
    }
    return undefined
}

const getPropertyAddressesAndPostcodes = (title, titleAddress) => {
    const result = []
    if (title?.propertyAddress?.length > 0) {
        // Address from title register
        title.propertyAddress.forEach((addressItem) => {
            const addressesAndPostcode = {}
            addressesAndPostcode.addressLines = ''
            let addressString = `${ addressItem.addressLine?.join(', ') }`
            if (addressItem.postcodeZone) {
                // Append postcode if available
                addressString += `, ${ addressItem.postcodeZone }`
                addressesAndPostcode.postcode = addressItem.postcodeZone
            }

            if (!isNullOrWhitespace(addressString)) {
                addressesAndPostcode.addressLines = addressString
                // Push an empty string as postcode if we have addressLines and no
                addressesAndPostcode.postcode = addressesAndPostcode.postcode ?? ''
            }
            result.push(addressesAndPostcode)
        })
    } else if (title?.ccodOcodTitle && title?.ccodOcodTitle.propertyAddress) {
        // Address from CCOD/OCOD
        const addressesAndPostcode = {}
        addressesAndPostcode.addressLines = title?.ccodOcodTitle.propertyAddress
        addressesAndPostcode.postcode = title?.ccodOcodTitle.postcode ?? ''
        result.push(addressesAndPostcode)
    } else if (titleAddress && !isNullOrEmpty(titleAddress.addresses)) {
        const addressesAndPostcode = {}
        addressesAndPostcode.addressLines = titleAddress.addresses[0].address
        addressesAndPostcode.postcode = titleAddress.addresses[0].postcode ?? ''
        result.push(addressesAndPostcode)
    }
    // If we don't have an address we don't care to return a random postcode
    return result.length > 0 ? result : undefined
}

const getCompanyRegNo = (title) => {
    const fromRegister = title?.proprietorshipParties?.map(p => p.organizationCompanyRegistrationNumber) ?? []
    const fromCCOD = title?.ccodOcodOwners?.map(o => o.companyRegistrationNo) ?? []
    const result = unique(fromRegister.concat(fromCCOD)).filter(n => n !== null)
    return result?.join(', ') ?? undefined
}

const getOwnersMatch = (title, companyRegNo) => {
    if (!companyRegNo) {
        return
    }
    let allOwnersPresent = true
    const returnValue = companyRegNo.split(', ').every((num) => {
        let proprietor = title?.proprietorshipParties?.find(a => a.organizationCompanyRegistrationNumber === num)?.organizationName
        if (!proprietor) {
            proprietor = title?.ccodOcodOwners?.find(a => a.companyRegistrationNo === num)?.proprietorName
        }

        const companyProprietor = title?.companiesHouseData?.find(a => a.companyRegistrationNumber === num.padStart(8, '0'))?.companyName

        if (!proprietor || !companyProprietor) {
            allOwnersPresent = false
            return false
        }

        return proprietor.toUpperCase() === companyProprietor.toUpperCase()
    })
    if (!allOwnersPresent) {
        return false
    }
    return returnValue.toString()
}

const getLeaseParty = (data) => {
    let result = ''
    if (data !== null) {
        data.forEach(lease => {
            if (lease.leaseParties) {
                lease.leaseParties.forEach(leaseParty => {
                    if (leaseParty.privateIndividualForeNames) {
                        result += `${ leaseParty.privateIndividualForeNames } `
                    }
                    if (leaseParty.privateIndividualSurname) {
                        result += `${ leaseParty.privateIndividualSurname } `
                    }
                    if (leaseParty.organizationName) {
                        result += `${ leaseParty.organizationName }`
                    }
                    if (leaseParty.charityName) {
                        result += `${ leaseParty.leasePartyCharityName } `
                    }
                    if (leaseParty.tradingName) {
                        result += `(${ leaseParty.tradingName }) `
                    }
                    result += '\n'
                })
            }
        })
    }

    if (result === '') {
        result = undefined
    }
    return result
}

const getBankChargeHolders = (data) => {
    if (data) {
        const results = data.map(d => d.entryText)
        return results.join(', ')
    }
    return undefined
}

const getTitleComplexity = (data) => {
    return {
        label: camelCaseToSentenceCase(data.category),
        ...data,
    }
}

const getScheduleOfNoticesOfLease = (data) => {
    return data.filter(d => d.titleNumber !== null)
}

const getLeaseStartDate = (lease) => new Date(Math.min.apply(null, lease.filter(l => l.startDate != null)
    .map(l => new Date(l.startDate))))
const getLeaseEndDate = (lease) => new Date(Math.max.apply(null, lease.filter(l => l.endDate != null)
    .map(l => new Date(l.endDate))))

const getRemainingLeaseTerm = (lease, asDays = false) => {
    const endDate = getLeaseEndDate(lease)
    if (!isNaN(endDate)) {
        const totalDaysDiff = differenceInDays(endDate, new Date())
        if (asDays === true) {
            return totalDaysDiff
        }

        let now = new Date()
        const yearsDiff = differenceInYears(endDate, now)
        now = add(now, { years: yearsDiff })
        const monthsDiff = differenceInMonths(endDate, now)
        now = add(now, { months: monthsDiff })
        const daysDiff = differenceInDays(endDate, now)

        const label = `${ yearsDiff } ${ pluralize('year', yearsDiff) } ${ monthsDiff } ${ pluralize('month', monthsDiff) } ${ daysDiff } ${ pluralize('day', daysDiff) }`

        return {
            value: totalDaysDiff,
            label,
        }
    }
    return undefined
}

const getChargesTotalCount = (title) => {
    if (title.chargeCount === null && title.equitableChargeCount === null) {
        return undefined
    }

    return (title.chargeCount ?? 0) +
           (title.equitableChargeCount ?? 0) +
           (title.discountChargeCount ?? 0) +
           (title.notedChargeCount ?? 0) +
           (title.rentChargeCount ?? 0)
}

const getIsProprietorOverseas = (title) => {
    if (isNullOrEmpty(title.proprietorshipParties)) {
        return undefined
    }

    return title.proprietorshipParties.some(p => p.isOverseas)
        ? 'Yes'
        : 'No'
}

const getProprietorCountriesIncorporated = (title) => {
    if (isNullOrEmpty(title.proprietorshipParties)) {
        return undefined
    }

    return title.proprietorshipParties
        .map(p => p.countryIncorporated)
        .filter(c => c)
}

const getEstateInterest = (title) => {
    if (title.ccodOcodTitle?.estateInterest) {
        switch (title.ccodOcodTitle.estateInterest) {
            case 'rentchargePerpetual':
                return 'Rentcharge (Perpetual)'
            case 'rentchargeTerminable':
                return 'Rentcharge (Terminable)'
            case 'estateInLand':
                return 'Estate in land'
            case 'affectingFranchise':
                return 'Affecting franchise'
            case 'relatingFranchise':
                return 'Relating franchise'
            case 'manor':
                return 'Manor'
            case 'profitAPrendreInGross':
                return 'Profit a prendre in gross'
            case 'rentcharge':
                return 'Rentcharge'
        }
    }

    return undefined
}

export {
    getBankChargeHolders,
    getCcodOcodMatchesOwner,
    getCcodOcodOwnerName,
    getChargesTotalCount,
    getCompanyHousePropertyArray,
    getCompanyRegNo,
    getEntryNumberAndText,
    getEntryText,
    getEstateInterest,
    getIsProprietorOverseas,
    getLeaseEndDate,
    getLeaseParty,
    getLeaseStartDate,
    getOwnersMatch,
    getPricePaid,
    getPropertyAddressesAndPostcodes,
    getProprietorAddress,
    getProprietorAddressArray,
    getProprietorCountriesIncorporated,
    getProprietorNameArray,
    getQualityOfTenure,
    getRegisterOwnerName,
    getRemainingLeaseTerm,
    getRestrictions,
    getRestrictionsCount,
    getScheduleOfNoticesOfLease,
    getTenureType,
    getTitleComplexity,
    getTradingAsNameArray,
    joinArray,
}

<template>
    <div class="title-panel-tabs"
         :class="{
             'title-panel-tabs--loading': isLoading,
             'title-panel-tabs--full-screen': isFullScreen,
             'px-4': isLoading,
         }">
        <div v-if="isLoading"
             class="title-panel-tabs__loading d-flex flex-row gc-3"
             :class="{
                 'py-3': !isFullScreen,
             }">
            <ow-loading-skeleton v-for="i in 5"
                                 :key="i"
                                 border-radius
                                 is-primary-color
                                 lighter-color
                                 style="max-height: 44px; max-width: 80px;" />
        </div>
        <v-tabs v-else
                v-model="model"
                class="title-panel-tabs__container"
                :show-arrows="isFullScreen"
                slider-color="black">
            <v-tab v-for="row in visibleTabs"
                   v-show="showTab(row.key)"
                   :id="row.id"
                   :key="'tab_' + row.key"
                   :value="row.key"
                   :ripple="false"
                   class="title-panel__tab"
                   color="black"
                   :data-test="row.dataTest"
                   :data-track="row.dataTrack"
                   @click="e => onTabClick(e, row)">
                <span class="button-small">{{ row.label }}</span>
            </v-tab>
        </v-tabs>
    </div>
    <div class="d-flex gc-4">
        <v-window v-model="model"
                  :class="{
                      'flex-grow-1': !isFullScreen,
                  }">
            <v-window-item v-for="(component) in components"
                           :key="'wi_' + component.key"
                           :value="component.key"
                           :eager="component.eager">
                <component :is="component.component"
                           :key="'cmp_' + component.key"
                           class="title-panel__tab-content"
                           :class="{
                               'has-warning': isRegisterUploaded,
                           }"
                           :is-ordering-allowed="isOrderingAllowed"
                           :order-with-checkout="orderWithCheckout"
                           :is-register-purchased="isRegisterPurchased"
                           :is-register-uploaded="isRegisterUploaded"
                           :is-full-screen="isFullScreen"
                           :selected-title="selectedTitle"
                           :official-copies="selectedTitle?.officialCopiesAvailability?.results"
                           :selected-title-number="selectedTitleNumber"
                           :selected-title-numbers="[selectedTitleNumber]"
                           :show-title-complexity="showTitleComplexity"
                           :title-charge="titleSummaryCharge"
                           :current-matter-id="currentMatterId"
                           :target-map="map"
                           :map="map"
                           :is-caution-against-first-registration="isCautionAgainstFirstRegistration"
                           :is-loading="isLoading"
                           :scroll-element-container="scrollElementContainer"
                           @set-selected-tab="tab => $emit('set-selected-tab', tab)"
                           @title-number-selected="redirectToTitle" />
            </v-window-item>
        </v-window>
        <template v-if="$slots.default">
            <slot />
        </template>
    </div>
</template>

<script setup lang="ts">
    import { computed,
             onMounted } from "vue"
    import {
        markRaw,
        ref,
        watch,
    } from "vue"
    import { useI18n } from "vue-i18n"
    import { useStore } from "vuex"

    import owLoadingSkeleton from "@/components/core/ow-loading-skeleton.vue"
    import LeaseHierarchy from '@/components/lease-hierarchy/lease-hierarchy.vue'
    import PlanningContainer from '@/components/planning/planning-container.vue'
    import CopiesFiledTab from '@/components/title-panel/tabs/copies-filed-tab/copies-filed-tab.vue'
    import DaylistEnquiry from '@/components/title-panel/tabs/daylist-enquiry.vue'
    import FindNearbyTab from '@/components/title-panel/tabs/find-nearby-tab.vue'
    import RegisterTabRender from '@/components/title-panel/tabs/register/register-tab-renderer.vue'
    import SummaryTabRenderer from '@/components/title-panel/tabs/summary-tab/summary-tab-renderer.vue'
    import { DOCUMENT_SOURCE } from '@/consts/document-source'
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
    import {
        TITLE_GET_IS_REGISTER_PURCHASED,
        TITLE_GET_SELECTED_TITLE_NUMBER,
    } from '@/store/modules/titles/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        LOGGING_LOG_FEATURE_USAGE,
    } from "@/store/mutation-types"
    import { TENURE_FULL_FROM_CODE } from "@/utils/title-enums"

    const store = useStore()
    const visibleTabs = ref<Tab[]>([])
    const { t } = useI18n()
    const components = ref([])

    const props = withDefaults(defineProps<{
        isFullScreen: boolean,
        isLoading: boolean,
        isOrderingAllowed?: boolean,
        orderWithCheckout?: boolean,
        scrollElementContainer: string,
        showTitleComplexity?: boolean,
        map: any,
    }>(), {
        showTitleComplexity: true,
        orderWithCheckout: false,
        isOrderingAllowed: true,
    })

    const model = defineModel()

    const emit = defineEmits<{
        (e: 'title-number-selected', titleNumber: string),
        (e: 'set-selected-tab', tabIndex: string)
        (e: 'update:modelValue', value: TitlePanelTabName)
    }>()

    type Tab = {
        key: TitlePanelTabName,
        id: string,
        dataTest: string,
        dataTrack: string,
        label: string,
        onClick?: () => void,
    }

    const selectedTitle = computed(() => store.state?.title?.selectedTitle)
    const isRegisterPurchased = computed(() => store.getters[TITLE_GET_IS_REGISTER_PURCHASED])
    const isRegisterUploaded = computed(() => selectedTitle.value.officialCopiesAvailability?.results?.titleRegister?.documentSource === DOCUMENT_SOURCE.UPLOADED)
    const selectedTitleNumber = computed(() => store.getters[TITLE_GET_SELECTED_TITLE_NUMBER])
    const isFindNearbyEnabled = computed(() => store.state.config?.featureFlags?.findNearby)
    const planningData = computed(() => store.state.config?.featureFlags?.planningData)
    const documentSource = computed(() => store.state?.title?.selectedTitle?.record?.source)
    const isCautionAgainstFirstRegistration = computed(() => selectedTitle?.value.record?.tenure === TENURE_FULL_FROM_CODE.CN)
    const titleSummaryCharge = computed(() => store.state?.user?.titleSummaryCharge)
    const currentMatterId = computed(() => store.state?.matter?.currentMatter.id)

    const boundaryAvailable = computed(() => {
        return selectedTitle.value.bboxGeom !== null
    })

    const showRegisterTab = computed(() => {
        return !props.isFullScreen || isRegisterPurchased.value
    })

    const showPlanningTab = computed(() => {
        return boundaryAvailable.value && (!props.isFullScreen || isRegisterPurchased.value)
    })

    const showFindNearbyTab = computed(() => {
        return isFindNearbyEnabled.value && boundaryAvailable.value
    })

    const showTab = (tab: TitlePanelTabName) => {
        switch (tab) {
            case TitlePanelTabName.Register:
                return showRegisterTab.value
            case TitlePanelTabName.Planning:
                return showPlanningTab.value
            case TitlePanelTabName.FindNearby:
                return showFindNearbyTab.value
            default:
                return true
        }
    }

    const trackHeapPlanning = () => {
        store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'Title panel - Planning tab',
        })

        store.dispatch(LOGGING_LOG_FEATURE_USAGE, {
            type: planningData.value ? 'PLANNING_TAB_VIEWED' : 'PLANNING_PROMO_VIEWED',
            description: planningData.value ? 'Planning tab viewed' : 'Planning promo tab viewed',
        })
    }


    const trackHeapFindNearby = () => {
        store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'TITLE-DETAILS-PANEL - Nearby tab',
        })
    }


    const logLeases = () => {
        store.dispatch(LOGGING_LOG_FEATURE_USAGE, {
            type: 'Leases hierarchy',
            description: `opened lease tab for title ${ selectedTitleNumber.value }`,
        })
    }

    const redirectToTitle = (titleNumber: string) => {
        emit('title-number-selected', titleNumber)
    }

    const onTabClick = (_e, row) => {
        row?.onClick && row.onClick()
    }

    const renderComponents = () => {
        let componentsToRender = [
            {
                key: TitlePanelTabName.Summary,
                component: markRaw(SummaryTabRenderer),
                eager: true,
            },
            {
                key: TitlePanelTabName.Register,
                component: markRaw(RegisterTabRender),
                eager: true,
            },
            {
                key: TitlePanelTabName.CopiesFiled,
                component: markRaw(CopiesFiledTab),
            },
            {
                key: TitlePanelTabName.Leases,
                component: markRaw(LeaseHierarchy),
            },
            {
                key: TitlePanelTabName.DayList,
                component: markRaw(DaylistEnquiry),
            },
            {
                key: TitlePanelTabName.Planning,
                component: markRaw(PlanningContainer),
            },
            {
                key: TitlePanelTabName.FindNearby,
                component: markRaw(FindNearbyTab),
            },
        ]

        switch (documentSource.value) {
            case DOCUMENT_SOURCE.SCOTLAND:
                // Scotland only has summary and register tabs
                componentsToRender = componentsToRender.filter(component => [TitlePanelTabName.Summary, TitlePanelTabName.Register, TitlePanelTabName.DayList].includes(component.key))
                break
        }

        components.value = componentsToRender
    }

    const renderTabs = () => {

        let tabs: Tab[] = [{
                               key: TitlePanelTabName.Summary,
                               id: "titlePanelTabSummary",
                               dataTest: "title-panel-tab-summary",
                               dataTrack: "TITLE-DETAILS-PANEL - Summary tab",
                               label: t("titlePanel.tabs.summary"),
                           },
                           {
                               key: TitlePanelTabName.Register,
                               id: "titlePanelTabRegister",
                               dataTest: "title-panel-tab-register",
                               dataTrack: "TITLE-DETAILS-PANEL - Register tab",
                               label: t("titlePanel.tabs.register"),
                           },
                           {
                               key: TitlePanelTabName.CopiesFiled,
                               id: "titlePanelTabOfficialCopies",
                               dataTest: "title-panel-tab-copies-filed",
                               dataTrack: "TITLE-DETAILS-PANEL - Copies filed tab",
                               label: t("titlePanel.tabs.officialCopies"),
                           },
                           {
                               key: TitlePanelTabName.Leases,
                               id: "titlePanelTabLeases",
                               dataTest: "title-panel-tab-leases",
                               dataTrack: "TITLE-DETAILS-PANEL - Lease hierarchy tab",
                               label: t("titlePanel.tabs.leaseHierarchy"),
                               onClick: () => {
                                   logLeases()
                               },
                           },
                           {
                               key: TitlePanelTabName.DayList,
                               id: "titlePanelTabDayList",
                               dataTest: "title-panel-tab-daylist",
                               dataTrack: "TITLE-DETAILS-PANEL - Daylist tab",
                               label: t("titlePanel.tabs.dayList"),
                           },
                           {
                               key: TitlePanelTabName.Planning,
                               id: "titlePanelTabPlanning",
                               dataTest: "title-panel-tab-planning",
                               dataTrack: "TITLE-DETAILS-PANEL - Planning tab",
                               label: t("titlePanel.tabs.planning"),
                               onClick: () => {
                                   trackHeapPlanning()
                               },
                           },
                           {
                               key: TitlePanelTabName.FindNearby,
                               id: "titlePanelTabNearby",
                               dataTest: "title-panel-tab-find-nearby",
                               dataTrack: "TITLE-DETAILS-PANEL - Find Nearby tab",
                               label: t("titlePanel.tabs.nearby"),
                               onClick: () => {
                                   trackHeapFindNearby()
                               },
                           }]

        // Scotland only has summary and register tabs
        switch (documentSource.value) {
            case DOCUMENT_SOURCE.SCOTLAND:
                // Scotland only has summary and register tabs
                tabs = tabs.filter(tab => [TitlePanelTabName.Summary, TitlePanelTabName.Register, TitlePanelTabName.DayList].includes(tab.key))
                break
        }

        // Remove the nearby tab if the feature flag is disabled
        if (props.isFullScreen) {
            tabs = tabs.filter(tab => tab.key !== TitlePanelTabName.FindNearby)
        }

        visibleTabs.value = tabs
    }

    watch(() => documentSource.value, (val) => {
        renderTabs()
        renderComponents()
    })

    onMounted(() => {
        renderTabs()
        renderComponents()
    })
</script>


<style lang="scss">
@import './title-panel-tabs.scss';
</style>

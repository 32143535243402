<template>
    <div>
        <h1>Company Monitors</h1>
    </div>
</template>

<script setup lang="ts">
    import {computed,
            onBeforeMount} from "vue"
    import {useRouter} from "vue-router"
    import {useStore} from "vuex"

    import {Route} from "@/enums/route.enum"

    const router = useRouter()
    const store = useStore()
    const isCompanyMonitorsEnabled = computed<boolean>(() => store.state.config.featureFlags?.assetMonitoringCompanyMonitorsTabs)

    onBeforeMount(async () => {
        if(!isCompanyMonitorsEnabled.value){
            await router.push({
                name: Route.Homepage,
            })
        }
    })
</script>

<style scoped lang="scss">
    @import './company-alerts';
</style>

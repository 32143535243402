// @/stores/core/index.ts
import {
    acceptHMRUpdate,
    defineStore,
} from 'pinia'


import getters from './getters'
import actions from './actions'
/**
 * Represents the state of the core store.
 */
export interface ICoreState {
    pagination: {
        resetPageAfterUpdate: boolean
    }
}

export const coreOptions = {
    state: (): ICoreState => {
        return {
            pagination: {
                resetPageAfterUpdate: true,
            },
        }
    },
    getters: { ...getters },
    actions: { ...actions },
}

/**
 * The `useCoreStore` variable is a store created using the `defineStore` function.
 * It is used to manage state relating to core components
 */
export const useCoreComponentStore = defineStore('core', coreOptions)

// Enables Hot Module Replacement in Pinia
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCoreComponentStore, import.meta.hot))
}

<template>
    <div :style="{
             height: containerHeight,
         }"
         class="base-row d-flex justify-space-between align-center">
        <div class="w-100 h-100 d-flex flex-column justify-content-between">
            <div :class="{
                     'base-row__title--disabled': disableViewButton,
                 }"
                 :data-test="viewButtonDataTest"
                 :data-track="viewButtonDataTrack"
                 class="d-flex flex-column px-4 justify-center base-row__title"
                 @click="handleRowClick($event)">
                <div class="d-flex w-100 justify-space-between">
                    <div class="base-row__title-document d-flex align-center gc-1">
                        <v-icon :icon="icon" />
                        <span class="caption-highlight">{{ title }}</span>
                        <span>
                            <ow-tooltip :position="OwTooltipPosition.Bottom"
                                        activator="parent">
                                {{ helpIconText }}
                            </ow-tooltip>
                            <a href="#"
                               @click.prevent="handleIconClick"><v-icon v-if="showHelpIcon"
                                                                        id="planHelpIcon">
                                   $info
                               </v-icon></a>
                        </span>
                    </div>
                    <ow-document-availability-label v-if="notAvailable"
                                                    :hmlr-availability-code="documentAvailabilityCode"
                                                    :is-loading="isLoading"
                                                    :is-ordered="false"
                                                    class="base-row__status" />
                    <div v-else
                         ref="datesRef"
                         class="base-row__dates d-flex flex-column justify-center align-end gr-1">
                        <div v-for="date in dates"
                             :class="{
                                 'base-row__date--alert': date.alert,
                             }"
                             :data-test="date.dataTest"
                             class="label-caps-smallest hide-in-percy">
                            <span v-if="date.displayValue">{{ date.displayValue }}</span>
                            <span v-else>{{ date?.emptyValue || '' }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="infoContent"
                 ref="infoContentRef"
                 class="d-flex flex-column w-100">
                <div v-for="info in infoContent"
                     v-if="infoContent">
                    <div :class="{
                             [info?.class]: true,
                             'base-row__info--disabled': !info?.show || isLoading}"
                         class="base-row__info d-flex align-center justify-space-between base-row__backdated px-4 pr-3 py-0 gc-2">
                        <div class="d-flex align-center gc-2">
                            <span v-if="info?.title"
                                  :class="info?.subtitle ? 'accents-highlight' : 'accents-small'">{{ info?.title }}</span>
                            <span v-if="info.subtitle"
                                  class="accents-italic">{{ info?.subtitle }}</span>
                        </div>
                        <div class="base-row__info-button">
                            <ow-button v-if="info?.clickHandler"
                                       :data-test="info?.dataTest"
                                       :data-track="info?.dataTrack"
                                       :disabled="!info?.show || isLoading"
                                       :is-loading="isLoading"
                                       is-flat
                                       is-text
                                       small
                                       @click="info?.clickHandler">
                                {{ isLoading ? '' : info?.ctaText }}
                            </ow-button>
                            <ow-tooltip :disabled="info?.show"
                                        activator="parent"
                                        max-width="300px">
                                {{ $t('documents.message.addTitleToMatter') }}
                            </ow-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-divider vertical />
        <template v-for="action in customActions"
                  :key="action?.label">
            <div class="base-row__action-container h-100">
                <ow-button :data-test="action.dataTest"
                           :data-track="action.dataTrack"
                           :disabled="disableViewButton"
                           :is-secondary="true"
                           class="base-row__action"
                           full-height
                           is-borderless

                           @click="action.clickHandler">
                    {{ action.label }}
                </ow-button>
            </div>
            <v-divider vertical />
        </template>
        <div v-if="disableExportButtonMenu"
             class="h-100">
            <ow-button :data-test="exportButtonDataTest"
                       :data-track="exportButtonDataTrack"
                       :disabled="!showExportButton || disableExportButton"
                       class="base-row__action"
                       full-height
                       is-borderless
                       is-primary
                       is-secondary
                       @click="$emit('export')">
                {{ $t('action.download') }}
            </ow-button>
        </div>
        <ow-export-button-menu v-else
                               :button-height="containerHeight"
                               :current-matter-id="currentMatterId"
                               :data-test="exportButtonDataTest"
                               :data-track="exportButtonDataTrack"
                               :disabled="!showExportButton || disableExportButton"
                               :selected-title-numbers="[selectedTitleNumber]"
                               :show-pdf-export="!disableViewButton"
                               class="base-row__action"
                               heap-tracker-prefix="TITLE_REGISTER_CARD"
                               is-borderless
                               is-full-height
                               tooltip-position="top"
                               @selected-template="handleLinkSelected" />
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        onActivated,
        onMounted,
        ref,
        watchEffect,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwDocumentAvailabilityLabel from '@/components/core/ow-document-availability-label.vue'
    import owTooltip from '@/components/core/ow-tooltip.vue'
    import OwExportButtonMenu from '@/components/reporting/export-button-menu.vue'
    import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
    import { ColorClass } from '@/enums/color-class.enum'
    import { OwTooltipPosition } from "@/enums/ow-tooltip-position"
    import { isNullOrEmpty } from '@/utils/array-utils'

    export type InfoContentType = {
        dataTest: string
        dataTrack: string
        title: string
        subtitle: string
        ctaText?: string
        class?: ColorClass,
        clickHandler: () => void
        show?: boolean
    }

    export type DateType = {
        displayValue: string
        emptyValue?: string
        dataTest?: string
        alert?: boolean
    }

    export type CustomActionType = {
        label: string
        dataTest: string
        dataTrack: string
        clickHandler: () => void
    }

    const props = withDefaults(defineProps<{
        title: string
        icon: string
        currentMatterId?: number
        selectedTitleNumber?: string
        showExportButton: boolean
        showHelpIcon?: boolean
        helpIconText?: string
        helpIconLink?: string
        dates: DateType[]
        customActions?: CustomActionType[]
        infoContent?: InfoContentType[]
        disableExportButton: boolean
        disableExportButtonMenu: boolean
        disableViewButton: boolean
        documentAvailabilityCode?: string
        isLoading?: boolean
        viewButtonDataTest?: string
        viewButtonDataTrack?: string
        exportButtonDataTest?: string
        exportButtonDataTrack?: string
    }>(), {
        icon: '$register',
        showExportButton: true,
        showHelpIcon: false,
        disableExportButton: false,
    })
    const datesRef = ref<HTMLElement | null>(null)
    const infoContentRef = ref<HTMLElement | null>(null)
    const { t } = useI18n()
    const containerHeight = ref('')

    onMounted(() => {
        updateContainerHeight()
    })

    onActivated(() => {
        updateContainerHeight()
    })

    const handleIconClick = () => {
        window.open(props.helpIconLink, '_blank')
    }

    const handleRowClick = (event) => {
        if (event.target.id !== "planHelpIcon") {
            !props.disableViewButton && emit('view')
        }
    }

    const updateContainerHeight = () => {
        let size = 0
        if (!isNullOrEmpty(props.dates)) {
            let dateSize = datesRef.value?.clientHeight + (props.dates.length * 6) || 0
            if (dateSize < 60) {
                dateSize = 60
            }
            size += dateSize
        } else {
            size += 36
        }
        if (!isNullOrEmpty(props.infoContent)) {
            let infoSize = infoContentRef.value?.clientHeight || 0
            if (infoSize < 48) {
                infoSize = 48
            }
            size += infoSize
        }
        if (size < 48) {
            size = 48
        }
        containerHeight.value = `${ size }px`
    }

    const handleLinkSelected = (link: string) => {
        if (link === 'pdf') {
            emit('export')
        }
    }

    const notAvailable = computed(() => {
        const { documentAvailabilityCode } = props
        return documentAvailabilityCode === HmlrDocumentAvailabilityCode.NotImmediatelyAvailable
    })

    watchEffect(() => {
        updateContainerHeight()
    })

    const emit = defineEmits([ 'export', 'view', 'order' ])
</script>


<style scoped lang="scss">
@import './base-row.scss';
</style>

<template>
    <search-box :autofocus="false"
                :class="{ 'has-shadow': hasShadow }"
                :is-loading="isLoadingSearchResults"
                :items="searchResults"
                item-text="titleNumber"
                item-value="titleNumber"
                class="title-search-box search-page__header--searchbox"
                label="Enter title number (e.g. TGL50538)"
                @change="onResultClick"
                @clear="onClearHandler"
                @focus="onFocusHandler"
                @clear-search-history="onClearSearchHistory"
                @enter-pressed="onEnterPressed"
                @item-click="onResultClick"
                @search-input-update="onSearchTextUpdated"
                @show-all-results="showAllResults" />
</template>

<script lang="ts">
    import debounce from 'lodash.debounce'
    import {
        mapActions,
        mapMutations,
    } from 'vuex'

    import SearchApi from '@/api/search.api'
    import SearchBox from '@/components/search/search-box.vue'
    import { Route } from '@/enums/route.enum'
    import { SearchResultType } from '@/enums/search-result-type'
    import { TenureType } from '@/enums/tenure-type'
    import { ResultItemInterface } from '@/interfaces/search-result-item.interface'
    import { GLOBAL_SEARCH_MUTATE_ALL_RESULTS } from '@/store/modules/global-search/types'
    import { TITLE_LOOKUP_TITLE } from '@/store/modules/titles/types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    type TitleSearchBoxData = {
        searchText: string,
        abortSearchController: AbortController
        debouncedSearch: Function,
        isLoadingSearchResults: boolean,
        searchResults: ResultItemInterface[],
        allResultsLoading: boolean,
        searchHistory: string[],
    }

    export default {
        name: 'TitleSearchBox',

        components: {
            SearchBox,
        },

        props: {
            showSingleResult: {
                type: Boolean,
                required: false,
            },
            hasShadow: {
                type: Boolean,
                required: false,
            },
        },

        data(): TitleSearchBoxData {
            return {
                searchText: null,
                abortSearchController: null,
                debouncedSearch: debounce(async (text: string) => {
                    if (this.hasValidSearchText(text)) {
                        await this.doSearch()
                        this.searchText = text
                    }
                }, 700),
                isLoadingSearchResults: false,
                searchResults: [],
                allResultsLoading: false,
                searchHistory: [],
            }
        },

        computed: {
            scottishSpike() {
                return this.$store.state.config.featureFlags?.scottishSpike
            },
        },

        methods: {
            ...mapActions({
                lookupTitle: TITLE_LOOKUP_TITLE,
            }),

            ...mapMutations('globalSearch', {
                setAllResults: GLOBAL_SEARCH_MUTATE_ALL_RESULTS,
            }),

            async onEnterPressed(evt: any): Promise<void> {
                const result: ResultItemInterface = evt.item
                if (result) {
                    await this.onResultClick(result)
                }
            },

            async goToTitleDetails(titleNumber: string): Promise<void> {
                await this.$router.push({
                    name: Route.TitleDetails,
                    params: {
                        titleNumber,
                    },
                }).then(() => {
                    SearchApi.addSearchHistory(titleNumber)
                    this.lookupTitle(titleNumber)
                })
            },

            async showAllResults(): Promise<void> {
                this.allResultsLoading = true
                this.allResults = []

                const results = await this.getMatchingResults()
                if (!isNullOrEmpty(results)) {
                    const allResults = results.map(result => ({
                        titleNumber: result.number,
                        tenure: this.getTenureForResultItem(result.tenure),
                        address: result.name,
                        uprn: result.uprn,
                    }))
                    this.setAllResults(allResults)

                    if (this.$route.name !== Route.Search) {
                        await this.$router.push({ name: Route.Search })
                    }
                    await SearchApi.addSearchHistory(this.searchText)
                    this.searchText = ''
                }
                this.allResultsLoading = false
            },

            async onSearchTextUpdated(text: string): Promise<void> {
                this.searchResults = []
                this.searchText = text
                await this.debouncedSearch(this.searchText)
            },

            async setSearchTermHistory(): Promise<void> {
                this.searchResults = [
                    { header: 'Enter 4 or more characters' },
                ]
                this.searchHistory = await SearchApi.getSearchHistory()

                // Only show history if search text box is empty
                if (isNullOrWhitespace(this.searchText) &&
                    !isNullOrEmpty(this.searchHistory)) {
                    this.searchResults = [
                        { header: 'Recent searches' },
                    ]
                    this.searchHistory.forEach(searchTerm => {
                        this.searchResults.push({
                            titleNumber: searchTerm,
                            type: SearchResultType.history,
                        })
                    })
                    this.searchResults.push({
                        searchText: 'CLEAR_HISTORY',
                        type: SearchResultType.clearHistory,
                        titleNumber: '',
                    })
                }
            },

            hasValidSearchText(text: string): Boolean {
                return (!isNullOrWhitespace(text) && text.length >= 4)
            },

            async onResultClick(result: ResultItemInterface) {
                if (result?.titleNumber) {
                    const titleNumber = result.titleNumber
                    switch (result.type) {
                        case SearchResultType.title:
                        case SearchResultType.history:
                            await this.goToTitleDetails(titleNumber)
                            break

                        case SearchResultType.allResults:
                            await this.showAllResults()
                            break
                    }
                }
                this.searchText = null
                this.searchResults = []
            },

            async doSearch(): Promise<void> {
                if (!this.hasValidSearchText(this.searchText)) {
                    return
                }

                const matchingResults = await this.getMatchingResults()

                if (isNullOrEmpty(matchingResults)) {
                    const header = this.$t('error.noResultsFoundForSearchText', [this.searchText])
                    let infoText = null
                    if (this.scottishSpike) {
                        infoText = this.$t('label.scottishSearchWarningHomePage')
                    }
                    this.searchResults = [
                        { header: header, infoText  },
                    ]
                } else {
                    const titles = matchingResults.map(title => ({
                        titleNumber: title.number,
                        tenure: this.basicTenureText(title.tenure),
                        uprn: title.uprn,
                        type: SearchResultType.title,
                    }))

                    let otherMatches = []
                    const results = [
                        { ...titles[0] },
                    ]

                    if (titles.length > 1) {
                        otherMatches = [
                            { header: 'Other matches' },
                            ...titles.slice(1, 4),
                            {
                                message: `More search results for "${ this.searchText.toUpperCase() }"`,
                                searchText: this.searchText,
                                type: SearchResultType.allResults,
                                titleNumber: this.searchText,
                            },
                        ]
                    }

                    if (this.showSingleResult) {
                        this.searchResults = [{...titles[0]}]
                    } else {
                        this.searchResults = [
                            ...results,
                            ...otherMatches,
                        ]
                    }
                }
            },

            async getMatchingResults(): Promise<void> {
                if (!this.hasValidSearchText(this.searchText)) {
                    return
                }

                this.isLoadingSearchResults = true

                if (this.abortSearchController) {
                    this.abortSearchController.abort()
                }

                this.abortSearchController = new AbortController()

                try {
                    return await this.fetchResults()
                } catch (err) {
                    console.error('Error fetching title numbers', err)
                }
                this.isLoadingSearchResults = false
            },

            async fetchResults() {
                try {
                    const response: any = await SearchApi.titleSearch(this.searchText, this.abortSearchController.signal)

                    if (response?.ok) {
                        this.isLoadingSearchResults = false
                        return response.data.titleNumbers
                    } else {
                        console.error('Error fetching title numbers', response)
                    }
                } catch (err) {
                    console.error('Error fetching title numbers', err)
                }
            },

            getTenureForResultItem(tenure?: string) {
                if (!tenure) {
                    return TenureType.Unknown
                }
                if (tenure.includes('Freehold')) {
                    return TenureType.Freehold
                }
                if (tenure.includes('Leasehold')) {
                    return TenureType.Leasehold
                }
                return TenureType.Other
            },

            basicTenureText(tenure: string): string {
                if (!tenure) {
                    return 'N/A'
                }
                if (tenure.toLowerCase().includes('leasehold')) {
                    return 'Leasehold'
                }
                if (tenure.toLowerCase().includes('freehold')) {
                    return 'Freehold'
                }
                return 'Other'
            },

            async onClearSearchHistory(): Promise<void> {
                await SearchApi.clearSearchHistory()
                this.searchText = ''
                this.searchResults = []
                await this.setSearchTermHistory()
            },

            async onClearHandler(): Promise<void> {
                this.searchText = ''
                this.searchResults = []
                await this.setSearchTermHistory()
            },

            async onFocusHandler(): Promise<void> {
                this.searchResults = []
                if (isNullOrWhitespace(this.searchText)) {
                    await this.setSearchTermHistory()
                }
            },
        },
    }
</script>

<style lang="scss">
    @import './title-search-box';
</style>

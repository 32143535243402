<template>
    <base-monitoring-preference-card :card-type="t('assetMonitoring.preferences.cards.presets.cardTitle')"
                                     :card-type-description="t('assetMonitoring.preferences.cards.presets.cardDescription')"
                                     class="preference-presets-card">
        <ul class="preference-presets-card__options">
            <li :class="{'is-selected': isOwRecommendedSelected}"
                @click="setOwRecommended">
                <h4 class="caption-highlight">
                    {{ t(`assetMonitoring.preferences.cards.presets.owRecommendedTitle`) }}
                </h4>
                <p class="caption-regular">
                    {{ t(`assetMonitoring.preferences.cards.presets.owRecommendedDescription`) }}
                </p>
            </li>
        </ul>
    </base-monitoring-preference-card>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { INotificationSettings } from '@/api/notifications.api'
    import BaseMonitoringPreferenceCard from '@/components/matter/settings/monitoring-preferences/base-monitoring-preference-card.vue'

    const { t } = useI18n()
    const emit = defineEmits<{
        (event: 'update', value: INotificationSettings): void
    }>()

    const props = defineProps<{
        settings: INotificationSettings,
    }>()

    // OW Recommended card
    const isOwRecommendedSelected = computed<boolean>(() => {
        // TODO: will need to set these appropriately
        return props.settings.trackDaylist
        // TODO: temporarily disabling this option
        // && props.settings.trackOcda
    })
    const setOwRecommended = () => {
        const prefs: INotificationSettings = {
            trackDaylist: true,
            // TODO: temporarily disabling this option
            trackOcda: false,
            trackOwnership: false,
            trackBoundary: false,
        }
        emit('update', prefs)
    }

</script>

<style lang="scss" scoped>
    @import './preference-presets-card';
</style>

<template>
    <div class="ow-9-dot-menu">
        <v-menu v-model="menu"
                :close-on-content-click="false"
                open-on-hover
                :close-delay="500"
                transition="scale-transition"
                :content-class="position === 'bottom' ? 'ow-9-dot-menu__content--bottom' : 'ow-9-dot-menu__content'"
                location="right">
            <template #activator="{ props }">
                <button class="ow-9-dot-menu__button"
                        :class="{
                            'ow-9-dot-menu__button--active': menu,
                        }"
                        v-bind="props"
                        data-test="global-nav-titles-9-dot-menu"
                        data-track="GLOBAL-NAV - 9 dot menu">
                    <v-icon>$menu-dots</v-icon>
                </button>
            </template>

            <div ow-9-dot-menu__card>
                <div class="ow-9-dot-menu__app-container pa-4 d-flex flex-column gr-4">
                    <p class="label-caps-small">
                        Select App
                    </p>
                    <div v-for="item in items"
                         :key="item.title"
                         @click="menu = false">
                        <button class="ow-9-dot-menu__app-button px-4 w-100"
                                :class="{
                                    '--selected': selected === item.id,
                                }"
                                :heap-track="`Global App Nav - ${item.title}`"
                                @click="emit('select', item)">
                            <div class="d-flex flex-column">
                                <div class="d-flex justify-space-between mb-2">
                                    <ow-icon class="ow-9-dot-menu__app-button-icon"
                                             :icon="item.icon" />
                                    <span v-if="item.url"
                                          class="mdi mdi-open-in-new" />
                                </div>
                                <p class="caption-highlight text-left">
                                    {{ item.title }}
                                </p>
                                <p class="accents-small text-wrap text-left">
                                    {{ item.subtitle }}
                                </p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </v-menu>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue'

    import OwIcon from '@/components/core/ow-icon.vue'
    import { AppNavItem } from '@/composables/use-app-nav'

    const emit = defineEmits<{
        (e: 'select', item: AppNavItem),
    }>()

    withDefaults(defineProps<{
        selected?: string,
        items?: AppNavItem[],
        position: 'relative' | 'default'
    }>(), {
        position: 'default',
    })
    const menu = ref(false)
</script>

<style lang="scss">
    @import './ow-9-dot-menu.scss';
</style>

<template>
    <div class="splash-page"
         data-test="splash-page">
        <div class="splash-page__content">
            <div class="splash-page__content--icon">
                <ow-icon-asset-monitoring />
            </div>

            <div class="splash-page__content--text">
                <h3 class="headers-display">
                    {{ t('assetMonitoring.splashPage.title') }}
                </h3>
                <p class="body-regular">
                    {{ t('assetMonitoring.splashPage.description') }}
                </p>
                <p class="body-regular">
                    {{ t('assetMonitoring.splashPage.canMonitor') }}
                </p>
                <ul class="body-regular">
                    <li>{{ t('assetMonitoring.splashPage.monitorOptions.ownership') }}</li>
                    <li>{{ t('assetMonitoring.splashPage.monitorOptions.pendingApplications') }}</li>
                    <li>{{ t('assetMonitoring.splashPage.monitorOptions.boundary') }}</li>
                </ul>

                <ow-button :data-button-type="canEnableMonitoring ? 'Enable' : 'Interested'"
                           class="splash-page__content--cta"
                           data-test="asset-monitoring-splash-page-cta"
                           data-track="Asset Monitoring: Splash page CTA"
                           is-primary
                           @click="ctaHandler">
                    <span v-if="canEnableMonitoring">{{ t('action.enableMonitoring') }}</span>
                    <span v-else>{{ t('action.imInterested') }}</span>
                </ow-button>
            </div>
        </div>

        <ow-modal v-model="showRegisterInterestSuccess"
                  class="mb-4"
                  :title="t('label.registerInterest')"
                  data-test="register-interest-confirmation-modal"
                  @close="showRegisterInterestSuccess = false">
            <div class="w-100">
                {{ $t('searches.intro.registerInterestCtaThanks') }}
            </div>
        </ow-modal>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import OwIconAssetMonitoring from '@/components/core/icons/ow-icon-asset-monitoring.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import { Route } from '@/enums/route.enum'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'

    const props = defineProps<{
        matterId: Number,
    }>()

    const assetMonitoringStore = useAssetMonitoringStore()
    const { t } = useI18n()
    const router = useRouter()
    const store = useStore()
    const isMonitoringActive = computed(() => assetMonitoringStore.notificationSettings.isActive)
    const canEnableMonitoring = computed(() => store.state.config?.featureFlags?.assetMonitoring && !isMonitoringActive.value)
    const showRegisterInterestSuccess = ref(false)

    watch(isMonitoringActive, (newValue) => {
        console.info('isMonitoringActive', newValue)
    })
    watch(canEnableMonitoring, (newValue) => {
        console.info('canEnableMonitoring', newValue)
    })

    const ctaHandler = async () => {
        if (canEnableMonitoring.value) {
            await router.push({
                name: Route.MattersMonitoringPreferences,
                params: { matterId: props.matterId.toString() },
            })
        } else {
            await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'Asset Monitoring: Register interest',
                metadata: {
                    matterId: props.matterId.toString(),
                },
            })
            showRegisterInterestSuccess.value = true
        }
    }
</script>

<style lang="scss" scoped>
    @import './splash-page';
</style>

<template>
    <div :class="{ 'ow-data-grid--narrow': narrow, [`ow-data-grid--${theme}`]: true }"
         class="ow-data-grid">
        <div v-if="isCustomSelectionSlotPresent && selectedItems?.length"
             class="ow-data-grid__custom-selection-header"
             data-test="ow-data-grid-custom-selection-header">
            <slot name="customSelectionHeader" />
        </div>
        <v-data-table ref="dataTable"
                      v-model="selectedItems"
                      :custom-sort="customSort"
                      :density="dense ? 'compact' : 'default'"
                      :disable-pagination="!hasPagination"
                      :fixed-header="fixedHeader"
                      :group-by="groupBy"
                      :headers="headers"
                      :height="height"
                      :item-value="itemKey"
                      :items="items"
                      :items-per-page="itemsPerPage"
                      :item-selectable="itemSelectable"
                      :loading="isLoading"
                      :no-data-text="noDataText"
                      :page="activePage"
                      :show-select="allowSelection"
                      :single-select="singleSelect"
                      :select-strategy="singleSelect ? 'single' : undefined"
                      :sort-by="sortBy"
                      :hover="hover"
                      hide-default-footer
                      loading-text="Loading... Please wait"
                      mobile-breakpoint="0"
                      :row-props="colorRowItem"
                      @click:row="onRowClick"
                      @update:current-items="onCurrentItemsChange"
                      @update:options="onSortUpdate">
            <template v-for="field in Object.keys(slots)"
                      #[field]="{item}">
                <slot :item="item"
                      :name="field" />
            </template>

            <template #bottom>
                <ow-pagination v-if="isPaginationVisible"
                               :include-all="hasPaginationAllOption"
                               :result-count="items.length"
                               :disable-items-per-page-select="disableItemsPerPageSelect"
                               class="ow-data-grid__pagination"
                               @page-change="setActivePage"
                               @page-size-change="setItemsPerPage" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import {useSlots} from 'vue'

    import OwPagination from '@/components/core/ow-pagination.vue'
    import {INITIAL_ITEMS_PER_PAGE} from '@/consts/pagination'
    import {dynamicSort,
            isNullOrEmpty} from '@/utils/array-utils'

    export default {
        name: 'OwDataGrid',

        components: {
            OwPagination,
        },

        props: {
            modelValue: {
                type: Array,
                required: false,
            },
            items: {
                type: Array,
                required: true,
            },
            preselectedItems: {
                type: Array,
                default: () => [],
            },
            headers: {
                type: Array,
                required: true,
            },
            itemKey: {
                type: String,
                required: false,
                default: 'id',
            },
            allowSelection: {
                type: Boolean,
                default: true,
            },
            customSort: {
                type: Function,
                required: false,
            },
            dense: {
                type: Boolean,
                default: false,
            },
            narrow: {
                type: Boolean,
                default: false,
                required: false,
            },
            noDataText: {
                type: String,
                required: false,
                default: 'No data available',
            },
            hasPagination: {
                type: Boolean,
                required: false,
                default: false,
            },
            hasPaginationAllOption: {
                type: Boolean,
                default: false,
            },
            fixedHeader: {
                type: Boolean,
                required: false,
            },
            height: {
                type: String,
                required: false,
                default: null,
            },
            isLoading: {
                type: Boolean,
                required: false,
            },
            sortBy: {
                type: Array,
                required: false,
                default: null,
            },
            hover: {
                type: Boolean,
                required: false,
            },
            singleSelect: {
                type: Boolean,
                required: false,
            },
            groupBy: {
                type: Array,
                required: false,
            },
            disableItemsPerPageSelect : {
                type: Boolean,
                required: false,
                default: false,
            },
            itemSelectable : {
                type: String,
                required: false,
            },
            theme: {
                type: String,
                required: false,
                default: 'default',
                validator: (value) => {
                    return ['default', 'light'].includes(value)
                },
            },
        },

        emits: ['items-selected', 'current-items', 'update-data-sorted', 'row-click'],

        data() {
            return {
                selectedItems: this.preselectedItems,
                activePage: 1,
                itemsPerPage: !this.hasPagination ? Number.MAX_SAFE_INTEGER : INITIAL_ITEMS_PER_PAGE,
                minItemsPerPage: !this.hasPagination ? Number.MAX_SAFE_INTEGER : INITIAL_ITEMS_PER_PAGE,
                slots: useSlots(),
            }
        },

        computed: {
            isCustomSelectionSlotPresent() {
                return Boolean(this.slots.customSelectionHeader)
            },
            isPaginationVisible() {
                return this.hasPagination && this.items.length > this.minItemsPerPage
            },
        },

        watch: {
            selectedItems(val) {
                this.$emit('items-selected', val)
            },
            preselectedItems(val) {
                if(isNullOrEmpty(this.preselectedItems)) {
                    this.selectedItems = val
                }
            },
            'items.length'() {
                this.setActivePage(1)
            },

            modelValue(val) {
                this.selectedItems = val
            },
        },

        methods: {
            setActivePage(page) {
                this.activePage = page
            },
            setItemsPerPage(items) {
                this.itemsPerPage = items
            },
            onCurrentItemsChange(items) {
                this.$emit('current-items', items)
            },
            onSortUpdate(event) {
                const sortByEvent = event.sortBy[0]
                if (sortByEvent && !isNullOrEmpty(this.items)) {
                    const keyAndOrder = event.sortBy[0].order === 'asc' ? event.sortBy[0].key : `-${ event.sortBy[0].key }`
                    const sorted = this.items.sort(dynamicSort(keyAndOrder))
                    this.$emit('update-data-sorted', sorted)
                }
            },

            // eslint-disable-next-line vue/no-unused-properties
            clearSelection() { // called by parent component when needed.
                this.selectedItems = []
            },

            onRowClick(event, args) {
                if (this.singleSelect) {
                    this.selectedItems = [args.item.id]
                }
                this.$emit('row-click', args.item)
            },
            colorRowItem(row) {
                if (this.selectedItems.includes(row.item.id)) {
                    return {
                        'data-selected': true,
                    }
                }
            },
        },
    }
</script>

<style lang="scss">
@import './ow-data-grid.scss';
</style>

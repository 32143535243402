<template>
    <ow-modal v-model="props.show"
              persistent>
        <div class="ow-video-modal">
            <LiteYouTubeEmbed :id="videoId"
                              :title="title" />
        </div>
        <template #actions>
            <div class="ow-video-modal__actions">
                <ow-button class="ow-confirmation-dialog__button-cancel"
                           data-test="ow-confirmation-dialog-button-cancel"
                           @click="$emit('cancel')">
                    {{ $t('action.cancel') }}
                </ow-button>
                <div class="support-prompt">
                    <span class="body-copy mr-2"
                          data-test="video-modal-info-msg">
                        {{ infoMessage }}
                    </span>
                    <ow-button is-secondary
                               data-test="video-modal-btn"
                               data-track="Video Modal submit button"
                               @click="$emit('submit')">
                        {{ submitMessage }}
                    </ow-button>
                </div>
            </div>
        </template>
    </ow-modal>
</template>

<script setup lang="ts">
    import 'vue-lite-youtube-embed/style.css'

    import LiteYouTubeEmbed from 'vue-lite-youtube-embed'

    import OwButton from "@/components/core/ow-button-ds.vue"
    import OwModal from '@/components/core/ow-modal'
    import i18n from "@/plugins/i18n"

    interface Props {
        show: boolean
        videoId?: string
        title?: string
        infoMessage?: string
        submitMessage?: string
    }
    const props = withDefaults(defineProps<Props>(), {
        show: false,
        videoId: 'jAInN0dz6XE',
        title: i18n.global.t('homepage.cards.copilotCtaWitnessUser.defaultVideoTitle'),
        infoMessage: i18n.global.t('homepage.cards.copilotCtaWitnessUser.infoMessage'),
        submitMessage: i18n.global.t('buttons.support'),
    })
    const emit = defineEmits<{
        (e: 'cancel'): void,
        (e: 'submit'): void
    }>()
</script>

<style scoped lang="scss">
    @import './ow-video-modal.scss';
</style>

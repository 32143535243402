<template>
    <component :is="renderComponent?.component"
               :key="renderComponent?.key"
               ref="register"
               :show="show"
               :class="{
                   'has-warning': isRegisterUploaded,
               }"
               :is-loading="isLoading"
               :is-ordering-allowed="isOrderingAllowed"
               :hide-flags="renderComponent?.data?.hideFlags"
               class="title-panel__tab-content"
               @title-number-selected="$emit('title-number-selected')" />
</template>

<script setup lang="ts">
    import { computed,
             markRaw,
             ref,
             watch} from 'vue'
    import { useStore } from 'vuex'

    import Register from '@/components/title-panel/tabs/register.vue'
    import RegisterV2 from '@/components/title-panel/tabs/register-v2.vue'
    import { DOCUMENT_SOURCE } from '@/consts/document-source'

    const props = defineProps<{
        isLoading: boolean,
        isRegisterUploaded: boolean,
        isOrderingAllowed: boolean,
        selectedTitle: any,
    }>()

    defineEmits(['title-number-selected'])

    const show = ref(true)
    const renderComponent = ref()
    const key = ref(0)

    const documentSource = computed(() => props.selectedTitle?.record?.source)
    const store = useStore()
    const render = () => {
        const scottishSpike = store.state.config.featureFlags?.scottishSpike
        if (scottishSpike) {
            const scottishDocumentSource = documentSource.value === DOCUMENT_SOURCE.SCOTLAND
            return {
                key: key.value++,
                component: markRaw(RegisterV2),
                data: {
                    hideFlags: scottishDocumentSource,
                },
            }
        }
        return {
            component: markRaw(Register),
        }
    }

    watch(() => documentSource.value, () => {
        renderComponent.value = render()
    }, { immediate: true })


</script>

<style scoped>

</style>

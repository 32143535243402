<template>
    <v-card id="multiTitlePanel"
            ref="container"
            :class="{ expand: showPanel, close: !showPanel }"
            class="multi-title-panel"
            row
            @scroll="handleScroll">
        <v-card-title>
            <div v-show="loading"
                 class="loader"
                 data-test="multi-title-panel-is-loading">
                <v-progress-linear :indeterminate="true" />
            </div>

            <div class="back-link-container">
                <a class="back-link"
                   data-test="multi-title-panel__back"
                   @click.prevent="closePanel">
                    <v-icon>$back</v-icon>
                    Back
                </a>
            </div>
        </v-card-title>

        <v-card-text id="multiTitlePanelContent"
                     data-test="multi-title-panel-content">
            <div id="header"
                 ref="searchBox">
                <h2>{{ titleFoundText }}</h2>
                <h3 class="caption-regular align-center mb-2">
                    {{ titlesNotInMatter }}
                </h3>

                <v-text-field v-model.trim="filterTitlesText"
                              class="search-box"
                              clearable
                              hide-details
                              label="Filter these titles"
                              @click:clear="onClearFilterText" />
                <div v-if="uniqueSelectedTitles.length > 0"
                     class="d-flex flex-row py-2 align-end justify-content-between multi-title-panel__group">
                    <ow-add-to-group ref="addToGroup"
                                     v-model="selectedGroup"
                                     class="multi-title-panel__group__combo"
                                     clearable />
                </div>
            </div>

            <div class="multi-title-panel__actions">
                <v-checkbox v-model="isSelectAllChecked"
                            :indeterminate="isSelectAllIndeterminate"
                            :label="`Select all (${totalFilteredTitles})`"
                            class="multi-title-panel__select-all-checkbox"
                            data-test="multi-title-panel-select-all-checkbox"
                            hide-details />

                <ow-button :disabled="isAddToMatterButtonDisabled"
                           is-primary
                           :is-loading="isAddingTitles"
                           data-test="multi-title-panel-button-add-titles"
                           @click="addSelectedTitlesToMatter">
                    Add to matter
                </ow-button>
            </div>
            <div class="multi-title-panel__title-item-sections">
                <section v-if="hasFreeholdTitles">
                    <h3 class="freehold">
                        Freehold ({{ freeholdTitles.length }})
                    </h3>
                    <ul class="multi-title-panel__titles-list"
                        data-test="multi-title-panel-freehold-list">
                        <expandable-title-details-list-item v-for="(title, i) in freeholdTitles"
                                                            :key="title.titleNumber"
                                                            :addresses="getTitleAddressArray(title)"
                                                            :is-register-ordered="title.isRegisterPurchased"
                                                            :is-register-uploaded="title.isRegisterUploaded"
                                                            :show-title-added-to-matter="!titleInMatter(title)"
                                                            :register-response-date="title.responseDate"
                                                            :is-expanded="title.titleNumber === expandedTitleNumber
                                                            "
                                                            :is-highlighted="title.titleNumber === expandedTitleNumber
                                                            "
                                                            :is-selected="title.selected"
                                                            :title-number="title.titleNumber"
                                                            :has-light-background="i % 2 === 1"
                                                            is-selectable
                                                            tenure="freehold"
                                                            see-details-button-data-track="POLYGON - See details button clicked"
                                                            @click="selectTitle"
                                                            @on-collapse="onCollapse"
                                                            @on-expand="onExpand"
                                                            @selection-change="onSelectionChange($event, title)" />
                    </ul>
                </section>
                <section v-if="hasOtherTitles">
                    <h3 class="other">
                        Others ({{ otherTitles.length }})
                    </h3>
                    <ul class="multi-title-panel__titles-list">
                        <expandable-title-details-list-item v-for="(title, i) in otherTitles"
                                                            :key="title.titleNumber"
                                                            :addresses="getTitleAddressArray(title)"
                                                            :is-register-ordered="title.isRegisterPurchased"
                                                            :is-register-uploaded="title.isRegisterUploaded"
                                                            :show-title-added-to-matter="!titleInMatter(title)"
                                                            :register-response-date="title.responseDate"
                                                            :is-expanded="title.titleNumber === expandedTitleNumber
                                                            "
                                                            :is-highlighted="title.titleNumber === expandedTitleNumber
                                                            "
                                                            :is-selected="title.selected"
                                                            :title-number="title.titleNumber"
                                                            :has-light-background="i % 2 === 1"
                                                            is-selectable
                                                            tenure="other"
                                                            see-details-button-data-track="POLYGON - See details button clicked"
                                                            @click="selectTitle"
                                                            @on-collapse="onCollapse"
                                                            @on-expand="onExpand"
                                                            @selection-change="onSelectionChange($event, title)" />
                    </ul>
                </section>
                <section v-if="hasLeaseholdTitles">
                    <h3 class="leasehold">
                        Leasehold ({{ leaseholdTitles.length }})
                    </h3>
                    <ul class="multi-title-panel__titles-list"
                        data-test="multi-title-panel-leasehold-list">
                        <expandable-title-details-list-item v-for="(title, i) in leaseholdTitles"
                                                            :key="title.titleNumber"
                                                            :addresses="getTitleAddressArray(title)"
                                                            :is-register-ordered="title.isRegisterPurchased"
                                                            :is-register-uploaded="title.isRegisterUploaded"
                                                            :show-title-added-to-matter="!titleInMatter(title)"
                                                            :register-response-date="title.responseDate"
                                                            :is-expanded="title.titleNumber === expandedTitleNumber
                                                            "
                                                            :is-highlighted="title.titleNumber === expandedTitleNumber
                                                            "
                                                            :is-selected="title.selected"
                                                            :title-number="title.titleNumber"
                                                            :has-light-background="i % 2 === 1"
                                                            is-selectable
                                                            tenure="leasehold"
                                                            see-details-button-data-track="POLYGON - See details button clicked"
                                                            @click="selectTitle"
                                                            @on-collapse="onCollapse"
                                                            @on-expand="onExpand"
                                                            @selection-change="onSelectionChange($event, title)" />
                    </ul>
                </section>
            </div>
            <div v-if="showScrollBtn"
                 class="btn-top">
                <v-btn ref="btnTop"
                       color="light grey"
                       data-test="multi-title-panel-scroll-to-top-btn"
                       icon
                       location="bottom far"
                       rounded
                       size="small"
                       title="Back to top"
                       @click="scrollToTop">
                    <v-icon>keyboard_arrow_up</v-icon>
                </v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import debounce from 'lodash.debounce'
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import ExpandableTitleDetailsListItem from '@/components/matter/titles/expandable-title-details-list-item.vue'
    import OwAddToGroup from '@/components/matter/titles/matter-side-panel-add-titles/add-to-group.vue'
    import { Route } from '@/enums/route.enum'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IState } from '@/interfaces/store/state.interface'
    import { CORE_GET_APP_ELEMENT } from '@/store/modules/core/types'
    import {
        MAP_GET_VIEW,
        MAP_REMOVE_ALL_MARKERS,
    } from '@/store/modules/map/types'
    import {
        MATTER_ADD_MULTIPLE_TITLES,
        MATTER_ADD_TITLES_TO_MATTER_AND_GROUP,
    } from '@/store/modules/matter/types'
    import { PLANNING_CLEAR } from '@/store/modules/planning/types'
    import {
        TITLE_GET_ALL_TITLE_DATA_SORTED_BY_NUMBER,
        TITLE_GET_SHOW_MULTI_TITLE_PANEL,
        TITLE_GET_TITLES_LOADING,
        TITLE_HIDE_MULTI_TITLE_SELECTION_PANEL,
        TITLE_MUTATE_COLLAPSE_PANEL,
        TITLE_MUTATE_EXPANDED_TITLE_NUMBER,
    } from '@/store/modules/titles/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        LOGGING_LOG_FEATURE_USAGE,
    } from '@/store/mutation-types'
    import {
        isNullOrEmpty,
        unique,
    } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const filterDebounceTimeout = 500

    export default {
        name: 'MultiTitlePanel',

        components: {
            ExpandableTitleDetailsListItem,
            OwButton,
            OwAddToGroup,
        },

        mixins: [flagsMixin],
        emits: ['clear-titles', 'on-collapse'],

        data() {
            return {
                filterTitlesText: '',
                showScrollBtn: false,
                isAddingTitles: false,
                filteredTitles: [],
                debouncedFilter: debounce(() => {
                    this.filterTitles()
                }, filterDebounceTimeout),
                selectedGroup: null,
            } as {
                filterTitlesText: string;
                showScrollBtn: boolean;
                isAddingTitles: boolean;
                filteredTitles: Array<any>;
                debouncedFilter: Function;
                selectedGroup: Record<string, any>;
            }
        },

        computed: {
            ...mapGetters({
                loading: TITLE_GET_TITLES_LOADING,
                titleData: TITLE_GET_ALL_TITLE_DATA_SORTED_BY_NUMBER,
                showPanel: TITLE_GET_SHOW_MULTI_TITLE_PANEL,
            }),

            ...mapState({
                currentMatter: (state: IState) => state.matter.currentMatter,
                expandedTitleNumber: (state: IState) => state.title.expandedTitleNumber,
            }),

            freeholdTitles(): Array<any> {
                const titles = [...this.filteredTitles]
                return titles?.filter((x) => x.tenure.includes('F'))
            },

            leaseholdTitles(): Array<any> {
                const titles = [...this.filteredTitles]
                return titles?.filter((x) => x.tenure.includes('L'))
            },

            otherTitles(): Array<any> {
                const titles = [...this.filteredTitles]
                return titles?.filter(
                    (x) => !x.tenure.includes('L') && !x.tenure.includes('F'),
                )
            },

            allTitles(): Array<any> {
                return this.titleData?.titles
            },

            shouldFilterTitles(): boolean {
                return (
                    !this.loading &&
                    !isNullOrWhitespace(this.filterTitlesText) &&
                    !isNullOrEmpty(this.allTitles)
                )
            },

            selectedTitles(): Array<any> {
                return this.filteredTitles?.filter((title) => title.selected)
            },

            allTitlesSelected(): boolean {
                return this.allTitles?.every((title) => title.selected)
            },

            someTitlesSelected(): boolean {
                const someSelected = this.allTitles?.filter(
                    (title) => title.selected,
                )
                // This could be the same as all selected, but want it to be false in that case
                return (
                    !isNullOrEmpty(someSelected) &&
                    someSelected.length !== this.allTitles.length
                )
            },

            hasFilterTitleText(): boolean {
                return !isNullOrWhitespace(this.filterTitlesText)
            },

            totalTitles(): number {
                return this.allTitles.length
            },

            totalFilteredTitles(): number {
                return this.filteredTitles?.length
            },

            titleFoundText(): string {
                if (
                    this.hasFilterTitleText &&
                    this.totalFilteredTitles !== this.totalTitles
                ) {
                    return `${ this.totalFilteredTitles } of ${ this.totalTitles } titles found`
                } else {
                    return `${ this.totalFilteredTitles } titles found`
                }
            },

            titlesNotInMatter(): string {
                const titles = (this?.filteredTitles ?? this.allTitles)
                const titlesNotInMatter = titles.filter(title => {
                    return this.currentMatter.selectedTitles.find(t => t.titleNumber === title.titleNumber) === undefined
                })
                if (titlesNotInMatter.length === 0) {
                    return `${ titles.length } titles in your matter`
                }
                return `${ titles.length - titlesNotInMatter.length } titles in your matter`
            },

            isAddToMatterButtonDisabled(): boolean {
                return isNullOrEmpty(this.selectedTitles)
            },

            hasFreeholdTitles(): boolean {
                return !isNullOrEmpty(this.freeholdTitles)
            },

            hasLeaseholdTitles(): boolean {
                return !isNullOrEmpty(this.leaseholdTitles)
            },

            hasOtherTitles(): boolean {
                return !isNullOrEmpty(this.otherTitles)
            },

            isSelectAllChecked: {
                get(): boolean {
                    return this.someTitlesSelected ? false : this.allTitlesSelected
                },
                set(isAllTitlesSelected: boolean): void {
                    this.setAllTitlesSelection(isAllTitlesSelected)
                },
            },

            isSelectAllIndeterminate(): boolean {
                return this.someTitlesSelected
            },

            uniqueSelectedTitles: {
                get() {
                    return unique(this.selectedTitles.map((t) => t.titleNumber))
                },
            },
        },

        watch: {
            // When loading new titles, need to clear any filter text from the search box
            loading(): void {
                this.filterTitlesText = ''
                this.selectedGroup = null
            },

            allTitles: {
                handler(): void {
                    this.debouncedFilter()
                },
                immediate: true,
            },

            filterTitlesText(): void {
                this.debouncedFilter()
            },

            selectedTitles(): void {
                if (this.selectedTitles.length === 0) {
                    this.selectedGroup = null
                }
            },
        },
        methods: {
            ...mapActions({
                removeAllMarkerLayers: MAP_REMOVE_ALL_MARKERS,
                hideMultiTitleSelectionPanel:
                    TITLE_HIDE_MULTI_TITLE_SELECTION_PANEL,
                logFeatureUsage: LOGGING_LOG_FEATURE_USAGE,
                addMultipleTitlesToMatter: MATTER_ADD_MULTIPLE_TITLES,
                addTitlesToMatterAndGroup: MATTER_ADD_TITLES_TO_MATTER_AND_GROUP,
                clearPlanningApplications: PLANNING_CLEAR,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            ...mapMutations({
                setExpandedTitleNumber: TITLE_MUTATE_EXPANDED_TITLE_NUMBER,
                collapseTitlePanel: TITLE_MUTATE_COLLAPSE_PANEL,
            }),

            closePanel(): void {
                this.removeAllMarkerLayers()
                this.hideMultiTitleSelectionPanel()
            },

            async addSelectedTitlesToMatter(): Promise<void> {
                this.isAddingTitles = true

                // move to group if selected
                if (this.selectedGroup) {
                    await this.addTitlesToMatterAndGroup({
                        matterGroupId: this.selectedGroup.id,
                        titles: this.selectedTitles,
                        showPopup: true,
                    })
                } else {
                    await this.addMultipleTitlesToMatter({
                        showPopup: !this.selectedGroup,
                        titleNumbers: this.selectedTitles.map(
                            (title) => title.titleNumber,
                        ),
                    })
                }
                this.closePanel()
                this.setAllTitlesSelection(false)
                this.isAddingTitles = false
            },

            onExpand(titleNumber: string): void {
                this.setExpandedTitleNumber(titleNumber)
                this.clearPlanningApplications()
                this.$emit('clear-titles')
                this.logHeapEvent({
                    type: 'POLYGON - Expand title list item',
                    metadata: {
                        titleNumber,
                    },
                })
            },

            onCollapse(titleNumber: string): void {
                this.$emit('on-collapse')
                this.setExpandedTitleNumber(null)
                this.logHeapEvent({
                    type: 'POLYGON - Collapse title list item',
                    metadata: {
                        titleNumber,
                    },
                })
            },

            onClearFilterText(): void {
                this.filterTitlesText = ''
            },

            setAllTitlesSelection(isSelected: boolean): void {
                this.filteredTitles.forEach((title) => {
                    title.selected = isSelected
                })
            },

            async selectTitle(titleNumber: string): Promise<void> {
                this.collapseTitlePanel(false)
                await this.$router.push({
                    name: Route.MatterMapTitle,
                    params: {
                        id: this.currentMatter.id,
                        titleNumber,
                    },
                })

                await this.logFeatureUsage({
                    type: 'map-titles-click-select',
                    description: titleNumber,
                })
            },

            handleScroll(event: any): void {
                const scrollTop = event.target.scrollTop
                this.showScrollBtn = scrollTop > 20
            },

            getTitleAddressArray(title: any): Array<string> {
                if (!isNullOrEmpty(title.addresses)) {
                    return title.addresses.map((address: any) => address.address)
                }
                return []
            },

            scrollToTop(): void {
                if (this.$refs.container?.$el) {
                    this.$refs.container.$el.scrollTop = 0
                }
            },

            onSelectionChange(isSelected: boolean, title: any): void {
                title.selected = isSelected
            },

            filterTitles(): void {
                if (this.shouldFilterTitles) {
                    const search = this.filterTitlesText.toLowerCase()

                    this.filteredTitles = this.allTitles?.filter((title) => {
                        if (title.titleNumber.toLowerCase().includes(search)) {
                            return true
                        }

                        // Not all titles include the address.
                        if (!isNullOrEmpty(title.addresses)) {
                            const titleAddress = title.addresses[0].address
                            return titleAddress.toLowerCase().includes(search)
                        }

                        return false
                    })
                } else {
                    this.filteredTitles = isNullOrEmpty(this.allTitles)
                        ? []
                        : this.allTitles
                }
            },

            titleInMatter(title) {
                return this.currentMatter.selectedTitles.find(t => t.titleNumber === title.titleNumber) === undefined
            },
        },
    }
</script>
<style lang="scss">
@import 'multi-title-panel';
</style>

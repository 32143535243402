<template>
    <div class="side-nav-actions"
         :class="{
             'side-nav-actions--drop-shadow': props.hasDropShadow,
         }">
        <div class="side-nav-actions__header d-flex label-caps-smallest ml-6 pb-3">
            {{ 'Actions' }}
        </div>
        <div v-if="props.isLoading"
             class="side-nav-actions__container d-flex flex-column w-100 gr-1">
            <div v-for="i in [0,1,2]"
                 :key="i"
                 class="side-nav-actions__action d-flex align-center mx-5 mb-2">
                <div class="w-100 d-flex gc-2">
                    <ow-loading-skeleton circle
                                         style="max-width: 20px; height: 20px;" />
                    <ow-loading-skeleton border-radius
                                         class="w-100"
                                         style="height: 20px" />
                </div>
            </div>
        </div>
        <div v-else
             class="side-nav-actions__container d-flex flex-column gr-1">
            <div v-for="action in actions"
                 :key="action.title"
                 class="side-nav-actions__action d-flex align-center mx-4"
                 tabindex="0"
                 :data-test="action?.dataTestId ?? `side-nav-action-${action?.id ?? action.title}`"
                 :data-track="action?.dataTrackId ?? `${dataTrackPrefix} - Click action ${action.title}`"
                 @click="$emit('click', action)">
                <div class="caption-highlight d-flex px-2 align-center gc-1">
                    <ow-icon v-if="action?.icon"
                             width="20px"
                             height="20px"
                             :icon="action.icon" />
                    {{ action.title }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import OwIcon from "@/components/core/ow-icon.vue"
    import OwLoadingSkeleton from "@/components/core/ow-loading-skeleton.vue"
    import { ISideNavAction } from "@/components/side-nav/index"

    const props = withDefaults(defineProps<{
        actions: ISideNavAction[]
        hasDropShadow?: boolean
        dataTrackPrefix?: string
        isLoading?: boolean
    }>(), {
        actions: () => [],
        dataTrackPrefix: 'Sidebar',
        hasDropShadow: false,
        isLoading: false,
    })

    const emit = defineEmits<{
        (e: 'click', action: ISideNavAction): void,
    }>()
</script>

<style scoped lang="scss">
@import './side-nav-actions';
</style>

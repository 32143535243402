<template>
    <div class="title-complexity-copies-filed">
        <h2 class="label-caps-smallest title-complexity-copies-filed__heading">
            {{ t('titlePanel.cards.complexity.labels.copiesFiled') }}
        </h2>

        <ow-loading-skeleton v-if="isLoading"
                             height="24px"
                             width="100%" />

        <div v-else
             class="title-complexity-copies-filed__data">
            <div class="title-complexity-copies-filed__data--text caption-regular">
                <span class="caption-highlight">{{ purchasedDocumentCount }}</span> of {{ totalDocumentCount }}
                documents purchased
            </div>
            <div class="title-complexity-copies-filed__progress-bar">
                <div class="title-complexity-copies-filed__progress-bar--bar">
                    <div :style="{ width: `${percentOfDocumentsPurchased}%` }"
                         class="title-complexity-copies-filed__progress-bar--fill" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const props = defineProps<{
        copiesFiledDocuments: Array<any>,
        isLoading: boolean,
    }>()

    const { t } = useI18n()


    const purchasedDocumentCount = computed<number>(() => {
        if (isNullOrEmpty(props.copiesFiledDocuments)) {
            return 0
        }

        return props.copiesFiledDocuments?.filter((document: any) => !isNullOrWhitespace(document?.downloadUrl))?.length ?? 0
    })
    const totalDocumentCount = computed<number>(() => props.copiesFiledDocuments?.length ?? 0)
    const percentOfDocumentsPurchased = computed<number>(() => {
        if (totalDocumentCount.value === 0) {
            return 0
        }

        return ((purchasedDocumentCount.value / totalDocumentCount.value) * 100) ?? 0
    })
</script>

<style lang="scss" scoped>
    @import './title-complexity-copies-filed';
</style>

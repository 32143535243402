import axios from 'axios'

import SecurityService from '@/api/security'
import { StatusCode } from '@/consts/status-code'
import { AppVersion } from '@/models/app-version.model'
import { getApiUri } from '@/utils/environment.utils'

/**
 * A wrapper around axios for making server requests
 */
export class HttpClient {
    // These urls have 401 as a valid response code
    whitelistedUrlRegexes = [
        /^linkshare\/check-access\/([0-9a-zA-Z]*)$/,
    ]

    constructor() {
        this.instance = axios.create({
            baseURL: getApiUri(),
            timeout: 1800000,
        })
    }

    /**
     * GET data through axios
     * @param url {String} - the endpoint to post data to
     * @param options {Object} - the axios configuration
     * @param includeAuthHeader {Boolean} - include the Authorization token in
     *     the header if true
     * @returns {Promise<T | (*&{ok: boolean})>}
     */
    async get(url, options = {}, includeAuthHeader = true) {
        let config = options
        if (includeAuthHeader) {
            config = await this.addAuthHeader(options)
        }

        return this.instance
            .get(url, config)
            .then(response => ({
                ...response,
                ok: true,
            }))
            .catch(error => {
                return this.errorResponseHandler(error)
            })
    }

    /**
     * POST data through axios
     * @param url {String} - the endpoint to post data to
     * @param data {Object} - the data to post
     * @param options {Object} - the axios configuration
     * @param includeAuthHeader {Boolean} - include the Authorization token in
     *     the header if true
     * @returns {Promise<T | (*&{ok: boolean})>}
     */
    async post(url, data, options = {}, includeAuthHeader = true) {
        let config = options
        if (includeAuthHeader) {
            config = await this.addAuthHeader(options)
        }

        return this.instance
            .post(url, data, config)
            .then(response => ({
                ...response,
                ok: true,
            }))
            .catch(error => {
                return this.errorResponseHandler(error)
            })
    }

    /**
     * PUT data through axios
     * @param url {String} - the endpoint to post data to
     * @param data {Object} - the data to post
     * @param options {Object} - the axios configuration
     * @param includeAuthHeader {Boolean} - include the Authorization token in
     *     the header if true
     * @returns {Promise<T | (*&{ok: boolean})>}
     */
    async put(url, data, options = {}, includeAuthHeader = true) {
        let config = options
        if (includeAuthHeader) {
            config = await this.addAuthHeader(options)
        }

        return this.instance
            .put(url, data, config)
            .then(response => ({
                ...response,
                ok: true,
                message: '',
            }))
            .catch(error => {
                return this.errorResponseHandler(error)
            })
    }

    /**
     * DELETE data through axios
     * @param url
     * @param options
     * @param includeAuthHeader
     * @returns {Promise<T>}
     */
    async delete(url, options = {}, includeAuthHeader = true) {
        let config = options
        if (includeAuthHeader) {
            config = await this.addAuthHeader(options)
        }
        return this.instance
            .delete(url, config)
            .then(response => ({
                ...response,
                ok: true,
                message: '',
            }))
            .catch(error => {
                return this.errorResponseHandler(error)
            })
    }

    /**
     * Send all the promises to the server together to be resolved
     * @param promises {Array} - of the axios promises
     * @returns {Promise<[]>}
     */
    async getAllByPromise(promises) {
        return axios.all(promises)
    }

    /**
     * Add the Authorization header and token in to the axios configuration
     * @param options {Object} - axios configuration options
     * @return {Promise<Object>}
     */
    async addAuthHeader(options) {
        const token = await SecurityService.getAccessToken()
        if (!token) {
            return null
        }

        return {
            ...options,
            headers: {
                ...options?.headers,
                Authorization: `Bearer ${ token }`,
            },
        }
    }

    async errorResponseHandler(error) {
        if (error?.response?.status === StatusCode.UNAUTHORIZED) {
            if (!this.whitelistedUrlRegexes.some(regex => regex.test(error.config.url))) {
                await SecurityService.signOutRedirect()
                return
            }
            return
        }

        if (this.isCancelledError(error)) {
            // The request was cancelled, take no action
            return
        }

        console.info(`appVersion: ${ AppVersion.currentAppVersion }`)

        if (error?.response &&
            error?.response?.status !== StatusCode.FORBIDDEN) {
            // Request made and server responded
            console.error('axios error', error.response.status, error.response.data, error.response.headers)
        } else if (error?.request) {
            // The request was made but no response was received
            console.error('axios error, no response', error.request)
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('axios error, unable to setup request', error)
        }
        throw error
    }

    isCancelledError(error) {
        return error.constructor.name === 'Cancel' || axios.isCancel(error)
    }
}

export default new HttpClient()

<template>
    <router-link :class="{'current-matter': isCurrentMatter, 'is-newly-shared': isNewlyShared, 'is-closed': !isMatterOpen}"
                 :data-matterid="matter.id"
                 :to="matterLink"
                 class="matters-list-item is-matter-activity-states-enabled"
                 data-test="matters-list-item"
                 @click="onClick">
        <div v-if="show"
             class="matters-list-item__wrapper">
            <div class="matters-list-item__column-left">
                <div class="matters-list-item__matter-name-wrapper">
                    <span class="matters-list-item__matter-name content__heading hide-in-percy">
                        {{ matter.name }}
                    </span>
                    <ow-colored-label v-if="isNewlyShared"
                                      :is-notification-dot="true"
                                      margin="0 0 0 12" />
                </div>
                <div>
                    <div class="hide-in-percy body-copy--greyout">
                        Created on: <span>{{ formatDate(matter.createdTimestamp) }}</span> by
                        <span>{{ matter.createdByUserName }}</span>
                    </div>
                </div>
            </div>
            <div class="hide-in-percy matters-list-item__column-middle">
                <span v-if="matter.code">{{ matter.code }}</span>
                <span v-if="matter.clientCode"
                      class="bullet">&#9679;</span>
                <span v-if="matter.clientCode">{{ matter.clientCode }}</span>
                <span v-if="matter.clientCode"
                      class="bullet">&#9679;</span>
                <span v-if="matter.type">{{ matter.type }}</span>
            </div>
            <div class="matters-list-item__column-right">
                <div class="matters-list-item__matter-metrics">
                    <!-- Link share -->
                    <div class="matters-list-item__matter-metrics-link-share">
                        <ow-tooltip v-if="matter.hasLinkShare"
                                    :disabled="!matter.linkShareTipText"
                                    :position="OwTooltipPosition.Left"
                                    activator="parent">
                            <template #activator>
                                <span class="metric is-public-icon">
                                    <v-icon :class="{'is-disabled': matter.hasDisabledLinkShare || matter.hasExpiredLinkShare}"
                                            class="matters-list-item__icon-public">
                                        $matter-link-share
                                    </v-icon>
                                </span>
                            </template>
                            <span data-test="matter-list-item-shared-with-text">
                                {{ matter.linkShareTipText }}
                            </span>
                        </ow-tooltip>
                    </div>

                    <!-- Permissions -->
                    <div class="matters-list-item__matter-metrics-permissions">
                        <ow-tooltip :disabled="!matter.sharedWithText"
                                    :position="OwTooltipPosition.Left"
                                    activator="parent">
                            <template #activator>
                                <span :class="{'not-applicable': !matter.sharedWithTextShort}"
                                      class="metric">
                                    <v-icon v-if="matter.sharedWithOrganisation || matter.sharedWithSpecificUsers"
                                            class="matters-list-item__icon-person">
                                        $matter-shared
                                    </v-icon>
                                    <span v-if="matter.sharedWithTextShort"
                                          class="matters-list-item__count-label">{{ matter.sharedWithTextShort }}</span>
                                </span>
                            </template>
                            <span data-test="matter-list-item-shared-with-text">
                                {{ matter.sharedWithText }}
                            </span>
                        </ow-tooltip>
                    </div>

                    <!-- Title count -->
                    <div class="matters-list-item__matter-metrics-title-count">
                        <ow-tooltip :position="OwTooltipPosition.Left"
                                    activator="parent">
                            <template #activator>
                                <span :class="{'not-applicable': matter.titleCount === 0}"
                                      class="metric">
                                    <v-icon class="matters-list-item__icon-domain">$title</v-icon>
                                    <span v-if="matter.titleCount > 0"
                                          class="matters-list-item__count-label">
                                        {{ matter.titleCount }}
                                    </span>
                                </span>
                            </template>
                            <span>{{ matter.titleCount }} titles in the matter</span>
                        </ow-tooltip>
                    </div>

                    <matter-activity-toggle :matter="matter" />
                </div>
                <div v-if="matter.lastAccessed"
                     class="hide-in-percy body-copy--greyout text-right matters-list-item__last-accessed-label">
                    Last accessed <span>{{ formatDate(matter.lastAccessed) }}</span>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script lang="ts">
    import {
        mapActions,
        mapState,
    } from 'vuex'

    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import MatterActivityToggle from '@/components/matter/matter-activity-toggle.vue'
    import useDates from '@/composables/use-dates'
    import { MatterState } from '@/consts/matter'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { appendMatterHintText } from '@/utils/matter-utils'

    export default {
        name: 'MattersListItem',

        components: {
            MatterActivityToggle,
            OwColoredLabel,
            OwTooltip,
        },

        mixins: [flagsMixin],

        props: {
            matter: {
                type: Object,
                required: true,
            },
            isNewlyShared: {
                type: Boolean,
                required: false,
            },
            intersectionObserverParent: {
                type: String,
                required: false,
                default: null,
            },
        },
        emits: ['select-matter'],

        data: () => {
            return {
                element: null, // A reference to the component element determined on mounted().
                scrollVisibility: false, // Used to determine whether an element should be visible based on it's scroll position.
                scrollElement: null, // The parent scrollable element.
                scrollObserver: null, // The Intersection Observer used to detect visibility changes.
                OwTooltipPosition,
            }
        },

        computed: {
            ...mapState({
                currentMatter: state => state.matter.currentMatter,
            }),

            isCurrentMatter() {
                return this.currentMatter?.id === this.matter.id
            },

            matterLink() {
                return this.isMatterOpen ? `/matters/${ this.matter.id }/titles` : `/matters/${ this.matter.id }/documents`
            },

            isMatterOpen() {
                return this.matter.state === MatterState.Open
            },

            show() {
                return this.scrollVisibility || !this.intersectionObserverParent
            },
        },

        watch: {
            matter: {
                handler() {
                    appendMatterHintText(this.matter)
                },
                immediate: true,
            },
        },

        mounted() {
            this.$nextTick(() => {
                if (this.intersectionObserverParent) {
                    this.initialiseIntersectionObserver()
                }
            })
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            onClick() {
                this.$emit('select-matter', this.matter)
                if (!this.isMatterOpen) {
                    this.logHeapEvent({
                        type: 'Accessing closed matter',
                        metadata: {
                            matterId: this.matter.id,
                        },
                    })
                }
            },

            initialiseIntersectionObserver() {
                // Listen for changes to the position of this component within it's parent scrollable element
                if (this.scrollObserver === null) {
                    this.element = this.$el
                    this.scrollElement = document.querySelector(this.intersectionObserverParent)
                    if (this.scrollElement) {
                        const options = {
                            root: this.scrollElement,
                            rootMargin: '100px',
                            threshold: 1.0,
                        }

                        this.scrollObserver = new IntersectionObserver((entries) => {
                            entries.forEach((entry: any) => {
                                if (entry.target.dataset.matterid == this.matter.id) {
                                    this.scrollVisibility = entry.intersectionRatio > 0
                                }
                            })
                        }, options)
                        this.scrollObserver.observe(this.element)
                    }
                }
            },

            formatDate(value: Date): string {
                const {formatDate} = useDates()
                return formatDate(value)
            },
        },
    }

</script>
<style lang="scss">
    @import 'matters-list-item';
</style>

<template>
    <div data-test="matter-link-share-settings">
        <div class="d-flex flex-column matter-link-share-settings">
            <v-progress-linear v-show="loading"
                               class="matter-link-share-settings__progress"
                               indeterminate />

            <div class="d-flex align-center matter-link-share-settings__header">
                <div>
                    <h3 class="matter-link-share-settings__title"
                        data-test="matter-link-share-settings-title">
                        {{ $t('matter.share.shareMatter.title', { sharingType}) }}
                    </h3>

                    <div class="matter-link-share-settings__subtitle">
                        {{ $t('matter.share.shareMatter.subtitle', { sharingType}) }}
                        <a :href="`https://intercom.help/orbital-witness/en/articles/${ helpArticleIdentifier }`"
                           data-track="MAT-SETTINGS - Open help for client link share"
                           target="_blank">{{ $t('buttons.readMore') }}</a>
                    </div>
                </div>

                <div v-if="hasBeenShared"
                     class="mr-4">
                    <v-switch v-if="isForWalkthroughOnly"
                              v-model="isWalkthroughShare"
                              color="primary"
                              data-test="matter-link-share-settings-walkthrough-toggle"
                              hide-details
                              @update:model-value="onWalkthroughToggleChange" />
                    <v-switch v-else
                              v-model="enabled"
                              color="primary"
                              data-test="matter-link-share-settings-enable-toggle"
                              hide-details />
                </div>
            </div>

            <div v-if="hasBeenShared"
                 class="d-flex flex-column">
                <div :disabled="!isShareableLinkEnabled"
                     class="d-flex flex-row align-center matter-link-share-settings__url">
                    <v-text-field ref="urlLinkInput"
                                  v-model="URLForLink"
                                  :disabled="!isShareableLinkEnabled"
                                  data-test="matter-link-share-settings-url-for-link"
                                  data-track="MAT-SETTINGS - Share link url"
                                  hide-details
                                  variant="outlined"
                                  readonly
                                  type="text">
                        <template #append-inner>
                            <v-btn :disabled="!isShareableLinkEnabled"
                                   data-test="link-share-settings-copy-button"
                                   data-track="MAT-SETTINGS - Copy share link url to clipboard"
                                   icon
                                   size="small"
                                   @click="onCopyButtonClick">
                                <v-icon>$copy</v-icon>
                                <ow-tooltip v-model="showCopiedLinkTip"
                                            activator="parent"
                                            :show-on-click="true"
                                            :position="OwTooltipPosition.Left">
                                    <span>{{ $t('matter.share.copyLinkToClipboard') }}</span>
                                </ow-tooltip>
                            </v-btn>
                        </template>
                    </v-text-field>
                    <ow-button v-if="isShareableLinkEnabled"
                               data-test="matter-link-share-settings-preview-button"
                               data-track="MAT-SETTINGS - Preview shareable link"
                               full-height
                               is-secondary
                               @click="onPreviewClientShareClick">
                        {{ $t('matter.share.preview') }}
                    </ow-button>
                </div>
            </div>
            <div v-else
                 class="d-flex flex-column">
                <ow-button :disabled="loading"
                           :loading="loading"
                           color="primary"
                           dark
                           data-test="matter-link-share-settings-create-button"
                           data-track="MAT-SETTINGS - Create a shareable link"
                           large
                           @click="onCreateClick">
                    {{ $t('matter.share.createLink') }}
                </ow-button>
            </div>

            <div v-if="canBeUpdated && linkShare.enabled && !isForWalkthroughOnly && !areOptionsExpanded"
                 class="d-flex flex-row matter-link-share-settings__checkboxes"
                 data-test="matter-link-share-settings-checkboxes">
                <matter-link-share-checkbox v-model="isIspShare"
                                            class="matter-link-share-settings__checkboxes-row"
                                            data-test="matter-link-share-settings-isp-checkbox"
                                            :label="$t('matter.share.isp.title')">
                    <template #help-text>
                        <div class="d-flex flex-column accents-small align-center justify-center">
                            <div class="text-center">
                                {{ $t('matter.share.isp.subtitle') }}
                            </div>
                            <a class="accents-highlight"
                               :href="`https://intercom.help/orbital-witness/en/articles/4687764-interactive-walkthroughs-explained`"
                               data-track="MAT-SETTINGS - Open help for client link share"
                               target="_blank">{{ $t('buttons.readMore') }}</a>
                        </div>
                    </template>
                    <template #icon>
                        <img src="@/media/interactive-site-plan.svg" />
                    </template>
                </matter-link-share-checkbox>

                <matter-link-share-checkbox v-model="isWalkthroughShare"
                                            class="matter-link-share-settings__checkboxes-row"
                                            data-test="matter-link-share-settings-walkthrough-checkbox"
                                            :label="$t('matter.share.walkthrough.title')">
                    <template #help-text>
                        <div class="d-flex flex-column accents-small align-center justify-center">
                            <div class="text-center">
                                {{ $t('matter.share.walkthrough.subtitle') }}
                            </div>
                            <a class="accents-highlight"
                               :href="`https://intercom.help/orbital-witness/en/articles/4687764-interactive-walkthroughs-explained`"
                               data-track="MAT-SETTINGS - Open help for client link share"
                               target="_blank">{{ $t('buttons.readMore') }}</a>
                        </div>
                    </template>
                    <template #icon>
                        <img src="@/media/interactive-walkthrough.svg" />
                    </template>
                </matter-link-share-checkbox>
            </div>
        </div>
        <button v-if="canBeUpdated"
                class="matter-link-share-settings__options-icon-label"
                data-test="matter-link-share-settings-options-button"
                data-track="MAT-SETTINGS - Toggle link share options"
                @click="toggleOptions">
            <v-icon class="matter-link-share-settings__options-icon">
                {{ areOptionsExpanded ? '$chevron-up' : '$chevron-down' }}
            </v-icon>
            {{ areOptionsExpanded ? $t('matter.share.hideOptions') : $t('matter.share.showOptions') }}
        </button>
        <div v-if="linkShare && areOptionsExpanded"
             class="d-flex flex-column matter-link-share-settings__options">
            <v-checkbox v-model="linkShare.passwordProtected"
                        class="matter-link-share-settings__password-checkbox"
                        color="primary"
                        data-test="matter-link-share-settings-checkbox-password"
                        data-track="MAT-SETTINGS - Toggle password protection"
                        hide-details
                        :hint-text="$t('matter.share.passwordDescription')"
                        :label="$t('matter.share.password')" />

            <v-text-field v-if="linkShare.passwordProtected"
                          v-model="newPassword"
                          :append-icon="showPassword ? '$visibility' : '$visibility-off'"
                          :label="passwordInputLabel"
                          :type="showPassword ? 'text' : 'password'"
                          data-test="matter-link-share-settings-input-password"
                          data-track="MAT-SETTINGS - Set password"
                          @click:append="showPassword = !showPassword" />

            <v-menu ref="expiresDatePickerMenu"
                    v-model="expiresDatePickerMenu"
                    v-model:return-value="linkShare.expiresOn"
                    :close-on-content-click="false"
                    offset="40"
                    min-width="290px"
                    transition="scale-transition">
                <template #activator="{ props }">
                    <v-text-field v-model="linkShare.expiresOn"
                                  clearable
                                  data-track="MAT-SETTINGS - Set link expiry date"
                                  :hint="$t('matter.share.expiryDateDescription')"
                                  :label="$t('matter.share.expiryDate')"
                                  persistent-hint
                                  hide-details
                                  prepend-icon="$calendar"
                                  readonly
                                  v-bind="props" />
                </template>
                <v-date-picker v-model="linkShare.expiresOn"
                               :min="minExpiryDate"
                               data-track="MAT-SETTINGS - Open calendar to pick expiry date"
                               no-title
                               scrollable>
                    <v-spacer />
                    <v-btn color="primary"
                           data-track="MAT-SETTINGS - Cancel calendar picker"
                           variant="text"
                           @click="expiresDatePickerMenu = false">
                        {{ $t('action.cancel') }}
                    </v-btn>
                    <v-btn color="primary"
                           data-track="MAT-SETTINGS - Ok date on calendar picker"
                           variant="text"
                           @click="$refs.expiresDatePickerMenu.save(linkShare.expiresOn)">
                        {{ $t('action.ok') }}
                    </v-btn>
                </v-date-picker>
            </v-menu>
        </div>
        <div v-if="canBeUpdated && areOptionsExpanded"
             class="matter-link-share-settings__options-actions">
            <v-btn :disabled="!hasChanges || !isValid"
                   color="primary"
                   data-test="matter-link-share-settings-button-save-changes"
                   data-track="MAT-SETTINGS - Save changes to external sharing"
                   size="large"
                   @click="saveChanges">
                {{ $t('action.saveChanges') }}
            </v-btn>
        </div>
        <v-fade-transition>
            <div v-if="showChangesSaved"
                 class="matter-link-share-settings__save-changes px-2 py-1">
                {{ $t('label.changesSaved') }}
            </div>
        </v-fade-transition>
    </div>
</template>
<script>
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import {
        MATTER_CREATE_LINK_SHARE,
        MATTER_ENABLE_LINK_SHARE,
        MATTER_GET_LINK_SHARE_URL,
        MATTER_MUTATE_LINK_SHARE_DIRTY,
        MATTER_MUTATE_PENDING_CHANGES_TO_LINK_SHARE_SETTINGS,
        MATTER_MUTATE_SHOW_LINK_SHARE_SETTINGS_DIALOG,
        MATTER_UPDATE_LINK_SHARE,
        MATTER_ZOOM_TO_CURRENT_MATTER_EXTENT,
    } from '@/store/modules/matter/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        OPEN_WINDOW,
    } from '@/store/mutation-types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    import MatterLinkShareCheckbox from './matter-link-share-checkbox.vue'

    export default {
        name: 'MatterLinkShareSettings',

        components: {
            OwTooltip,
            OwButton,
            MatterLinkShareCheckbox,
        },

        props: {
            isPreventPreviewEnabled: {
                type: Boolean,
                required: false,
            },
            isForWalkthroughOnly: {
                type: Boolean,
                required: false,
            },
        },

        emits: [
            'prevent-preview',
            'log-heap-event',
        ],

        data() {
            return {
                showCopiedLinkTip: false,
                activeTab: 1,
                showPassword: false,
                newPassword: null,

                expiresDatePickerMenu: false,
                hasExpiry: false,
                areOptionsExpanded: false,

                OwTooltipPosition,
                showChangesSavedHandler: null,
                showChangesSaved: false,
            }
        },

        computed: {
            ...mapGetters({
                URLForLink: MATTER_GET_LINK_SHARE_URL,
            }),

            ...mapState({
                originalLinkShare: state => state.matter.currentMatter.linkShare,
                linkShareDirty: state => state.matter.currentMatter.linkShareDirty,
                loading: state => state.matter.currentMatter.loading.linkShare,
                currentMatter: state => state.matter.currentMatter,
                getShowMatterLinkSettings: state => state.matter.prompts.linkShare.show,
            }),

            linkShare: {
                get() {
                    return this.linkShareDirty
                },
                set(val) {
                    this.setLinkShareDirty(val)
                },
            },

            show: {
                get() {
                    return this.getShowMatterLinkSettings
                },
                set(val) {
                    this.setShowMatterLinkSettings(val)
                    if (val === true) {
                        this.storeLocalCopyOfLinkShare()
                    }
                },
            },

            enabled: {
                get() {
                    return this.linkShare?.enabled
                },
                async set(val) {
                    this.linkShare.enabled = val
                    await this.saveChanges()
                    this.logHeapEvent({
                        type: 'MAT-SETTINGS - Enable shareable link',
                        metadata: {
                            value: val,
                        },
                    })
                },
            },

            isIspShare: {
                get() {
                    return this.linkShare?.isIspShare
                },
                async set(val) {
                    this.linkShare.isIspShare = val
                    await this.saveChanges()
                    this.logHeapEvent({
                        type: 'MAT-SETTINGS - Toggle ISP share availability',
                        metadata: {
                            value: val,
                        },
                    })
                },
            },

            isWalkthroughShare: {
                get() {
                    return this.linkShare?.isWalkthroughShare
                },
                async set(val) {
                    this.linkShare.isWalkthroughShare = val
                    await this.saveChanges()
                    this.logHeapEvent({
                        type: 'MAT-SETTINGS - Toggle walkthrough share availability',
                        metadata: {
                            value: val,
                        },
                    })
                },
            },

            sharingType() {
                return this.isForWalkthroughOnly ? 'walkthrough' : 'matter'
            },

            hasBeenShared() {
                return this.originalLinkShare !== null
            },

            hasChanges() {
                return Boolean((JSON.stringify(this.linkShare) !== JSON.stringify(this.originalLinkShare)) ||
                    this.newPassword)
            },

            // Validates the dialog, used to prevent submission of invalid data.
            isValid() {
                if (this.linkShare) {
                    // Validate password setting.
                    if (this.passwordSettingUpdated) {
                        if (this.validPassword) {
                            return true
                        }
                        return false
                    }
                }
                return true
            },

            validPassword() {
                return (this.linkShare.passwordProtected && this.newPassword?.length > 3) ||
                    !this.linkShare.passwordProtected
            },

            helpArticleIdentifier() {
                return this.isForWalkthroughOnly ? '4687764-interactive-walkthroughs-explained' : '4689498-interactive-site-plans-explained'
            },

            minExpiryDate() {
                return new Date().toISOString()
            },

            passwordInputLabel() {
                return this.originalLinkShare?.passwordProtected ? this.$t('matter.share.passwordOverwrite') : this.$t('matter.share.passwordOptional')
            },

            canBeUpdated() {
                return this.hasBeenShared
            },

            isShareableLinkEnabled() {
                return this.linkShare?.enabled && (this.linkShare.isIspShare || this.linkShare.isWalkthroughShare)
            },

            passwordSettingUpdated() {
                return this.originalLinkShare?.passwordProtected !== this.linkShare?.passwordProtected ||
                    !isNullOrWhitespace(this.newPassword)
            },
        },

        watch: {
            show(val) {
                if (val) {
                    this.activeTab = 0
                }
            },

            originalLinkShare() {
                this.storeLocalCopyOfLinkShare()
            },

            'linkShare.title'(val) {
                if (val === '') {
                    this.linkShare.title = null
                }
            },

            'linkShare.description'(val) {
                if (val === '') {
                    this.linkShare.description = null
                }
            },

            hasChanges(val) {
                this.setHasPendingChanges(val)
            },

            'linkShare.passwordProtected'(val) {
                if (!val) {
                    this.newPassword = null
                }
                this.logHeapEvent({
                    type: 'MAT-SETTINGS - Toggle password protection',
                    metadata: {
                        value: val,
                    },
                })
            },

            'linkShare.expiresOn'(val) {
                this.logHeapEvent({
                    type: 'MAT-SETTINGS - Open calendar to pick expiry date',
                    metadata: {
                        value: val,
                    },
                })
            },

            showChangesSaved(value) {
                if (this.showChangesSavedHandler) {
                    clearTimeout(this.showChangesSavedHandler)
                }
                if (value) {
                    this.showChangesSavedHandler = setTimeout(() => {
                        this.showChangesSaved = false
                    }, 1000)
                }
            },
        },

        created() {
            this.storeLocalCopyOfLinkShare()
        },

        methods: {
            ...mapMutations({
                setShowMatterLinkSettings: MATTER_MUTATE_SHOW_LINK_SHARE_SETTINGS_DIALOG,
                setLinkShareDirty: MATTER_MUTATE_LINK_SHARE_DIRTY,
                setHasPendingChanges: MATTER_MUTATE_PENDING_CHANGES_TO_LINK_SHARE_SETTINGS,
            }),

            ...mapActions({
                openWindow: OPEN_WINDOW,
                createShareableLink: MATTER_CREATE_LINK_SHARE,
                updateShareableLink: MATTER_UPDATE_LINK_SHARE,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            storeLocalCopyOfLinkShare() {
                this.linkShare = { ...this.originalLinkShare }
                this.hasExpiry = this.originalLinkShare?.expiresOn?.length > 0
                this.newPassword = null
            },

            async onCreateClick() {
                await this.createShareableLink({
                    enabled: true,
                    isIspShare: !this.isForWalkthroughOnly,
                    isWalkthroughShare: this.isForWalkthroughOnly,
                })
                this.storeLocalCopyOfLinkShare()
            },

            onCopyButtonClick() {
                this.$refs.urlLinkInput.$el.getElementsByTagName('input')[0].select()
                document.execCommand('copy')
                this.showCopiedLinkTip = true
                setTimeout(() => {
                    this.showCopiedLinkTip = false
                }, 2000)
                getSelection().removeAllRanges()
            },

            onWalkthroughToggleChange(val) {
                if (this.isForWalkthroughOnly && !this.originalLinkShare.enabled) {
                    this.linkShare.enabled = val
                }
            },

            async saveChanges() {
                const request = {
                    matterId: this.currentMatter.id,
                    title: this.linkShare.title,
                    description: this.linkShare.description,
                    password: this.linkShare.passwordProtected ? this.newPassword : null,
                    updatePassword: this.passwordSettingUpdated,
                    enabled: this.linkShare.enabled,
                    configurationJSON: this.linkShare.configurationJSON,
                    expiresOn: this.linkShare.expiresOn,
                    isIspShare: this.linkShare?.isIspShare && this.linkShare?.enabled ? this.linkShare.isIspShare : false,
                    isWalkthroughShare: this.linkShare?.isWalkthroughShare && this.linkShare?.enabled ? this.linkShare.isWalkthroughShare : false,
                }
                await this.updateShareableLink(request)

                this.showChangesSaved = true
            },

            toggleOptions() {
                this.areOptionsExpanded = !this.areOptionsExpanded
            },

            openLinkShare() {
                this.openWindow(this.URLForLink)
            },

            onPreviewClientShareClick() {
                if (this.isPreventPreviewEnabled) {
                    this.$emit('prevent-preview')
                } else {
                    this.openLinkShare()
                    this.$emit('log-heap-event')
                }
            },
        },
    }
</script>

<style lang="scss">
    @import 'matter-link-share-settings.scss';
</style>

<template>
    <v-container id="organisationsPage">
        <div class="admin-organisations__table-header">
            <ow-button-ds :loading="loading"
                          is-primary
                          data-test="admin-organisations-admin-add-organisation"
                          size="large"
                          @click="openOrganisationFormDialog(false)">
                {{ $t('admin.organisation.add') }}
                <template #iconPrefix>
                    <v-icon>$organisation</v-icon>
                </template>
            </ow-button-ds>
            <ow-button-ds :loading="loading"
                          is-primary
                          size="large"
                          @click="getOrganisations()">
                {{ $t('action.refresh') }}
                <template #iconPrefix>
                    <v-icon>$refresh</v-icon>
                </template>
            </ow-button-ds>
        </div>
        <ow-data-grid :headers="headers"
                      :fixed-header="true"
                      :item-key="'id'"
                      :items="organisations"
                      :items-per-page="10"
                      :allow-selection="false"
                      data-test="admin-organisations-list-table"
                      class="admin-table"
                      hide-default-footer
                      disable-pagination
                      hover
                      :sort-by="sortBy">
            <template #[`item.sections`]="{ item }">
                <span style="padding-right: 10px;">{{ item.sections.length }}</span>

                <a v-if="item.sections.length > 0"
                   style="padding-right: 5px;"
                   :data-test="`admin-organisations-view-section-${ item.name }`"
                   @click="openOrganisationSectionsListDialog(item)">View</a>
                <span v-if="item.sections.length > 0"
                      style="padding-right: 5px;">/</span>
                <a :data-test="`admin-organisations-add-section-${ item.name }`"
                   @click="onAddOrganisationSectionClick(item)">Add</a>
            </template>
            <template #[`item.users`]="{ item }">
                {{ item.activeUsersCount + item.inactiveUsersCount }} ({{
                    item.activeUsersCount
                }}, {{ item.inactiveUsersCount }}) / {{ item.maxStandardUsers + item.maxPremiumUsers }}
            </template>
            <template #[`item.pricingModelId`]="{ item }">
                <td :data-test="`admin-organisations-pricing-model-${ item.name }`">
                    {{ pricingPlanNames[item.pricingModelId] }}
                </td>
            </template>
            <template #[`item.ppcStandardCharge`]="{ item }">
                {{ item.ppcStandardCharge !== null ? `£${item.ppcStandardCharge}` : '' }}
            </template>
            <template #[`item.edit`]="{ item }">
                <v-btn icon
                       :data-test="`admin-organisations-edit-org-${ item.name }`"
                       variant="text"
                       @click="openEditOrganisationDialog(item)">
                    <v-icon>$edit</v-icon>
                </v-btn>
            </template>
        </ow-data-grid>

        <ow-modal v-model="isOrganisationFormDialogOpened"
                  :title="isEditOrganisationMode ? $t('admin.organisation.edit') : $t('admin.organisation.add')"
                  :persistent="true"
                  content-class="organisation-sections-modal__organisation-form">
            <template v-if="isEditOrganisationMode"
                      #subtitleSlot>
                <licence-overview :active-users-count="licenceCounts.activeUsersCount"
                                  :max-users-count="licenceCounts.maxUsersCount"
                                  :active-lite-users-count="licenceCounts.activeLiteUsersCount"
                                  :max-lite-users-count="licenceCounts.maxLiteUsersCount"
                                  :active-standard-users-count="licenceCounts.activeStandardUsersCount"
                                  :max-standard-users-count="licenceCounts.maxStandardUsersCount"
                                  :active-premium-users-count="licenceCounts.activePremiumUsersCount"
                                  :max-premium-users-count="licenceCounts.maxPremiumUsersCount" />
            </template>
            <organisation-form :is-edit="isEditOrganisationMode"
                               @on-cancel-click="closeOrganisationFormDialog"
                               @after-submit="closeOrganisationFormDialog" />
        </ow-modal>

        <ow-modal v-model="isOrganisationSectionsDialogOpened"
                  :title="$t('admin.organisation.sections')"
                  :subtitle="selectedOrganisation.organisationName">
            <organisation-sections-list :sections="selectedOrganisation.sections"
                                        @on-close-click="closeOrganisationSectionsListDialog"
                                        @on-edit-click="editOrganisationSection" />
            <template #actions>
                <v-btn class="organisation-sections-list__button-close"
                       color="primary"
                       variant="text"
                       data-test="organisation-section-list-button-close"
                       @click="closeOrganisationSectionsListDialog">
                    {{ $t('action.close') }}
                </v-btn>
            </template>
        </ow-modal>

        <ow-modal v-model="isOrganisationSectionFormDialogOpened"
                  :title="isEditOrganisationSectionMode ? $t('admin.organisation.editSection') : $t('admin.organisation.addSection')"
                  :subtitle="selectedOrganisationSection.organisationName">
            <organisation-section-form :is-edit="isEditOrganisationSectionMode"
                                       @on-cancel-click="closeOrganisationSectionFormDialog"
                                       @after-submit="closeOrganisationSectionFormDialog" />
        </ow-modal>
    </v-container>
</template>
<script>
    import { ref } from 'vue'
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import LicenceOverview from '@/components/admin/licences/licence-overview.vue'
    import OrganisationForm from '@/components/admin/organisations/organisation-form.vue'
    import OrganisationSectionForm from '@/components/admin/organisations/organisation-section-form.vue'
    import OrganisationSectionsList from '@/components/admin/organisations/organisation-sections-list.vue'
    import OwButtonDs from "@/components/core/ow-button-ds.vue"
    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import OwModal from "@/components/core/ow-modal.vue"
    import { pricingPlanNames } from '@/consts/pricing-plans.js'
    import {
        GET_LIST,
        LOAD_LIST,
        MUTATE_INITIALISE_SELECTED_ORGANISATION,
        MUTATE_SELECTED_ORGANISATION,
        MUTATE_SELECTED_ORGANISATION_SECTION,
    } from '@/store/modules/organisation/types'

    export default {
        name: 'AdminOrganisations',

        components: {
            OwButtonDs,
            OwModal,
            OrganisationForm,
            OrganisationSectionForm,
            OrganisationSectionsList,
            OwDataGrid,
            LicenceOverview,
        },

        data() {
            return {
                loading: false,
                isOrganisationFormDialogOpened: false,
                isOrganisationSectionFormDialogOpened: false,
                isEditOrganisationMode: false,
                isEditOrganisationSectionMode: false,
                isOrganisationSectionsDialogOpened: false,
                pricingPlanNames,
                organisations2: [],
                licenceCounts: {},
                sortBy: ref([{key: 'name', order: 'asc'}]),
            }
        },

        computed: {
            ...mapState({
                selectedOrganisation: state => state.organisation.selectedOrganisation,
                selectedOrganisationSection: state => state.organisation.selectedOrganisationSection,
            }),

            ...mapGetters('organisation', {
                organisations: GET_LIST,
            }),

            headers() {
                return [{
                    title: 'ID',
                    key: 'id',
                    sortable: false,
                    align: 'left',
                }, {
                    title: 'Name',
                    key: 'name',
                    sortable: false,
                    align: 'left',
                }, {
                    title: 'Sections',
                    key: 'sections',
                    sortable: false,
                    align: 'left',
                }, {
                    title: 'Users (active, inactive) / quota',
                    key: 'users',
                    align: 'left',
                    sortable: false,
                }, {
                    title: 'Model',
                    key: 'pricingModelId',
                    align: 'left',
                }, {
                    title: 'PPC',
                    key: 'ppcStandardCharge',
                    align: 'left',
                    sortable: false,
                }, {
                    title: 'TS',
                    key: 'edit',
                    align: 'left',
                    sortable: false,
                }]
            },
        },

        watch: {
            isOrganisationFormDialogOpened(val) {
                if (!val) {
                    this.initialiseSelectedOrganisation()
                }
            },

            isOrganisationSectionFormDialogOpened(val) {
                if (!val) {
                    this.isEditOrganisationSectionMode = false
                }
            },

            organisations(val) {
                this.organisations2 = val
            },
        },

        mounted() {
            this.getOrganisations()
        },

        methods: {
            openOrganisationFormDialog(isEdit) {
                this.isOrganisationFormDialogOpened = true
                this.isEditOrganisationMode = Boolean(isEdit)
            },

            closeOrganisationFormDialog() {
                this.isOrganisationFormDialogOpened = false
                this.isEditOrganisationSectionMode = false
            },

            closeOrganisationSectionFormDialog() {
                this.isOrganisationSectionFormDialogOpened = false
                this.isEditOrganisationSectionMode = false
            },

            ...mapActions('organisation', {
                getOrganisations: LOAD_LIST,
            }),

            ...mapMutations('organisation', {
                updateSelectedOrganisation: MUTATE_SELECTED_ORGANISATION,
                updateSelectedOrganisationSection: MUTATE_SELECTED_ORGANISATION_SECTION,
                initialiseSelectedOrganisation: MUTATE_INITIALISE_SELECTED_ORGANISATION,
            }),

            editOrganisationSection(sections) {
                this.isOrganisationSectionsDialogOpened = false
                this.onEditOrganisationSectionClick(sections, this.selectedOrganisation)
            },

            openEditOrganisationDialog(organisation) {
                this.openOrganisationFormDialog(true)
                this.setSelectedOrganisation(organisation)
            },

            openOrganisationSectionsListDialog(organisation) {
                this.isOrganisationSectionsDialogOpened = true
                this.setSelectedOrganisation(organisation)
            },

            closeOrganisationSectionsListDialog() {
                this.isOrganisationSectionsDialogOpened = false
                this.initialiseSelectedOrganisation()
            },

            setSelectedOrganisation(organisation) {
                this.updateSelectedOrganisation({
                    id: organisation.id,
                    name: organisation.name,
                    maxUsers: organisation.maxUsers,
                    maxStandardUsers: organisation.maxStandardUsers,
                    maxPremiumUsers: organisation.maxPremiumUsers,
                    activePremiumUsersCount: organisation.activePremiumUsersCount,
                    activeStandardUsersCount: organisation.activeStandardUsersCount,
                    pricingModelId: organisation.pricingModelId,
                    chargesEmails: organisation.chargesEmails,
                    address: organisation.address,
                    defaultSectionId: organisation.defaultSectionId,
                    sections: organisation.sections,
                    usesClientCodes: organisation.usesClientCodes,
                    mandatoryMatterCodes: organisation.mandatoryMatterCodes,
                    ppcStandardCharge: organisation.ppcStandardCharge,
                    pmpmMatterCharge: organisation.pmpmMatterCharge,
                    preferChargesCSVWithoutHeader: organisation.preferChargesCSVWithoutHeader,
                    preferChargesLandRegistryCSVFormat: organisation.preferChargesLandRegistryCSVFormat,
                    preferChargesUserFullNameRatherThanEmail: organisation.preferChargesUserFullNameRatherThanEmail,
                    preferChargesCSVSectionNameForOffice: organisation.preferChargesCSVSectionNameForOffice,
                    preferChargesCSVMatterCodeSeparator: organisation.preferChargesCSVMatterCodeSeparator,
                    hasTitleSummaryCharge: organisation.titleSummaryCharge != null,
                    titleSummaryCharge: organisation.titleSummaryCharge,
                    ssoOption: organisation.ssoOption,
                    ssoProviderId: organisation.ssoProviderId,
                    validEmailDomains: organisation.validEmailDomains,
                    enableFeatureHideableMatterSidePanel: organisation.enableFeatureHideableMatterSidePanel,
                    industry: organisation.industry,
                    status: organisation.status,
                    matterCodeFormat: organisation.matterCodeFormat,
                    matterCodeMask: organisation.matterCodeMask,
                    clientCodeFormat: organisation.clientCodeFormat,
                    canShareMattersWithWholeOrg: organisation.canShareMattersWithWholeOrg,
                    products: organisation.products,
                    regions: organisation.regions,
                    defaultLocale: organisation.defaultLocale,
                    grandfatheredModules: organisation.grandfatheredModules,
                })
                this.licenceCounts = {
                    activeUsersCount: organisation.activeUsersCount,
                    maxUsersCount: organisation.maxUsers,
                    activeLiteUsersCount: organisation.activeLiteUsersCount,
                    maxLiteUsersCount: organisation.totalMaxLiteUsers,
                    activeStandardUsersCount: organisation.activeStandardUsersCount,
                    maxStandardUsersCount: organisation.maxStandardUsers,
                    activePremiumUsersCount: organisation.activePremiumUsersCount,
                    maxPremiumUsersCount: organisation.maxPremiumUsers,
                }
            },

            onAddOrganisationSectionClick(organisation) {
                this.updateSelectedOrganisationSection({
                    organisationName: organisation.name,
                    organisationId: organisation.id,
                    industry: organisation.industry,
                    status: organisation.status,
                    organisation,
                    name: null,
                    addressHouseNumber: null,
                    addressStreet: null,
                    addressLocality: null,
                    addressTown: null,
                    addressPostcode: null,
                })
                this.isOrganisationSectionFormDialogOpened = true
            },

            onEditOrganisationSectionClick(section, organisation) {
                this.updateSelectedOrganisationSection({
                    id: section.id,
                    organisationName: organisation.name,
                    organisationId: section.organisationId,
                    industry: organisation.industry,
                    status: organisation.status,
                    organisationDefaultSectionid: organisation.defaultSectionId,
                    name: section.name,
                    addressHouseNumber: section.addressHouseNumber,
                    addressStreet: section.addressStreet,
                    addressLocality: section.addressLocality,
                    addressTown: section.addressTown,
                    addressPostcode: section.addressPostcode,
                })
                this.isEditOrganisationSectionMode = true
                this.isOrganisationSectionFormDialogOpened = true
            },
        },
    }
</script>

<style lang="scss">
    @import './admin-organisations';
</style>

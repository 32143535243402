<template>
    <v-tooltip :location="tooltipAlignment"
               :color="tooltipColor"
               :disabled="isTooltipDisabled || !tooltipText"
               data-test="app-dropdown-menu-tooltip">
        <template #activator=" { props }">
            <v-menu ref="menu"
                    v-model="isOpen"
                    :open-delay="menuDelay"
                    :close-delay="menuDelay"
                    :location="alignment"
                    :disabled="isMenuDisabled"
                    :close-on-content-click="closeOnContentClick"
                    class="app-dropdown-menu"
                    data-test="app-dropdown-menu"
                    :open-on-hover="openOnHover"
                    v-bind="props">
                <template #activator="{ props: menuProps }">
                    <v-btn :class="{
                               'is-icon-button': theme === 'iconButton',
                               'is-submenu': theme === 'submenu',
                               [activatorClassName]: activatorClassName.length > 0,
                               'menu-open': isOpen,
                               'full-height': size === 'fullHeight',
                               'secondary': theme === 'secondary',
                           }"
                           :color="theme === 'buttonLarge' || theme === 'primary' ? 'primary' : null"
                           :data-test="buttonDataTest"
                           :data-track="buttonDataTrack"
                           :disabled="isButtonDisabled"
                           :size="size"
                           :loading="isLoading"
                           :variant="variant"
                           class="app-dropdown-menu__button"
                           v-bind="menuProps">
                        <v-icon v-if="icon"
                                class="app-dropdown-menu__icon"
                                data-test="app-dropdown-menu-icon">
                            {{ icon }}
                        </v-icon>
                        <span v-if="buttonText"
                              :class="{'hidden-md-and-down': hideButtonTextOnSmallScreens}"
                              class="app-dropdown-menu__button-text"
                              data-test="app-dropdown-menu-button-text">
                            {{ buttonText }}
                        </span>
                        <v-icon v-if="theme === 'submenu'"
                                class="app-dropdown-menu__icon submenu-icon"
                                data-test="app-dropdown-menu-icon">
                            $chevron-right
                        </v-icon>
                    </v-btn>
                </template>
                <v-card :class="{ [menuItemsClassName]: menuItemsClassName.length > 0 }"
                        :style="{maxWidth: `${ maxWidth }px`, width: `${ maxWidth }px`}"
                        class="app-dropdown-menu__content"
                        data-test="app-dropdown-menu-content">
                    <slot />
                    <div v-if="infoText"
                         class="app-dropdown-menu__info-text"
                         data-test="app-dropdown-menu-info-text">
                        {{ infoText }}
                    </div>
                </v-card>
            </v-menu>
        </template>
        <span v-if="tooltipText && !isTooltipDisabled">{{ tooltipText }}</span>
    </v-tooltip>
</template>

<script>
    export default {
        props: {
            activatorClassName: {
                type: String,
                default: '',
            },
            alignment: {
                type: String,
                required: false,
                default: 'bottom',
            },
            buttonText: {
                type: String,
                required: false,
                default: null,
            },
            buttonDataTest: {
                type: String,
                required: false,
                default: 'app-dropdown-menu-button',
            },
            buttonDataTrack: {
                type: String,
                required: false,
            },
            hideButtonTextOnSmallScreens: {
                type: Boolean,
                required: false,
            },
            icon: {
                type: String,
                required: false,
                default: null,
            },
            infoText: {
                type: String,
                required: false,
                default: null,
            },
            isButtonDisabled: {
                type: Boolean,
                required: false,
            },
            isLoading: {
                type: Boolean,
                required: false,
            },
            isMenuDisabled: {
                type: Boolean,
                required: false,
            },
            isTooltipDisabled: {
                type: Boolean,
                required: false,
            },
            maxWidth: {
                type: Number,
                required: false,
                default: null,
            },
            menuItemsClassName: {
                type: String,
                default: '',
            },
            theme: {
                type: String,
                required: false,
                default: 'primary',
                validator: (val) => {
                    return ['button', 'buttonLarge', 'icon', 'iconButton', 'submenu', 'primary', 'secondary'].includes(val)
                },
            },
            size: {
                type: String,
                required: false,
                default: 'default',
                validator: (val) => {
                    return ['large', 'small', 'default', 'fullHeight'].includes(val)
                },
            },
            tooltipAlignment: {
                type: String,
                required: false,
                default: 'bottom',
            },
            tooltipColor: {
                type: String,
                required: false,
                default: null,
            },
            tooltipText: {
                type: String,
                required: false,
                default: null,
            },
            openOnHover: {
                type: Boolean,
                required: false,
                default: false,
            },
            closeOnContentClick: {
                type: Boolean,
                required: false,
                default: true,
            },
            menuDelay: {
                type: Number,
                required: false,
                default: 0,
            },
        },

        data() {
            return {
                isOpen: false,
            }
        },

        computed: {

            variant(){
                switch(this.theme){
                    case 'primary':
                        return 'elevated'
                    case 'button':
                        return 'outlined'
                    case 'buttonLarge':
                        return 'outlined'
                    case 'submenu':
                        return 'text'
                    default:
                        return 'text'
                }
            },

        },
    }
</script>

<style lang="scss"
       scoped>
    @import './ow-dropdown-menu';
</style>

import { linear } from 'ol/easing'
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import {
    Fill,
    Icon,
    Stroke,
    Style,
} from 'ol/style'

import { FindNearbyDefaults } from '@/components/find-nearby/defaults'
import { PlanningColours } from '@/consts/planning'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import {
    bufferFeatures,
    initBritishNationalGridProjection,
} from '@/utils/map-utils'
import { layerEquals } from '@/utils/map-utils'
import {
    featureArraysAreEqualByProperty,
    getColourForDecision,
    getMarkerPinSVG,
} from '@/utils/planning-utils'

import {
    PLANNING_MUTATE_DISTANCE,
    PLANNING_MUTATE_FILTERED_RESULTS,
    PLANNING_MUTATE_FORCE_PLANNING_PROVIDER,
    PLANNING_MUTATE_HIGHLIGHT_FEATURES,
    PLANNING_MUTATE_INIT_LAYERS,
    PLANNING_MUTATE_LOADING_RESULTS,
    PLANNING_MUTATE_MAX_DISTANCE,
    PLANNING_MUTATE_PROMPT_FOR_RETRY,
    PLANNING_MUTATE_RATE_LIMITED_SECONDS,
    PLANNING_MUTATE_RESULTS,
    PLANNING_MUTATE_SEARCHED_TITLE_NUMBERS,
    PLANNING_MUTATE_SELECTED_PLANNING_DECISIONS,
    PLANNING_MUTATE_SELECTED_SORT_OPTION,
    PLANNING_MUTATE_SELECTED_TITLE_FEATURES,
    PLANNING_MUTATE_TEXT_FILTER,
    PLANNING_MUTATE_TEXT_FILTER_KEYWORDS,
    PLANNING_MUTATE_UNAVAILABLE_APPLICATIONS,
} from './types.js'

export default {

    [PLANNING_MUTATE_SELECTED_PLANNING_DECISIONS](state, val) {
        state.selectedPlanningDecisions = val
    },

    [PLANNING_MUTATE_TEXT_FILTER](state, val) {
        state.inputs.textFilter = val
    },

    [PLANNING_MUTATE_SELECTED_TITLE_FEATURES](state, val) {
        state.inputs.selectedTitleFeatures = val
    },

    [PLANNING_MUTATE_RESULTS](state, val) {
        state.results = val
    },

    [PLANNING_MUTATE_SELECTED_SORT_OPTION](state, val) {
        state.selectedSortOption = val
    },

    [PLANNING_MUTATE_LOADING_RESULTS](state, val) {
        state.loadingResults = val
    },

    [PLANNING_MUTATE_FILTERED_RESULTS](state, val) {
        state.filteredResults = val
        state.filteredResultsCount = val.length
    },

    [PLANNING_MUTATE_UNAVAILABLE_APPLICATIONS](state, val) {
        state.unavailableApplications = val
    },

    [PLANNING_MUTATE_INIT_LAYERS](state, targetMap) {
        initBritishNationalGridProjection()

        // Initialise layers if required, assume this only needs doing once for at most a single map - revisit if
        // results are to be rendered on multiple maps.
        if (!state.distanceLayer && targetMap) {
            state.targetMap = targetMap

            state.distanceLayer = new VectorLayer({
                zIndex: 999,
                source: new VectorSource(),
                updateWhileAnimating: false,
                updateWhileInteracting: false,
            })

            state.distanceLayer.getSource().addFeature(state.distanceFeature)

            // A layer to hold the planning application features/markers.
            state.resultsLayer = new VectorLayer({
                source: new VectorSource(),
                style: (feature) => {
                    if (feature.get('show')) {
                        const properties = feature.getProperties()

                        const colour = getColourForDecision(properties.decision)
                        const isHighlighted = state.highlightedFeatures.includes(feature)

                        return new Style({
                            image: new Icon(({
                                src: `data:image/svg+xml;utf8,${ getMarkerPinSVG(colour) }`,
                                scale: isHighlighted ? 1.7 : 1,
                                anchor: [0.2, 0.85],
                            })),
                            zIndex: isHighlighted ? 2 : 1,
                        })
                    }
                    return null
                },
                visible: true,
                zIndex: 600,
                updateWhileAnimating: false,
                updateWhileInteracting: false,
            })

            targetMap.addLayer(state.distanceLayer)
            targetMap.addLayer(state.resultsLayer)

            // Listen for roll-over events.
            targetMap.on('pointermove', (e) => {
                if (state.resultsLayer?.getVisible()) {
                    const pixel = targetMap.getEventPixel(e.originalEvent)
                    const newHighlightedFeatures = targetMap.getFeaturesAtPixel(pixel, {
                        layerFilter: currentLayer => layerEquals(state.resultsLayer, currentLayer),
                        hitTolerance: 5,
                    })
                    if (!featureArraysAreEqualByProperty(newHighlightedFeatures, state.highlightedFeatures, 'index')) {
                        state.highlightedFeatures = newHighlightedFeatures
                    }

                    if (state.highlightedFeatures.length) {
                        targetMap.getTargetElement().classList.add('ow-over-layer-planning')
                    } else {
                        targetMap.getTargetElement().classList.remove('ow-over-layer-planning')
                    }
                }
            })

            // Listen for click events.
            targetMap.on('singleclick', (e) => {
                const pixel = targetMap.getEventPixel(e.originalEvent)

                const newSelectedFeatures = targetMap.getFeaturesAtPixel(pixel, {
                    layerFilter: currentLayer => layerEquals(state.resultsLayer, currentLayer),
                    hitTolerance: 5,
                })

                if (!featureArraysAreEqualByProperty(newSelectedFeatures, state.selectedFeatures, 'index')) {
                    state.selectedFeatures = newSelectedFeatures
                }
            })
        }

        /* eslint-disable no-unused-expressions */
        state.distanceLayer?.setVisible(true)
        state.resultsLayer?.setVisible(true)
        /* eslint-enable no-unused-expressions */
    },

    [PLANNING_MUTATE_HIGHLIGHT_FEATURES](state, val) {
        if (!featureArraysAreEqualByProperty(val, state.highlightedFeatures, 'index')) {
            state.highlightedFeatures = val
        }
    },

    [PLANNING_MUTATE_MAX_DISTANCE](state, val) {
        state.inputs.maxDistance = val
    },

    [PLANNING_MUTATE_DISTANCE](state, val) {
        state.inputs.selectedDistance = val

        // If there are selected titles, determine a buffer area.
        if (state.inputs.selectedTitleFeatures.length > 0) {
            // Create a new distance feature
            const newDistanceFeature = bufferFeatures(state.inputs.selectedTitleFeatures, state.inputs.selectedDistance)

            // Reproject back to BNG
            newDistanceFeature.getGeometry().transform(CoordinateSystemCode.EPSG4326, CoordinateSystemCode.EPSG27700)

            // Use the geometry to update the distance feature on the map
            state.distanceFeature.setGeometry(newDistanceFeature.getGeometry())

            if (state.targetMap) {
                // Update the rendering of the buffer on the map, start by removing the old buffer
                // state.distanceLayer.getSource().clear()
                if (state.distanceAnimateListener) {
                    state.targetMap.un('postcompose', state.distanceAnimateListener)
                }

                const extent = state.distanceFeature.getGeometry().getExtent()
                if (extent) {
                    state.targetMap.getView().fit(extent, {
                        maxZoom: FindNearbyDefaults.BUFFER_DEFAULT_MAX_ZOOM,
                        padding: FindNearbyDefaults.BUFFER_DEFAULT_VIEW_PADDING,
                    })
                }

                // Animate the buffer
                const duration = 30000
                const start = new Date().getTime()

                state.distanceAnimateListener = event => {
                    const frameState = event.frameState
                    const elapsed = frameState.time - start
                    const elapsedRatio = elapsed / duration
                    const animateValue = (linear(elapsedRatio) * 300)

                    const style = new Style({
                        stroke: new Stroke({
                            color: PlanningColours.distance,
                            width: 3,
                            lineDash: [10, 7],
                            lineDashOffset: animateValue,
                        }),
                        fill: new Fill({
                            color: 'rgba(0,0,0,0.1)',
                        }),
                        zIndex: 999,
                    })

                    state.distanceFeature.setStyle(style)
                    state.targetMap.render()
                }
                state.targetMap.on('postcompose', state.distanceAnimateListener)
            }
        }
    },

    [PLANNING_MUTATE_TEXT_FILTER_KEYWORDS](state, val) {
        state.inputs.textFilterKeywords = val
    },

    [PLANNING_MUTATE_RATE_LIMITED_SECONDS](state, val) {
        state.isRateLimitedRemainingSeconds = val
        const retryCheck = setInterval(() => {
            if (state.isRateLimitedRemainingSeconds > 0) {
                state.isRateLimitedRemainingSeconds--
            } else {
                clearInterval(retryCheck)
            }
        }, 1000)
    },

    [PLANNING_MUTATE_PROMPT_FOR_RETRY](state, val) {
        state.promptForRetry = val
    },

    [PLANNING_MUTATE_SEARCHED_TITLE_NUMBERS](state, val) {
        state.inputs.searchedTitleNumbers = val
    },

    [PLANNING_MUTATE_FORCE_PLANNING_PROVIDER](state, val) {
        state.forcePlanningDataProvider = val
    },
}

<template>
    <li class="title-details-list-item"
        :class="`${ tenure } clickable-area--${clickableArea}${ isHighlighted ? ' is-highlighted' : '' }${ hasLightBackground ? ' has-light-background' : '' }`"
        :data-test="`title-details-list-item-${ titleNumber }`"
        @click="clickableArea === 'whole-item' ? onClick() : undefined">
        <div class="title-details-list-item__top">
            <v-checkbox-btn v-if="isSelectable"
                            :model-value="isSelected"
                            :disabled="disabled"
                            class="title-details-list-item__checkbox"
                            data-test="title-details-list-item-checkbox"
                            color="primary"
                            hide-details
                            @update:model-value="onSelectionChange" />
            <div class="title-details-list-item__content"
                 data-test="title-details-list-item-content"
                 @click="clickableArea === 'content' ? onClick() : undefined">
                <div class="title-details-list-item__content-top body-copy">
                    <div class="title-details-list-item__top-label"
                         data-test="title-details-list-item-top-label"
                         :style="labelStyle"
                         :title="customName || ''"
                         :class="{'is-visible': isVisible, 'uses-visibility': usesVisibilityVariations}">
                        <ow-text-highlight :queries="[filter]">
                            {{ customName || titleNumber }}
                        </ow-text-highlight>
                    </div>
                    <ow-register-status-icon v-if="showTitleRegisterIcon"
                                             :is-register-ordered="isRegisterOrdered"
                                             :is-register-uploaded="isRegisterUploaded"
                                             :show-title-added-to-matter="showTitleAddedToMatter"
                                             :response-date-value="registerResponseDate"
                                             :tr-owner-mismatch="trOwnershipMismatch"
                                             is-small />
                    <slot name="afterLabel" />
                </div>
                <div class="title-details-list-item__content-bottom content__label">
                    <div v-if="customName"
                         class="title-details-list-item__title-number">
                        <ow-text-highlight :queries="[filter]">
                            {{ titleNumber }}
                        </ow-text-highlight>
                    </div>
                    <div v-if="isAddressVisible"
                         class="title-details-list-item__address"
                         data-test="title-details-list-item-address"
                         :title="address">
                        <ow-text-highlight :queries="[filter]">
                            {{ Boolean(address) ? address : 'Address unavailable' }}
                        </ow-text-highlight>
                    </div>
                </div>
            </div>
            <slot name="afterContent" />
        </div>
        <div class="title-details-list-item__bottom"
             @click="onClick()">
            <slot name="belowContent" />
        </div>
        <slot name="actions" />
    </li>
</template>

<script lang="ts">
    import { inject } from 'vue'

    import TitleInformationApi from "@/api/title-information.api"
    import OwRegisterStatusIcon from '@/components/core/ow-register-status-icon.vue'
    import OwTextHighlight from '@/components/core/ow-text-highlight.vue'
    import FlagsMixin from '@/feature-flags/feature-flags-mixin'

    const EVENTS = {
        click: 'click',
        slectionChange: 'selection-change',
    }

    export default {
        name: 'TitleDetailsListItem',
        components: {
            OwTextHighlight,
            OwRegisterStatusIcon,
        },
        mixins: [FlagsMixin],
        props: {
            tenure: {
                type: String,
                required: true,
            },
            titleNumber: {
                type: String,
                required: true,
            },
            address: {
                type: String,
                default: null,
                required: false,
            },
            isHighlighted: {
                type: Boolean,
                required: false,
            },
            isSelected: {
                type: Boolean,
                required: false,
                default: false,
            },
            isSelectable: {
                type: Boolean,
                required: false,
            },
            isAddressVisible: {
                type: Boolean,
                required: false,
                default: true,
            },
            isRegisterOrdered: {
                type: Boolean,
                required: false,
            },
            isRegisterUploaded: {
                type: Boolean,
                required: false,
            },
            registerResponseDate: {
                type: String,
                required: false,
            },
            clickableArea: {
                type: String,
                default: 'content',
                required: false,
                validator(val: string) {
                    return ['content', 'whole-item', 'none'].includes(val)
                },
            },
            customName: {
                type: String,
                required: false,
                default: null,
            },
            labelColour: {
                type: String,
                required: false,
                default: null,
            },
            hasLightBackground: {
                type: Boolean,
                default: false,
            },
            isVisible: {
                type: Boolean,
                default: false,
            },
            usesVisibilityVariations: {
                type: Boolean,
                default: false,
            },
            isMatterLinkShareUser: {
                type: Boolean,
                default: false,
            },
            showTitleAddedToMatter: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        emits: Object.values(EVENTS),
        setup() {
            const filterProvider = inject('FILTER_PROVIDER', () => {})
            return {
                filterProvider,
            }
        },
        data() {
            return {
                trOwnershipMismatch: false,
            }
        },
        computed: {
            labelStyle() {
                if (this.isVisible && this.usesVisibilityVariations) {
                    return this.labelColour
                        ? { background: this.labelColour, marginBottom: '4px' }
                        : {}
                }
                return null
            },
            showTitleRegisterIcon() {
                return !this.isMatterLinkShareUser && (this.isRegisterOrdered || this.isRegisterUploaded || this.showTitleAddedToMatter)
            },
            filter() {
                return this.filterProvider ?? ''
                    .toLowerCase()
                    .replace(/\W/g, ' ')
            },
        },
        async created() {
            if (this.titleNumber) {
                const trOwnershipFlag = await TitleInformationApi.checkOwnerMatchWithCcodOcodByTitleNumber(this.titleNumber)
                this.trOwnershipMismatch = trOwnershipFlag === false
            }
        },
        methods: {
            onClick() {
                this.$emit(EVENTS.click, this.titleNumber)
            },
            onSelectionChange(val: boolean) {
                this.$emit(EVENTS.slectionChange, val)
            },
        },
    }
</script>

<style lang="scss">
    @import 'title-details-list-item';
</style>

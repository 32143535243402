import Feature from 'ol/Feature'
import Geometry from 'ol/geom/Geometry'
import VectorLayer from 'ol/layer/Vector'
import Map from 'ol/Map'
import VectorSource from 'ol/source/Vector'
import {
    Stroke,
    Style,
} from 'ol/style'

import { FindNearbyDefaults } from '@/components/find-nearby/defaults'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import { isNullOrEmpty } from '@/utils/array-utils'
import { bufferFeatures } from '@/utils/map-utils'

export interface IFindNearbyBufferLayerParams {

    // The OpenLayers map that be the target of the buffer layer.
    targetMap: Map
}

// Controls visibility of the buffer layer intended to illustrate the find-nearby 'area'.
export class FindNearbyBufferLayer {
    // The OpenLayers map that be the target of the integration.
    private targetMap: Map

    // A layer that will display the 'nearby' area - a buffer around one or more titles.
    private readonly layer: VectorLayer<VectorSource<Geometry>>

    private selectedTitleFeatures: Feature[]

    private radiusInMetres: number

    private bufferFeature: Feature

    constructor(data: IFindNearbyBufferLayerParams) {
        // Initialise the map layer.
        this.layer = new VectorLayer({
            source: new VectorSource(),
            zIndex: 700,
            style: new Style({
                stroke: new Stroke({
                    color: FindNearbyDefaults.BUFFER_COLOUR,
                    width: 3,
                    lineDash: [10, 7],
                }),
            }),
        })

        this.setTargetMap(data.targetMap)
    }

    // Resets the current buffer layer.
    public reset():void {
        this.layer.getSource().clear()
    }

    public dispose(): void {
        this.reset()
        this.targetMap?.removeLayer(this.layer)
        this.layer.dispose()
        this.bufferFeature?.dispose()
    }

    public setTargetMap(map: Map): void {
        this.targetMap = map

        if (this.targetMap) {
            this.targetMap.addLayer(this.layer)
        }
    }

    // The geometry that will be used to create the buffer.
    public setSelectedTitleFeatures(selectedTitleFeatures: Feature[]): void {
        this.selectedTitleFeatures = selectedTitleFeatures
        this.redrawLayer()
    }

    public setRadius(radiusMetres: number): void {
        this.radiusInMetres = radiusMetres
        this.redrawLayer()
    }

    public getBufferFeature(): Feature {
        return this.bufferFeature
    }

    private redrawLayer(): void {
        this.layer.getSource()?.clear()

        // If there are selected titles, determine a buffer area.
        if (!isNullOrEmpty(this.selectedTitleFeatures)) {
            // Create a new distance feature
            this.bufferFeature = bufferFeatures(this.selectedTitleFeatures, this.radiusInMetres)

            // Re-project back to BNG
            this.bufferFeature.getGeometry().transform(CoordinateSystemCode.EPSG4326, CoordinateSystemCode.EPSG27700)

            this.layer.getSource().addFeature(this.bufferFeature)
        }
    }
}

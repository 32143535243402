<template>
    <v-container v-if="documentMetadata"
                 id="documentViewer"
                 class="doc-library-pdf-viewer"
                 data-test="ocr-pdf-viewer-container">
        <div id="background-image" />

        <viewer-controls :current-matter-id="currentMatterId"
                         :current-zoom="currentZoom"
                         :document-metadata="documentMetadata"
                         :loading="!loadedPDF"
                         :max-zoom="maxZoom"
                         :min-zoom="minZoom"
                         :num-pages="numOfPages"
                         :search-loading="searchLoading"
                         :search-result-count="searchResultCount"
                         :search-text="searchText"
                         :selected-search-result-number="selectedSearchResultNumber"
                         :template-options="templateOptions"
                         @fit-to-height="fitPdfTo = 'height'"
                         @fit-to-width="fitPdfTo = 'width'"
                         @on-close-click="onCloseClick"
                         @on-download-click="onDownloadClick"
                         @on-export-click="onExportClick"
                         @reset-zoom="resetZoom"
                         @search-clear-clicked="onSearchClearClicked"
                         @set-search-text="setSearchText"
                         @show-next-search-result="showNextSearchResult"
                         @zoom-in="zoomIn"
                         @zoom-out="zoomOut" />

        <!-- PDF contents -->
        <div class="doc-library-pdf-viewer__the-doc">
            <ow-pdf-viewer :current-matter-id="currentMatterId"
                           :document-metadata="documentMetadata"
                           :document-url="documentUrl"
                           :fit-to="fitPdfTo"
                           :next-search-result="nextSearchResult"
                           :search-text="searchText"
                           :zoom="zoom"
                           is-full-width
                           @current-zoom="currentZoom = $event"
                           @document-scrolled="hasScrolledDocument = true"
                           @has-interacted="hasInteractedWithDocument = true"
                           @is-loaded="loadedPDF = $event"
                           @loading-error="hasLoadingError = $event"
                           @pages="numOfPages = $event"
                           @search-loading="searchLoading = $event"
                           @search-result-count="searchResultCount = $event"
                           @selected-search-result-number="selectedSearchResultNumber = $event" />
        </div>
    </v-container>
</template>
<script lang="ts">
    import { PropType } from 'vue'
    import { useRoute,
             useRouter } from 'vue-router'
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwPdfViewer from '@/components/core/ow-pdf-viewer.vue'
    import ViewerControls from '@/components/documents/viewer/ocr-pdf-viewer-controls.vue'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { getDataProviderFromNumber } from '@/consts/document-source'
    import { Route } from '@/enums/route.enum'
    import { IDocumentMetadata } from '@/interfaces/documents/document-metadata.interface'
    import { IState } from '@/interfaces/store/state.interface'
    import {
        DOCUMENT_VIEWER_MUTATE_ENTRY_POINT,
        DOCUMENTS_DOWNLOAD_UPLOADED_DOCUMENT,
        DOWNLOAD_DOCUMENT,
        GENERATE_DOCX_AND_DOWNLOAD,
        SET_VIEWER_LOGGING_PROPERTIES,
    } from '@/store/modules/documents/documents-types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { getApiUri } from '@/utils/environment.utils'

    const BASE_DOCUMENT_URL = `${ getApiUri() }/BusinessGateway/GetBGDocumentFileByFilename?filename=`

    export default {
        name: 'OcrPdfViewer',

        components: {
            OwPdfViewer,
            ViewerControls,
        },

        props: {
            documentMetadata: {
                type: Object as PropType<IDocumentMetadata>,
                required: false,
            },
            templateOptions: {
                type: Array,
                required: false,
            },
        },

        emits: [
            'downloading-document',
        ],

        data() {
            return {
                ocrViewerHistoryKey: 'ocrViewerHistory',
                ocrViewerHashHistoryKey: 'ocrViewerHashHistory',
                loadedPDF: false,
                hasLoadingError: false,
                fitPdfTo: 'auto',

                // Search
                searchLoading: false,
                searchText: null,
                searchResultCount: 0,
                selectedSearchResultNumber: null,
                numOfPages: null,
                nextSearchResult: 0, // Use positive number to search forward and negative to search backwards

                // Zoom related properties
                minZoom: 25,
                maxZoom: 250,
                currentZoom: 100,
                zoom: 'reset', // Can be set to in/out/reset
                zoomCounter: 0, // Way to get zoom to trigger when clicked multiple times

                // Logging properties
                hasInteractedWithDocument: false,
                hasScrolledDocument: false,

                route: useRoute(),
                router: useRouter(),
            }
        },

        computed: {
            ...mapState({
                entryPoint: (state: IState) => state.documents?.viewer.originalEntryPoint,
                selectedTitles: (state: IState) => state.matter?.currentMatter?.selectedTitles ?? [],
            }),

            documentUrl() {
                if (this.documentMetadata) {
                    if (this.documentMetadata.documentType === 'Register' ||
                        this.documentMetadata.documentType === 'Title Plan' ||
                        this.documentMetadata.augmentedFilename === null) {
                        return `${ getApiUri() }/${ this.documentMetadata.documentDownloadUrl }`
                    } else if (this.documentMetadata.documentType === HighLevelDocumentType.Uploaded) {
                        // need to return something to trigger the refresh.
                        // The filename isn't actually used for uploaded documents.
                        return this.documentMetadata.filename
                    } else {
                        return BASE_DOCUMENT_URL + this.documentMetadata.augmentedFilename
                    }
                }
                return null
            },

            currentMatterId() {
                return this.route.query?.fromMatterId ? parseInt(this.route.query.fromMatterId) : null
            },
        },

        watch: {
            searchTerms(val) {
                const self = this
                if (this.searchInputDebounce != null) {
                    clearTimeout(this.searchInputDebounce)
                }
                this.searchInputDebounce = setTimeout(() => {
                    // Log new search event
                    if (val != null) {
                        self.logHeapEvent({
                            type: 'Augmented PDF search',
                            metadata: {
                                documentId: self.documentMetadata.documentId,
                                text: val.toString(),
                                titleNumber: self.documentMetadata.titleNo,
                                documentType: self.documentMetadata.documentType,
                                documentDate: self.documentMetadata.documentDate,
                                filedUnder: self.documentMetadata.filedUnderField,
                            },
                        })
                    }
                }, 400)
            },

            hasInteractedWithDocument(val) {
                this.setViewerLoggingProperties({
                    hasInteractedWithDocument: val,
                })
            },

            hasScrolledDocument(val) {
                this.setViewerLoggingProperties({
                    hasScrolledDocument: val,
                })
            },

            hasDownloadedDocument(val) {
                this.setViewerLoggingProperties({
                    hasDownloadedDocument: val,
                })
            },
        },

        mounted() {
            this.setHistory()
        },

        activated() {
            this.setHistory()
        },

        methods: {
            ...mapActions({
                downloadDocument: DOWNLOAD_DOCUMENT,
                downloadUploadedDocument: DOCUMENTS_DOWNLOAD_UPLOADED_DOCUMENT,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            ...mapMutations({
                setViewerLoggingProperties: SET_VIEWER_LOGGING_PROPERTIES,
                setEntryPoint: DOCUMENT_VIEWER_MUTATE_ENTRY_POINT,
            }),

            async onCloseClick() {
                this.resetViewer()
                if (this.route.params.fromRouteName === Route.OverlaysCreate) {
                    this.clearStoredHistory()
                    await this.router.push(this.entryPoint)
                }
                const storedHistory = this.getStoredHistory()
                if (storedHistory.length > 0) {
                    const lastRoute = storedHistory[storedHistory.length - 1]
                    const hash = this.getStoredHash()
                    this.clearStoredHistory()
                    await this.router.push({
                        name: lastRoute,
                        hash: hash,
                        params: {
                            matterId: this.currentMatterId,
                            titleNumber: this.documentMetadata.titleNo,
                        },
                    })
                } else {
                    await this.router.push({ name: Route.Homepage })
                }
            },

            setHistory() {
                if (this.route.params.fromRouteName != Route.OverlaysCreate) {
                    this.setEntryPoint(this.route.params.fromFullPath)
                    this.setStoredHistory(this.route.params.fromRouteName, this.route.params.fromHash)
                }
            },

            getStoredHistory(): [] {
                return JSON.parse(sessionStorage.getItem(this.ocrViewerHistoryKey)) ?? []
            },

            getStoredHash(): [] {
                const storedHash = sessionStorage.getItem(this.ocrViewerHashHistoryKey)
                return storedHash ? JSON.parse(storedHash) : ''
            },

            setStoredHistory(item: string, hash: string) {
                if (!item) {
                    return
                }

                this.clearStoredHistory()
                sessionStorage.setItem(this.ocrViewerHistoryKey, JSON.stringify([item]))
                if (hash) {
                    sessionStorage.setItem(this.ocrViewerHashHistoryKey, JSON.stringify(hash))
                }
            },

            clearStoredHistory() {
                sessionStorage.removeItem(this.ocrViewerHistoryKey)
                sessionStorage.removeItem(this.ocrViewerHashHistoryKey)
            },

            zoomIn() {
                this.zoom = `in${ this.zoomCounter }`
                this.zoomCounter++
            },

            zoomOut() {
                this.zoom = `out${ this.zoomCounter }`
                this.zoomCounter++
            },

            resetZoom() {
                this.zoom = `reset${ this.zoomCounter }`
                this.zoomCounter++
                this.fitPdfTo = 'auto'
            },

            resetViewer() {
                this.resetZoom()
                this.setSearchText(null)
            },

            setSearchText(text) {
                if (text !== this.searchText) {
                    this.searchText = text
                } else {
                    this.showNextSearchResult()
                }
            },

            showNextSearchResult(backwards = false) {
                if (backwards === true) {
                    this.nextSearchResult = this.nextSearchResult > 0 ? -1 : this.nextSearchResult - 1
                } else {
                    this.nextSearchResult = this.nextSearchResult < 0 ? 1 : this.nextSearchResult + 1
                }
            },

            onSearchClearClicked() {
                this.setSearchText(null)
            },

            async onDownloadClick() {
                // Get the document
                this.logHeapEvent({
                    type: 'OCR pdf Viewer - ',
                    metadata: {
                        documentId: this.documentMetadata.documentId,
                        titleNumber: this.documentMetadata.titleNo,
                        documentType: this.documentMetadata.documentType,
                        documentSource: getDataProviderFromNumber(
                            this.selectedTitles.find(x => x.titleNumber === this.documentMetadata.titleNo)?.dataProvider),
                    },
                })
                this.$emit('downloading-document', true)
                if (this.documentMetadata.documentType === HighLevelDocumentType.Uploaded) {
                    await this.downloadUploadedDocument({
                        matterId: this.currentMatterId,
                        documentId: this.documentMetadata.documentId,
                    })
                } else {
                    await this.downloadDocument(this.documentMetadata)
                }
                this.hasDownloadedDocument = true
            },

            async onExportClick() {
                await this.router.push({
                    name: Route.MatterReports,
                    params: {
                        matterId: this.currentMatterId,
                    },
                    query: {
                        selectedTitleNumbers: [this.documentMetadata.titleNo],
                        origin: 'DOCUMENT_VIEWER',
                    },
                })
            },
        },

    }
</script>

<style lang="scss">
    @import './ocr-pdf-viewer';
</style>

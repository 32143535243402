<template>
    <div class="ow-page-container">
        <title-page v-if="selectedTitleNumber"
                    :selected-title-number="selectedTitleNumber"
                    :is-loading="isLoading || isLookupTitleLoading"
                    @add-to-matter="toggleAddToMatterDialog(true)"
                    @title-number-selected="goToTitle" />
        <add-to-matter-dialog v-if="selectedTitleNumber"
                              v-model="isAddToMatterDialogVisible"
                              :title-number="selectedTitleNumber"
                              :matter="currentMatter"
                              @close-dialog="toggleAddToMatterDialog(false)" />
    </div>
</template>

<script lang="ts">
    import { Route } from 'vue-router'
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import AddToMatterDialog from '@/components/search/add-to-matter-dialog.vue'
    import TitlePage from '@/components/title-panel/title-page/title-page.vue'
    import { Route as AppRoute } from '@/enums/route.enum'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin.js'
    import defaultTitleStatus from '@/store/modules/titles/default-title-status'
    import {
        TITLE_LOOKUP_TITLE,
        TITLE_MUTATE_SELECTED_TITLE,
        TITLE_MUTATE_SELECTED_TITLE_NUMBER,
    } from '@/store/modules/titles/types'

    export default {
        name: 'TitleDetailsPage',
        components: {
            AddToMatterDialog,
            TitlePage,
        },
        mixins: [FeatureFlagsMixin],
        data() {
            return {
                isAddToMatterDialogVisible: false,
            }
        },
        computed: {
            ...mapState({
                currentMatter: (state: any) => state.matter.currentMatter,
                selectedTitleNumber: (state: any) => state.title.selectedTitleNumber,
                isLoading: (state: any) => state.title.selectedTitle.loading,
                isLookupTitleLoading: (state: any) => state.title.loadingState.lookupTitle,
            }),
        },
        watch: {
            $route(to: Route, from: Route) {
                const isNewSearch = Boolean(from.name === AppRoute.Search && to.name === AppRoute.TitleDetails)
                const isClickBetweenTabs = Boolean(from.name === AppRoute.TitleDetails && to.name === AppRoute.TitleDetails)
                const isClickAwayFromTitleDetails = Boolean(from.name === AppRoute.TitleDetails && to.name !== AppRoute.TitleDetails && to.name !== AppRoute.DocumentViewer)

                if (isNewSearch || isClickBetweenTabs) {
                    this.populateTitlePage()
                }

                if (isClickAwayFromTitleDetails) {
                    this.mutateSelectedTitleNumber(null)
                    this.mutateSelectedTitle(defaultTitleStatus)
                }
            },
        },
        mounted() {
            this.populateTitlePage()
        },
        methods: {
            ...mapActions({
                lookupTitle: TITLE_LOOKUP_TITLE,
            }),
            ...mapMutations({
                mutateSelectedTitle: TITLE_MUTATE_SELECTED_TITLE,
                mutateSelectedTitleNumber: TITLE_MUTATE_SELECTED_TITLE_NUMBER,
            }),
            populateTitlePage() {
                const titleNumber = this.$route.params.titleNumber
                if (this.selectedTitleNumber !== titleNumber) {
                    this.lookupTitle(titleNumber)
                }
            },
            toggleAddToMatterDialog(val: boolean) {
                this.isAddToMatterDialogVisible = val
            },
            goToTitle(titleNumber: string) {
                this.$router.push({
                    name: AppRoute.TitleDetails,
                    params: {
                        titleNumber,
                    },
                })
            },
        },
    }
</script>

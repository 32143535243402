import i18n from '@/plugins/i18n.js'
import WMTSTileGrid from 'ol/tilegrid/WMTS'

export const MAX_ZOOM_TO_TITLE_ZOOM_LEVEL = 19

export const INITIAL_XY = [333774, 540357]
export const INITIAL_ZOOM = 7
// NOTE: Update min zoom based on extent of the countries we support
export const MIN_ZOOM = 7
export const MAX_ZOOM = 21
export const PADDING = [20, 20, 20, 20]

export const BASE_LAYERS = {
    OS_MAP: 'Road_27700',
    OS_MAP_LIGHT: 'Light_27700',
}

export const mapBackdrops = {
    osMap: 'osMaps',
    osMapLight: 'osMapsLight',
    aerial: 'azureSatellite',
    blueskyAerial: 'blueskyAerial',
}

export interface IDisplayLayer {
    [key: number]: {
        label: string,
        color: string,
    }
}

export const DISPLAY_LAYERS: { [key: string]: IDisplayLayer } = {
    highways: [
        {
            color: '#00C882',
            label: i18n.global.t('map.options.legend.highways[0]') as string,
        },
        {
            color: '#963232',
            label: i18n.global.t('map.options.legend.highways[1]') as string,
        },
        {
            color: '#1964A0',
            label: i18n.global.t('map.options.legend.highways[2]') as string,
        },
        {
            color: '#1964A0',
            label: i18n.global.t('map.options.legend.highways[3]') as string,
        },
        {
            color: '#1964A0',
            label: i18n.global.t('map.options.legend.highways[4]') as string,
        },
        {
            color: '#646464',
            label: i18n.global.t('map.options.legend.highways[5]') as string,
        },
    ],
    prow: [
        {
            color: '#E113FE',
            label: i18n.global.t('map.options.legend.prow[0]') as string,
        },
        {
            color: '#29FF4E',
            label: i18n.global.t('map.options.legend.prow[1]') as string,
        },
        {
            color: '#1964A0',
            label: i18n.global.t('map.options.legend.prow[2]') as string,
        },
        {
            color: '#FF0202',
            label: i18n.global.t('map.options.legend.prow[3]') as string,
        },
        {
            color: '#F9FB4F',
            label: i18n.global.t('map.options.legend.prow[4]') as string,
        },
        {
            color: '#3236A0',
            label: i18n.global.t('map.options.legend.prow[5]') as string,
        },
    ],
    conservationAreas: [
        {
            color: '#C39BD3',
            label: i18n.global.t('map.options.conservationAreas.conservationArea') as string,
        },
        {
            color: '#B2BABB',
            label: i18n.global.t('map.options.conservationAreas.noDataForLPA') as string,
        },
    ],
    listedBuildings: [
        {
            color: '#73C5E6',
            label: i18n.global.t('map.options.legend.listedBuildings[0]') as string,
        },
        {
            color: '#1D73AD',
            label: i18n.global.t('map.options.legend.listedBuildings[1]') as string,
        },
        {
            color: '#052153',
            label: i18n.global.t('map.options.legend.listedBuildings[2]') as string,
        },
    ],
    scheduledMonuments: [
        {
            color: '#7B68EE',
            label: i18n.global.t('map.options.legend.scheduledMonuments[0]') as string,
        },
    ],
    dno: [
        {
            color: '#29B6F6',
            label: i18n.global.t('map.options.legend.dno[0]') as string,
        },
        {
            color: '#E74C3C',
            label: i18n.global.t('map.options.legend.dno[1]') as string,
        },
        {
            color: '#64DD17',
            label: i18n.global.t('map.options.legend.dno[2]') as string,
        },
        {
            color: '#FF6D00',
            label: i18n.global.t('map.options.legend.dno[3]') as string,
        },
        {
            color: '#F48FB1',
            label: i18n.global.t('map.options.legend.dno[4]') as string,
        },
        {
            color: '#7B68EE',
            label: i18n.global.t('map.options.legend.dno[5]') as string,
        },
    ],
    floodZones: [
        {
            color: 'rgba(173, 203, 234, 0.4)',
            label: i18n.global.t('map.options.legend.floodZones[0]') as string,
        },
        {
            color: 'rgba(50, 119, 225, 0.4)',
            label: i18n.global.t('map.options.legend.floodZones[1]') as string,

        },
        {
            color: '#EB984E',
            label: i18n.global.t('map.options.legend.floodZones[2]') as string,
        },
        {
            color: '#52BE80',
            label: i18n.global.t('map.options.legend.floodZones[3]') as string,
        },
    ],
    environmental: [
        {
            color: '#004D40',
            label: i18n.global.t('map.options.legend.environmental[0]') as string,
        },
        {
            color: '#66BB6A',
            label: i18n.global.t('map.options.legend.environmental[1]') as string,
        },
        {
            color: '#388E3C',
            label: i18n.global.t('map.options.legend.environmental[2]') as string,
        },
        {
            color: '#64DD17',
            label: i18n.global.t('map.options.legend.environmental[3]') as string,
        },
        {
            color: '#BF9494',
            label: i18n.global.t('map.options.legend.environmental[4]') as string,
        },
    ],
}

export const osLayerTileGrid = new WMTSTileGrid({
    tileSize: [256, 256],
    extent: [-238375, -229375.99974714452, 908504.9998193888, 1376256],
    resolutions: [895.9999998588976,
        447.9999999294488,
        223.9999999647244,
        111.9999999823622,
        55.9999999911811,
        27.99999999559055,
        13.999999997795275,
        6.999999998897637,
        3.4999999994488187,
        1.7499999997244093,
        0.8749999998622047,
        0.43749999993110233,
        0.21874999996555117,
        0.10937499998277558],
    matrixIds: null,
})

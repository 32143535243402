<template>
    <v-switch v-model="updated"
              v-bind="props"
              color="primary"
              :loading="isLoading"
              hide-details />
</template>

<script setup lang="ts">
    import { computed } from 'vue'

    const props = defineProps<{
        modelValue: boolean,
        label?: string,
        isLoading?: boolean,
    }>()

    const emit = defineEmits<{
        (e: 'update:modelValue', value: boolean): void,
    }>()

    const updated = computed({
        get: () => props.modelValue,
        set: (value: boolean) => {
            emit('update:modelValue', value)
        },
    })
</script>

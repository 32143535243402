<template>
    <div class="purchased-documents"
         data-test="document-library-content">
        <!-- Summary -->
        <documents-summary :documents="documents"
                           :documents-title-numbers="documentsTitleNumbers"
                           :filtered-documents="filteredDocuments"
                           :filtered-documents-title-numbers="filteredDocumentsTitleNumbers"
                           :has-filter="hasFilter"
                           :loading="isLoading"
                           :loading-bulk-download="loadingBulkDownload"
                           :selected-documents="selectedDocuments"
                           @refresh="refreshEverything"
                           @download-as-pdf-clicked="handleDownloadClicked"
                           @see-reporting-options-clicked="handleReportClicked" />

        <!-- Table of documents -->
        <documents-table :documents="filteredDocuments"
                         :is-current-matter-inactive="isCurrentMatterClosed"
                         @selected-documents-changed="setSelectedDocuments"
                         @title-number-selected="redirectToTitleNumber" />

        <title-details-panel v-if="selectedTitleNumber"
                             :is-pinned="isPinned"
                             :selected-title-number="selectedTitleNumber"
                             :show-add-to-matter-action="false"
                             :show-title-visibility-action="false"
                             class="document-library__title-details-panel"
                             @clear-titles="clearSelectedTitle"
                             @title-number-selected="redirectToTitleNumber" />
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import {
        mapActions,
        mapGetters,
        mapState,
    } from 'vuex'

    import DocumentsSummary from '@/components/documents/library/purchased-documents/documents-summary.vue'
    import DocumentsTable from '@/components/documents/library/purchased-documents/documents-table.vue'
    import TitleDetailsPanel from '@/components/title-panel/title-panel.vue'
    import { Route } from '@/enums/route.enum'
    import {
        DOWNLOAD_MANY,
        GENERATE_DOCX_AND_DOWNLOAD,
    } from '@/store/modules/documents/documents-types'
    import {
        MATTER_GET_IS_CURRENT_MATTER_CLOSED,
        MATTER_HAS_TITLE_NUMBER,
    } from '@/store/modules/matter/types'
    import {
        TITLE_CLEAR,
        TITLE_LOOKUP_TITLE,
    } from '@/store/modules/titles/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import {
        getDocumentDownloadAvailability,
        getDocumentStatus,
    } from '@/utils/document-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const EVENTS = {
        RefreshDocuments: 'refresh-documents',
        SelectDocuments: 'select-documents',
    }

    export default {
        name: 'PurchasedDocuments',

        components: {
            DocumentsSummary,
            DocumentsTable,
            TitleDetailsPanel,
        },

        props: {
            filteredDocumentTypes: {
                type: Array as PropType<Array<String>>,
                required: false,
                default: () => [],
            },
            filteredStatusOptions: {
                type: Array as PropType<Array<String>>,
                required: false,
                default: () => [],
            },
            hasFilter: {
                type: Boolean,
                required: true,
                default: false,
            },
            currentMatterId: {
                type: [String, Number],
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: true,
            },
            searchText: {
                type: String,
                required: false,
            },
            documents: {
                type: Array as PropType<Array<any>>,
                required: true,
            },
        },

        emits: [
            EVENTS.RefreshDocuments,
            EVENTS.SelectDocuments,
        ],

        data() {
            return {
                selectedDocuments: [],
                selectedTitleNumber: null,
                loadingBulkDownload: false,
                tabHash: '#purchased',
            }
        },

        computed: {
            ...mapGetters({
                isCurrentMatterClosed: MATTER_GET_IS_CURRENT_MATTER_CLOSED,
                matterHasTitle: MATTER_HAS_TITLE_NUMBER,
            }),

            routeName() {
                return this.$route.name
            },

            hasStatusFilterApplied() {
                return !isNullOrEmpty(this.filteredStatusOptions)
            },

            hasTypeFilterApplied() {
                return !isNullOrEmpty(this.filteredDocumentTypes)
            },

            hasSearchText() {
                return !isNullOrWhitespace(this.searchText)
            },

            documentsTitleNumbers() {
                return [...new Set(this.documents.map(doc => doc.titleNo))]
            },

            filteredDocumentsTitleNumbers() {
                return [...new Set(this.filteredDocuments.map(doc => doc.titleNo))]
            },

            filteredDocuments() {
                return this.documents.filter(doc => {
                    // Status
                    if (this.hasStatusFilterApplied) {
                        if (!this.filteredStatusOptions.some((t: any) => {
                            return t === getDocumentStatus(doc) || (t === 'Available' && getDocumentDownloadAvailability(doc))
                        })) {
                            return false
                        }
                    }

                    // Type
                    if (this.hasTypeFilterApplied) {
                        if (!this.filteredDocumentTypes.some(t => t === doc.documentType)) {
                            return false
                        }
                    }

                    // Search text - title number and group
                    if (this.hasSearchText) {
                        if (!(doc.titleNo?.toUpperCase().includes(this.searchText.toUpperCase())) &&
                            !(doc.groupName?.toUpperCase().includes(this.searchText.toUpperCase()))) {
                            return false
                        }
                    }

                    return true
                })
            },

            selectedDownloadableDocuments() {
                return this.selectedDocuments.filter(doc => getDocumentDownloadAvailability(doc))
            },

            selectedDownloadableTitles() {
                const downloadableTitles = new Set(this.selectedDownloadableTitleRegisters.map(document => document.titleNo))
                return Array.from(downloadableTitles)
            },

            selectedDownloadableTitleRegisters() {
                return this.selectedDocuments.filter(document => document.documentType === 'Register')
            },

            isPinned() {
                return this.matterHasTitle(this.selectedTitleNumber)
            },
        },

        watch: {
            routeName(newRouteName, oldRouteName) {
                if (newRouteName !== oldRouteName || this.selectedTitleNumber !== this.$route?.params?.titleNumber) {
                    const onThisTab = this.tabHash == this.$route.hash
                    if (!onThisTab) {
                        this.selectedTitleNumber = null
                        return
                    }
                    switch (newRouteName) {
                        case Route.DocumentsLibraryTitleDetails:
                            this.showTitleDetailsPanel(this.$route?.params?.titleNumber)
                            break

                        case Route.DocumentsLibrary:
                            this.clearSelectedTitle()
                            break
                    }
                }
            },
        },

        mounted() {
            this.showTitleDetailsPanel(this.$route.params.titleNumber)
        },

        methods: {
            ...mapActions({
                bulkDownload: DOWNLOAD_MANY,
                clearTitle: TITLE_CLEAR,
                lookupTitle: TITLE_LOOKUP_TITLE,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            setSelectedDocuments(val) {
                this.selectedDocuments = val
                this.$emit(EVENTS.SelectDocuments, val)
            },

            async refreshEverything() {
                if (this.currentMatterId !== null) {
                    this.selectedDocuments = []
                    this.$emit(EVENTS.RefreshDocuments)
                }
            },

            clearSelectedTitle() {
                this.clearTitle()
                this.selectedTitleNumber = null
                this.$router.push({
                    name: Route.DocumentsLibrary,
                    params: {
                        matterId: this.currentMatterId,
                    },
                    hash: this.tabHash,
                })
            },

            redirectToTitleNumber(titleNumber) {
                if (titleNumber !== this.selectedTitleNumber) {
                    this.$router.push({
                        name: Route.DocumentsLibraryTitleDetails,
                        params: {
                            matterId: this.currentMatterId,
                            titleNumber,
                        },
                        hash: this.tabHash,
                    })
                }
            },

            showTitleDetailsPanel(titleNumber) {
                if (!titleNumber) {
                    return
                }

                if (titleNumber !== this.selectedTitleNumber ||
                    !this.selectedTitleNumber?.record) {
                    this.lookupTitle(titleNumber)
                }

                this.selectedTitleNumber = titleNumber
            },

            async handleDownloadClicked() {
                this.loadingBulkDownload = true
                const request = this.selectedDownloadableDocuments.map(document => {
                    return {
                        documentType: document.documentType,
                        documentId: document.documentId,
                        responseType: 'blob',
                    }
                })
                await this.bulkDownload(request)
                await this.logHeapEvent({
                    type: 'DOCS - Export as PDF',
                    metadata: {
                        option: 'Separate .pdf files',
                        numberOfTitles: this.selectedDownloadableDocuments.length,
                        matterId: this.currentMatterId,
                        titleNumbers: this.selectedDownloadableDocuments.map(document => document.titleNo).join(','),
                    },
                })
                this.loadingBulkDownload = false
            },

            async handleReportClicked() {
                await this.$router.push({
                    name: Route.MatterReports,
                    params: {
                        matterId: this.currentMatterId,
                    },
                    query: {
                        selectedTitleNumbers: this.selectedDownloadableTitles,
                        origin: 'DOCS',
                    },
                })
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './purchased-documents';
</style>

<template>
    <ow-checkbox :id="productName"
                 v-model="productChecked"
                 color="primary"
                 hide-details
                 :data-test="`product-checkbox-${productName.replace(' ','-').toLowerCase()}`"
                 :label="productName"
                 density="compact" />

    <section v-if="licencingActive && productName === $t('products.witness') && !witnessLicencingDisabled"
             class="pb-4 pt-1">
        <label id="section-id-label"
               class="caption-regular"
               for="section-id">
            {{ t('licencing.licenceType') }}
        </label>
        <v-select v-model="licenceType"
                  :items="licenceTypeOptions"
                  data-test="licence-type-dropdown"
                  item-value="id"
                  item-title="label"
                  variant="solo"
                  hide-details />
    </section>
</template>

<script setup lang="ts">
    import { computed } from "vue"
    import { useI18n } from "vue-i18n"

    import OwCheckbox from "@/components/core/ow-checkbox.vue"
    import useLicenceController from "@/composables/use-licence-controller"

    const props = defineProps<{
        productName: string
        accountActive: boolean
        maxPremiumUsers: number
    }>()

    const { t } = useI18n()

    const productChecked = defineModel()
    const licenceType = defineModel('licenceType')

    const {active: licencingActive, licenceTypeOptions} = useLicenceController()
    const witnessLicencingDisabled = computed(() => {
        return !props.accountActive || !productChecked.value || props.maxPremiumUsers === 0
    })
</script>

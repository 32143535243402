import { LayerNames } from '@/consts/map-layers'
import i18n from '@/plugins/i18n'

import {
    StyleLike,
} from 'ol/style/Style'

import { FeatureLike } from 'ol/Feature'

import { IMapRolloverOptions } from '@/components/map-rollover/common/map-rollover-interfaces'

type BaseEnvironmentalFeatureType = {
    name: string,
    england: boolean,
    wales: boolean,
}

export type AonbLayerFeaturesType = BaseEnvironmentalFeatureType & {
    // eslint-disable-next-line camelcase
    designation_date: string,
    // eslint-disable-next-line camelcase
    external_link: string,
}

export type SssiFeaturesType = BaseEnvironmentalFeatureType & {
}

export type CommonLandFeaturesType = BaseEnvironmentalFeatureType & {
    // eslint-disable-next-line camelcase
    local_authority: string,
}

export type AncientWoodlandFeaturesType = BaseEnvironmentalFeatureType & {
    category_name: string,
}

export const getEnvironmentalRolloverOptions = (layerName: LayerNames, style: StyleLike): IMapRolloverOptions => {
    return {
        showPrimary: true,
        sortOrder: 0,
        category: i18n.global.t('map.rollover.environmental.category'),
        primary: i18n.global.t(`map.rollover.environmental.${ layerName.toLowerCase() }.primary`),
        getStyle: () => style,
        getSource: (features: FeatureLike[] | Record<string, FeatureLike[]>): string => {
            const properties = features[0].getProperties() as BaseEnvironmentalFeatureType
            return properties.england ? i18n.global.t('map.rollover.environmental.source.england') : i18n.global.t('map.rollover.environmental.source.wales')
        },
        getExtended: (features) => {
            const properties = features[0].getProperties() as BaseEnvironmentalFeatureType
            return [{
                name: i18n.global.t('map.rollover.environmental.extended.name'),
                value: properties?.name ?? '-',
            }]
        },
    }
}

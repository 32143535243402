<template>
    <v-snackbar v-model="visible"
                :timeout="timeout"
                :vertical="multiLine"
                class="ow-snackbar"
                location="bottom start"
                rounded="4px"
                transition="slide-y-reverse-transition">
        <div :class="multiLine ? 'ow-snackbar__content ow-snackbar__content--multi-line' : 'ow-snackbar__content'">
            <section>
                <slot />
            </section>
            <v-icon v-if="multiLine"
                    class="ow-snackbar__actions--close-btn"
                    data-test="ow-snackbar-close-btn"
                    data-track="Close snackbar"
                    @click="visible=false">
                $close
            </v-icon>
        </div>

        <template #actions>
            <div class="ow-snackbar__actions"
                 :class="multiLine ? 'ow-snackbar__actions ow-snackbar__actions--multi-line' : 'ow-snackbar__actions'">
                <slot name="actions" />
                <v-icon v-if="!multiLine"
                        class="ow-snackbar__actions--close-btn"
                        data-test="ow-snackbar-close-btn"
                        data-track="Close snackbar"
                        @click="visible=false">
                    $close
                </v-icon>
            </div>
        </template>
    </v-snackbar>
</template>

<script lang="ts">
    export default {
        name: 'OwSnackbar',

        props: {
            // Should use v-model and not this prop directly. See docs in Storybook for more details.
            modelValue: {
                type: Boolean,
                required: false,
            },
            timeout: {
                type: Number,
                required: false,
                default: -1,
            },
            multiLine: {
                type: Boolean,
                required: false,
            },
        },

        emits: ['update:modelValue'],

        computed: {
            visible: {
                // This is so that v-model can be used on the component
                get(): boolean {
                    return this.modelValue
                },
                set(val: boolean) {
                    this.$emit('update:modelValue', val)
                },
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-snackbar.scss';
</style>

export enum Route {
    AdminLogPage = 'admin-log-page',
    AdminOrganisationsPage = 'admin-organisations-page',
    AdminSettingsPage = 'admin-settings-page',
    AdminUsersPage = 'admin-users-page',
    Analysis = 'analysis',
    AnalysisTitleDetails = 'analysis-title-details',
    AssetMonitoring = 'asset-monitoring',
    AssetMonitoringTitleAlerts = 'title-alerts',
    AssetMonitoringCompanyAlerts = 'company-alerts',
    Checkout = 'checkout',
    CheckoutProcessing = 'checkout-processing',
    CheckoutReview = 'checkout-review',
    DocumentsLibrary = 'documents-library',
    DocumentsLibraryTitleDetails = 'documents-library-title-details',
    DocumentViewer = 'document-viewer',
    Homepage = 'homepage',
    LinkShareLanding = 'link-share-landing',
    MatterMap = 'matter-map',
    MatterMapTitle = 'matter-map-title',
    MatterMapWalkthrough = 'matter-map-walkthrough',
    MatterReports = 'matter-reports',
    MattersCreate = 'matters-create',
    MatterSketches = 'matter-sketches',
    MattersList = 'matters-list',
    MattersMonitoringPreferences = 'matters-monitoring-preferences',
    MattersSettings = 'matters-settings',
    MatterTitle = 'matter-title',
    MatterTitles = 'matter-titles',
    MatterSearches = 'matter-searches',
    MatterSearchesCreate = 'matter-searches-create',
    MatterSearchesDetails = 'matter-searches-details',
    OrganisationChargesPage = 'organisation-charges-page',
    OrganisationMattersPage = 'organisation-matters-page',
    OverlaysCreate = 'overlays-create',
    OverlaysList = 'overlays-list',
    Overlay = 'overlay',
    Reports = 'reports',
    ReportsSelectTitles = 'reports-select-titles',
    ReviewAssistant = 'review-assistant',
    Search = 'search',
    SearchesDocument = 'searches-document',
    SearchesDraftOrder = 'searches-draft-order',
    SettingsAccountPage = 'settings-account-page',
    SettingsPage = 'settings-page',
    Snapshot = 'snapshot',
    SnapshotAssistant = 'snapshot-assistant',
    TitleDetails = 'title-details',
    Unauthorised = 'unauthorised',
    UsersPage = 'users-page',
    Other = 'other',
}

<template>
    <nav class="sub-navigation">
        <ul class="sub-navigation__container">
            <li v-for="item in navigationItems"
                :class="{
                    'is-active': route.name == item.location.name
                }">
                <router-link :to="item.location"
                             class="caption-highlight">
                    {{ item.heading }}
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script setup lang="ts">
    import {useRoute} from "vue-router"

    import {SubNavigationItem} from "@/components/core/sub-navigation/sub-navigation-item.interface"
    const route = useRoute()
    const props = defineProps<{
        navigationItems: Array<SubNavigationItem>
    }>()
</script>

<style scoped lang="scss">
@import './sub-navigation';
</style>

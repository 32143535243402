<template>
    <section ref="rootRef"
             :data-group="item.titleNumber"
             class="row-item">
        <template v-if="isVisible">
            <div class="grid-row"
                 data-track="Asset Monitoring: alert grid item">
                <div class="grid-row__date">
                    <div class="row-item__read-status">
                        <ow-colored-label v-if="!item.isRead"
                                          is-notification-dot
                                          theme="notification-success" />
                    </div>
                    <div class="caption-regular row-item__date">
                        {{ format(item.createdOn) }}
                    </div>
                </div>
                <div class="grid-row__content">
                    <div class="row-item__content">
                        <div class="main-content">
                            <div class="main-content__info">
                                <h3 class="body-highlight main-content__info--type">
                                    {{ model.title }}
                                </h3>

                                <i18n-t :keypath="model.descriptionPath"
                                        scope="global"
                                        class="main-content__info--title-number"
                                        tag="p">
                                    <template #titleNumber>
                                        <ow-title-number-tooltip :title-number="model.titleNumber" />
                                    </template>
                                    <template v-if="model.boundaryDifference"
                                              #size>
                                        <span v-dompurify-html="`${model.boundaryDifference.toFixed(1)} m<sup>2</sup>`" />
                                    </template>
                                    <template v-if="model.boundaryDifference"
                                              #isLarger>
                                        {{
                                            model.isBoundaryLarger
                                                ? t('assetMonitoring.subType.boundaryUpdated.larger')
                                                : t('assetMonitoring.subType.boundaryUpdated.smaller')
                                        }}
                                    </template>
                                </i18n-t>
                            </div>
                        </div>
                    </div>
                    <div class="row-item--actions">
                        <i aria-hidden="true"
                           class="material-icons row-item--actions--source-icon">info_outline
                            <ow-tooltip :position="OwTooltipPosition.Top"
                                        activator="parent"
                                        nudge-right="4px"
                                        nudge-top="5px">
                                <span>{{ `${$t('assetMonitoring.text.source')} ${$t(`assetMonitoring.dataSource.${AssetMonitoringDataSource[model.dataSource]}`)} ` }}</span>
                            </ow-tooltip>
                        </i>
                        <ow-button v-if="!item.isRead"
                                   :is-loading="isUpdating"
                                   is-secondary
                                   data-track="Asset monitoring: mark as read"
                                   @click="handleMarkAsRead">
                            {{ t('action.markAsRead') }}
                        </ow-button>
                        <slot name="actions" />
                    </div>
                </div>
            </div>
            <div v-if="hasExtraInfoSlotContent"
                 class="row-item__extra-info">
                <div>&nbsp;</div> <!-- Used to push the elements below along one space in the grid -->
                <slot name="extra-info" />
            </div>
        </template>
    </section>
</template>

<script lang="ts"
        setup>
    import {
        computed,
        onMounted,
        ref,
        useSlots,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import {
        AssetMonitoringDataSource,
        BaseRowItemModel,
    } from '@/components/asset-monitoring/base-row-item-model'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import OwTitleNumberTooltip from '@/components/core/ow-title-number-tooltip.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'
    import { format } from '@/utils/date-utils'

    const props = defineProps<{
        item: IAssetMonitoringNotification,
        containerSelector: string,
        model: BaseRowItemModel
    }>()

    const emit = defineEmits<{
        (e: 'view-title', titleNumber: string),
    }>()

    const slots = useSlots()
    const store = useAssetMonitoringStore()
    const { t } = useI18n()
    const hasExtraInfoSlotContent = computed(() => Boolean(slots['extra-info']))

    const rootRef = ref(null)
    const scrollObserver = ref(null) // The Intersection Observer used to detect visibility changes.
    let isVisible = ref<boolean>(true)
    const initialiseIntersectionObserver = () => {
        // Listen for changes to the position of this component within it's parent scrollable element.
        if (scrollObserver.value === null) {
            // The parent scrollable element.
            // This is set in /components/asset-monitoring/grid/data-grid.vue
            const scrollElement = document.querySelector(props.containerSelector)
            if (scrollElement !== null) {
                const options = {
                    root: scrollElement,
                    rootMargin: '16px',
                    threshold: 1.0,
                }

                scrollObserver.value = new IntersectionObserver((entries: Array<any>) => {
                    entries.forEach((entry: any) => {
                        if (entry.target.dataset.group === props.item.titleNumber) {
                            // True if component instant is visible.
                            isVisible.value = entry.intersectionRatio > 0
                        }
                    })
                }, options)
                scrollObserver.value.observe(rootRef.value)
            }
        }
    }

    const isUpdating = ref<boolean>(false)
    const handleMarkAsRead = async () => {
        isUpdating.value = true
        await store.dismissNotification(props.item.notificationId)
        isUpdating.value = false
    }

    onMounted(() => {
        // TODO: get this working!
        // initialiseIntersectionObserver()
    })
</script>

<style lang="scss"
       scoped>
    @import './base-row-item.scss';
</style>

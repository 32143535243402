<template>
    <section class="title-panel-card"
             data-section="boundary">
        <div class="title-panel-card__content">
            <div class="boundary__content--header">
                <h2 v-t="'titlePanel.tabs.sections.summary.boundary'"
                    class="label-caps-medium boundary__content--title" />
                <ow-loading-skeleton v-if="isLoading"
                                     class="mb-3"
                                     height="24px"
                                     style="max-width: 100px" />
                <div v-else-if="hasBoundary"
                     class="d-flex flex-row">
                    <ow-toggle v-model="showMatterBoundaries"
                               :is-loading="loadingMatterBoundaries"
                               data-track="Show matter boundaries"
                               :label="loadingMatterBoundaries ? t('titlePanel.cards.boundary.loadingMatterBoundaries') : t('titlePanel.cards.boundary.showMatterBoundaries')" />

                    <ow-button-ds is-text
                                  is-flat
                                  data-test="open-matter-map"
                                  data-track="open-matter-map"
                                  class="boundary__content--matter-button mx-2"
                                  @click="handleGoToMatterMap">
                        <label v-t="'titlePanel.header.actions.goToMatterMap'" />
                    </ow-button-ds>
                </div>
            </div>
            <div v-if="!hasBoundary"
                 class="boundary__content--warning">
                <v-icon class="boundary__content--warning-icon">
                    $warning
                </v-icon>
                <span>{{
                    $t('titles.unavailableBoundaryWithDataSource',
                       [dataProviderText(selectedTitle?.record.source)])
                }}</span>
                <a class="boundary-data-unavailable__learn-more"
                   href="https://intercom.help/orbital-witness/en/articles/5394971-title-boundary-can-t-be-displayed"
                   target="_blank"
                   data-track="TITLE-DETAILS-PANEL - Learn more about why boundary cannot be displayed">Learn more</a>
            </div>
            <v-container v-else
                         class="d-flex flex-column pa-0">
                <ow-loading-skeleton v-if="isLoading"
                                     height="200px" />
                <ow-title-boundary-map v-show="!isLoading"
                                       :boundary-width="5"
                                       enable-map-interactions
                                       :geoserver-nps-layer-name="npsLayer"
                                       :title-number="selectedTitleNumber"
                                       :layer-attribution="layerAttributionText"
                                       :layer-source="layerSource"
                                       interactive-scroll-zoom
                                       boundary-colour="#f44336"
                                       :show-matter-boundaries="showMatterBoundaries"
                                       style="height: 20em;"
                                       class="boundary__content--map"
                                       @loading-matter-boundaries-updated="loadingMatterBoundaries = $event" />
                <div v-if="isLoading"
                     class="mt-3 d-flex w-100 justify-center">
                    <ow-loading-skeleton height="24px"
                                         style="max-width: 150px" />
                </div>
                <ow-button-ds v-else
                              is-text
                              is-flat
                              small
                              data-test="open-matter-map"
                              data-track="open-matter-map"
                              class="boundary__content--change-boundary my-2"
                              @click="handleChangeBoundary">
                    <label v-t="'titlePanel.header.actions.changeStyleAndName'" />
                </ow-button-ds>
            </v-container>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
    } from 'vue'
    import {useI18n} from "vue-i18n"
    import {useStore} from "vuex"

    import OwTitleBoundaryMap from "@/components/core/maps/ow-title-boundary-map.vue"
    import OwButtonDs from "@/components/core/ow-button-ds.vue"
    import OwLoadingSkeleton from "@/components/core/ow-loading-skeleton.vue"
    import OwToggle from "@/components/core/ow-toggle.vue"
    import {ITitlePanelTabController} from "@/composables/use-title-panel"
    import {getDataProviderText} from "@/consts/document-source"
    import {TitlePanelTabName} from "@/enums/title-panel-tab-name"
    import {getBoundaryDataAttribution} from "@/store/modules/map/layers/hmlr"
    import {
        MAP_MUTATE_SET_CLICKED_TITLE_NUMBERS,
        MAP_VALIDATE_NPS_DISPLAY,
    } from "@/store/modules/map/types"
    import {
        MATTER_GET_MATTER_CONTENTS,
        MATTER_MUTATE_STYLE_PROMPT,
        MATTER_SHOW_BOUNDARIES,
    } from "@/store/modules/matter/types"
    import { TITLE_CLEAR } from "@/store/modules/titles/types"
    import { isNullOrEmpty } from "@/utils/array-utils"

    const props = defineProps<{
        isLoading?: boolean,
        tabController: ITitlePanelTabController,
        selectedTitle: any,
    }>()
    const store = useStore()
    const { t } = useI18n()
    const loadingMatterBoundaries = ref<boolean>(false)

    props.tabController.addTabSection(TitlePanelTabName.Summary, {
        id: 'boundary',
        order: 3,
    })

    const showMatterBoundaries = ref<boolean>(false)

    const npsLayer = computed(() => store.state.config.settings.npsLayer)
    const monthlyHMLRDataVersion = computed(() => store.state.config.settings.monthlyHMLRDataVersion)
    const selectedTitleNumber = computed<string>(() => store.state.title.selectedTitleNumber)
    const layerAttributionText = computed(() => {
        return getBoundaryDataAttribution(monthlyHMLRDataVersion.value)
    })
    const layerSource = computed(() => {
        const matterTitles = store.getters[MATTER_GET_MATTER_CONTENTS]?.titles
        const layerProperties = matterTitles?.find((title: any) => title.titleNumber === selectedTitleNumber.value)
        if (!layerProperties) return
        layerProperties.show = true
        return layerProperties
    })
    const hasBoundary = computed(() => {
        return props.selectedTitle.bboxGeom !== null
    })

    const handleGoToMatterMap = async () => {
        store.commit(MAP_MUTATE_SET_CLICKED_TITLE_NUMBERS, [])
        await store.dispatch(TITLE_CLEAR)
        await store.dispatch(MAP_VALIDATE_NPS_DISPLAY)
    }

    const handleChangeBoundary = async () => {
        const matterTitles = store.getters[MATTER_GET_MATTER_CONTENTS]?.titles
        const matterTitle = !isNullOrEmpty(matterTitles) && matterTitles.filter((title: any) => title.titleNumber === selectedTitleNumber.value)
        if (!isNullOrEmpty(matterTitle)) {
            await store.dispatch(MATTER_SHOW_BOUNDARIES, {
                titles: matterTitle,
                show: true,
                skipStyleUpdate: true,
            })
            store.commit(MATTER_MUTATE_STYLE_PROMPT,
                         {
                             titles: matterTitle,
                             updateMainMap: true,
                         })
        }
        await handleGoToMatterMap()
    }

    const dataProviderText = (val) => getDataProviderText(val)

</script>

<style lang="scss">
@import './boundary';
</style>

import olMap from 'ol/Map'
import {
    getHeight,
    getWidth,
} from 'ol/extent'
import { LayerNames } from '@/consts/map-layers'
import { NpsLayer } from '@/store/modules/map/layers/nps-layer'
import { OsImageTileLayer } from '@/store/modules/map/layers/os-image-tile-layer'
import { MatterTitleBoundaryLayerGroup } from '@/store/modules/map/layers/title-boundary-layer/layer-group'
import { TitleNumbersLayer } from '@/store/modules/map/layers/title-numbers-layer'
import { OsHighwaysMaintenanceLayer } from '@/store/modules/map/layers/os-highways-maintenance-layer'
import { OsPublicRightsOfWayLayer } from '@/store/modules/map/layers/os-public-rights-of-way-layer'
import { ListedBuildingsLayer } from '@/store/modules/map/layers/listed-buildings-layer'
import { ScheduledMonumentsLayer } from '@/store/modules/map/layers/scheduled-monuments-layer'
import { ConservationAreasLayer } from '@/store/modules/map/layers/conservation-areas-layer'
import { FloodZone2Layer } from '@/store/modules/map/layers/flood-zone2-layer'
import { FloodZone3Layer } from '@/store/modules/map/layers/flood-zone3-layer'
import { FloodRisksSeaAndRiversLayer } from '@/store/modules/map/layers/flood-risks-sea-and-rivers-layer'
import { FloodRisksSurfaceWaterLayer } from '@/store/modules/map/layers/flood-risks-surface-water-layer'
import { AzureMapsAerialLayer } from '@/store/modules/map/layers/azure-maps-layer'
import { AncientWoodlandLayer } from '@/store/modules/map/layers/ancient-woodland-layer'
import { CommonLandLayer } from '@/store/modules/map/layers/common-land-layer'
import { SssiLayer } from '@/store/modules/map/layers/sssi-layer'
import { AonbLayer } from '@/store/modules/map/layers/aonb-layer'
import { TitleSelectionLayer } from '@/store/modules/map/layers/title-selection-layer'
import { SketchesLayer } from '@/store/modules/map/layers/sketches-layer'
import { OverlaysLayer } from '@/components/map/overlays/overlays-layer'
import { UnregisteredLandLayer } from '@/store/modules/map/layers/unregistered-land-layer'
import { BlueskyAerialLayer } from '@/store/modules/map/layers/bluesky-aerial-layer'

/***
 * Get the extent of the map, extended to include the extent of an area.
 * @param targetMap - the map to get the extent from
 */
export const getExtendedMapExtentForSnapshot = (targetMap: olMap) => {
    const multiplier = 3
    const currentExtent = targetMap.getView().calculateExtent(targetMap.getSize())
    const extentWidth = getWidth(currentExtent)
    const extentHeight = getHeight(currentExtent)
    return [
        currentExtent[0] - extentWidth * multiplier,
        currentExtent[1] - extentHeight * multiplier,
        currentExtent[2] + extentWidth * multiplier,
        currentExtent[3] + extentHeight * multiplier,
    ]
}

/**
 * To allow the MapSnapshotService to map layer names to layer classes, we need to map the layer names to the layer classes.
 */
export const layerTypeMapping = {
    [LayerNames.AncientWoodland]: AncientWoodlandLayer,
    [LayerNames.AzureMapsAerial]: AzureMapsAerialLayer,
    [LayerNames.BlueskyAerial]: BlueskyAerialLayer,
    [LayerNames.AONB]: AonbLayer,
    [LayerNames.CommonLand]: CommonLandLayer,
    [LayerNames.SSSI]: SssiLayer,
    [LayerNames.ConservationAreas]: ConservationAreasLayer,
    [LayerNames.FloodZone2]: FloodZone2Layer,
    [LayerNames.FloodZone3]: FloodZone3Layer,
    [LayerNames.FloodRiskSeaAndRivers]: FloodRisksSeaAndRiversLayer,
    [LayerNames.FloodRiskSurfaceWater]: FloodRisksSurfaceWaterLayer,
    [LayerNames.HighwaysMaintenance]: OsHighwaysMaintenanceLayer,
    [LayerNames.ListedBuildings]: ListedBuildingsLayer,
    [LayerNames.MatterTitles]: MatterTitleBoundaryLayerGroup,
    [LayerNames.NPS]: NpsLayer,
    [LayerNames.OrdnanceSurvey]: OsImageTileLayer,
    [LayerNames.PublicRightsOfWay]: OsPublicRightsOfWayLayer,
    [LayerNames.ScheduledMonuments]: ScheduledMonumentsLayer,
    [LayerNames.TitleNumbers]: TitleNumbersLayer,
    [LayerNames.TitleSelectionLayer]: TitleSelectionLayer,
    [LayerNames.Sketches]: SketchesLayer,
    [LayerNames.Overlays]: OverlaysLayer,
    [LayerNames.UnregisteredLand]: UnregisteredLandLayer,
}

<template>
    <section v-hotkey="keymap"
             :class="{ 'top-nav-visible': isTopNavVisible}"
             class="map-layers-panel">
        <header class="map-layers-panel__header">
            <ow-map-layers-icon />
            <h1 v-t="'map.options.mapLayers'"
                class="headers-h1-page-title" />
            <v-icon class="map-layers-panel__header--close-icon"
                    data-test="map-layers-panel-close-btn"
                    @click="handleClosePanel">
                $close
            </v-icon>
        </header>

        <section v-if="extentInScotland"
                 class="map-layers-panel__hmlr-layers">
            <h3 v-t="'map.options.scotlandLayers'"
                class="caption-highlight" />
            <div class="map-layers-panel__hmlr-layers--options">
                <ow-checkbox v-if="isScotlandLayerEnabled"
                             id="map-layers-panel-scotland"
                             v-model="showScotlandLayer"
                             :label="$t('map.options.scotland')"
                             data-test="map-layers-panel-scotland" />
            </div>
        </section>
        <section v-else
                 class="map-layers-panel__hmlr-layers">
            <h3 v-t="'map.options.hmlrLayers'"
                class="caption-highlight" />
            <div class="map-layers-panel__hmlr-layers--options">
                <ow-checkbox id="map-layers-panel-freeholds"
                             v-model="showFreeholds"
                             :label="$t('map.options.freeholds')"
                             data-test="map-layers-panel-freeholds"
                             label-class-name="map-layers-panel__hmlr-layers--freeholds"
                             shortcut="f" />
                <ow-checkbox id="map-layers-panel-leaseholds"
                             v-model="showLeaseholds"
                             :label="$t('map.options.leaseholds')"
                             data-test="map-layers-panel-leaseholds"
                             label-class-name="map-layers-panel__hmlr-layers--leaseholds"
                             shortcut="l" />
                <ow-checkbox id="map-layers-panel-poc-unregistered-land"
                             v-model="showUnregisteredLand"
                             :label="$t('map.options.pocUnregisteredLand')"
                             :loading="isUnregisteredLandLayerLoading"
                             data-test="map-layers-panel-poc-unregistered-land"
                             shortcut="u" />
                <ow-checkbox id="map-layers-panel-title-numbers"
                             v-model="showTitleNumbers"
                             :label="$t('map.options.titleNumbers')"
                             data-test="map-layers-panel-title-numbers"
                             shortcut="t" />
            </div>
        </section>

        <section class="map-layers-panel__base-map">
            <h3 v-t="'map.options.baseMap'"
                class="caption-highlight" />
            <div class="map-layers-panel__base-map--container">
                <div class="map-layers-panel__base-map--container-os">
                    <div v-t="'map.options.os'"
                         class="label-caps-small" />
                    <div class="map-layers-panel__base-map--list">
                        <img :alt="$t('map.options.osText')"
                             :class="{ active: baseLayer === mapBackdrops.osMap }"
                             aria-hidden="true"
                             class="map-layers-panel__base-map--checkmark"
                             data-test="map-layers-panel__base-map--os"
                             src="@/media/map-options-os.png"
                             @click="updateBaseLayer(mapBackdrops.osMap)" />
                        <img :alt="$t('map.options.osLightText')"
                             :class="{ active: baseLayer === mapBackdrops.osMapLight }"
                             aria-hidden="true"
                             class="map-layers-panel__base-map--checkmark"
                             data-test="map-layers-panel__base-map--os-light"
                             src="@/media/map-options-os-light.png"
                             @click="updateBaseLayer(mapBackdrops.osMapLight)" />
                    </div>
                </div>
                <span aria-hidden="true"
                      class="map-layers-panel__divider"
                      role="presentation" />
                <div class="map-layers-panel__base-map--container-aerial">
                    <div v-t="'map.options.aerial'"
                         class="label-caps-small" />
                    <img v-if="aerialTrialEnabled"
                         :alt="$t('map.options.aerial')"
                         :class="{ active: baseLayer === mapBackdrops.blueskyAerial }"
                         aria-hidden="true"
                         class="map-layers-panel__base-map--checkmark"
                         data-test="map-layers-panel__base-map--aerial"
                         src="@/media/map-options-aerial.png"
                         @click="updateBaseLayer(mapBackdrops.blueskyAerial)" />
                    <img v-else
                         :alt="$t('map.options.aerial')"
                         :class="{ active: baseLayer === mapBackdrops.aerial }"
                         aria-hidden="true"
                         class="map-layers-panel__base-map--checkmark"
                         data-test="map-layers-panel__base-map--aerial"
                         src="@/media/map-options-aerial.png"
                         @click="updateBaseLayer(mapBackdrops.aerial)" />
                </div>
            </div>
        </section>

        <expandable-item v-model="displayLayers.showHighways"
                         :label="$t('map.options.highways')"
                         data-test="map-layers-panel__base-map--highways">
            <map-layers-legend v-model="DISPLAY_LAYERS.highways" />
        </expandable-item>

        <div v-if="!extentInScotland">
            <expandable-item v-model="displayLayers.showRightsOfWay"
                             :label="$t('map.options.rightOfWay')"
                             data-test="map-layers-panel__base-map--prow">
                <map-layers-legend v-model="DISPLAY_LAYERS.prow" />
            </expandable-item>

            <expandable-item v-model="displayLayers.showListedBuildings"
                             :label="$t('map.options.listedBuildings.text')"
                             :loading="isListedBuildingsLayerLoading"
                             data-test="map-layers-panel__base-map--listedBuildings">
                <map-layers-legend v-model="DISPLAY_LAYERS.listedBuildings"
                                   circle />
            </expandable-item>

            <expandable-item v-model="displayLayers.showScheduledMonuments"
                             :label="$t('map.options.scheduledMonuments.text')"
                             :loading="isScheduledMonumentsLayerLoading"
                             data-test="map-layers-panel__base-map--scheduledMonuments">
                <map-layers-legend v-model="DISPLAY_LAYERS.scheduledMonuments"
                                   square />
            </expandable-item>

            <expandable-item v-model="displayLayers.showConservationAreas"
                             :label="$t('map.options.conservationAreas.text')"
                             :loading="isConservationAreasLayerLoading"
                             data-test="map-layers-panel__base-map--conservationAreas">
                <map-layers-legend v-model="DISPLAY_LAYERS.conservationAreas"
                                   square />
            </expandable-item>

            <expandable-item v-model="showFloodRisk"
                             :indeterminate="isFloodZoneLayerIndeterminate"
                             :label="$t('map.options.flood.text')"
                             :loading="isFloodZoneLayerLoading"
                             data-test="map-layers-panel__base-map--floodZone">
                <map-layers-legend v-model="displayLayerFloodZones"
                                   checkbox
                                   square />
            </expandable-item>

            <expandable-item v-model="showEnvironmentalLayers"
                             :indeterminate="isEnvironmentalLayerIndeterminate"
                             :label="$t('map.options.environmental.text')"
                             :loading="isEnvironmentalLayerLoading"
                             data-test="map-layers-panel__base-map--environmental">
                <map-layers-legend v-model="displayEnvironmentalLayers"
                                   checkbox
                                   square />
            </expandable-item>
        </div>

        <section class="map-layers-panel__other">
            <span v-t="'message.dataQuery'"
                  class="caption-regular" />
            <a :href="MapLayersFeedbackURL"
               class="map-layers-panel__other--feedback"
               rel="noreferrer noopener"
               target="_blank">
                <ow-icon-megaphone />
                <span v-t="'action.giveFeedback'"
                      class="accents-link-text" />
            </a>
        </section>
    </section>
</template>

<script lang="ts" setup>
    import { storeToRefs } from 'pinia'
    import {
        computed,
        onActivated,
        onDeactivated,
        onMounted,
        ref,
        shallowRef,
        watch,
    } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import OwIconMegaphone from '@/components/core/icons/ow-icon-megaphone.vue'
    import OwMapLayersIcon from '@/components/core/icons/ow-map-layers-icon.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import MapLayersLegend from '@/components/map/map-layers-legend.vue'
    import ExpandableItem from '@/components/map/map-layers-panel-expandable-item.vue'
    import { useMapTopNav } from '@/composables/use-map-top-nav'
    import { DISPLAY_LAYERS,
             IDisplayLayer,
             mapBackdrops } from '@/consts/map'
    import { MapLayersFeedbackURL } from '@/consts/urls'
    import { Route } from '@/enums/route.enum'
    import { AncientWoodlandLayer } from '@/store/modules/map/layers/ancient-woodland-layer'
    import { AonbLayer } from '@/store/modules/map/layers/aonb-layer'
    import { CommonLandLayer } from '@/store/modules/map/layers/common-land-layer'
    import { ConservationAreasLayer } from '@/store/modules/map/layers/conservation-areas-layer'
    import { FloodRisksSeaAndRiversLayer } from '@/store/modules/map/layers/flood-risks-sea-and-rivers-layer'
    import { FloodRisksSurfaceWaterLayer } from '@/store/modules/map/layers/flood-risks-surface-water-layer'
    import { FloodZone2Layer } from '@/store/modules/map/layers/flood-zone2-layer'
    import { FloodZone3Layer } from '@/store/modules/map/layers/flood-zone3-layer'
    import { ListedBuildingsLayer } from '@/store/modules/map/layers/listed-buildings-layer'
    import { RadonLayer } from '@/store/modules/map/layers/radon-layer'
    import { ScheduledMonumentsLayer } from '@/store/modules/map/layers/scheduled-monuments-layer'
    import {
        ScotlandInspireFeatureType,
        ScotlandInspireLayer,
    } from '@/store/modules/map/layers/scotland-inspire-layer'
    import { SssiLayer } from '@/store/modules/map/layers/sssi-layer'
    import { UnregisteredLandLayer } from '@/store/modules/map/layers/unregistered-land-layer'
    import {
        MAP_ANCIENT_WOODLAND_MUTATE_OVER_TEXT,
        MAP_AONB_MUTATE_OVER_TEXT,
        MAP_COMMON_LAND_MUTATE_OVER_TEXT,
        MAP_CONSERVATION_AREAS_MUTATE_OVER_TEXT,
        MAP_FLOOD_RISKS_SEA_AND_RIVERS_MUTATE_OVER_TEXT,
        MAP_FLOOD_RISKS_SURFACE_WATER_MUTATE_OVER_TEXT,
        MAP_FLOOD_ZONE_2_MUTATE_OVER_TEXT,
        MAP_FLOOD_ZONE_3_MUTATE_OVER_TEXT,
        MAP_LISTED_BUILDINGS_MUTATE_OVER_TEXT,
        MAP_SCHEDULED_MONUMENTS_MUTATE_OVER_TEXT,
        MAP_SET_BASELAYER,
        MAP_SHOW_HIGHWAY_MAINTENANCE,
        MAP_SHOW_NPS_FREEHOLDS,
        MAP_SHOW_NPS_LEASEHOLDS,
        MAP_SHOW_NPS_TITLENUMBERS,
        MAP_SHOW_PUBLIC_RIGHTS_OF_WAY,
        MAP_SHOW_UNREGISTERED_LAND,
        MAP_SSSI_MUTATE_OVER_TEXT,
        MAP_UNREGISTERED_MUTATE_OVER_TEXT,
    } from '@/store/modules/map/types'
    import {
        TITLE_FETCH_TITLE_ADDRESSES,
        TITLE_GET_INFORMATION_BY_TITLE_NUMBERS,
        TITLE_MUTATE_SET_TITLES,
        TITLE_SHOW_MULTI_TITLE_SELECTION_PANEL,
    } from "@/store/modules/titles/types"
    import { LOGGING_HEAP_TRACK_EVENT,
             LOGGING_LOG_FEATURE_USAGE } from '@/store/mutation-types'
    import { useScotlandStore } from '@/stores/scotland'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import {
        isExtentInScotland,
    } from "@/utils/map-utils"

    interface ISelectedDisplayLayer extends IDisplayLayer {
        selected: boolean,
        layerName: string,
        isLoading?: boolean,
    }

    const emits = defineEmits<{
        (e: 'close'): void,
    }>()

    const handleClosePanel = () => {
        emits('close')
    }

    const store = useStore()
    const {isTopNavVisible} = useMapTopNav()

    const displayLayers = ref({
        showHighways: false,
        showRightsOfWay: false,
        showListedBuildings: false,
        showScheduledMonuments: false,
        showConservationAreas: false,
    })

    const settings = computed(() => store.state.config?.settings)
    const targetMap = computed(() => store.state.map.map)
    const aerialTrialEnabled = computed(() => store.state.config.featureFlags?.aerialTrial)

    const logHeapEvent = async ({ type, metadata }) => {
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type,
            metadata,
        })
    }

    const waitForLayerToLoad = (layer: any) => {
        return new Promise<void>((resolve) => {
            const interval = setInterval(() => {
                if(!layer.getIsLoading()) {
                    clearInterval(interval)
                    resolve()
                }
            }, 100)
        })
    }

    const handleHotkeys = ({key}) => {
        switch (key) {
            case 'f':
                showFreeholds.value = !showFreeholds.value
                break
            case 'l':
                showLeaseholds.value = !showLeaseholds.value
                break
            case 'u':
                showUnregisteredLand.value = !showUnregisteredLand.value
                break
            case 't':
                showTitleNumbers.value = !showTitleNumbers.value
                break
        }
    }
    const keymap = computed(() => ({
        f: handleHotkeys,
        l: handleHotkeys,
        t: handleHotkeys,
        u: handleHotkeys,
    }))

    let baseLayer = ref<string>(mapBackdrops.osMap)
    const updateBaseLayer = async (newBaseLayer: string): Promise<void> => {
        await store.dispatch(MAP_SET_BASELAYER, newBaseLayer)
        baseLayer.value = newBaseLayer

        // log usage
        await store.dispatch(LOGGING_LOG_FEATURE_USAGE, {
            type: 'baselayer-selection',
            description: newBaseLayer,
        })
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'MAP - Toggle base layer on map',
            metadata: {
                value: newBaseLayer,
            },
        })
    }

    /**
     * Freeholds Layer
     */
    const showNPSFreeholdsValue = computed((): boolean => store.state.map.nps.showNPSFreeholds)

    const showFreeholds = computed({
        get: (): boolean => showNPSFreeholdsValue.value,
        set: async (value: boolean): Promise<void> => {
            await store.dispatch(MAP_SHOW_NPS_FREEHOLDS, value)
            await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'MAP - Toggle freeholds layer on map',
                metadata: {value},
            })
        },
    })

    /**
     * Leaseholds Layer
     */
    const showNPSLeaseholdsValue = computed((): boolean => store.state.map.nps.showNPSLeaseholds)

    const showLeaseholds = computed({
        get: (): boolean => showNPSLeaseholdsValue.value,
        set: async (value: boolean): Promise<void> => {
            await store.dispatch(MAP_SHOW_NPS_LEASEHOLDS, value)
            await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'MAP - Toggle leaseholds layer on map',
                metadata: {value},
            })
        },
    })

    /**
     * Title Numbers Layer
     */
    const showNPSTitleNumbersValue = computed((): boolean => store.state.map.nps.showNPSTitleNumbers)

    const showTitleNumbers = computed({
        get: (): boolean => showNPSTitleNumbersValue.value,
        set: async (value: boolean): Promise<void> => {
            await store.dispatch(MAP_SHOW_NPS_TITLENUMBERS, value)
            await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'MAP - Toggle title numbers layer on map',
                metadata: {value},
            })
        },
    })

    /**
     * Unregistered Land Layer
     */
    let unregisteredLandLayer = ref<UnregisteredLandLayer>(null)

    const isUnregisteredLandLayerLoading = computed((): boolean => unregisteredLandLayer.value?.getIsLoading())

    const initialiseUnregisteredLandLayer = () => {
        if (targetMap.value && !unregisteredLandLayer.value) {
            unregisteredLandLayer.value = new UnregisteredLandLayer({
                targetMap: targetMap.value,
                onHoverTextChangeCallback: (textArray, event) => {
                    store.commit(MAP_UNREGISTERED_MUTATE_OVER_TEXT, {textArray, event})
                },
                eventLogger: {logEvent: logHeapEvent},
            }, settings.value)
        }
    }

    const showUnregisteredLandLayer = computed((): boolean => store.state.map.dataLayers.unregisteredLand.showUnregisteredLandlayer)

    const showUnregisteredLand = computed({
        get: (): boolean => showUnregisteredLandLayer.value,
        set: async (value: boolean): Promise<void> => {
            await store.dispatch(MAP_SHOW_UNREGISTERED_LAND, value)
            await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'MAP - Toggle unregistered land layer on map ',
                metadata: {value},
            })
        },
    })

    /**
     * Highways Layer
     */
    watch(() => displayLayers.value.showHighways, async (value: boolean): Promise<void> => {
        await store.dispatch(MAP_SHOW_HIGHWAY_MAINTENANCE, value)
        if (value === true) {
            await store.dispatch(LOGGING_LOG_FEATURE_USAGE, {
                type: 'highways-maintenance',
                description: null,
            })
        }
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'MAP - Toggle highways maintenance layer',
            metadata: {value},
        })
    })

    /**
     * Public Rights of Way Layer
     */
    watch(() => displayLayers.value.showRightsOfWay, async (value) => {
        await store.dispatch(MAP_SHOW_PUBLIC_RIGHTS_OF_WAY, value)
        if (value) {
            await store.dispatch(LOGGING_LOG_FEATURE_USAGE, {
                type: 'highways-dedication',
                description: null,
            })
        }
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'MAP - Toggle public rights of way layer',
            metadata: { value },
        })
    })

    /**
     * Listed Buildings Layer
     */
    let listedBuildingsLayer = ref<ListedBuildingsLayer>(null)

    const initialiseListedBuildings = () => {
        if (targetMap.value !== undefined) {
            listedBuildingsLayer.value = (shallowRef(new ListedBuildingsLayer({
                targetMap: targetMap.value,
                onHoverTextChangeFn: (textArray, event) => {
                    store.commit(MAP_LISTED_BUILDINGS_MUTATE_OVER_TEXT, {textArray, event})
                },
                logToggleEvent: (value) => {
                    store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                        type: 'MAP - Toggle listed buildings layer on map',
                        metadata: {value},
                    })
                },
                logClickEvent: (value) => {
                    store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                        type: 'MAP - Listed building point clicked',
                        metadata: {value},
                    })
                },
            }, settings.value,
            )) as any).value
        }
    }

    watch(() => displayLayers.value.showListedBuildings, async (value: boolean): Promise<void> => {
        await listedBuildingsLayer.value?.setVisible(value, targetMap.value)
    })

    const isListedBuildingsLayerLoading = computed((): boolean => listedBuildingsLayer.value?.getIsLoading())

    /**
     * Scheduled Monuments Layer
     */
    let scheduledMonumentsLayer = ref<ScheduledMonumentsLayer>(null)

    const initialiseScheduledMonumentsLayer = () => {
        if (targetMap.value && !scheduledMonumentsLayer.value) {
            scheduledMonumentsLayer.value = new ScheduledMonumentsLayer({
                targetMap: targetMap.value,
                onHoverTextChangeCallback: (textArray, event) => {
                    store.commit(MAP_SCHEDULED_MONUMENTS_MUTATE_OVER_TEXT, {textArray, event})
                },
                eventLogger: { logEvent: logHeapEvent },
            }, settings.value)
        }
    }

    watch(() => displayLayers.value.showScheduledMonuments, async (value: boolean): Promise<void> => {
        await scheduledMonumentsLayer.value?.setVisible(value, targetMap.value)
    })

    const isScheduledMonumentsLayerLoading = computed((): boolean => scheduledMonumentsLayer.value?.getIsLoading())

    /**
     * Conservation Areas Layer
     */
    let conservationAreasLayer = ref<ConservationAreasLayer>(null)

    const initialiseConservationAreas = () => {
        if (targetMap.value && !conservationAreasLayer.value) {
            conservationAreasLayer.value = new ConservationAreasLayer({
                targetMap: targetMap.value,
                onHoverTextChangeCallback: (textArray, event) => {
                    store.commit(MAP_CONSERVATION_AREAS_MUTATE_OVER_TEXT, {textArray, event})
                },
                eventLogger: {logEvent: logHeapEvent},
            }, settings.value)
        }
    }

    watch(() => displayLayers.value.showConservationAreas, async (value: boolean): Promise<void> => {
        await conservationAreasLayer.value?.setVisible(value)
    })

    const isConservationAreasLayerLoading = computed((): boolean => conservationAreasLayer.value?.getIsLoading())

    /**
     * Flood Risk Layers
     */
    const displayLayerFloodZones = ref<Array<ISelectedDisplayLayer>>([])
    const initialisedFloodLayers = ref<Array<any>>([])

    let floodZone2Layer = ref<FloodZone2Layer>(null)
    const initialiseFloodZone2Layer = () => {
        if (targetMap.value && !floodZone2Layer.value) {
            floodZone2Layer.value = new FloodZone2Layer({
                targetMap: targetMap.value,
                onHoverTextChangeCallback: (textArray, event) => {
                    store.commit(MAP_FLOOD_ZONE_2_MUTATE_OVER_TEXT, {textArray, event})
                },
                eventLogger: {logEvent: logHeapEvent},
            }, settings.value)

            displayLayerFloodZones.value.push({
                ...DISPLAY_LAYERS.floodZones[0],
                selected: false,
                layerName: floodZone2Layer.value.layerName,
            })
            initialisedFloodLayers.value.push(floodZone2Layer.value)
        }
    }

    let floodZone3Layer = ref<FloodZone3Layer>(null)
    const initialiseFloodZone3Layer = () => {
        if (targetMap.value && !floodZone3Layer.value) {
            floodZone3Layer.value = new FloodZone3Layer({
                targetMap: targetMap.value,
                onHoverTextChangeCallback: (textArray, event) => {
                    store.commit(MAP_FLOOD_ZONE_3_MUTATE_OVER_TEXT, {textArray, event})
                },
                eventLogger: {logEvent: logHeapEvent},
            }, settings.value)

            displayLayerFloodZones.value.push({
                ...DISPLAY_LAYERS.floodZones[1],
                selected: false,
                layerName: floodZone3Layer.value.layerName,
            })
            initialisedFloodLayers.value.push(floodZone3Layer.value)
        }
    }

    let floodRisksSeaAndRiversLayer = ref<FloodRisksSeaAndRiversLayer>(null)
    const initialiseFloodRisksSeaAndRiversLayer = () => {
        if (targetMap.value && !floodRisksSeaAndRiversLayer.value) {
            floodRisksSeaAndRiversLayer.value = new FloodRisksSeaAndRiversLayer({
                targetMap: targetMap.value,
                onHoverTextChangeCallback: (textArray, event) => {
                    store.commit(MAP_FLOOD_RISKS_SEA_AND_RIVERS_MUTATE_OVER_TEXT, {textArray, event})
                },
                eventLogger: {logEvent: logHeapEvent},
            }, settings.value)

            displayLayerFloodZones.value.push({
                ...DISPLAY_LAYERS.floodZones[2],
                selected: false,
                layerName: floodRisksSeaAndRiversLayer.value.layerName,
            })
            initialisedFloodLayers.value.push(floodRisksSeaAndRiversLayer.value)
        }
    }

    let floodRisksSurfaceWaterLayer = ref<FloodRisksSurfaceWaterLayer>(null)
    const initialiseFloodRisksSurfaceWaterLayer = () => {
        if (targetMap.value && !floodRisksSurfaceWaterLayer.value) {
            floodRisksSurfaceWaterLayer.value = new FloodRisksSurfaceWaterLayer({
                targetMap: targetMap.value,
                onHoverTextChangeCallback: (textArray, event) => {
                    store.commit(MAP_FLOOD_RISKS_SURFACE_WATER_MUTATE_OVER_TEXT, {textArray, event})
                },
                eventLogger: {logEvent: logHeapEvent},
            }, settings.value)
            displayLayerFloodZones.value.push({
                ...DISPLAY_LAYERS.floodZones[3],
                selected: false,
                layerName: floodRisksSurfaceWaterLayer.value.layerName,
            })
            initialisedFloodLayers.value.push(floodRisksSurfaceWaterLayer.value)
        }
    }

    const showFloodRisk = computed({
        get: (): boolean => !isNullOrEmpty(selectedFloodRiskLayers.value),
        set: (): void => {
            if (isFloodZoneLayerIndeterminate.value || isNullOrEmpty(selectedFloodRiskLayers.value)) {
                displayLayerFloodZones.value.forEach((item: ISelectedDisplayLayer) => {
                    item.selected = true
                })
            } else {
                displayLayerFloodZones.value.forEach((item: ISelectedDisplayLayer) => {
                    item.selected = false
                })
            }
        },
    })

    const getFloodLayerFromLayerName = (layerName: string): any => {
        return initialisedFloodLayers.value.find(item => item.layerName === layerName)
    }

    const isFloodZoneLayerLoading = computed((): boolean => selectedFloodRiskLayers.value.some((layer: ISelectedDisplayLayer) => layer.isLoading))

    const selectedFloodRiskLayers = computed((): Array<ISelectedDisplayLayer> => {
        return displayLayerFloodZones.value.filter((item: ISelectedDisplayLayer) => item.selected)
    })

    const isFloodZoneLayerIndeterminate = computed(() => {
        return !isNullOrEmpty(selectedFloodRiskLayers.value) &&
            selectedFloodRiskLayers.value.length < displayLayerFloodZones.value.length
    })

    watch(selectedFloodRiskLayers, async (updatedLayers: Array<ISelectedDisplayLayer>) => {
        // Turn off the ones that aren't selected
        initialisedFloodLayers.value.forEach(layer => {
            if (!updatedLayers.find(i => i.layerName === layer.layerName)) {
                layer.setVisible(false)
            }
        })

        // Then turn the selected ones on
        for (const layer of updatedLayers) {
            const selected = getFloodLayerFromLayerName(layer.layerName)
            if  (selected?.getVisible() !== layer.selected) {
                layer.isLoading = true
                selected.setVisible(layer.selected)
                await waitForLayerToLoad(selected)
                layer.isLoading = false
            }
        }
    }, { deep: true })

    /**
     * Environmental Layers
     */
    const displayEnvironmentalLayers = ref<Array<ISelectedDisplayLayer>>([])
    const initialisedEnvironmentalLayers = ref<Array<any>>([])

    // Areas of outstanding natural beauty
    let aonbLayer = ref<AonbLayer>(null)
    const initialiseAonbLayer = () => {
        if (targetMap.value && !aonbLayer.value) {
            aonbLayer.value = new AonbLayer({
                targetMap: targetMap.value,
                onHoverTextChangeCallback: (everythingToDoWithThisNeedsRefactoring, event) => {
                    store.commit(MAP_AONB_MUTATE_OVER_TEXT, {
                        features: everythingToDoWithThisNeedsRefactoring,
                        event,
                    })
                },
                eventLogger: {logEvent: logHeapEvent},
            }, settings.value)

            displayEnvironmentalLayers.value.push({
                ...DISPLAY_LAYERS.environmental[0],
                selected: false,
                layerName: aonbLayer.value.layerName,
            })
            initialisedEnvironmentalLayers.value.push(aonbLayer.value)
        }
    }

    let commonLandLayer = ref<CommonLandLayer>(null)
    const initialiseCommonLandLayer = () => {
        if (targetMap.value && !commonLandLayer.value) {
            commonLandLayer.value = new CommonLandLayer({
                targetMap: targetMap.value,
                onHoverTextChangeCallback: (textArray, event) => {
                    store.commit(MAP_COMMON_LAND_MUTATE_OVER_TEXT, {textArray, event})
                },
                eventLogger: {logEvent: logHeapEvent},
            }, settings.value)

            displayEnvironmentalLayers.value.push({
                ...DISPLAY_LAYERS.environmental[1],
                selected: false,
                layerName: commonLandLayer.value.layerName,
            })
            initialisedEnvironmentalLayers.value.push(commonLandLayer.value)
        }
    }

    let ancientWoodlandLayer = ref<AncientWoodlandLayer>(null)
    const initialiseAncientWoodlandLayer = () => {
        if (targetMap.value && !ancientWoodlandLayer.value) {
            ancientWoodlandLayer.value = new AncientWoodlandLayer({
                targetMap: targetMap.value,
                onHoverTextChangeCallback: (textArray, event) => {
                    store.commit(MAP_ANCIENT_WOODLAND_MUTATE_OVER_TEXT, {textArray, event})
                },
                eventLogger: {logEvent: logHeapEvent},
            }, settings.value)

            displayEnvironmentalLayers.value.push({
                ...DISPLAY_LAYERS.environmental[2],
                selected: false,
                layerName: ancientWoodlandLayer.value.layerName,
            })
            initialisedEnvironmentalLayers.value.push(ancientWoodlandLayer.value)
        }
    }

    // Sites of special scientific interest
    let sssiLayer = ref<SssiLayer>(null)
    const initialiseSssiLayer = () => {
        if (targetMap.value && !sssiLayer.value) {
            sssiLayer.value = new SssiLayer({
                targetMap: targetMap.value,
                onHoverTextChangeCallback: (textArray, event) => {
                    store.commit(MAP_SSSI_MUTATE_OVER_TEXT, {textArray, event})
                },
                eventLogger: {logEvent: logHeapEvent},
            }, settings.value)

            displayEnvironmentalLayers.value.push({
                ...DISPLAY_LAYERS.environmental[3],
                selected: false,
                layerName: sssiLayer.value.layerName,
            })
            initialisedEnvironmentalLayers.value.push(sssiLayer.value)
        }
    }

    // Sites of special scientific interest
    let radonLayer = ref<RadonLayer>(null)
    const initialiseRadonLayer = () => {
        if (targetMap.value && !radonLayer.value) {
            radonLayer.value = new RadonLayer({
                targetMap: targetMap.value,
                onHoverTextChangeCallback: (textArray, event) => {
                    console.info('text')
                },
                eventLogger: {logEvent: logHeapEvent},
            }, settings.value)

            displayEnvironmentalLayers.value.push({
                ...DISPLAY_LAYERS.environmental[4],
                selected: false,
                layerName: radonLayer.value.layerName,
            })
            initialisedEnvironmentalLayers.value.push(radonLayer.value)
        }
    }

    const router = useRouter()
    const scotlandStore = useScotlandStore()
    const isScotlandLayerEnabled = computed(() => store.state.config.featureFlags?.scottishSpike)
    const showScotlandLayer = computed({
        get: (): boolean => store.state.map.dataLayers.scotland.showScotlandLayer,
        set: (value: boolean) => {
            store.commit('_setShowScotland', value)
        },
    })
    const { selectedTitleNumbers } = storeToRefs(scotlandStore)
    let scotlandLayer = ref<ScotlandInspireLayer>(null)
    const extentInScotland = ref<boolean>(false)
    const extentCheckIntervalTime = 3000
    let extentCheckInterval: any = undefined
    watch(() => selectedTitleNumbers.value.length, async () => {
        if (!isNullOrEmpty(selectedTitleNumbers.value)) {
            if (selectedTitleNumbers.value.length === 1) {
                await router.push({
                    name: Route.MatterMapTitle,
                    params: {
                        titleNumber: selectedTitleNumbers.value[0],
                        matterId: store.state.matter.currentMatter.id,
                    },
                })
            } else {
                const filteredTitles = selectedTitleNumbers.value
                    .map(title => {
                        return {
                            titleNumber: title,
                            tenure: 'Other',
                            addresses: null,
                            selected: false,
                        }
                    })
                store.commit(TITLE_MUTATE_SET_TITLES, filteredTitles)

                // NOTE: Don't await the following to not block the UI
                store.dispatch(TITLE_FETCH_TITLE_ADDRESSES, filteredTitles)
                store.dispatch(TITLE_GET_INFORMATION_BY_TITLE_NUMBERS, filteredTitles)

                await store.dispatch(TITLE_SHOW_MULTI_TITLE_SELECTION_PANEL)
            }
        }
    })
    const checkExtentInScotland = () => {
        if (targetMap.value && targetMap.value.getView().getProjection().getCode() === 'EPSG:27700') {
            const extent = targetMap.value.getView().calculateExtent(targetMap.value.getSize())
            extentInScotland.value = isExtentInScotland(extent)
        }
    }
    const initialiseScotlandLayer = () => {
        if (targetMap.value && !scotlandLayer.value) {
            scotlandLayer.value = new ScotlandInspireLayer({
                targetMap: targetMap.value,
                async onClickFn(clickedObject) {
                    const coordinate = clickedObject.clickCoordinate
                    const features = clickedObject.clickFeatures
                    if (coordinate.length == 2) {
                        await scotlandStore.matchClickedCoordinate(coordinate)
                    } else if (!isNullOrEmpty(features)) {
                        const inspireIds = features.map(f => (f.getProperties() as ScotlandInspireFeatureType).label)
                        await scotlandStore.matchInspireIds(inspireIds)
                    }
                },
                eventLogger: {logEvent: logHeapEvent},
            }, settings.value)
        }
    }
    watch(() => showScotlandLayer.value, async (value: boolean): Promise<void> => {
        await scotlandLayer.value?.setVisible(value, targetMap.value)
    })

    const getEnvironmentLayerFromLayerName = (layerName: string): any => {
        return initialisedEnvironmentalLayers.value.find(item => item.layerName === layerName)
    }

    const isEnvironmentalLayerLoading = computed(() => selectedEnvironmentalLayers.value.some((layer: ISelectedDisplayLayer) => layer.isLoading))

    const selectedEnvironmentalLayers = computed((): Array<ISelectedDisplayLayer> => {
        return displayEnvironmentalLayers.value.filter((item: ISelectedDisplayLayer) => item.selected)
    })

    const isEnvironmentalLayerIndeterminate = computed((): boolean => {
        return !isNullOrEmpty(selectedEnvironmentalLayers.value) &&
            selectedEnvironmentalLayers.value.length < displayEnvironmentalLayers.value.length
    })

    const showEnvironmentalLayers = computed({
        get: (): boolean => !isNullOrEmpty(selectedEnvironmentalLayers.value),
        set: (): void => {
            if (isEnvironmentalLayerIndeterminate.value || isNullOrEmpty(selectedEnvironmentalLayers.value)) {
                displayEnvironmentalLayers.value.forEach((item: ISelectedDisplayLayer) => {
                    item.selected = true
                })
            } else {
                displayEnvironmentalLayers.value.forEach((item: ISelectedDisplayLayer) => {
                    item.selected = false
                })
            }
        },
    })

    watch(selectedEnvironmentalLayers, async (updatedLayers: Array<ISelectedDisplayLayer>) => {
        // Turn off the ones that aren't selected
        initialisedEnvironmentalLayers.value.forEach(layer => {
            if (!updatedLayers.find(i => i.layerName === layer.layerName)) {
                layer.setVisible(false)
            }
        })

        // Then turn the selected ones on
        for (const layer of updatedLayers) {
            const selected = getEnvironmentLayerFromLayerName(layer.layerName)
            if  (selected?.getVisible() !== layer.selected) {
                layer.isLoading = true
                selected.setVisible(layer.selected)
                await waitForLayerToLoad(selected)
                layer.isLoading = false
            }
        }
    }, { deep: true })

    watch(targetMap, () => {
        initialiseConservationAreas()
        initialiseListedBuildings()
        initialiseScheduledMonumentsLayer()
        initialiseFloodZone2Layer()
        initialiseFloodZone3Layer()
        initialiseFloodRisksSeaAndRiversLayer()
        initialiseFloodRisksSurfaceWaterLayer()
        initialiseAonbLayer()
        initialiseCommonLandLayer()
        initialiseAncientWoodlandLayer()
        initialiseSssiLayer()
        initialiseRadonLayer()
        initialiseUnregisteredLandLayer()
        initialiseScotlandLayer()
    })

    onMounted(() => {
        initialiseConservationAreas()
        initialiseListedBuildings()
        initialiseScheduledMonumentsLayer()
        initialiseFloodZone2Layer()
        initialiseFloodZone3Layer()
        initialiseFloodRisksSeaAndRiversLayer()
        initialiseFloodRisksSurfaceWaterLayer()
        initialiseAonbLayer()
        initialiseCommonLandLayer()
        initialiseAncientWoodlandLayer()
        initialiseSssiLayer()
        initialiseRadonLayer()
        initialiseUnregisteredLandLayer()
        initialiseScotlandLayer()
    })
    onActivated(() => {
        extentCheckInterval = setInterval(checkExtentInScotland, extentCheckIntervalTime)
    })
    onDeactivated(() => {
        if (extentCheckInterval) {
            clearInterval(extentCheckInterval)
        }
    })
</script>

<style lang="scss">
    @import './map-layers-panel';
</style>

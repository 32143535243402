<template>
    <ow-full-width-layout :inline-step-flow="true"
                          data-test="matter-settings"
                          header-icon="$matter-settings"
                          header-title="Matter Settings"
                          is-fixed-width
                          page-name="monitoring-preferences">
        <template #navigation>
            <settings-sub-navigation :current-matter-id="currentMatterId" />
        </template>

        <div class="monitoring-preferences__instructions">
            <p v-t="'assetMonitoring.preferences.instructions'"
               class="body-regular" />

            <router-link :to="{
                             name: Route.AssetMonitoringTitleAlerts,
                             params: { matterId: currentMatterId?.toString() }
                         }"
                         data-test="asset-monitoring-button-to-alerts"
                         data-track="Asset Monitoring: Button to navigate to alerts">
                <ow-button is-secondary>
                    {{ t('assetMonitoring.actions.viewAlerts') }}
                </ow-button>
            </router-link>
        </div>

        <section class="monitoring-preferences__content">
            <preference-presets-card :settings="settings"
                                     class="monitoring-preferences__content--row"
                                     data-track="Asset Monitoring: Orbital recommended preference card"
                                     data-test="orbital-recommended-preference-card"
                                     @update="updateSettings" />

            <title-details-card :is-loading="isUpdating"
                                :settings="settings"
                                class="monitoring-preferences__content--row"
                                @update="updateSettings" />
        </section>
    </ow-full-width-layout>
</template>

<script lang="ts" setup>
    import {
        computed,
        onMounted,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRoute } from 'vue-router'

    import { INotificationSettings } from '@/api/notifications.api'
    import OwFullWidthLayout from '@/components/core/layout/full-width-white-header.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import BaseMonitoringPreferenceCard from '@/components/matter/settings/monitoring-preferences/base-monitoring-preference-card.vue'
    import PreferencePresetsCard from '@/components/matter/settings/monitoring-preferences/preference-presets-card.vue'
    import TitleDetailsCard from '@/components/matter/settings/monitoring-preferences/title-details-card.vue'
    import SettingsSubNavigation from '@/components/matter/settings/settings-sub-navigation.vue'
    import { Route } from '@/enums/route.enum'
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'

    const { t } = useI18n()
    const route = useRoute()
    const store = useAssetMonitoringStore()
    const currentMatterId = computed<number>(() => Number(route.params.matterId))
    const settings = computed<INotificationSettings>(() => store.notificationSettings)
    const isUpdating = ref(false)

    onMounted(() => {
        if (!store.areNotificationSettingsLoaded) {
            store.getNotificationSettings(currentMatterId.value)
        }
    })

    const updateSettings = async (request: INotificationSettings) => {
        isUpdating.value = true
        let data = {
            ...request,
        }
        try {
            await store.updateNotificationSettings(currentMatterId.value, data)
            store.notificationSettings = data
        } catch (error) {
            console.error('Error updating notification settings', error)
        } finally {
            isUpdating.value = false
        }
    }
</script>

<style lang="scss" scoped>
    @import './matters-settings-monitoring-preferences';
</style>

<template>
    <div class="title-panel-register">
        <div class="title-panel-register__content">
            <order-documents-card :current-matter-id="currentMatterId"
                                  :is-ordering-allowed="isOrderingAllowed"
                                  :selected-title="selectedTitle"
                                  :selected-title-number="selectedTitleNumber"
                                  :official-copies="officialCopies"
                                  :user-has-vts-charge="userHasVTSCharge"
                                  :is-loading="isLoading"
                                  hide-title-plan
                                  no-padding />

            <div v-if="hasBackdatedInfo"
                 class="title-panel-register__container--backdated">
                <div>
                    <ow-icon-text :is-italic-text="true"
                                  :is-single-line="false"
                                  :text="backdatedText"
                                  icon="$info" />
                </div>
            </div>

            <ow-register-card v-model:show-document="showDocument"
                              :hide-flags="hideFlags"
                              :loading="isLoading"
                              @title-number-selected="goToTitle"
                              @scroll-to-entry="filterTitlesText = $event" />
        </div>
        <document-tab v-if="showDocument"
                      :matter-id="currentMatterId"
                      :title-number="selectedTitleNumber"
                      :active-tab="activeDocumentTab"
                      :in-focus="false"
                      :pdf-page="pdfPage"
                      :register-metadata="registerMetadata"
                      :related-document-metadata="null"
                      :search-index="documentSearchResultIndex"
                      :search-text="filterTitlesText"
                      :title-plan-metadata="titlePlanMetadata"
                      class="title-panel-register__document"
                      @page-changed="pageChangedHandler"
                      @search-count-changed="documentSearchResultCount = $event"
                      @tab-changed="tabChangeHandler" />
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import {
        useStore,
    } from 'vuex'

    import DocumentsApi from "@/api/documents.api"
    import OwIconText from '@/components/core/ow-icon-text.vue'
    import DocumentTab from "@/components/review-assistant/document-tab.vue"
    import OrderDocumentsCard from '@/components/title-panel/tabs/summary-tab/order-documents-card/order-documents-card.vue'
    import OwRegisterCard from '@/components/title-panel/v2/register/register-card.vue'
    import {useSelectedTitle} from "@/composables/use-selected-title"
    import {HighLevelDocumentType} from "@/consts/document-high-level-type"
    import {ReviewAssistantDocumentTab} from "@/interfaces/review-assistant.interface"

    withDefaults(defineProps<{
        isLoading: boolean,
        isOrderingAllowed: boolean,
        hideFlags: boolean,
    }>(), {
        isOrderingAllowed: true,
    })

    const emit = defineEmits([
        'title-number-selected',
    ])

    const store = useStore()
    const { t } = useI18n()
    const {
        selectedTitle,
        selectedTitleNumber,
    } = useSelectedTitle()

    const currentMatterId = computed(() => store.state.matter.currentMatter.id)
    const titleSummaryCharge = computed(() => store.state.user.titleSummaryCharge)
    const officialCopies = computed(() => store.state.title.selectedTitle?.officialCopiesAvailability?.results)
    const userHasVTSCharge = computed(() => titleSummaryCharge.value !== null)
    const hasBackdatedInfo = computed(() => selectedTitle?.value?.record?.titleMetadata?.isBackOrderCopy)
    const backdatedText = computed(() => t('backdatedInfoFlag', { date: selectedTitle?.value.record?.bgProprietorshipData?.dateDataOC }))
    const registerAvailability = computed(() => store.state.title?.selectedTitle?.officialCopiesAvailability?.results?.titleRegister)
    const titlePlanAvailability = computed(() => store.state.title?.selectedTitle?.officialCopiesAvailability?.results?.titlePlan)

    const showDocument = ref<boolean>(false)
    const activeDocumentTab = ref<number>(ReviewAssistantDocumentTab.TitleRegister)
    const pdfPage = ref<number>(1)
    const documentSearchResultIndex = ref<number>(0)
    const filterTitlesText = ref<string>(null)
    const documentSearchResultCount = ref<number>(0)
    const registerMetadata = ref<any>(null)
    const titlePlanMetadata = ref<any>(null)

    watch(() => registerAvailability.value, (newVal) => {
        if (newVal) {
            registerMetadata.value = {
                documentType: 'Register',
                documentDownloadUrl: newVal.downloadUrl,
                documentId: newVal.documentId,
            }
        }
    }, { immediate: true, deep: true })

    watch(() => titlePlanAvailability.value, async (newVal) => {
        if (!newVal) {
            return
        }

        if (!newVal.documentId) {
            titlePlanMetadata.value = newVal
        } else {
            titlePlanMetadata.value = await DocumentsApi.getDocumentMetadataByTypeAndId(
                HighLevelDocumentType.TitlePlan,
                newVal.documentId,
            )
        }
    }, { immediate: true, deep: true })

    const pageChangedHandler = (newPage: number): void => {
        pdfPage.value = newPage
        filterTitlesText.value = null
    }

    const tabChangeHandler = async (newTab: ReviewAssistantDocumentTab): Promise<void> => {
        activeDocumentTab.value = newTab
    }

    const goToTitle = (titleNumber: string) => {
        emit('title-number-selected', titleNumber)
    }
</script>

<style lang="scss">
    @import './register';
</style>

import { computed } from "vue"

import useLicenceController, { FeatureId } from "@/composables/use-licence-controller"
import { checkFlag } from "@/feature-flags"

const useUser = () => {
    const { userHasAccess, licencedFeatures, accessTokenData, active } = useLicenceController({
        debug: import.meta.env.DEV,
    })

    const hasAccessToPlanning = async (): Promise<boolean> => {
        return await userHasAccess(FeatureId.Planning)
    }

    const hasAccessToFindNearby = async (): Promise<boolean> => {
        return await userHasAccess(FeatureId.Nearby)
    }

    const hasAccessToAlerts = async (): Promise<boolean> => {
        return await userHasAccess(FeatureId.Alerts)  || checkFlag('asset-monitoring', false)
    }

    const hasAccessToShapefileExport = async (): Promise<boolean> => {
        return await userHasAccess(FeatureId.ShapefileExport) || checkFlag('title-boundary-export', false)
    }

    const isPremiumUser = computed(() => {
        return accessTokenData.value?.ow_licence_type.toLowerCase() === "premium"
    })

    const isStandardUser = computed(() => {
        return accessTokenData.value?.ow_licence_type.toLowerCase() === "standard"
    })

    const isLiteUser = computed(() => {
        return accessTokenData.value?.ow_licence_type.toLowerCase() === "lite"
    })

    const isDisabledUser = computed(() => {
        return accessTokenData.value?.ow_licence_type.toLowerCase() === "none"
    })

    const isLicencingActive = computed(() => {
        return active.value
    })

    const hasAccessToFeature = async (featureId: FeatureId): Promise<boolean> => {
        return await userHasAccess(featureId)
    }

    const getLicenceByFeatureId = (featureId: FeatureId) => {
        return licencedFeatures[featureId]?.licenceType ?? null
    }

    return {
        isLicencingActive,
        isDisabledUser,
        isLiteUser,
        isPremiumUser,
        isStandardUser,

        hasAccessToPlanning,
        hasAccessToFindNearby,
        hasAccessToAlerts,
        hasAccessToShapefileExport,

        hasAccessToFeature,
        getLicenceByFeatureId,
    }
}

export default useUser

// @/stores/asset-monitoring/getters.ts

import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
import {
    IAssetMonitoringState,
    useAssetMonitoringStore,
} from '@/stores/asset-monitoring/index'
import store from '@/store'
import { IGetNotificationResponseItem } from '@/api/notifications.api'
import {
    convertToNotificationSubType,
    convertToNotificationType,
} from '@/stores/asset-monitoring/utils'
import { isNullOrEmpty } from '@/utils/array-utils'

export default {
    userNotifications(state: IAssetMonitoringState): Array<IAssetMonitoringNotification> {
        return [...state.notifications?.values()].map((notification: IGetNotificationResponseItem) => {
            return {
                notificationId: notification.notificationId,
                userNotificationId: notification.userNotificationId,
                message: notification.message,
                createdOn: new Date(notification.createdOn),
                isRead: notification.isRead,
                titleNumber: notification.titleNumber,
                organisationId: notification.organisationId,
                notificationType: convertToNotificationType(notification.type),
                notificationDetails: notification.notificationDetails?.map(x => ({
                    difference: x.difference,
                    notificationSubType: convertToNotificationSubType(x.notificationSubType),
                })),
            } as IAssetMonitoringNotification
        })
    },

    /**
     * Checks if the given state has any unread notifications.
     *
     * @param {IAssetMonitoringState} state - The state object containing notifications.
     * @return {boolean} - True if there are unread notifications, false otherwise.
     */
    hasUnreadNotifications(state: IAssetMonitoringState): boolean {
        return [...state.notifications?.values()].some(x => !x.isRead)
    },

    /**
     * Retrieves a notification by its title number.
     *
     * @returns {Function} Function that takes a title number as input and returns the corresponding notification.
     */
    getNotificationByTitleNumber(): Function {
        // To use a getter inside a getter we need a reference to the store again
        const assetMonitoringStore = useAssetMonitoringStore()
        return (titleNumber: string) => assetMonitoringStore.userNotifications.find(notification => notification.titleNumber === titleNumber)
    },
}

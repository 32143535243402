export enum LayerNames {
    BlueskyAerial = 'bluesky-aerial',
    AzureMapsAerial = 'azure-maps-aerial',
    OrdnanceSurvey = 'os',
    NPS = 'nps',
    MatterTitles = 'matter-titles',
    TitleNumbers = 'title-numbers',
    HighwaysMaintenance = 'highways-maintenance',
    PublicRightsOfWay = 'public-rights-of-way',
    ListedBuildings = 'listed-buildings',
    ConservationAreas = 'conservation-areas',
    ScheduledMonuments = 'scheduled-monuments',
    FloodZone2 = 'flood-zone-2',
    FloodZone3 = 'flood-zone-3',
    FloodRiskSeaAndRivers = 'flood-risk-sea-and-rivers',
    FloodRiskSurfaceWater = 'flood-risk-surface-water',
    AONB = 'areas-of-natural-beauty',
    CommonLand = 'common-land',
    AncientWoodland = 'ancient-woodland',
    SSSI = 'sssi',
    TitleSelectionLayer = 'title-selection-layer',
    Sketches = 'sketches',
    Overlays = 'overlays',
    UnregisteredLand = 'unregistered-land',
    SearchesLayer = 'searches-layer',
    BoundaryLayer = 'boundary-layer',
    RadonLayer = 'radon-layer',
    ScotlandInspireLayer = 'scotland-inspire-layer'
}

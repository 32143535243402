<template>
    <div class="register-card-actions d-flex justify-space-between w-100 align-center gc-3">
        <div class="d-flex align-center gc-2 flex-grow-1">
            <v-icon class="caption-regular">
                $search
            </v-icon>
            <ow-text-field v-model="searchText"
                           class="register-card__actions-search flex-grow-1"
                           clearable
                           borderless
                           data-test-id="register-card-search"
                           data-track-id="TITLE-DETAILS-PANEL - search register entries"
                           placeholder="Keyword search"
                           @click:clear="$emit('search-clear')" />
        </div>
        <div class="d-flex align-center caption-regular gc-1">
            <label for="titlePanelRegisterHighlightDates">Highlight dates &amp; times</label>
            <ow-checkbox id="titlePanelRegisterHighlightDates"
                         v-model="highlightDates"
                         class="flex-grow-0"
                         data-test="checkbox-highight-dates"
                         data-track="TITLE-DETAILS-PANEL - highlight dates in register"
                         hide-details />
        </div>
        <template v-if="!hideFlags">
            <ow-button-menu v-model="showButtonMenu"
                            button-text="Flags"
                            full-height
                            :disabled="entryTypes.length === 0"
                            button-data-test-attribute="btn-select-flags"
                            :close-on-content-click="false">
                <template #links>
                    <ul class="register-card__flag-links flex-column d-flex">
                        <li v-for="(item, index) in entryTypes"
                            :key="index"
                            :data-test="`filter-flags-entry-${index}`"
                            data-track="TITLE-DETAILS-PANEL - Register flag"
                            @click="$emit('toggle-entry-type', item)">
                            <div class="d-flex align-center justify-space-between w-100 pa-2"
                                 :class="{'selected': entryTypeIsSelected(item)}">
                                <span class="w-100"
                                      :class="{'caption-highlight': entryTypeIsSelected(item),
                                               'caption-regular': !entryTypeIsSelected(item)}">
                                    {{ item.label }}
                                </span>
                                <span class="caption-highlight px-2 rounded"
                                      :style="{ background: item.colour}">{{ item.codes.length }}</span>
                            </div>
                        </li>
                    </ul>
                </template>
                <template #iconSuffix>
                    <v-icon v-if="selectedEntryTypes.length === 0">
                        $filter
                    </v-icon>
                    <v-icon v-if="selectedEntryTypes.length > 0"
                            color="primary">
                        $filter
                    </v-icon>
                </template>
            </ow-button-menu>
        </template>
    </div>
</template>

<script setup lang="ts">
    import {              computed,
                          ref } from 'vue'
    import { useStore } from 'vuex'

    import OwButtonMenu from '@/components/core/ow-button-menu.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwTextField from '@/components/core/ow-textfield.vue'
    import { IRegisterLinkedIndicator } from '@/interfaces/documents/entry-type.interface'

    const store = useStore()

    const emit = defineEmits<{
        (e: 'toggle-entry-type', value: IRegisterLinkedIndicator),
        (e: 'search-clear'): void,
    }>()

    const searchText = defineModel<string>('searchText')
    const highlightDates = defineModel<boolean>('highlightDates')
    const showButtonMenu = defineModel<boolean>('showButtonMenu')

    const props = defineProps<{
        selectedEntryTypes: IRegisterLinkedIndicator[]
        entryTypes: IRegisterLinkedIndicator[]
        hideFlags: boolean
    }>()

    const entryTypeIsSelected = (item: IRegisterLinkedIndicator) => {
        return props.selectedEntryTypes
            .find((t) => t.label === item.label) !== undefined
    }
</script>

<style scoped>

</style>

<template>
    <base-row-item :model="baseRowModel"
                   v-bind="props"
                   @view-title="viewTitleClickHandler">
        <template #actions>
            <ow-button is-secondary
                       is-small
                       @click="viewTitleClickHandler">
                {{ t('action.viewTitle') }}
            </ow-button>
        </template>
        <template #extra-info>
            <ownership-diff-table :items="items" />
        </template>
    </base-row-item>
</template>

<script setup lang="ts">

    import {
        computed,
        ref,
        watch,
    } from "vue"
    import { useI18n } from "vue-i18n"
    import { useRouter } from "vue-router"

    import {IAssetMonitoringNotification} from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import {AssetMonitoringDataSource,
            BaseRowItemModel} from "@/components/asset-monitoring/base-row-item-model"
    import {
        CompaniesHouseDatapoint,
    } from "@/components/asset-monitoring/datapoint-interfaces"
    import {Difference} from "@/components/asset-monitoring/difference-generic"
    import BaseRowItem from "@/components/asset-monitoring/grid/row-items/base-row-item.vue"
    import OwnershipDiffTable from "@/components/asset-monitoring/grid/sub-type-items/ownership-diff-table/ownership-diff-table.vue"
    import {IOwnershipDiffTableData} from "@/components/asset-monitoring/grid/sub-type-items/ownership-diff-table/ownership-diff-table-models"
    import OwButton from "@/components/core/ow-button-ds.vue"
    import {Route} from "@/enums/route.enum"

    const { t } = useI18n()
    const router = useRouter()

    const props = defineProps<{
        item: IAssetMonitoringNotification,
        containerSelector: string,
        matterId: number,
    }>()

    const viewTitleClickHandler = async () => {
        await router.push({
            name: Route.MatterMapTitle,
            params: {
                titleNumber: props.item.titleNumber,
                matterId: props.matterId,
            },
        })
    }

    const baseRowModel = computed((): BaseRowItemModel => {
        return {
            title: t('assetMonitoring.subType.companiesHouseUpdated.title'),
            descriptionPath: 'assetMonitoring.subType.companiesHouseUpdated.description',
            dataSource: AssetMonitoringDataSource.companiesHouse,
            titleNumber: props.item.titleNumber,
        }
    })

    const diff = computed<Difference<CompaniesHouseDatapoint>>(() => {
        return JSON.parse(props.item?.notificationDetails[0]?.difference)
    })

    const items = ref<Difference<IOwnershipDiffTableData>>(null)

    watch(diff, (newVal: Difference<CompaniesHouseDatapoint>) => {
        const added = newVal.added.map((item) => ({
            companyName: item.companyName,
            companyNumber: item.companyRegistrationNumber,
            displayAddress: item.displayAddress,
            companyStatus: item.companyStatus,
            displayNatureOfBusiness: item.displayNatureOfBusiness,
        }))
        const removed = newVal.removed.map((item) => ({
            companyName: item.companyName,
            companyNumber: item.companyRegistrationNumber,
            displayAddress: item.displayAddress,
            companyStatus: item.companyStatus,
            displayNatureOfBusiness: item.displayNatureOfBusiness,
        }))
        const updated = newVal.updated.map((item) => ({
            before: {
                companyName: item?.before?.companyName,
                companyNumber: item?.before?.companyRegistrationNumber,
                displayAddress: item?.before?.displayAddress,
                companyStatus: item?.before?.companyStatus,
                displayNatureOfBusiness: item?.before?.displayNatureOfBusiness,
            },
            after: {
                companyName: item?.after?.companyName,
                companyNumber: item?.after?.companyRegistrationNumber,
                displayAddress: item?.after?.displayAddress,
                companyStatus: item?.after?.companyStatus,
                displayNatureOfBusiness: item?.after?.displayNatureOfBusiness,
            },
        }))

        items.value = {
            added,
            removed,
            updated,
        }

    }, { immediate: true })

</script>

<style scoped lang="scss">

</style>

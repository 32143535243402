<template>
    <div class="ow-clipboard"
         :data-track="dataTrack"
         @mouseleave="reset">
        <div class="ow-clipboard__content"
             :data-test="dataTest">
            <slot />
        </div>
        <div class="ow-clipboard__actions">
            <div v-if="scrollable"
                 class="ow-clipboard__actions--scroll accents-italic">
                <div data-test="ow-clipboard-scroll-button"
                     @click="handleScroll">
                    <v-icon class="visible">
                        $arrow-target
                    </v-icon>
                </div>
                <ow-tooltip activator="parent"
                            :position="OwTooltipPosition.Top">
                    <span>{{ $t('action.scrollToEntry') }}</span>
                </ow-tooltip>
            </div>
            <div class="ow-clipboard__actions--copy accents-italic">
                <span :class="{ 'visible': copied }">{{ $t('message.copied') }}</span>
                <div data-test="ow-clipboard-copy-button"
                     @click="handleClick">
                    <v-icon :class="{ 'visible': !copied }">
                        $copy
                    </v-icon>
                </div>
                <ow-tooltip activator="parent"
                            :position="OwTooltipPosition.Top">
                    <span>{{ $t('action.copy') }}</span>
                </ow-tooltip>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'

    const EVENTS = {
        SCROLLED: 'scrolled',
        CLICKED: 'clicked',
    }

    export default {
        name: 'OwClipboard',

        components: {
            OwTooltip,
        },

        props: {
            text: {
                type: String,
                required: false,
            },
            scrollable: {
                type: Boolean,
                required: false,
            },
            dataTrack: {
                type: String,
                required: false,
            },
            dataTest: {
                type: String,
                required: false,
                default: 'ow-clipboard-content',
            },
        },

        emits: Object.values(EVENTS),

        data() {
            return {
                OwTooltipPosition,
                copied: false,
            }
        },

        computed: {
            content: {
                get() {
                    if (this.text) {
                        return this.text
                    }

                    const defaultSlot = this.$slots.default
                    if (!defaultSlot) {
                        return ''
                    }
                    const vNodes = defaultSlot()
                    return vNodes[0].text
                },
                set() {
                    throw new Error('OwClipboard: content is read-only')
                },
            },
        },

        methods: {
            reset() {
                this.copied = false
            },

            handleScroll() {
                this.$emit(EVENTS.SCROLLED)
            },

            handleClick() {
                this.copied = true
                navigator.clipboard.writeText(this.content)

                this.$emit(EVENTS.CLICKED)
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-clipboard.scss';
</style>

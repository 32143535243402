import GeoJSON from 'ol/format/GeoJSON'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import olMap from 'ol/Map'
import { Extent } from 'ol/extent'
import {
    Layer,
} from 'ol/layer'
import Overlay from 'ol/Overlay'
import { Style } from 'ol/style'
import { IBaseLayerConfiguration } from '@/interfaces/map-layers-panel.interface'
import Feature from 'ol/Feature'
import VectorTileLayer from 'ol/layer/VectorTile'
import VectorTileSource from 'ol/source/VectorTile'
export interface IMapState {
    loaded: boolean
    isMapDisabled: boolean
    preventTitleBoundaryDeselection: boolean
    map: olMap
    initialTitle: string // zoom to title on load

    snapshot: {
        takingSnapshot: boolean
        itemsHiddenForSnapshot: Array<any> // TODO: can be removed?
    }

    launchSearchResult: {
        center: Array<number>,
        zoom: number,
        extent: Extent,
        title: any,
    }
    search: {
        resultsLayer: Layer
    }
    events: {
        mapIsMoving: boolean
        viewStoppedMovingAt: Array<number>
    }
    mapOverlay: Overlay
    nps: {
        record: {
            titleNumber: string
        }
        defaultMaxResolution: number
        maxResolution: number
        overTimer: any
        showNPSTitleNumbers: boolean
        showNPSFreeholds: boolean
        showNPSLeaseholds: boolean
        emphasiseNPSCoverage: boolean
        layer: VectorTileLayer
        highlightLayer: VectorTileLayer
        titleNumbersLayer: Layer
        overIds: Array<string>
        overTitles: {
            freehold: Array<string>
            leasehold: Array<string>
            other: Array<string>
        }
        overTitleNumbers: Array<string>
        clickedTitleNumbers: Array<string>
        loadingTiles: number
        appearance: {
            freehold: string
            leasehold: string
            other: string
            emphasiseUnregistered: string
            cautionAgainstFirstRegistration: string
            /** When a style is required for rendering an NPS feature, rather than creating a new object
             * each time, re-use one with the same properties if it has been created previously. */
            styleCache: Map<string, Style>
        },
    },

    baseLayers: Array<IBaseLayerConfiguration>
    baseLayerOpacity: number
    mastermapBaseLayerId: string
    overlayBaseLayer: boolean
    currentBaseLayer: IBaseLayerConfiguration
    currentZoomLevel: number
    currentMapCentre: Array<number>
    mouseIsDown: boolean
    mapIsMoving: boolean
    geoJSONFormat: GeoJSON
    mapElement: HTMLElement
    markerLayers: Array<Layer>

    dataLayers: {
        highways: {
            publicRightsOfWayLayer: VectorTileLayer
            highwayMaintenanceLayer: VectorTileLayer
            highwayDedicationLayer: VectorTileLayer
            defaultMaxResolution: number
            showPublicRightsOfWayLayer: boolean
            showHighwayMaintenance: boolean
            highwayMaintenanceLayerTileSource: VectorTileSource
            highwayDedicationLayerTileSource: VectorTileSource
            overPublicRightsOfWayFeatures: Array<Feature>
            overMaintenanceFeatures: Array<Feature>
        },
        conservationAreas: {
            event: any
            overConservationAreasText: Array<string>
        },
        listedBuildings: {
            event: any
            overListedBuildingsText: Array<string>
        },
        scheduledMonuments: {
            event: any
            overScheduledMonumentsText: Array<string>
        },
        dno: {
            event: any
            overDNOText: Array<string>
        },
        floodZone2: {
            event: any
            overFloodZone2Text: Array<string>
        },
        floodZone3: {
            event: any
            overFloodZone3Text: Array<string>
        }
        floodRisksSeaAndRivers: {
            event: any
            overFloodRisksSeaAndRiversText: Array<string>
        }
        floodRisksSurfaceWater: {
            event: any
            overFloodRisksSurfaceWaterText: Array<string>
        }
        aonb: {
            event: any
            overAonbFeatures: {
                text: Array<string>
                hoverCaption: string
            },
        },
        commonLand: {
            event: any
            overCommonLandText: Array<string>
        },
        ancientWoodland: {
            event: any
            overAncientWoodlandText: Array<string>
        },
        sssi: {
            event: any
            overSssiText: Array<string>
        },
        unregisteredLand: {
            unregisteredLandLayer: VectorTileLayer,
            showUnregisteredLandlayer: boolean,
            event: any
            overUnregisteredLand: Array<string>
        },
        radon: {
            showRadonLayer: boolean,
            event: any
        }
        scotland: {
            showScotlandLayer: boolean,
            event: any
        }
    },
}

const state: IMapState = {
    loaded: false,
    isMapDisabled: false,
    map: null,
    initialTitle: null, // zoom to title on load

    snapshot: {
        takingSnapshot: false,
        itemsHiddenForSnapshot: [],
    },

    launchSearchResult: {
        center: null,
        zoom: null,
        extent: null,
        title: null,
    },
    search: {
        resultsLayer: null,
    },
    events: {
        mapIsMoving: false,
        viewStoppedMovingAt: null,
    },
    mapOverlay: null,
    nps: {
        record: {
            titleNumber: null,
        },
        defaultMaxResolution: 3,
        maxResolution: 3,
        overTimer: null,
        showNPSTitleNumbers: false,
        showNPSFreeholds: false,
        showNPSLeaseholds: false,
        emphasiseNPSCoverage: false,
        layer: null,
        highlightLayer: null,
        titleNumbersLayer: null,
        overIds: [],
        overTitles: {
            freehold: [],
            leasehold: [],
            other: [],
        },
        overTitleNumbers: [],
        clickedTitleNumbers: [],
        loadingTiles: 0,
        appearance: {
            freehold: '255, 126, 22',
            leasehold: '0, 219, 230',
            other: '90, 90, 90',
            emphasiseUnregistered: '0, 0, 0',
            cautionAgainstFirstRegistration: '255, 0, 0',
            /** When a style is required for rendering an NPS feature, rather than creating a new object
              * each time, re-use one with the same properties if it has been created previously. */
            styleCache: new Map(),
        },
    },

    baseLayers: [],
    baseLayerOpacity: 1,
    mastermapBaseLayerId: 'osMaps',
    overlayBaseLayer: false,
    currentBaseLayer: null,
    currentZoomLevel: null,
    currentMapCentre: null,
    mouseIsDown: false,
    mapIsMoving: false,
    geoJSONFormat: new GeoJSON(),
    mapElement: null,
    markerLayers: [],

    dataLayers: {
        highways: {
            highwayDedicationLayer: null,
            highwayMaintenanceLayer: null,
            publicRightsOfWayLayer: null,
            defaultMaxResolution: 6,
            showPublicRightsOfWayLayer: false,
            showHighwayMaintenance: false,
            highwayMaintenanceLayerTileSource: null,
            highwayDedicationLayerTileSource: null,
            overPublicRightsOfWayFeatures: [],
            overMaintenanceFeatures: [],
        },
        conservationAreas: {
            event: null,
            overConservationAreasText: [],
        },
        listedBuildings: {
            event: null,
            overListedBuildingsText: [],
        },
        scheduledMonuments: {
            event: null,
            overScheduledMonumentsText: [],
        },
        dno: {
            event: null,
            overDNOText: [],
        },
        floodZone2: {
            event: null,
            overFloodZone2Text: [],
        },
        floodZone3: {
            event: null,
            overFloodZone3Text: [],
        },
        floodRisksSeaAndRivers: {
            event: null,
            overFloodRisksSeaAndRiversText: [],
        },
        floodRisksSurfaceWater: {
            event: null,
            overFloodRisksSurfaceWaterText: [],
        },
        aonb: {
            event: null,
            overAonbFeatures: {
                text: [],
                hoverCaption: '',
            },
        },
        commonLand: {
            event: null,
            overCommonLandText: [],
        },
        ancientWoodland: {
            event: null,
            overAncientWoodlandText: [],
        },
        sssi: {
            event: null,
            overSssiText: [],
        },
        unregisteredLand: {
            unregisteredLandLayer: null,
            showUnregisteredLandlayer: false,
            event: null,
            overUnregisteredLand: [],
        },
        radon: {
            showRadonLayer: false,
            event: null,
        },
        scotland: {
            showScotlandLayer: false,
            event: null,
        },
    },
}

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
}

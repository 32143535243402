<template>
    <base-row-item :model="baseRowModel"
                   v-bind="props"
                   @view-title="viewTitleClickHandler">
        <template #actions>
            <ow-button is-secondary
                       is-small
                       data-track="Asset monitoring: view title"

                       @click="viewTitleClickHandler">
                {{ t('action.viewTitle') }}
            </ow-button>
        </template>
        <template v-if="hasExtraInfo"
                  #extra-info>
            <sub-type-renderer :notification-details="item.notificationDetails"
                               :title-number="item.titleNumber"
                               :notification-id="item.notificationId"
                               @area-difference="handleBoundaryArea"
                               @count-of-updates="numberOfUpdates = $event" />
        </template>
    </base-row-item>
</template>

<script lang="ts"
        setup>
    import {computed,
            ref} from 'vue'
    import {useI18n} from 'vue-i18n'
    import {useRouter} from 'vue-router'

    import {IAssetMonitoringNotification} from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import {AssetMonitoringDataSource,
            BaseRowItemModel} from "@/components/asset-monitoring/base-row-item-model"
    import BaseRowItem from "@/components/asset-monitoring/grid/row-items/base-row-item.vue"
    import SubTypeRenderer from "@/components/asset-monitoring/grid/sub-type-items/sub-type-renderer.vue"
    import {NotificationSubType} from '@/components/asset-monitoring/notification-sub-type.enum'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import {Route} from '@/enums/route.enum'

    const { t } = useI18n()
    const router = useRouter()
    const numberOfUpdates = ref<number>(0)

    const props = defineProps<{
        item: IAssetMonitoringNotification,
        containerSelector: string,
        matterId: number,
    }>()

    /**
     * Handles the click event on the view title button.
     * Navigates to the MatterMapTitle route with the specified parameters.
     *
     * @async
     * @function viewTitleClickHandler
     * @returns A promise that resolves once navigation is complete.
     */
    const viewTitleClickHandler = async () => {
        let tabHash = ''
        if (hasOC2ChangeNotification.value) {
            tabHash = '#copies-filed'
        } else if (hasPendingApplicationChangeNotification.value) {
            tabHash = '#daylist'
        }

        await router.push({
            name: Route.MatterMapTitle,
            params: {
                titleNumber: props.item.titleNumber,
                matterId: props.matterId,
            },
            hash: tabHash,
        })
    }

    const hasBoundaryChangeNotification = computed(() => props.item?.notificationDetails?.some((notificationDetail) => {
        return notificationDetail.notificationSubType === NotificationSubType.BoundaryChange
    }))

    const hasOwnershipChangeNotification = computed(() => props.item?.notificationDetails?.some((notificationDetail) => {
        return notificationDetail.notificationSubType === NotificationSubType.OwnershipChange
    }))

    const hasOC2ChangeNotification = computed(() => props.item?.notificationDetails?.some((notificationDetail) => {
        return notificationDetail.notificationSubType === NotificationSubType.NewDocumentInOCDA
    }))

    const hasEditionDateMismatchNotification = computed(() => props.item?.notificationDetails?.some((notificationDetail) => {
        return notificationDetail.notificationSubType === NotificationSubType.EditionDateDoesntMatchOcdaResponse
    }))

    const hasPendingApplicationChangeNotification = computed(() => props.item?.notificationDetails?.some((notificationDetail) => {
        return notificationDetail.notificationSubType === NotificationSubType.CompletedPendingApplication
    }))

    const hasExtraInfo = computed(() => {
        return !hasEditionDateMismatchNotification.value
    })

    const boundaryAreaDifference = ref<number>(0)
    const isBoundaryLarger = ref<boolean>(false)
    const handleBoundaryArea = (area: number) => {
        isBoundaryLarger.value = area > 0
        boundaryAreaDifference.value = area > 0 ? area : area * -1
    }

    const baseRowModel = computed((): BaseRowItemModel => {
        if (hasBoundaryChangeNotification.value) {
            return {
                title: t('assetMonitoring.subType.boundaryUpdated.title'),
                descriptionPath: 'assetMonitoring.subType.boundaryUpdated.description',
                dataSource: AssetMonitoringDataSource.HMLR,
                titleNumber: props.item.titleNumber,
                boundaryDifference: boundaryAreaDifference.value,
                isBoundaryLarger: isBoundaryLarger.value,
            }
        }
        if (hasOwnershipChangeNotification.value) {
            return {
                title: t('assetMonitoring.subType.ownershipUpdated.title'),
                descriptionPath: 'assetMonitoring.subType.ownershipUpdated.description',
                dataSource: AssetMonitoringDataSource.HMLR,
                titleNumber: props.item.titleNumber,
            }
        }
        if (hasOC2ChangeNotification.value) {
            return {
                title: `${ t('assetMonitoring.subType.oc2DocumentUpdated.title') }  (${ numberOfUpdates.value })`,
                descriptionPath: 'assetMonitoring.subType.oc2DocumentUpdated.description',
                dataSource: AssetMonitoringDataSource.HMLR,
                titleNumber: props.item.titleNumber,
            }
        }
        if (hasPendingApplicationChangeNotification.value) {
            return {
                title: `${ t('assetMonitoring.subType.pendingApplicationsUpdated.title') }  (${ numberOfUpdates.value })`,
                descriptionPath: 'assetMonitoring.subType.pendingApplicationsUpdated.description',
                dataSource: AssetMonitoringDataSource.HMLR,
                titleNumber: props.item.titleNumber,
            }
        }
        if (hasEditionDateMismatchNotification.value) {
            return {
                title: t('assetMonitoring.subType.EditionDateDoesntMatchOcdaResponse.title'),
                descriptionPath: 'assetMonitoring.subType.EditionDateDoesntMatchOcdaResponse.description',
                dataSource: AssetMonitoringDataSource.HMLR,
                titleNumber: props.item.titleNumber,
            }
        }

        throw new Error(`Unable to match notification type to row model: ${ JSON.stringify(props.item?.notificationDetails) })`)
    })
</script>

<style lang="scss"
       scoped>
    @import "./title-register-outdated-row-item.scss";
</style>

<template>
    <v-card class="users-header">
        <v-card-text class="py-2 px-0">
            <div class="d-flex flex-row justify-space-between align-center users-header__wrapper flex-wrap ">
                <div class="d-flex flex align-center justify-space-between">
                    <ow-textfield v-model="searchValue"
                                  :placeholder="$t('admin.organisation.label.searchUsers')"
                                  clearable
                                  data-test="user-list-search"
                                  class="users-header__search">
                        <template #iconPrefix>
                            <v-icon>$search</v-icon>
                        </template>
                    </ow-textfield>
                </div>
                <div class="users-header__action-buttons">
                    <v-select v-model="selectedFilterRole"
                              :items="rolesFilterOptions"
                              density="compact"
                              variant="outlined"
                              hide-details
                              multiple
                              chips
                              clearable
                              class="users-header__status-filter"
                              data-test="users-header-filter-by-role"
                              :label="$t('admin.user.label.filterByRole')" />

                    <v-select v-model="selectedFilterStatus"
                              :items="statusFilterOptions"
                              density="compact"
                              variant="outlined"
                              hide-details
                              multiple
                              clearable
                              chips
                              class="users-header__status-filter"
                              data-test="users-header-filter-by-status"
                              :label="$t('admin.user.label.filterByStatus')" />

                    <v-select v-if="licencingActive"
                              v-model="selectedFilterLicenceType"
                              :items="licenceTypeOptions"
                              density="compact"
                              variant="outlined"
                              hide-details
                              multiple
                              clearable
                              chips
                              class="users-header__status-filter"
                              data-test="users-header-filter-by-licence-type"
                              :label="$t('admin.user.label.filterByLicence')" />

                    <v-tooltip :text="$t('admin.user.tooltips.refresh')"
                               location="bottom">
                        <template #activator="{ props }">
                            <ow-button :is-loading="loadingUsers"
                                       class="hidden-sm-and-down"
                                       is-secondary
                                       icon
                                       size="large"
                                       v-bind="props"
                                       data-test="users-header-button-refresh"
                                       @click="$emit('refresh-users')">
                                <v-icon>$refresh</v-icon>
                            </ow-button>
                        </template>
                    </v-tooltip>
                    <v-tooltip :text="$t('admin.user.tooltips.download')"
                               location="bottom">
                        <template #activator="{ props }">
                            <ow-button is-secondary
                                       data-test="users-header-button-export"
                                       icon
                                       size="large"
                                       v-bind="props"
                                       @click="$emit('download-users')">
                                <v-icon>$download</v-icon>
                            </ow-button>
                        </template>
                    </v-tooltip>
                    <v-tooltip :text="$t('admin.user.tooltips.newUser')"
                               location="bottom">
                        <template #activator="{ props }">
                            <ow-button is-primary
                                       data-test="users-header-button-add-user"
                                       size="large"
                                       v-bind="props"
                                       @click="$emit('add-user')">
                                <template #iconPrefix>
                                    <v-icon>$add-user</v-icon>
                                </template>
                                {{ $t('admin.user.label.addUser') }}
                            </ow-button>
                        </template>
                    </v-tooltip>
                </div>
            </div>
            <p v-if="hasFiltersApplied"
               class="caption-regular users-header__applied-filter-text">
                {{ $t('admin.user.label.userCount', { count: userCount }) }}
            </p>
        </v-card-text>
    </v-card>
</template>

<script>
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import useLicenceController from "@/composables/use-licence-controller"
    import {isNullOrEmpty} from '@/utils/array-utils'
    import {isNullOrWhitespace} from '@/utils/string-utils'

    export default {
        name: 'UsersHeader',
        components: { OwButton, OwTextfield },

        props: {
            statusFilterOptions: {
                type: Array,
                required: true,
            },

            rolesFilterOptions: {
                type: Array,
                required: true,
            },

            licenceTypeOptions: {
                type: Array,
                required: true,
            },

            loadingUsers: {
                type: Boolean,
                required: true,
            },

            userCount: {
                type: Number,
                required: false,
            },
        },

        emits: [
            'add-user',
            'refresh-users',
            'download-users',
            'filter-status',
            'filter-role',
            'filter-licence-type',
            'search',
        ],

        setup() {
            const { active: licencingActive } = useLicenceController()
            return { licencingActive }
        },

        data() {
            return {
                selectedFilterStatus: [],
                selectedFilterRole: [],
                selectedFilterLicenceType: [],
                searchValue: '',
            }
        },

        computed: {
            hasFiltersApplied() {
                return (!isNullOrEmpty(this.selectedFilterStatus) ||
                    !isNullOrEmpty(this.selectedFilterRole) ||
                    !isNullOrEmpty(this.selectedFilterLicenceType) ||
                    !isNullOrWhitespace(this.searchValue)) &&
                    this.userCount > 0
            },
        },

        watch: {
            selectedFilterStatus(newVal) {
                this.$emit('filter-status', newVal)
            },
            selectedFilterRole(newVal) {
                this.$emit('filter-role', newVal)
            },
            selectedFilterLicenceType(newVal) {
                this.$emit('filter-licence-type', newVal)
            },
            searchValue(newVal) {
                this.$emit('search', newVal)
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './users-header';
</style>

<template>
    <div v-if="hasRegisterEntries"
         :class="{ highlightDates }"
         class="review-assistant-register-record"
         data-test="register-record">
        <h3 class="review-assistant-register-record__header headers-h3-card-heading"
            data-test="register-record-heading"
            @click="expanded = !expanded">
            <ow-text-highlight :queries="[filter]"
                               class="review-assistant-register-record__header--prefix"
                               @match-count-changed="prefixSearchMatchCount = $event">
                {{ register.prefix }}
            </ow-text-highlight>
            <ow-text-highlight :queries="[filter]"
                               class="review-assistant-register-record__header--name"
                               @match-count-changed="nameSearchMatchCount = $event">
                {{ register.displayName }}
            </ow-text-highlight>
            <v-icon v-if="!expanded">
                $chevron-down
            </v-icon>
            <v-icon v-else>
                $chevron-up
            </v-icon>
        </h3>
        <div v-for="(entry, entryIndex) in register.entries"
             v-show="expanded"
             class="review-assistant-register-record__entry"
             data-test="register-record-entry">
            <div :style="{ background: getHighlightColour(entry.code) }"
                 :class="{'selected': entry.nr === selectedEntryNumber}"
                 class="review-assistant-register-record__entry--code">
                <ow-text-highlight :queries="[filter]"
                                   class="body-regular"
                                   data-test="review-assistant-register-record-entry-code"
                                   data-track="REVIEW-ASSISTANT - Register entry value"
                                   @match-count-changed="codeSearchMatchCount = $event">
                    {{ entry.code }}
                </ow-text-highlight>
                <span v-if="entry.date"
                      class="accents-smallest">
                    {{ entry.date }}
                </span>
            </div>
            <section>
                <register-record-entry :content="entry.displayString"
                                       :entry-number="entry.nr"
                                       :highlight-dates="highlightDates"
                                       :search-filter="filter"
                                       @match-count-changed="entrySearchMatchCount[entryIndex] = $event"
                                       @scrolled="selectEntry(entry)" />
                <ow-button v-if="entry.hasPlanReference"
                           class="review-assistant-register-record__entry--view-title-plan"
                           small
                           is-secondary
                           data-test="review-assistant-register-record-entry-view-plan"
                           data-track="Review Assistant - open title plan from entry"
                           @click="onViewPlanClicked">
                    View Title Plan
                </ow-button>
                <!-- Document details -->
                <div v-for="(document, documentIndex) of entry.referencedDocuments"
                     :key="`${ document.documentType }-${ document.documentDate }-${ documentIndex }`"
                     class="review-assistant-register-record__entry--document"
                     data-test="review-assistant-register-record-entry-document">
                    <span class="body-highlight">
                        {{ document.documentType }}
                    </span>
                    <span class="caption-regular register-highlight-text">
                        {{ document.documentDate }}
                    </span>
                    <ow-document-availability-label :hmlr-availability-code="getHmlrAvailabilityCodeForDocument(document)"
                                                    :is-ordered="isDocumentOrdered(document)" />
                    <document-ordering :document="getReferredToDocument(document)"
                                       :document-type="HighLevelDocumentType.OC2Document"
                                       :heap-metadata="{
                                           type: 'Review Assistant - Order related copies filed document',
                                           metadata: {
                                               matterId: currentMatterId,
                                               hasOrderedRegister: false,
                                           },
                                       }"
                                       :index="documentIndex.toString()"
                                       :title-number="titleNumber"
                                       class="review-assistant-register-record__entry--document-order"
                                       is-small
                                       review-assistant
                                       @view-link-clicked="onViewLinkClicked" />
                </div>
            </section>
        </div>
    </div>
</template>

<script lang="ts">
    import {              inject,
                          PropType } from 'vue'
    import { mapState } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwDocumentAvailabilityLabel from '@/components/core/ow-document-availability-label.vue'
    import OwTextHighlight from '@/components/core/ow-text-highlight.vue'
    import DocumentOrdering from '@/components/documents/document-ordering.vue'
    import RegisterRecordEntry from '@/components/review-assistant/register-record-entry.vue'
    import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { ICopiesFiledDocument } from '@/interfaces/copies-filed-document.interface'
    import { IDocumentMetadata } from '@/interfaces/documents/document-metadata.interface'
    import { ISimplifiedRegisterLinkedIndicator } from '@/interfaces/documents/entry-type.interface'
    import { IReferencedDocument } from '@/interfaces/documents/referenced-document.interface'
    import { IRegister } from '@/interfaces/documents/register.interface'
    import { IRegisterEntry } from '@/interfaces/documents/register-entry.interface'
    import { IState } from '@/interfaces/store/state.interface'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { generateOrderKeyValueForOC2Document } from '@/utils/document-ordering-utils'

    const EVENTS = {
        ENTRY_CLICKED: 'entry-clicked',
        VIEW_LINK_CLICKED: 'view-link-clicked',
        MATCH_COUNT_CHANGED: 'match-count-changed',
        VIEW_PLAN_CLICKED: 'view-plan-clicked',
    }

    export default {
        components: {
            DocumentOrdering,
            OwDocumentAvailabilityLabel,
            OwTextHighlight,
            RegisterRecordEntry,
            OwButton,
        },
        props: {
            register: {
                type: Object as PropType<IRegister>,
                required: true,
            },

            entryTypes: {
                type: Array as PropType<ISimplifiedRegisterLinkedIndicator[]>,
                default: () => [],
            },

            highlightDates: {
                type: Boolean,
                required: false,
            },

            selectedEntryNumber: {
                type: String,
                required: false,
            },
        },

        emits: Object.values(EVENTS),
        setup() {
            const filterProvider = inject('FILTER_PROVIDER')
            return {
                filterProvider,
            }
        },
        data() {
            return {
                HighLevelDocumentType,
                expanded: true,

                prefixSearchMatchCount: 0,
                nameSearchMatchCount: 0,
                codeSearchMatchCount: 0,
                entrySearchMatchCount: new Array(this.register.entries?.length).fill(0),
            }
        },

        computed: {
            ...mapState({
                referredToDocsAvailability: (state: IState) =>
                    state.title?.selectedTitle?.officialCopiesAvailability?.results?.referredToDocuments as IDocumentMetadata[],
                availabilityLoading: (state: IState) =>
                    state.title?.selectedTitle?.officialCopiesAvailability?.loading,
                currentMatterId: (state: IState) => state.matter?.currentMatter?.id,
            }),

            titleNumber(): string {
                return this.$route.params.titleNumber ?? ''
            },

            totalSearchMatchCount(): number {
                return this.prefixSearchMatchCount +
                    this.nameSearchMatchCount +
                    this.codeSearchMatchCount +
                    this.entrySearchMatchCount.reduce((acc: number, v: number) => acc + v, 0)
            },

            hasRegisterEntries(): boolean {
                return !isNullOrEmpty(this.register?.entries)
            },

            filter() {
                return this.filterProvider ?? ''
                    .toLowerCase()
                    .replace(/\W/g, ' ')
            },
        },

        watch: {
            totalSearchMatchCount(value: number) {
                this.$emit(EVENTS.MATCH_COUNT_CHANGED, value)
            },
        },

        methods: {
            /**
             * Find the new OCDA document from the old register doc to display
             * @param entryDocument - found from the old register data
             */
            getReferredToDocument(entryDocument: IReferencedDocument): ICopiesFiledDocument {
                if (this.availabilityLoading && entryDocument) {
                    // The referred to docs availability can take a little while to load initially
                    return {} as ICopiesFiledDocument
                }

                const keyValueOfEntryDoc = generateOrderKeyValueForOC2Document(
                    this.titleNumber,
                    entryDocument.filedUnderField ?? this.titleNumber,
                    entryDocument.documentDate,
                    entryDocument.entryNumberField,
                )

                const doc = this.referredToDocsAvailability.find(doc => doc.keyValue === keyValueOfEntryDoc)
                return doc ?? {
                    hmlrAvailabilityCode: HmlrDocumentAvailabilityCode.Unknown,
                } as ICopiesFiledDocument
            },

            isDocumentOrdered(entryDocument: IReferencedDocument): boolean {
                if (this.availabilityLoading) {
                    return false
                }
                const ocdaDoc: ICopiesFiledDocument = this.getReferredToDocument(entryDocument)

                return Boolean(ocdaDoc?.downloadAvailable)
            },

            getHmlrAvailabilityCodeForDocument(entryDocument: IReferencedDocument): string {
                if (this.availabilityLoading) {
                    return ''
                }
                const ocdaDoc: ICopiesFiledDocument = this.getReferredToDocument(entryDocument)
                if (ocdaDoc) {
                    return ocdaDoc.hmlrAvailabilityCode
                }

                // If the document cannot be found in the OCDA response, then mark it as unavailable
                return HmlrDocumentAvailabilityCode.Unavailable
            },

            getHighlightColour(code: string) {
                return this.entryTypes
                    .filter((entry: ISimplifiedRegisterLinkedIndicator) => entry.selected)
                    .find((entry: ISimplifiedRegisterLinkedIndicator) => entry.codes.includes(code))?.colour
            },

            onViewLinkClicked(document: IDocumentMetadata) {
                this.$emit(EVENTS.VIEW_LINK_CLICKED, document)
            },

            onViewPlanClicked() {
                this.$emit(EVENTS.VIEW_PLAN_CLICKED)
            },

            selectEntry(entry: IRegisterEntry): void {
                this.$emit(EVENTS.ENTRY_CLICKED, entry)
            },
        },
    }
</script>

<style lang="scss">
    @import 'register-record.scss';
</style>

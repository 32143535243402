<template>
    <ow-full-page-lock v-model="showLock"
                       :feature-id="FeatureId.Alerts"
                       offset-x="2rem">
        <!-- Rendered if show lock is false -->
        <ow-full-width header-icon="$notification"
                       header-title="Monitoring Alerts"
                       is-fixed-width
                       has-beta-label
                       :inline-step-flow="true"
                       page-name="asset-monitoring">
            <template #navigation>
                <sub-navigation :navigation-items="navigationItems" />
            </template>
            <div class="content-container">
                <router-view />
            </div>
        </ow-full-width>
    </ow-full-page-lock>
</template>

<script lang="ts" setup>

    import {
        computed,
        onBeforeMount,
        ref,
    } from "vue"
    import {useI18n} from "vue-i18n"
    import {useStore} from "vuex"

    import OwFullWidth from "@/components/core/layout/full-width-white-header.vue"
    import OwFullPageLock from "@/components/core/ow-full-page-lock.vue"
    import SubNavigation from "@/components/core/sub-navigation/sub-navigation.vue"
    import {SubNavigationItem} from "@/components/core/sub-navigation/sub-navigation-item.interface"
    import {FeatureId} from "@/composables/use-licence-controller"
    import useUser from "@/composables/use-user"
    import {Route} from "@/enums/route.enum"

    const store = useStore()
    const { t } = useI18n()
    const showLock = ref<boolean>()
    const { hasAccessToAlerts } = useUser()

    onBeforeMount(async () => {
        const hasAccess = await hasAccessToAlerts()
        showLock.value = !hasAccess
    })

    const isCompanyMonitorsEnabled = computed<boolean>(() => store.state.config.featureFlags?.assetMonitoringCompanyMonitorsTabs)

    const navigationItems = computed(() => {
        let response: Array<SubNavigationItem> = [
            {
                location:{
                    name: Route.AssetMonitoringTitleAlerts,
                },
                heading: t('assetMonitoring.navigationHeadings.titles'),
            },
        ]

        if(isCompanyMonitorsEnabled.value){
            response.push({
                location: {
                    name: Route.AssetMonitoringCompanyAlerts,
                },
                heading: t('assetMonitoring.navigationHeadings.companies'),
            })
        }

        return response
    })
</script>

<style lang="scss" scoped>
    @import './asset-monitoring';
</style>

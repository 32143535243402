<template>
    <core-map v-if="loaded"
              :initial-view="initialView"
              :interactions="interactions"
              :layers="layers"
              :name="name"
              :show-map-scale="false"
              :show-layer-attribution="showLayerAttribution"
              :layer-attribution="layerAttribution"
              :show-watermark="true"
              :interactive-scroll-zoom="interactiveScrollZoom"
              data-test="ow-title-boundary-map"
              @loaded="$emit('loaded')"
              @loading="$emit('loading')"
              @map-initialised="handleMapInitialised" />
    <ow-title-boundary-map-loading-skeleton v-else />
</template>

<script lang="ts">
    import {Feature} from "ol"
    import GeoJSON from 'ol/format/GeoJSON'
    import {Geometry} from "ol/geom"
    import { Vector as VectorLayer } from 'ol/layer'
    import Map from 'ol/Map'
    import { Vector as VectorSource } from 'ol/source'
    import {
        Stroke,
        Style,
    } from 'ol/style'
    import {mapGetters} from "vuex"

    import HttpClient from '@/api/http-client'
    import LandRegistryApi from '@/api/land-registry.api'
    import MapApi from '@/api/map.api'
    import { CoreMapInteractions } from '@/components/core/maps/core-map-interactions.interface'
    import { CoreMapView } from '@/components/core/maps/core-map-view.interface'
    import {MatterBoundariesLayer} from "@/components/core/maps/matter-boundaries-layer"
    import OwTitleBoundaryMapLoadingSkeleton
        from '@/components/loading-skeletons/ow-title-boundary-map-loading-skeleton.vue'
    import {
        BASE_LAYERS,
        MAX_ZOOM,
        MAX_ZOOM_TO_TITLE_ZOOM_LEVEL,
        MIN_ZOOM,
        PADDING,
    } from '@/consts/map'
    import { Colors } from '@/enums/colors.enum'
    import { OsImageTileLayer } from '@/store/modules/map/layers/os-image-tile-layer'
    import {MATTER_GET_MATTER_CONTENTS} from "@/store/modules/matter/types"
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { getOLStyleForOWStyleDefinition } from "@/utils/map-utils"

    import CoreMap from './core-map.vue'

    export default {
        name: 'OwTitleBoundaryMap',

        components: {
            CoreMap,
            OwTitleBoundaryMapLoadingSkeleton,
        },

        props: {
            titleNumber: {
                type: String,
                required: true,
            },
            geoserverNpsLayerName: {
                type: String,
                required: false,
                default: 'ow:nps202112',
            },
            layerAttribution: {
                type: String,
                required: false,
            },
            enableMapInteractions: {
                type: Boolean,
                default: false,
            },
            baseLayer: {
                type: String,
                default: BASE_LAYERS.OS_MAP,
                required: false,
            },
            boundaryColour: {
                type: String,
                required: false,
                default: Colors.RedBoundary,
            },
            boundaryWidth: {
                type: Number,
                required: false,
                default: 5,
            },
            name: {
                type: String,
                required: false,
                default: 'title-boundary-map',
            },

            /**
             * Used for previewing maps where screen space is limited.
             */
            showLayerAttribution: {
                type: Boolean,
                required: false,
                default: true,
            },

            layerSource: {
                type: Object,
                required: false,
                default: null,
            },

            interactiveScrollZoom: {
                type: Boolean,
                required: false,
                default: false,
            },

            showMatterBoundaries: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        emits: [
            'loaded',
            'loading',
            'loading-matter-boundaries-updated',
        ],

        data() {
            return {
                title: null,
                titleLoaded: false,
                layers: [],
                titleBbox: null,
                titleGeometry: null,
                baseTileLayer: null,
                boundaryVectorLayer: null,
                matterTitlesLayer: null as MatterBoundariesLayer,
                matterTitlesGeometries: [],
                matterBoundariesLayerInitialised: false,
            }
        },

        computed: {
            interactions(): CoreMapInteractions {
                if (this.enableMapInteractions) {
                    return {
                        doubleClickZoom: true,
                        dragPan: true,
                        keyboard: true,
                        mouseWheelZoom: true,
                    }
                }

                return {}
            },

            initialView(): CoreMapView {
                return {
                    minZoom: MIN_ZOOM,
                    maxZoom: MAX_ZOOM,
                    paddingWithinMap: [24, 24, 24, 24],
                }
            },

            loaded(): Boolean {
                return this.layersSet && this.titleLoaded
            },

            layersSet(): Boolean {
                return !isNullOrEmpty(this.layers)
            },
        },

        watch: {
            baseLayer(): void {
                this.setLayers()
            },

            boundaryColour(): void {
                this.setLayers()
            },

            boundaryWidth(): void {
                this.setLayers()
            },

            titleNumber: {
                async handler(newVal: string): Promise<void> {
                    await this.loadTitleDetails(newVal)
                },
                immediate: true,
            },

            showMatterBoundaries: {
                async handler(newVal: boolean): Promise<void> {
                    if (!this.matterBoundariesLayerInitialised) {
                        this.$emit('loading-matter-boundaries-updated', true)
                        const matterTitles = this.getMatterContents()?.titles.map((title: any) => title.titleNumber)
                        const unselectedMatterTitles = matterTitles.filter((title: any) => title.titleNumber !== this.titleNumber)
                        for (const titleNumber of unselectedMatterTitles) {
                            const response: any = await MapApi.getGeometryForTitleNumber(titleNumber, this.geoserverNpsLayerName)
                            if (response?.features) {
                                const titleFeatures: Feature<Geometry>[] = new GeoJSON().readFeatures(response)
                                this.matterTitlesGeometries.push(...titleFeatures)
                            }
                        }
                        this.matterTitlesLayer.setMatterFeatures(this.matterTitlesGeometries)
                        this.matterBoundariesLayerInitialised = true
                        this.$emit('loading-matter-boundaries-updated', false)
                    }
                    this.matterTitlesLayer.setVisible(newVal)
                },
            },
        },

        methods: {
            ...mapGetters({
                getMatterContents: MATTER_GET_MATTER_CONTENTS,
            }),

            async loadTitleDetails(titleNumber: string): Promise<void> {
                const requests = []
                requests.push(this.getTitleDetails(titleNumber))
                requests.push(this.getTitleGeometry(titleNumber))
                await HttpClient.getAllByPromise(requests)
                this.setLayers()
                this.titleLoaded = true
            },

            async getTitleDetails(titleNumber: string): Promise<void> {
                this.titleLoaded = false
                const response: any = await LandRegistryApi.getTitleByTitleNumber(titleNumber)
                if (response.ok) {
                    this.title = response.titleNumbers[0]
                }
            },

            async getTitleGeometry(titleNumber: string): Promise<void> {
                const response: any = await MapApi.getGeometryForTitleNumber(titleNumber, this.geoserverNpsLayerName)
                if (response) {
                    this.titleGeometry = response
                }
            },

            setLayers(): void {
                this.baseTileLayer = new OsImageTileLayer({
                    layerName: this.baseLayer,
                    getTargetMapFn: () => undefined,
                }).getLayer()
                this.boundaryVectorLayer = this.initialiseTitleLayer(this.titleGeometry)

                this.layers = [
                    this.baseTileLayer,
                    this.boundaryVectorLayer,
                ]
            },

            initialiseTitleLayer(titleGeometry: any): VectorLayer<any> | any[] {
                if (titleGeometry?.features) {
                    this.titleBbox = new GeoJSON().readFeatures(titleGeometry)
                    const source = new VectorSource({
                        features: this.titleBbox,
                        attributions: this.layerAttribution,
                    })
                    return new VectorLayer({
                        zIndex: 900,
                        source,
                        style: this.layerSource ?
                            getOLStyleForOWStyleDefinition(this.layerSource) : () => new Style({
                                zIndex: 100,
                                stroke: new Stroke({
                                    color: this.boundaryColour,
                                    width: this.boundaryWidth,
                                }),
                            }),
                    })
                }
                return []
            },

            handleMapInitialised(map: Map): void {
                if (!isNullOrEmpty(this.titleBbox)) {
                    this.matterTitlesLayer = new MatterBoundariesLayer({
                        targetMap: map,
                    })
                    this.matterTitlesLayer.setVisible(this.showMatterBoundaries)

                    const extent = this.boundaryVectorLayer.getSource().getExtent()
                    const view = map.getView()
                    view.fit(extent, {
                        maxZoom: MAX_ZOOM_TO_TITLE_ZOOM_LEVEL,
                        duration: 200,
                        padding: PADDING,
                    })
                }
            },
        },
    }
</script>

<template>
    <div class="ow-title-number-tooltip">
        <h3 class="caption-regular ow-title-number-tooltip__activator">
            <router-link :to="getRouterLink()"
                         @click="isVisible = false">
                {{ titleNumber }}
            </router-link>

            <v-tooltip v-model="isVisible"
                       activator="parent"
                       content-class="caption-regular ow-title-number-tooltip__content"
                       location="bottom"
                       max-width="400px">
                <div class="ow-title-number-tooltip__content--arrow" />
                <div class="ow-title-number-tooltip__content--header">
                    <h4 class="headers-h1-page-title">
                        {{ titleNumber }}
                    </h4>
                    <ow-register-status-icon :is-register-ordered="titleData?.isRegisterOrdered"
                                             :is-register-uploaded="titleData?.isRegisterUploaded"
                                             :response-date-value="titleData?.registerResponseDate"
                                             :show-title-added-to-matter="titleData?.showTitleAddedToMatter"
                                             :tr-owner-mismatch="titleData?.trOwnershipMismatch"
                                             is-small />
                    <ow-colored-label :theme="labelTheme"
                                      is-extra-small>
                        {{ tenure }}
                    </ow-colored-label>
                </div>
                <dl class="ow-title-number-tooltip__content--data">
                    <dt class="label-caps-smallest">
                        {{ t('label.address') }}
                    </dt>
                    <dd class="body-regular">
                        {{ addressText }}
                    </dd>
                    <dt class="label-caps-smallest">
                        {{ t('label.owner') }}
                    </dt>
                    <dd class="body-regular">
                        <ow-loading-skeleton v-if="isLoadingOwners"
                                             height="24px"
                                             width="100%" />
                        <span v-else>{{ ownerNamesText }}</span>
                    </dd>
                </dl>
            </v-tooltip>
        </h3>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import TitleInformationApi from '@/api/title-information.api'
    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import OwRegisterStatusIcon from '@/components/core/ow-register-status-icon.vue'
    import { Route } from '@/enums/route.enum'
    import { MATTER_GET_BY_TITLE_NUMBER } from '@/store/modules/matter/types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const store = useStore()
    const { t } = useI18n()
    const tenure = ref<string>('')
    const labelTheme = ref<string>('')
    const matterId = computed<number>(() => store.state.matter.currentMatter.id)

    const props = defineProps({
        titleNumber: {
            type: String,
            required: true,
        },
    })

    const titleData = computed(() => store.getters[MATTER_GET_BY_TITLE_NUMBER](props.titleNumber))
    watch(titleData, () => {
        if (titleData.value.tenure?.includes('F')) {
            tenure.value = t('label.freehold')
            labelTheme.value = 'tenure-freehold'
            return
        }
        if (titleData.value.tenure?.includes('L')) {
            tenure.value = t('label.leasehold')
            labelTheme.value = 'tenure-leasehold'
            return
        }
        tenure.value = t('label.unknown')
        labelTheme.value = 'tenure-other'
    }, { immediate: true })

    const addressText = computed<string>(() =>
        isNullOrWhitespace(titleData.value?.addresses?.[0])
            ? t('label.unknown')
            : titleData.value.addresses[0])

    // Deal with loading the owner data, when the tooltip becomes visible
    const isLoadingOwners = ref<boolean>(false)
    const hasLoadedOwners = ref<boolean>(false)
    const ownerNames = ref<string[]>([])
    const isVisible = ref<boolean>(false)
    watch(isVisible, async (newValue: boolean): Promise<void> => {
        if (newValue && !hasLoadedOwners.value) {
            // Only load the owner data when tooltip becomes visible. If already loaded, then don't go fetch it again.
            await populateOwnerData()
        }
    })
    const populateOwnerData = async () => {
        isLoadingOwners.value = true
        ownerNames.value = await TitleInformationApi.getOwnerNamesByTitleNumber(props.titleNumber)
        isLoadingOwners.value = false
        hasLoadedOwners.value = true
    }
    const ownerNamesText = computed<string>(() =>
        isNullOrEmpty(ownerNames.value)
            ? t('label.unknown')
            : ownerNames.value.join(', '))

    const getRouterLink = () => {
        if (matterId.value) {
            return {
                name: Route.MatterMapTitle,
                params: {
                    matterId: matterId.value.toString(),
                    titleNumber: props.titleNumber,
                },
            }
        } else {
            return {
                name: Route.TitleDetails,
                params: {
                    titleNumber: props.titleNumber,
                },
            }
        }
    }
</script>

<style lang="scss">
    @import './ow-title-number-tooltip';
</style>



import {
    MATTER_MUTATE_BULK_ORDER_LOADING,
    MATTER_MUTATE_BULK_ORDER_RESPONSE,
    MATTER_MUTATE_CREATE_GROUP_PROMPT,
    MATTER_MUTATE_CREATE_GROUP_TITLES,
    MATTER_MUTATE_CURRENT_MATTER,
    MATTER_MUTATE_CURRENT_MATTER_ADDRESSES,
    MATTER_MUTATE_CURRENT_MATTER_STATE,
    MATTER_MUTATE_GROUP_SET_EXPANDED,
    MATTER_MUTATE_GROUPS,
    MATTER_MUTATE_HIDE_EXPORT_DIALOG,
    MATTER_MUTATE_LINK_SHARE_DIRTY,
    MATTER_MUTATE_LINK_SHARING_DETAILS,
    MATTER_MUTATE_LINK_SHARING_ENABLED,
    MATTER_MUTATE_LINK_SHARING_LOADING,
    MATTER_MUTATE_LIST_ITEM_MATTER_STATE_BY_MATTER_ID,
    MATTER_MUTATE_LOADING,
    MATTER_MUTATE_LOADING_LIST,
    MATTER_MUTATE_LOADING_STATE,
    MATTER_MUTATE_MATTER_LIST_FROM_CURRENT_MATTER,
    MATTER_MUTATE_MATTER_READ_STATUS,
    MATTER_MUTATE_MATTERS,
    MATTER_MUTATE_PENDING_CHANGES_TO_LINK_SHARE_SETTINGS,
    MATTER_MUTATE_PENDING_LOADING_MATTER_ID,
    MATTER_MUTATE_PENDING_REQUEST_TO_SHOW_TITLE_LIST,
    MATTER_MUTATE_RESET_CURRENT_MATTER,
    MATTER_MUTATE_RESET_LINK_SHARE,
    MATTER_MUTATE_SELECTED_TITLES,
    MATTER_MUTATE_SHOW_EXPORT_DIALOG,
    MATTER_MUTATE_SHOW_LINK_SHARE_SETTINGS_DIALOG,
    MATTER_MUTATE_SORT_ORDER_FOR_ALL_GROUPS,
    MATTER_MUTATE_STYLE_PROMPT,
    MATTER_MUTATE_TITLE,
    MATTER_MUTATE_TITLE_ORGANISER_ENABLED,
    MATTER_MUTATE_TITLE_ORGANISER_LOADING,
    MATTER_MUTATE_TITLE_ORGANISER_MATTER_CONTENT,
    MATTER_MUTATE_TITLE_ORGANISER_QUICK_STYLING,
    MATTER_MUTATE_TITLE_ORGANISER_SELECTED_FIELD,
    MATTER_MUTATE_TITLE_ORGANISER_SET_SERVICE,
    MATTER_MUTATE_TITLES_TO_ADD_AFTER_MATTER_CREATE,
    MATTER_MUTATE_UPDATE_STATE_ERROR,
    MATTER_MUTATE_WALKTHROUGH_ID,
    MATTER_MUTATE_ZOOM_TO_EXTENT_ON_TITLE_BOUNDARIES_LOADED,
    MATTER_MUTATE_SKETCHES_VISIBLE_MAP,
    MATTER_MUTATE_SKETCHES_SETTINGS,
    MATTER_MUTATE_SHOW_DUPLICATE_MATTER_DIALOG,
    MATTER_MUTATE_DUPLICATE_MATTER_ID,
    MATTER_MUTATE_REMOVE_DOCUMENTS_PROMPT,
} from './types.js'
import { isNullOrEmpty } from '@/utils/array-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

export default {
    _setCurrentMatterTitles(state, val) {
        if (state.currentMatter != null) {
            state.currentMatter.selectedTitles = val
        }
    },

    _addCurrentMatterTitle(state, val) {
        state.currentMatter.selectedTitles.push(val)
        if (val.addresses === undefined) {
            val.addresses = []
        }
    },

    [MATTER_MUTATE_MATTERS](state, val) {
        state.matters = val
    },

    _setCollapsePanel(state, val) {
        state.collapsePanel = val
    },

    _setShowDetailsPanel(state, val) {
        state.showDetailsPanel = val
    },

    _setMatterDrawPanel(state, val) {
        state.showMatterDrawPanel = val
    },

    _setCollapsMatterDrawPanel(state, val) {
        state.collapseMatterDrawPanel = val
    },

    [MATTER_MUTATE_PENDING_LOADING_MATTER_ID](state, val) {
        state.pendingLoadingMatterId = val
    },

    [MATTER_MUTATE_LOADING](state, isLoading) {
        state.loading = isLoading
    },

    [MATTER_MUTATE_LOADING_LIST](state, isLoading) {
        state.loadingList = isLoading
    },

    [MATTER_MUTATE_CREATE_GROUP_PROMPT](state, { show, callback = null }) {
        state.prompts.createGroup.show = show
        state.prompts.createGroup.callback = callback
    },

    [MATTER_MUTATE_UPDATE_STATE_ERROR](state, val) {
        state.updateMatterStateError = val
    },

    [MATTER_MUTATE_CREATE_GROUP_TITLES](state, titles) {
        state.prompts.createGroup.titles = titles
    },

    [MATTER_MUTATE_LINK_SHARING_ENABLED](state, enabled) {
        if (state.currentMatter.linkShare) {
            state.currentMatter.linkShare.enabled = enabled
        }
    },

    [MATTER_MUTATE_LINK_SHARE_DIRTY](state, linkShareDirty) {
        state.currentMatter.linkShareDirty = linkShareDirty
    },

    [MATTER_MUTATE_RESET_LINK_SHARE](state) {
        state.currentMatter.linkShareDirty = { ...state.currentMatter.linkShare }
    },

    [MATTER_MUTATE_LINK_SHARING_DETAILS](state, details) {
        if (details.expiresOn) {
            details.expiresOn = details.expiresOn.substr(0, 10)
        }
        state.currentMatter.linkShare = details
    },

    [MATTER_MUTATE_TITLES_TO_ADD_AFTER_MATTER_CREATE](state, titles) {
        state.titlesToAddAfterMatterCreate = titles
    },

    [MATTER_MUTATE_LINK_SHARING_LOADING](state, val) {
        state.currentMatter.loading.linkShare = val
    },

    [MATTER_MUTATE_SHOW_LINK_SHARE_SETTINGS_DIALOG](state, val) {
        state.prompts.linkShare.show = val
    },

    [MATTER_MUTATE_MATTER_READ_STATUS](state, { matterId, isMatterRead }) {
        const matter = state.matters.find(matter => matter.id === matterId)
        if (matter) {
            matter.isMatterRead = isMatterRead
        }
    },

    [MATTER_MUTATE_LOADING_STATE](state, { key, value }) {
        state.loadingState[key] = value
    },

    // We may want to update item(s) in the matter list to reflect the latest
    // metadata for the current matter
    [MATTER_MUTATE_MATTER_LIST_FROM_CURRENT_MATTER](state) {
        const matterListItem = state.matters.find(m => m.id === state.currentMatter.id)
        if (matterListItem) {
            matterListItem.hasLinkShare = state.currentMatter.linkShare !== null
            matterListItem.hasDisabledLinkShare = state.currentMatter.linkShare?.enabled === false

            if (state.currentMatter.linkShare.expiresOn !== null) {
                matterListItem.hasExpiredLinkShare = state.currentMatter.linkShare.expiresOn < new Date().toISOString()
            } else {
                matterListItem.hasExpiredLinkShare = false
            }
        }
    },

    // This should be replaced when sketches is rebuilt.
    [MATTER_MUTATE_STYLE_PROMPT](state, {
        titles,
        updateMainMap,
    }) {
        state.styleTitles = titles
        state.updateMainMapOnStylePromptChanges = updateMainMap
    },

    [MATTER_MUTATE_WALKTHROUGH_ID](state, val) {
        state.currentMatter.walkthroughId = val
    },

    [MATTER_MUTATE_CURRENT_MATTER](state, matter) {
        for (const prop in matter) {
            state.currentMatter[prop] = matter[prop]
        }
    },

    [MATTER_MUTATE_ZOOM_TO_EXTENT_ON_TITLE_BOUNDARIES_LOADED](state, val) {
        state.zoomToExtentOnTitleBoundariesLoaded = val
    },

    [MATTER_MUTATE_CURRENT_MATTER_ADDRESSES](state, addresses) {
        if (!isNullOrEmpty(addresses)) {
            state.currentMatter.addresses = addresses
            addresses.forEach(address => {
                state.currentMatter.addresses[address.titleNumber] = address.addresses
                const title = state.currentMatter?.selectedTitles.find(title => title.titleNumber === address.titleNumber)
                if (title) {
                    // map to address and uprn
                    const addrs = address.addresses.map(a => ({ address: a.address, uprn: a.uprn }))
                    title.addresses = addrs.filter(x => x.address !== null).map(a => a.address)
                    title.uprns = addrs.filter(x => x.uprn !== null).map(a => a.uprn)
                }
            })
        }
    },

    [MATTER_MUTATE_PENDING_REQUEST_TO_SHOW_TITLE_LIST](state, val) {
        state.pendingRequestToShowTitleList = val
    },

    [MATTER_MUTATE_RESET_CURRENT_MATTER](state) {
        state.currentMatter.id = null
        state.currentMatter.name = 'Loading...'
        state.currentMatter.state = null
        state.currentMatter.linkShare = null
        state.currentMatter.clientCode = null
        state.currentMatter.code = null
        state.currentMatter.type = null
        state.currentMatter.groups = []
        state.currentMatter.selectedTitles = []
        state.currentMatter.titleFeatures = []
    },

    [MATTER_MUTATE_TITLE](state, options) {
        if (isNullOrWhitespace(options?.titleNumber)) {
            return
        }

        const title = state.currentMatter.selectedTitles.find(title => title.titleNumber === options.titleNumber)

        if (title) {
            for (const key in options) {
                title[key] = options[key]
            }
        }
    },

    [MATTER_MUTATE_SORT_ORDER_FOR_ALL_GROUPS](state, request) {
        const copyOfGroups = [...state.currentMatter.groups]
        request.forEach(r => {
            const group = copyOfGroups.find(group => group.id === r.id)
            if (group !== undefined) {
                group.sortOrder = r.sortOrder
            }
        })
        state.currentMatter.groups = []
        state.currentMatter.groups = copyOfGroups
    },

    [MATTER_MUTATE_PENDING_CHANGES_TO_LINK_SHARE_SETTINGS](state, val) {
        state.pendingChangesToLinkShareSettings = val
    },

    [MATTER_MUTATE_BULK_ORDER_LOADING](state, isLoading) {
        state.bulkOrderRegisters.loading = isLoading
    },

    [MATTER_MUTATE_BULK_ORDER_RESPONSE](state, responses) {
        state.bulkOrderRegisters = {
            ...state.bulkOrderRegisters,
            ...responses,
        }
    },

    /**
     * Updates the current matter state.
     * @param {any} matterState - e.g. MatterState.Open
     */
    [MATTER_MUTATE_CURRENT_MATTER_STATE](state, matterState) {
        state.currentMatter.state = matterState
    },

    /**
     * Updates the state of a matter in state.matters given a matter Id and state.
     * * @param {any} request {matterId: 123, state: MatterState.Open}
     */
    [MATTER_MUTATE_LIST_ITEM_MATTER_STATE_BY_MATTER_ID](state, request) {
        const targetMatter = state.matters.find(x => x.id === request.matterId)

        if (targetMatter) {
            state.matters.find(x => x.id === request.matterId).state = request.state
        }
    },

    [MATTER_MUTATE_SHOW_EXPORT_DIALOG](state, { titles = [], purchase = false }) {
        state.prompts.export.titles = titles
        state.prompts.export.show = true
        state.prompts.export.purchase = purchase
    },

    [MATTER_MUTATE_HIDE_EXPORT_DIALOG](state) {
        state.prompts.export.titles = []
        state.prompts.export.show = false
    },

    [MATTER_MUTATE_TITLE_ORGANISER_ENABLED](state, val) {
        state.titleOrganiser.enable = val
        if (val === false) {
            state.titleOrganiser.contentRevisionCounter++
            state.titleOrganiser.titles = []
            state.titleOrganiser.groups = []
            state.titleOrganiser.selectedField = null
        }
    },

    [MATTER_MUTATE_TITLE_ORGANISER_SET_SERVICE](state, val) {
        state.titleOrganiser.selectedService = val
        state.titleOrganiser.selectedField = val?.selectedField ?? null
        state.titleOrganiser.applyQuickStyling = val?.quickStylingByDefault ?? false
    },

    [MATTER_MUTATE_TITLE_ORGANISER_MATTER_CONTENT](state, { titles, groups }) {
        state.titleOrganiser.titles = titles ?? []
        state.titleOrganiser.groups = groups ?? []
        state.titleOrganiser.contentRevisionCounter++
    },

    [MATTER_MUTATE_TITLE_ORGANISER_QUICK_STYLING](state, val) {
        state.titleOrganiser.applyQuickStyling = val
    },

    [MATTER_MUTATE_TITLE_ORGANISER_SELECTED_FIELD](state, val) {
        state.titleOrganiser.selectedField = val
    },

    [MATTER_MUTATE_SELECTED_TITLES](state, titles) {
        state.currentMatter.selectedTitles = titles
    },

    [MATTER_MUTATE_GROUPS](state, groups) {
        state.currentMatter.groups = groups
    },

    [MATTER_MUTATE_TITLE_ORGANISER_LOADING](state, val) {
        state.titleOrganiser.loading = val
    },

    [MATTER_MUTATE_GROUP_SET_EXPANDED](state, { groupId, expanded }) {
        const groups = state.titleOrganiser.enable ? state.titleOrganiser.groups : state.currentMatter.groups
        const group = groups.find(g => g.id === groupId)
        if (group) {
            group.expanded = expanded
        }
    },

    [MATTER_MUTATE_SKETCHES_VISIBLE_MAP](state, val) {
        state.currentMatter.sketchesVisibleMap = val
    },

    [MATTER_MUTATE_SKETCHES_SETTINGS](state, { sketchesUnit, sketchesVisibleMap }) {
        state.currentMatter.sketchesVisibleMap = sketchesVisibleMap
        state.currentMatter.sketchesUnit = sketchesUnit
    },

    [MATTER_MUTATE_SHOW_DUPLICATE_MATTER_DIALOG](state, val) {
        state.showDuplicateMatterDialog = val
    },

    [MATTER_MUTATE_DUPLICATE_MATTER_ID](state, val) {
        state.duplicateMatterId = val
    },

    [MATTER_MUTATE_REMOVE_DOCUMENTS_PROMPT](state, { show, callback = null }) {
        state.prompts.removeDocumentsFromMatter.show = show
        state.prompts.removeDocumentsFromMatter.callback = callback
    },
}

<template>
    <base-monitoring-preference-card :card-type="t('assetMonitoring.preferences.cards.titleDetails.cardTitle')"
                                     :card-type-description="t('assetMonitoring.preferences.cards.titleDetails.cardDescription')"
                                     class="title-details-card">
        <ul class="title-details-card__options">
            <li v-for="pref in computedItems"
                :class="{'is-selected': pref.option.value}">
                <div class="title-details-card__options--details">
                    <h4 class="caption-highlight">
                        {{ pref.title }}
                    </h4>
                    <p class="caption-regular">
                        {{ pref.description }}
                    </p>
                </div>
                <ow-toggle v-model="pref.option.value"
                           :is-loading="isLoading"
                           :data-track="`Asset monitoring: Toggle ${pref.title}`"
                           :data-test="`toggle ${pref.title}`"
                           class="title-details-card__toggle" />
            </li>
        </ul>
    </base-monitoring-preference-card>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { INotificationSettings } from '@/api/notifications.api'
    import OwToggle from '@/components/core/ow-toggle.vue'
    import BaseMonitoringPreferenceCard from '@/components/matter/settings/monitoring-preferences/base-monitoring-preference-card.vue'

    const { t } = useI18n()

    const emit = defineEmits<{
        (event: 'update', value: INotificationSettings): void
    }>()

    const props = defineProps<{
        settings: INotificationSettings,
        isLoading: boolean,
    }>()

    const preferenceProperties = ref([
        // TODO: temporarily disabling this option
        // { value: 'trackOcda' },
        { value: 'trackDaylist' },
        { value: 'trackOwnership' },
        { value: 'trackBoundary' },
        { value: 'dailySummaryEmailEnabled'},
        { value: 'trackCompaniesHouse'},
    ])

    const computedItems = preferenceProperties.value.map((property) => {
        return {
            option: computed<boolean>({
                get: () => props.settings[property.value],
                set: (value: boolean) => {
                    const updated = {
                        ...props.settings,
                        [property.value]: value,
                    }
                    emit('update', updated)
                },
            }),
            title: t(`assetMonitoring.preferences.cards.titleDetails.${ property.value }Title`),
            description: t(`assetMonitoring.preferences.cards.titleDetails.${ property.value }Description`),
        }
    })
</script>

<style lang="scss" scoped>
    @import './title-details-card';
</style>

<template>
    <!-- Order document -->
    <div v-if="document"
         :class="{'document-order-view-link__view-btn-unavailable': !document.viewAvailable && document.downloadAvailable}"
         class="document-ordering"
         :style="reviewAssistant ? { gap: '8px' } : {}">
        <ow-tooltip :disabled="!isErrorState"
                    :position="OwTooltipPosition.Bottom">
            <template #activator="{ props }">
                <div v-bind="props">
                    <template v-if="reviewAssistant">
                        <ow-button v-if="showOrderButton || showDisabledOrderButton"
                                   :disabled="showDisabledOrderButton"
                                   :loading="isLoading"
                                   class="title-lr-button document-ordering__button-order"
                                   data-test="document-ordering-order-document"
                                   data-track="DOCS - Order document"
                                   is-secondary
                                   small
                                   @click="orderDocument()">
                            {{ isErrorState ? 'Retry' : 'Order' }} {{ isSmall || !isErrorState ? '' : 'document ' }}(£3)
                        </ow-button>
                    </template>
                    <template v-else>
                        <v-btn v-if="showOrderButton || showDisabledOrderButton"
                               :disabled="showDisabledOrderButton"
                               :loading="isLoading"
                               class="title-lr-button document-ordering__button-order"
                               color="primary"
                               data-test="document-ordering-order-document"
                               data-track="DOCS - Order document"
                               size="small"
                               variant="text"
                               @click="orderDocument()">
                            {{ isErrorState ? 'Retry' : 'Order' }} {{ isSmall || !isErrorState ? '' : 'document ' }}(£3)
                        </v-btn>
                    </template>
                </div>
            </template>
            <span>There was an error with HMLR. You haven't been charged and are able to retry the order.</span>
        </ow-tooltip>

        <!-- Document status -->
        <!-- Remove document.viewLogic if stuck on enhancing is fixed (4335)-->
        <span v-if="showDocumentStatusMessage && !document.viewAvailable"
              class="document-status"
              data-test="document-ordering-message">
            <ow-loading-text :is-loading="showStatusTextLoadingIndicator"
                             :text="document.orderStatusText" />
        </span>

        <!-- View in PDF viewer-->
        <template v-if="reviewAssistant && document.viewAvailable">
            <ow-button v-if="reviewAssistant"
                       data-test="document-ordering-view-in-ow"
                       data-track="DOCS - View document"
                       :data-test-index="`document-ordering-view-in-ow-${ index }-${ document.documentType }`"
                       class="title-lr-button document-ordering__button-view"
                       is-secondary
                       small
                       @click="onViewLinkClicked">
                View
            </ow-button>
        </template>
        <router-link v-else-if="document.viewAvailable"
                     :to="{ name: Route.DocumentViewer,
                            params: {
                                documentType: documentType,
                                documentId: document.documentId,
                            },
                            query: {
                                fromMatterId: currentMatter.id,
                            }, }">
            <v-btn data-test="document-ordering-view-in-ow"
                   data-track="DOCS - View document"
                   :data-test-index="`document-ordering-view-in-ow-${ index }-${ document.documentType }`"
                   class="title-lr-button document-ordering__button-view"
                   color="primary"
                   size="small"
                   variant="text"
                   @click="onViewLinkClicked">
                <span>View</span>
            </v-btn>
        </router-link>

        <!-- Download -->
        <span v-if="document.downloadAvailable">
            <ow-button v-if="reviewAssistant"
                       class="document-ordering__button-download title-lr-button"
                       data-test="document-ordering-download"
                       data-track="DOCS - Download document"
                       is-secondary
                       small
                       @click="downloadDocument()">
                <template #iconPrefix>
                    <v-icon>$download</v-icon>
                </template>
                Download
            </ow-button>
            <v-btn v-else
                   class="document-ordering__button-download title-lr-button"
                   color="primary"
                   data-test="document-ordering-download"
                   data-track="DOCS - Download document"
                   size="small"
                   variant="text"
                   @click="downloadDocument()">
                <v-icon>$download</v-icon>
            </v-btn>
        </span>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import DocumentsApi from '@/api/documents.api'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwLoadingText from '@/components/core/ow-loading-text.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
    import { DocumentOrderStatus } from '@/consts/document-order-status'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { Route } from '@/enums/route.enum'
    import { ICopiesFiledDocument } from '@/interfaces/copies-filed-document.interface'
    import { IState } from '@/interfaces/store/state.interface'
    import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
    import { ORDER_DOCUMENTS } from '@/store/modules/document-ordering/types'
    import { ADD_DOCUMENT_TO_MATTER } from '@/store/modules/documents/documents-types'
    import { MATTER_ADD_TITLE } from '@/store/modules/matter/types'
    import { TITLE_MUTATE_OC2_DOCUMENT_STATUS } from '@/store/modules/titles/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        USER_SHOW_POPUP,
    } from '@/store/mutation-types'
    import { showLoadingIndicatorForOrderStatus } from '@/utils/document-ordering-utils'
    import { isErrorState } from '@/utils/document-utils'

    export default {
        name: 'DocumentOrderViewLink',

        components: {
            OwButton,
            OwLoadingText,
            OwTooltip,
        },

        props: {
            document: {
                type: Object as PropType<ICopiesFiledDocument>,
                required: true,
            },
            documentType: {
                type: String,
                required: true,
            },
            titleNumber: {
                type: String,
                required: true,
            },
            index: {
                type: String,
                required: false,
                default: 'A',
            },
            // If this is true, it's because the matter has been closed and should only show download button and not the view button.
            showOnlyDownloadButton: {
                type: Boolean,
                required: false,
            },
            isOrderingAllowed: {
                type: Boolean,
                required: false,
                default: true,
            },
            isSmall: {
                type: Boolean,
                required: false,
            },
            heapMetadata: {
                type: Object,
                required: false,
            },
            reviewAssistant: {
                type: Boolean,
                default: false,
            },
        },

        emits: [
            'view-link-clicked',
        ],

        data() {
            return {
                isLoading: false,
                Route,
                OwTooltipPosition,
            }
        },

        computed: {
            ...mapState({
                currentMatter: (state: IState) => state.matter.currentMatter,
            }),

            /**
             * Returns true if the order button should be shown for the document, false otherwise
             * @return {boolean}
             */
            showOrderButton() {
                if (this.showOnlyDownloadButton) {
                    return false
                }
                return this.document.orderAvailable || this.isErrorState
            },

            showDisabledOrderButton() {
                return (!this.isOrderingAllowed && !this.document.downloadAvailable) ||
                    (this.document.hmlrAvailabilityCode === HmlrDocumentAvailabilityCode.Unavailable &&
                        !this.document.viewAvailable)
            },

            showDocumentStatusMessage() {
                return this.document.orderStatusText && !this.isErrorState
            },

            isErrorState() {
                return isErrorState(this.document)
            },

            /***
             * Used to animate some dots at the end of the text.
             * @return {boolean}
             */
            showStatusTextLoadingIndicator() {
                return showLoadingIndicatorForOrderStatus(this.document.orderStatus)
            },
        },

        methods: {

            ...mapActions('documentOrdering', {
                orderDocuments: ORDER_DOCUMENTS,
            }),

            ...mapActions({
                addDocumentToMatter: ADD_DOCUMENT_TO_MATTER,
                addTitleToMatter: MATTER_ADD_TITLE,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                showPopup: USER_SHOW_POPUP,
            }),

            ...mapMutations({
                setDocumentOrderStatus: TITLE_MUTATE_OC2_DOCUMENT_STATUS,
            }),

            async onViewLinkClicked(): Promise<void> {
                if (this.reviewAssistant) {
                    this.$emit('view-link-clicked', this.document)
                } else {
                    await this.addDocumentToMatter({
                        matterId: this.$route.params.matterId,
                        documentType: this.documentType,
                        documentId: this.document.documentId,
                    })

                    // Log the event
                    await this.logHeapEvent({
                        type: 'View augmented PDF link clicked',
                        metadata: {
                            titleNumber: this.titleNumber,
                            documentType: this.document.documentType,
                            documentDate: this.document.documentDate,
                            filedUnder: this.document.filedUnderField,
                            matterId: this.$route.params.matterId,
                        },
                    })
                }
            },

            async downloadDocument(): Promise<void> {
                try {
                    await DocumentsApi.downloadDocumentByTypeAndId(this.documentType, this.document.documentId)
                    await this.addDocumentToMatter({
                        documentType: this.documentType,
                        documentId: this.document.documentId,
                        matterId: this.$route.params.matterId,
                    })
                } catch (error) {
                    await this.showPopup({
                        title: 'Error',
                        contentHTML: 'An error was encountered when downloading the document',
                    })
                    console.error(error)
                }
            },

            async orderDocument(): Promise<void> {
                this.isLoading = true
                this.setDocumentOrderStatus({
                    keyValue: this.document.keyValue,
                    status: DocumentOrderStatus.ORDERING,
                })

                // Add to current matter if applicable
                if (!this.currentMatter.id) {
                    await this.addTitleToMatter({
                        titleNumber: this.titleNumber,
                        show: false,
                    })
                }

                // Currently this order link only appears for copy filed documents - needs to be updated to work for registers, plans, spreadsheets(legacy).
                const documentRequest = DocumentOrderRequest.createCopiesFiledRequest(this.titleNumber, this.currentMatter.id, this.document, false)
                await this.orderDocuments([documentRequest])

                await this.logHeapEvent(this.heapMetadata)

                if (this.hasError) {
                    await this.showPopup({
                        title: 'Order Documents',
                        icon: '$info',
                        contentHTML: this.errorOrders.join(','),
                    })
                }
                this.isLoading = false
            },
        },
    }
</script>
<style lang="scss">
    @import './document-ordering.scss';
</style>

<template>
    <core-map v-if="isLoaded"
              :initial-view="initialView"
              :interactions="interactions"
              :layer-attribution="layerAttribution"
              :layers="layers"
              :name="name"
              :show-layer-attribution="showLayerAttribution"
              :show-map-scale="false"
              :show-watermark="true"
              data-test="ow-title-boundary-map"
              @loaded="$emit('loaded')"
              @loading="$emit('loading')"
              @map-initialised="handleMapInitialised" />
    <ow-title-boundary-map-loading-skeleton v-else />
</template>

<script lang="ts" setup>
    import { MapBrowserEvent } from "ol"
    import Feature from "ol/Feature"
    import Map from 'ol/Map'
    import {
        computed,
        onBeforeUnmount,
        onMounted,
        ref,
        watch,
    } from 'vue'
    import { useStore } from "vuex"

    import { CoreMapInteractions } from '@/components/core/maps/core-map-interactions.interface'
    import { CoreMapView } from '@/components/core/maps/core-map-view.interface'
    import { FindNearbyDefaults } from "@/components/find-nearby/defaults"
    import OwTitleBoundaryMapLoadingSkeleton from '@/components/loading-skeletons/ow-title-boundary-map-loading-skeleton.vue'
    import {
        FindNearbyBufferLayer,
        IFindNearbyBufferLayerParams,
    } from "@/components/title-panel/v2/find-nearby/buffer-layer"
    import {
        FindNearbyHighlightLayer,
        IFindNearbyHighlightLayerParams,
    } from "@/components/title-panel/v2/find-nearby/highlight-layer"
    import { IFindNearbyResults } from "@/components/title-panel/v2/find-nearby/implementations/_common/find-nearby-service.interface"
    import {
        FindNearbyResultsLayer,
        IFindNearbyResultsLayerParams,
    } from "@/components/title-panel/v2/find-nearby/results-layer"
    import {
        BASE_LAYERS,
        MAX_ZOOM,
        MIN_ZOOM,
    } from '@/consts/map'
    import { OsImageTileLayer } from '@/store/modules/map/layers/os-image-tile-layer'
    import {
        NPS_GET_FEATURES_BY_TITLE_NUMBERS,
        NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS,
    } from "@/store/modules/nps/types"
    import {
        equalsIgnoreOrder,
        isNullOrEmpty,
    } from '@/utils/array-utils'
    import { layerEquals } from "@/utils/map-utils"

    import CoreMap from './core-map.vue'

    const props = defineProps({
        layerAttribution: {
            type: String,
            required: false,
        },
        enableMapInteractions: {
            type: Boolean,
            default: false,
        },
        baseLayer: {
            type: String,
            default: BASE_LAYERS.OS_MAP,
            required: false,
        },
        name: {
            type: String,
            required: false,
            default: 'nearby-map',
        },

        /**
         * Used for previewing maps where screen space is limited.
         */
        showLayerAttribution: {
            type: Boolean,
            required: false,
            default: true,
        },

        selectedNearbyService: {
            type: Object,
            required: true,
        },

        selectedTitleNumbers: {
            type: Array<any>,
            required: false,
        },

        selectedDistance: {
            type: Number,
            required: false,
        },

        currentMatterId: {
            type: Number,
            required: true,
        },

        featuresToHighlight: {
            type: Array<any>,
            required: false,
        },
    })

    const emit = defineEmits<{
        (e: 'loaded'),
        (e: 'loading'),
        (e: 'filtered-results-updated', results: any),
        (e: 'default-radius-updated', radius: number),
        (e: 'hover-over-features', features: Array<any>),
        (e: 'loading-state-changed', loading: boolean),
        (e: 'error-state-changed', error: boolean),
    }>()

    const baseTileLayer = ref<any>(null)
    const map = ref<Map | null>(null) //null until initialised
    const store = useStore()

    const interactions = computed((): CoreMapInteractions => {
        if (props.enableMapInteractions) {
            return {
                doubleClickZoom: true,
                dragPan: true,
                keyboard: true,
                mouseWheelZoom: true,
            }
        }
        return {}
    })

    const initialView = computed((): CoreMapView => {
        return {
            minZoom: MIN_ZOOM,
            maxZoom: MAX_ZOOM,
            paddingWithinMap: [ 24, 24, 24, 24 ],
        }
    })
    let bufferLayer = null as FindNearbyBufferLayer
    let resultsLayer = null as FindNearbyResultsLayer
    let highlightLayer = null as FindNearbyHighlightLayer
    let overFeatureIds = [] as Array<Feature>
    let isLoadingResults = false
    let selectedTitleFeatures = [] as Array<Feature>
    let filteredResults = null as IFindNearbyResults

    const layers = ref<Array<any>>([])
    const layersSet = computed((): Boolean => {
        return !isNullOrEmpty(layers.value)
    })

    const isLoaded = computed((): Boolean => {
        return layersSet.value
    })

    const zoomToBufferLayerExtent = () :void => {
        if (map.value) {
            const extent = bufferLayer.bufferFeature?.getGeometry().getExtent()
            if (extent) {
                map.value.getView().fit(extent, {
                    maxZoom: FindNearbyDefaults.BUFFER_DEFAULT_MAX_ZOOM,
                    padding: FindNearbyDefaults.BUFFER_DEFAULT_VIEW_PADDING,
                })
            }
        }
    }

    const getTitleNumberFeatures = async (titleNumbers: number[]): Promise<Feature[]> => {
        await store.dispatch(NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS, titleNumbers)
        const features = await store.getters[NPS_GET_FEATURES_BY_TITLE_NUMBERS](titleNumbers)
        return features
    }

    const updateFilteredResults = (): void => {
        filteredResults = props.selectedNearbyService.getFilteredResults({ distanceMetres: props.selectedDistance })
        resultsLayer.setVisibleFeatures(filteredResults.features)
        emit('filtered-results-updated', filteredResults)
    }

    const loadNearbyResults = async (): Promise<void> => {
        if (!isLoadingResults && props.selectedNearbyService && props.selectedTitleNumbers) {
            isLoadingResults = true
            emit('error-state-changed', false)
            emit('loading-state-changed', true)

            if (!isNullOrEmpty(props.selectedTitleNumbers) && bufferLayer) {
                props.selectedNearbyService.results = null
                selectedTitleFeatures = await getTitleNumberFeatures(props.selectedTitleNumbers)
                bufferLayer.setRadius(props.selectedNearbyService.settings.defaultRadiusMetres)
                bufferLayer.setSelectedTitleFeatures(selectedTitleFeatures)
                emit('default-radius-updated', props.selectedNearbyService.settings.defaultRadiusMetres)
                try {
                    await props.selectedNearbyService.handleRequest({
                        titleNumbers: props.selectedTitleNumbers,
                        matterIds: [ props.currentMatterId ],
                    })
                    if (props.selectedNearbyService.results) {
                        resultsLayer.setResultFeatures(props.selectedNearbyService.results.features)
                        updateFilteredResults()
                        emit('loading-state-changed', false)
                    }
                } catch (error) {
                    console.warn('Error loading nearby results', error)
                    emit('error-state-changed', true)
                }
            }

            isLoadingResults = false
        }
    }

    const handlePointerMove = (e: MapBrowserEvent<UIEvent>): void => {
        if (resultsLayer?.layer.getVisible()) {
            const pixel = map.value.getEventPixel(e.originalEvent)
            const newHighlightedFeatures = map.value
                .getFeaturesAtPixel(pixel, {
                    layerFilter: currentLayer => layerEquals(resultsLayer.layer, currentLayer),
                    hitTolerance: 5,
                })

            const featureIds = newHighlightedFeatures.map(x => x.get(props.selectedNearbyService.settings.featureKey))
            if (equalsIgnoreOrder(overFeatureIds, featureIds)) {
                return
            }
            overFeatureIds = featureIds
            highlightLayer.setHighlightFeatures(newHighlightedFeatures)
            emit('hover-over-features', props.selectedNearbyService.getRecordsFromFeatureIds(featureIds))

            if (newHighlightedFeatures.length) {
                map.value.getTargetElement().classList?.add('ow-over-layer-nearby')
            } else {
                map.value.getTargetElement().classList?.remove('ow-over-layer-nearby')
            }
        }
    }

    const initialiseBufferLayer = (): void => {
        const params: IFindNearbyBufferLayerParams = {
            targetMap: null,
        }
        bufferLayer = new FindNearbyBufferLayer(params)
        layers.value.push(bufferLayer.layer)
    }

    const initialiseResultsLayer = (): void => {
        const params: IFindNearbyResultsLayerParams = {
            targetMap: null,
        }

        resultsLayer = new FindNearbyResultsLayer(params)
        layers.value.push(resultsLayer.layer)
    }

    const initialiseHighlightLayer = (): void => {
        const params: IFindNearbyHighlightLayerParams = {
            targetMap: null,
        }

        highlightLayer = new FindNearbyHighlightLayer(params)
        layers.value.push(highlightLayer.layer)
    }

    const setLayers = (): void => {
        baseTileLayer.value = new OsImageTileLayer({
            layerName: props.baseLayer,
            getTargetMapFn: () => undefined,
        }).getLayer()

        initialiseBufferLayer()
        initialiseResultsLayer()
        initialiseHighlightLayer()

        layers.value = [
            baseTileLayer.value,
            ...layers.value,
        ]
    }

    onMounted((): void => {
        setLayers()
    })

    const redrawMap = async () => {
        if (!props.selectedNearbyService) {
            return
        }
        highlightLayer?.reset()
        resultsLayer?.setFeatureIdKey(props.selectedNearbyService.settings.featureKey)
        await loadNearbyResults()
        bufferLayer?.setRadius(props.selectedNearbyService.settings.defaultRadiusMetres)
        bufferLayer.setSelectedTitleFeatures(selectedTitleFeatures)
        zoomToBufferLayerExtent()
    }

    watch(() => props.selectedNearbyService, async (): Promise<void> => {
        if (map.value) {
            await redrawMap()
        }
    })

    watch(() => map.value, async (val) => {
        if (val) {
            await redrawMap()
        }
    })

    watch(() => props.selectedDistance, (): void => {
        highlightLayer.setHighlightFeatures([])
        bufferLayer?.setRadius(props.selectedDistance)
        updateFilteredResults()
        zoomToBufferLayerExtent()
    })
    watch(() => props.selectedTitleNumbers, async (): Promise<void> => {
        await loadNearbyResults()
    })
    watch(() => props.featuresToHighlight, (): void => {
        highlightLayer.setHighlightFeatures(props.featuresToHighlight)
    })

    onBeforeUnmount((): void => {
        resultsLayer?.dispose()
        highlightLayer?.dispose()
        bufferLayer?.dispose()
        map.value?.un('pointermove', handlePointerMove)
        emit('hover-over-features', [])
        props.selectedNearbyService.reset()
    })

    const handleMapInitialised = (mapObj: Map): void => {
        if(mapObj !== null) {
            map.value = mapObj
            // Listen for roll-over events.
            map.value?.on('pointermove', handlePointerMove)
            bufferLayer.setSelectedTitleFeatures(selectedTitleFeatures)
        }
    }
</script>

/**
 * Map types
 * @type {string}
 */
export const MAP_MUTATE_ADD_CLICKED_IDS_FEATURE = 'MAP_MUTATE_ADD_CLICKED_IDS_FEATURE'
export const MAP_MUTATE_DISABLED = 'MAP_MUTATE_DISABLED'
export const MAP_MUTATE_INITIAL_TITLE = 'MAP_MUTATE_INITIAL_TITLE'
export const MAP_MUTATE_LAUNCH_SEARCH_RESULT = 'MAP_SET_LAUNCH_SEARCH_RESULT'
export const MAP_MUTATE_MARKER_LAYER_ADD = 'map/MUTATE_MARKER_LAYER_ADD'
export const MAP_MUTATE_NPS_OVER_IDS = 'MAP_MUTATE_NPS_OVER_IDS'
export const MAP_MUTATE_OVER_TITLE_NUMBERS = 'MAP_MUTATE_OVER_TITLE_NUMBERS'
export const MAP_MUTATE_SET_CLICKED_TITLE_NUMBERS = 'MAP_MUTATE_SET_CLICKED_TITLE_NUMBERS'
export const MAP_MUTATE_TAKING_SNAPSHOT = 'MAP_MUTATE_TAKING_SNAPSHOT'

export const MAP_SET_BASELAYER = 'MAP_SET_BASELAYER' // with baselayer ID
export const MAP_INITIALISE = 'MAP_INITIALISE'
export const MAP_GET_VIEW = 'MAP_GET_VIEW'
export const MAP_ZOOM_IN = 'MAP_ZOOM_IN'
export const MAP_ZOOM_OUT = 'MAP_ZOOM_OUT'
export const MAP_TAKING_SNAPSHOT = 'MAP_TAKING_SNAPSHOT'
export const MAP_NPS_IS_LOADING = 'MAP_NPS_IS_LOADING'
export const MAP_ADD_MARKER = 'map/ADD_MARKER'
export const MAP_REMOVE_ALL_MARKERS = 'map/REMOVE_ALL_MARKERS'
export const MAP_VALIDATE_NPS_DISPLAY = 'map/VALIDATE_NPS_DISPLAY'
export const MAP_UPDATE_SIZE = 'map/UPDATE_SIZE'
export const MAP_GET_NPS_IS_AVAILABLE_AT_CURRENT_ZOOM = 'MAP_GET_NPS_IS_AVAILABLE_AT_CURRENT_ZOOM'

/**
 * Map NPS types
 */
export const MAP_SHOW_NPS_TITLENUMBERS = 'MAP_SHOW_NPS_TITLENUMBERS' // with boolean param
export const MAP_SHOW_NPS_FREEHOLDS = 'MAP_SHOW_NPS_FREEHOLDS' // with boolean param
export const MAP_SHOW_NPS_LEASEHOLDS = 'MAP_SHOW_NPS_LEASEHOLDS' // with boolean param
export const MAP_SET_BASELAYER_OPACITY = 'MAP_SET_BASELAYER_OPACITY' // with 0 - 1 param
export const MAP_OVERLAY_BASELAYER = 'MAP_OVERLAY_BASELAYER' // with boolean param
export const MAP_GET_NPS_IS_IN_SOME_WAY_VISIBLE = 'MAP_GET_NPS_IS_IN_SOME_WAY_VISIBLE' // is any NPS visualisation turned on?
export const MAP_EMPHASISE_NPS_COVERAGE = 'MAP_EMPHASISE_NPS_COVERAGE' // greys out all visible NPS polygons

// OS Road data
export const MAP_SHOW_PUBLIC_RIGHTS_OF_WAY = 'MAP_SHOW_PUBLIC_RIGHTS_OF_WAY' // with boolean param
export const MAP_SHOW_HIGHWAY_MAINTENANCE = 'MAP_SHOW_HIGHWAY_MAINTENANCE' // with boolean param

// Highways data
export const MAP_HIGHWAYS_SET_OVER_PROW_FEATURES = 'MAP_HIGHWAYS_SET_OVER_PROW_FEATURES' // action - with array of ol.Feature
export const MAP_HIGHWAYS_MUTATE_OVER_PROW_FEATURES = 'MAP_HIGHWAYS_MUTATE_OVER_PROW_FEATURES' // mutation - with array of ol.Feature
export const MAP_HIGHWAYS_SET_OVER_MAINTENANCE_FEATURES = 'MAP_HIGHWAYS_SET_OVER_MAINTENANCE_FEATURES' // action - with array of ol.Feature
export const MAP_HIGHWAYS_MUTATE_OVER_MAINTENANCE_FEATURES = 'MAP_HIGHWAYS_MUTATE_OVER_MAINTENANCE_FEATURES' // mutation - with array of ol.Feature

// Listed Buildings data
export const MAP_LISTED_BUILDINGS_MUTATE_OVER_TEXT = 'MAP_LISTED_BUILDINGS_MUTATE_OVER_TEXT' // mutation - string array

// DNO data
export const MAP_DNO_MUTATE_OVER_TEXT = 'MAP_DNO_MUTATE_OVER_TEXT' // mutation - string array

// Flood data
export const MAP_FLOOD_ZONE_2_MUTATE_OVER_TEXT = 'MAP_FLOOD_ZONE_2_MUTATE_OVER_TEXT' // mutation - string array
export const MAP_FLOOD_ZONE_3_MUTATE_OVER_TEXT = 'MAP_FLOOD_ZONE_3_MUTATE_OVER_TEXT' // mutation - string array
export const MAP_FLOOD_RISKS_SEA_AND_RIVERS_MUTATE_OVER_TEXT = 'MAP_FLOOD_RISKS_SEA_AND_RIVERS_MUTATE_OVER_TEXT' // mutation - string array
export const MAP_FLOOD_RISKS_SURFACE_WATER_MUTATE_OVER_TEXT = 'MAP_FLOOD_RISKS_SURFACE_WATER_MUTATE_OVER_TEXT' // mutation - string array

// Conservation Areas data
export const MAP_CONSERVATION_AREAS_MUTATE_OVER_TEXT = 'MAP_CONSERVATION_AREAS_MUTATE_OVER_TEXT' // mutation - string array

// Scheduled Monuments data
export const MAP_SCHEDULED_MONUMENTS_MUTATE_OVER_TEXT = 'MAP_SCHEDULED_MONUMENTS_MUTATE_OVER_TEXT' // mutation - string array

// Environmental data
export const MAP_ANCIENT_WOODLAND_MUTATE_OVER_TEXT = 'MAP_ANCIENT_WOODLAND_MUTATE_OVER_TEXT' // mutation - string array
export const MAP_AONB_MUTATE_OVER_TEXT = 'MAP_AONB_MUTATE_OVER_TEXT' // mutation - string array
export const MAP_COMMON_LAND_MUTATE_OVER_TEXT = 'MAP_COMMON_LAND_MUTATE_OVER_TEXT' // mutation - string array
export const MAP_SSSI_MUTATE_OVER_TEXT = 'MAP_SSSI_MUTATE_OVER_TEXT' // mutation - string array

// Unregistered land data
export const MAP_UNREGISTERED_MUTATE_OVER_TEXT = 'MAP_UNREGISTERED_MUTATE_OVER_TEXT' // mutation - string array
export const MAP_SHOW_UNREGISTERED_LAND = 'MAP_SHOW_UNREGISTERED_LAND' // with boolean param

export const MAP_SHOW_RADON = 'MAP_SHOW_RADON' // with boolean param

// Map Overlays
export const MAP_OVERLAYS_START = 'MAP_OVERLAYS_START'

export const MAP_PREVENT_TITLE_BOUNDARY_LAYER_DESELECTION = 'MAP_PREVENT_TITLE_BOUNDARY_LAYER_DESELECTION'

<template>
    <ow-card :loading="loading"
             :loading-skeleton="{ rows: 3, columns: 0, expandRows: true, reverse: false }"
             :title="$t('titlePanel.registerEntries')"
             class="register-card">
        <span v-if="!isTitleRegisterPurchased"
              v-t="'documents.noRegisterEntriesPurchase'"
              data-test="no-register-entries"
              class="accents-italic" />
        <span v-else-if="!registers.length"
              v-t="'documents.noRegisterEntries'"
              data-test="no-register-entries"
              class="accents-italic" />
        <template v-else>
            <register-card-actions v-model:searchText="searchTextInput"
                                   v-model:highlightDates="highlightDates"
                                   v-model:showButtonMenu="showButtonMenu"
                                   :selected-entry-types="selectedEntryTypes"
                                   :entry-types="entryTypes"
                                   :hide-flags="hideFlags"
                                   @search-clear="onSearchClear"
                                   @toggle-entry-type="toggleEntryType" />

            <register-card-flags v-if="!hideFlags"
                                 :selected-entry-types="selectedEntryTypes"
                                 @toggle-entry-type="toggleEntryType" />

            <register-card-entries v-model="openedPanels"
                                   :registers="registers"
                                   :highlight-dates="highlightDates"
                                   :selected-entry-types="selectedEntryTypes" />
        </template>
    </ow-card>
</template>

<script setup lang="ts">
    import {              computed,
                          ref,
                          watch} from 'vue'
    import { onBeforeUnmount } from 'vue'
    import { nextTick } from 'vue'
    import { useStore } from 'vuex'

    import OwCard from '@/components/core/ow-card.vue'
    import { useNlpSearch } from '@/composables/use-nlp-search'
    import { IRegisterLinkedIndicator } from '@/interfaces/documents/entry-type.interface'
    import { IRegister } from '@/interfaces/documents/register.interface'
    import {
        TITLE_MUTATE_ENTRY_TYPES_SELECTION,
        TITLE_MUTATE_REGISTER_INDICATOR_SELECTION,
    } from '@/store/modules/titles/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'

    import RegisterCardActions from './register-card-actions.vue'
    import RegisterCardEntries from './register-card-entries.vue'
    import RegisterCardFlags from './register-card-flags.vue'
    const store = useStore()

    defineProps<{
        hideFlags?: boolean
    }>()

    defineEmits(['on-search-clear', 'title-number-selected'])

    const searchTextInput = ref('')
    const highlightDates = ref<boolean>(false)
    const showButtonMenu = ref(false)
    const openedPanels = ref<string[]>([])
    const registers = ref<IRegister[]>([])
    const entryTypes = ref<IRegisterLinkedIndicator[]>([])

    const loading = ref(true)
    const availableRegisterContentHeight = ref(0)
    const searchTextInputLoggingDebounce = ref<number | null>(null)
    const selectedTitle = computed(() => store.state.title.selectedTitle)
    const selectedTitleNumber = computed(() => store.state.title.selectedTitleNumber)
    const bgDataLoaded = computed(() => selectedTitle.value?.record?.titleMetadata?.bgDataLoaded)
    const pendingRegisterIndicatorSelection = computed(() => store.state.title.pendingRegisterIndicatorSelection)
    const selectedEntryTypes = computed<IRegisterLinkedIndicator[]>(() => store.state?.title?.selectedEntryTypes)
    const bgProprietorshipData = computed(() => selectedTitle.value?.record?.bgProprietorshipData)
    const isTitleRegisterPurchased = computed(() => selectedTitle?.value?.officialCopiesAvailability?.results?.titleRegister?.documentId)

    const {
        searchText,
        setTerms,
    } = useNlpSearch({
        highlightDates,
        inputValue: searchTextInput,
    })

    onBeforeUnmount(() => {
        if (searchTextInputLoggingDebounce.value != null) {
            window.clearTimeout(searchTextInputLoggingDebounce.value)
        }
    })

    const toggleEntryType = (item: IRegisterLinkedIndicator) => {
        const selected = selectedEntryTypes.value.find(t => {
            return t.label === item.label
        })

        if (selected) {
            const index = selectedEntryTypes.value.indexOf(selected)
            // selectedEntryTypes without the selected type
            const newEntries = [...selectedEntryTypes.value.slice(0, index), ...selectedEntryTypes.value.slice(index + 1)]
            store.commit(TITLE_MUTATE_ENTRY_TYPES_SELECTION, newEntries)
        } else {
            store.commit(TITLE_MUTATE_ENTRY_TYPES_SELECTION, [...selectedEntryTypes.value, item])
        }
    }

    const populateRegisterInformation = (register: IRegister) => {
        // Register structures are the same, this function avoids code duplication when populating data.registers derived values.
        if (selectedTitle?.value.record != null) {
            let textMatch = false
            let filterMatch = false
            register.highlightText = []
            register.highlightEntryNumbers = []
            register.searchCount = 0
            register.showEntries = []
            const registerTextMatches = []
            register.show = true
            register.showAllEntries = false
            register.filterCounts = []
            register.expandPanel = true

            // Check if the register matches the search text
            if (searchText.value.length > 0 || selectedEntryTypes.value.length > 0) {
                const processEntry = entry => {
                    const entryTextMatches = []

                    // Determine text matches and subsequently whether or not to show an entry
                    if (searchText.value.length > 0) {
                        searchText.value.forEach(text => {
                            const regExMatches = entry.displayString.match(new RegExp(text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) || []
                            entryTextMatches.push(...regExMatches)
                        })
                    }

                    // Determine entry number matches (and increment match counts)
                    searchText.value.forEach(text => {
                        if (text.toUpperCase() === entry.code.toUpperCase()) {
                            register.highlightEntryNumbers.push(text.toLowerCase())
                            textMatch = true
                            entryTextMatches.push(text.toLowerCase())
                        }
                    })

                    registerTextMatches.push(...entryTextMatches)
                    register.searchCount += entryTextMatches.length

                    // Determine filter matches
                    if (selectedEntryTypes.value.length > 0) {
                        const selected = selectedEntryTypes.value.find(t => {
                            // Get matching codes
                            const codes = t.codes.map(code => code.code)
                            const subcodes = t.codes.map(code => code.subCodes).flat()

                            return codes.includes(entry.code) || subcodes.includes(entry.code)
                        })

                        if (selected) {
                            filterMatch = true
                            register.showEntries.push(entry.code)
                            const filterCount = register.filterCounts.find(f => {
                                return f.type === selected
                            })
                            if (filterCount) {
                                filterCount.count++
                            } else {
                                register.filterCounts.push({
                                    type: selected,
                                    count: 1,
                                })
                            }
                        }
                    }

                    // Determine document matches
                    if (highlightDates.value && selectedTitle.value.record?.bgProprietorshipData?.documentDetails) {
                        const documentDetails = selectedTitle.value.record.bgProprietorshipData.documentDetails
                        const documentsWithDates = documentDetails.filter((document) => {
                            return document.entryNumberField?.indexOf(entry.code) !== -1
                        }).length
                        register.searchCount += documentsWithDates
                    }

                    if (entryTextMatches.length > 0) {
                        register.showEntries.push(entry.code)
                    }
                }

                register.entries.forEach(entry => {
                    processEntry(entry)
                })

                textMatch = registerTextMatches.length > 0
                register.show = (textMatch || filterMatch) || register.searchCount > 0
            } else {
                register.showAllEntries = true
                register.show = true
            }

            // this.registers[registerName].searchCount = registerTextMatches.length;
            const uniqueTextMatches = [...new Set(registerTextMatches)]
            register.highlightText.push(...uniqueTextMatches)
        }
    }


    const updateData = () => {
        registers.value.forEach(register => {
            populateRegisterInformation(register)
        })

        openedPanels.value = (registers.value || [])
            .map(register => !isNullOrEmpty(register.showEntries) ? register.prefix : null)
            .filter(register => register !== null)
    }

    const onSearchClear = () => {
        searchTextInput.value = null
        updateData()
    }

    const createRegisterData = () => {
        registers.value = []
        if (selectedTitle?.value.record?.bgProprietorshipData?.registers != null) {
            registers.value = selectedTitle.value.record.bgProprietorshipData.registers.map(register => {
                register.show = true
                register.searchCount = 0
                register.highlightText = []
                register.highlightEntryNumbers = []
                register.showEntries = []
                register.showAllEntries = false
                register.filterCounts = []
                return register
            })
        }
        return []
    }

    const detectTermsOfInterest = () => {
        let combinedText = ''
        registers.value.forEach(register => {
            const combineText = text => {
                combinedText = combinedText + ' ' + text
            }

            // Combine text from the main registers
            register.entries.forEach(entry => {
                combineText(entry.displayString)
            })
        })

        setTerms(combinedText)
    }

    const recalculateAvailableContentHeight = () => {
        if (document.getElementById('titlePanelContent')) {
            const parentAvailableHeight = Number(document.getElementById('titlePanelContent')
                .style
                .maxHeight
                .replace('px', ''))
            let availableHeight = parentAvailableHeight - 135

            const filterElement = document.getElementById('registerSelectedEntryTypes')
            if (filterElement != null) {
                availableHeight = availableHeight - filterElement.clientHeight
            }
            availableRegisterContentHeight.value = availableHeight
        }
    }

    const populateEntryTypes = () => {
        entryTypes.value = []
        store.commit(TITLE_MUTATE_ENTRY_TYPES_SELECTION, [])
        if (selectedTitle?.value.record?.bgProprietorshipData?.linkedIndicators != null) {
            entryTypes.value = selectedTitle.value.record.bgProprietorshipData.linkedIndicators.filter(indicator => {
                return indicator.codes.length > 0
            })

            entryTypes.value.sort((a, b) => {
                const textA = a.label.toUpperCase()
                const textB = b.label.toUpperCase()
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
            })
        }
    }

    watch(() => bgDataLoaded.value, (val) => {
        if (!val) {
            return
        }

        // Wait for the register data to be available before processing
        nextTick(() => {
            loading.value = true
            try {
                createRegisterData()
                populateEntryTypes()
                detectTermsOfInterest()
                updateData()
                recalculateAvailableContentHeight()
            } finally {
                loading.value = false
            }
        })
    }, {
        immediate: true,
    })

    watch(() => bgProprietorshipData.value, (val) => {
        loading.value = val?.registers
    }, {
        immediate: true,
        deep: true,
    })

    watch(() => pendingRegisterIndicatorSelection.value, (val) => {
        // a request has been made to select an indicator (as a filter) within the title panel register tab
        if (val != null) {
            // Update existing selection
            store.commit(TITLE_MUTATE_ENTRY_TYPES_SELECTION, [val])

            // Done, remove pending selection
            store.commit(TITLE_MUTATE_REGISTER_INDICATOR_SELECTION, null)
        }
    }, {
        immediate: true,
    })

    watch(() => searchTextInput.value, (val) => {
        if (val != null) {
            if (val.trim().length > 1) {
                // Log a search event if required
                if (searchTextInputLoggingDebounce.value != null) {
                    window.clearTimeout(searchTextInputLoggingDebounce.value)
                }
                searchTextInputLoggingDebounce.value = window.setTimeout(() => {
                    store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                        type: 'Register Search',
                        metadata: {
                            text: searchTextInput.value,
                            titleNumber: selectedTitleNumber.value,
                        },
                    })
                }, 1500)
            }
        }

        updateData()
    })

    watch(() => highlightDates.value, () => {
        updateData()
    })

    watch(() => selectedEntryTypes.value, () => {
        window.setTimeout(() => {
            recalculateAvailableContentHeight()
        }, 10)
        updateData()
    })
</script>

<style lang="scss">
    @import './register-card';
</style>

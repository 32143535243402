import Feature from "ol/Feature"
import Geometry from "ol/geom/Geometry"
import VectorLayer from "ol/layer/Vector"
import Map from "ol/Map"
import VectorSource from "ol/source/Vector"
import {Stroke,
    Style} from "ol/style"

import {isNullOrEmpty} from "@/utils/array-utils"

export interface IMatterBoundariesLayerParams {
    // The OpenLayers map that be the target of the buffer layer.
    targetMap: Map
}

export class MatterBoundariesLayer {
    // The OpenLayers map that be the target of the integration.
    private targetMap: Map

    // A layer that will display the 'nearby' area - a buffer around one or more titles.
    private readonly layer: VectorLayer<VectorSource<Geometry>>

    constructor(params: IMatterBoundariesLayerParams) {
        // Initialise the map layer.
        this.layer = new VectorLayer({
            source: new VectorSource(),
            zIndex: 850,
            style: new Style({
                stroke: new Stroke({
                    color: '#ff0000',
                    width: 3,
                }),
            }),
        })

        this.setTargetMap(params.targetMap)
    }

    public setTargetMap(map: Map): void {
        this.targetMap = map

        if (this.targetMap) {
            this.targetMap.addLayer(this.layer)
        }
    }

    public getMap(): Map {
        return this.targetMap
    }

    public getVisible(): boolean {
        return this.layer.getVisible()
    }

    public getLayer(): VectorLayer<VectorSource<Geometry>> {
        return this.layer
    }

    public setMatterFeatures(features: Feature[]): void {
        this.layer.getSource().clear()

        if (!isNullOrEmpty(features)) {
            this.layer.getSource().addFeatures(features)
        }
    }

    public setVisible(visible: boolean): void {
        this.layer.setVisible(visible)
    }

    public reset():void {
        this.layer.getSource().clear()
    }

    public dispose(): void {
        this.reset()
        if (this.targetMap) {
            this.targetMap.removeLayer(this.layer)
        }
        this.layer.dispose()
    }
}

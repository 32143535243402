<template>
    <div class="users-table"
         data-test="users-table">
        <ow-data-grid :allow-selection="false"
                      :headers="headers"
                      :is-loading="loading"
                      :items="filteredUsers"
                      :no-data-text="$t('admin.user.error.noUsers')"
                      :sort-by="sortBy"
                      class="elevation-1"
                      has-pagination
                      has-pagination-all-option
                      hover
                      item-key="id">
            <template #[`item.name`]="{ item }">
                <div :title="item.name"
                     class="users-table-name d-flex align-center gc-2">
                    <v-icon v-if="!item.active"
                            class="users-table-name__icon-inactive"
                            :data-test="`users-table-icon-inactive-user-${ item.email }`">
                        $account-user-lock
                    </v-icon>
                    <v-icon v-else-if="item.licenceType === LicenceType.Premium"
                            class="users-table-name__icon-premium"
                            :data-test="`users-table-icon-licence-type-${ item.licenceType }`">
                        $account-user
                    </v-icon>
                    <v-icon v-else
                            class="users-table-name__icon-active">
                        $account-user
                    </v-icon>

                    {{ item.firstName }} {{ item.lastName }}

                    <ow-tooltip v-if="!item.active"
                                :position="OwTooltipPosition.Bottom"
                                activator="parent"
                                color="primary">
                        <span v-t="'admin.user.tooltips.inactive'" />
                    </ow-tooltip>
                    <ow-tooltip v-else-if="licencingActive && item.licenceType === LicenceType.Premium"
                                :position="OwTooltipPosition.Bottom"
                                activator="parent"
                                color="primary">
                        <span v-t="'admin.user.tooltips.premium'" />
                    </ow-tooltip>
                </div>
            </template>
            <template #[`item.edit`]="{ item }">
                <v-btn :data-test="`users-table-button-edit-${ item.email }`"
                       icon
                       variant="text"
                       @click="$emit('edit-user', item)">
                    <v-icon>$edit</v-icon>
                </v-btn>
            </template>

            <template #[`item.email`]="{ item }">
                <div :title="item.email"
                     class="users-table-email">
                    {{ item.email }}
                </div>
            </template>

            <template v-if="licencingActive"
                      #[`item.licenceType`]="{ item }">
                <ow-colored-label is-small
                                  :theme="licenceTypeTheme(item)">
                    <span class="label-caps-smallest">{{ item.licenceType }}</span>
                </ow-colored-label>
            </template>

            <template #[`item.roles`]="{ item }">
                <div :title="item.roles"
                     class="users-table-org">
                    {{ getDisplayRoles(item.roles) }}
                </div>
            </template>
        </ow-data-grid>
    </div>
</template>

<script>
    import {ref} from 'vue'

    import OwColoredLabel from "@/components/core/ow-colored-label.vue"
    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import useLicenceController from "@/composables/use-licence-controller"
    import {LicenceType} from "@/enums/licenceType"
    import {OwTooltipPosition} from '@/enums/ow-tooltip-position'
    import {getDisplayRoles} from '@/utils/user.utils'

    export default {
        name: 'UserTable',
        components: {OwColoredLabel, OwTooltip, OwDataGrid },

        props: {
            users: {
                type: Array,
                required: true,
            },

            selectedOrganisationId: {
                type: Number,
                required: false,
            },

            loading: {
                type: Boolean,
                required: true,
            },
        },
        emits: [ 'edit-user' ],
        setup() {
            const { active: licencingActive } = useLicenceController()
            return { licencingActive }
        },

        data() {
            return {
                headers: [ {
                    title: this.$t('table.label.name'),
                    align: 'left',
                    key: 'name',
                    class: 'hidden-md-and-down',
                }, {
                    title: this.$t('table.label.email'),
                    key: 'email',
                }, {
                    title: this.$t('table.label.roles'),
                    key: 'roles',
                }, {
                    title: this.$t('table.label.lastSeen'),
                    key: 'lastSeen',
                }, this.licencingActive && {
                    title: this.$t('table.label.licenceType'),
                    key: 'licenceType',
                }, {
                    title: this.$t('table.label.registered'),
                    key: 'registered',
                    class: 'hidden-sm-and-down',

                }, {
                    title: this.$t('action.edit'),
                    key: 'edit',
                } ],
                OwTooltipPosition,
                sortBy: ref([ { key: 'name', order: 'asc' } ]),
            }
        },


        computed: {
            LicenceType() {
                return LicenceType
            },
            filteredUsers() {
                return this.users?.filter(user => {
                    return !(this.selectedOrganisationId != null &&
                        user.organisationId !== this.selectedOrganisationId)


                })
            },
        },

        methods: {
            getDisplayRoles,
            licenceTypeTheme(item) {
                switch (item.licenceType) {
                    case LicenceType.Premium:
                        return 'primary'
                    case LicenceType.Standard:
                        return 'warning'
                    case LicenceType.Lite:
                        return 'success'
                    default:
                        return 'shadow'
                }
            },
        },
    }
</script>

<style lang="scss">
@import './users-table';
</style>

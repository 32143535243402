<template>
    <li class="ownership-private-owner"
        data-test="ownership-private-owner">
        <p v-if="index > -1"
           class="owner-index-banner label-caps-small mt-3">
            {{ t('titlePanel.cards.ownership.labels.owner') }} {{ index + 1 }}
        </p>

        <ul class="card-content">
            <li v-if="hasOwnerName"
                class="card-content__row">
                <span v-t="'titlePanel.cards.ownership.labels.owner'"
                      class="label-caps-smallest card-content__row--label" />
                <span class="body-highlight card-content__row--value">
                    <ow-loading-skeleton v-if="isLoading"
                                         height="24px"
                                         width="100%" />
                    <OwClipboard v-else
                                 :text="owner.name"
                                 data-track="TITLE-DETAILS-PANEL - Copy owner name info">
                        {{ owner.name }}
                    </OwClipboard>
                </span>
            </li>
            <li v-if="hasProprietorship && isExpanded"
                class="card-content__row">
                <span v-t="'titlePanel.cards.ownership.labels.proprietorship'"
                      class="label-caps-smallest card-content__row--label" />
                <span class="body-regular card-content__row--value">
                    <ow-loading-skeleton v-if="isLoading"
                                         height="24px"
                                         width="100%" />
                    <OwClipboard v-else
                                 :text="owner.correspondanceAddress"
                                 data-track="TITLE-DETAILS-PANEL - Copy owner proprietorship info">
                        {{ owner.proprietorship }}
                    </OwClipboard>
                </span>
            </li>

            <li v-if="hasCorrespondenceAddress && isExpanded"
                class="card-content__row">
                <span v-t="'titlePanel.cards.ownership.labels.ownerAddress'"
                      class="label-caps-smallest card-content__row--label" />
                <span class="body-regular card-content__row--value">
                    <ow-loading-skeleton v-if="isLoading"
                                         height="24px"
                                         width="100%" />
                    <OwClipboard v-else
                                 :text="owner.correspondanceAddress"
                                 data-track="TITLE-DETAILS-PANEL - Copy owner address info">
                        {{ owner.correspondanceAddress }}
                    </OwClipboard>
                </span>
            </li>
            <li v-if="hasDateAdded && isExpanded"
                class="card-content__row">
                <span v-t="'titlePanel.cards.ownership.labels.dateAdded'"
                      class="label-caps-smallest card-content__row--label" />
                <span class="body-regular-small card-content__row--value">
                    <ow-loading-skeleton v-if="isLoading"
                                         height="24px"
                                         width="100%" />
                    <OwClipboard v-else
                                 :text="owner.dateAdded"
                                 data-track="TITLE-DETAILS-PANEL - Copy owner date added info">
                        {{ owner.dateAdded }}
                    </OwClipboard>
                </span>
            </li>

            <li class="card-content__row">
                <div class="card-content__row--label">
                    &nbsp;
                </div>
                <div class="card-content__row--value expander">
                    <span>
                        <a class="card-content__row--value expander--link body-regular"
                           href="#"
                           @click.prevent="isExpanded = !isExpanded">
                            <ow-loading-skeleton v-if="isLoading"
                                                 height="24px"
                                                 style="min-width: 100px" />
                            <span v-else-if="isExpanded"
                                  v-t="'buttons.showLess'" />
                            <span v-else
                                  v-t="'buttons.showMore'" />
                        </a>
                    </span>
                    <span v-if="isLoading"
                          class="accents-italic expander--source">
                        <ow-loading-skeleton height="14px"
                                             width="100px" />
                    </span>
                    <i18n-t v-else
                            :keypath="source?.path"
                            class="accents-italic expander--source hide-in-percy"
                            scope="global"
                            tag="span">
                        <template #date>
                            {{ source?.date }}
                        </template>
                        <template #icon>
                            <ow-icon-hmlr v-if="isHmlr"
                                          height="12"
                                          title="HMLR"
                                          width="12" />
                            <ow-icon-scotlis v-if="isScottish"
                                             height="16"
                                             title="Scotlis"
                                             width="50" />
                        </template>
                    </i18n-t>
                </div>
            </li>
        </ul>
    </li>
</template>

<script lang="ts"
        setup>
    import {
        computed,
        PropType,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwIconHmlr from '@/components/core/icons/ow-icon-hmlr.vue'
    import OwIconScotlis from '@/components/core/icons/ow-icon-scotlis.vue'
    import OwClipboard from '@/components/core/ow-clipboard.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const { t } = useI18n()

    const props = defineProps({
        owner: {
            type: Object as PropType<any>,
            required: true,
        },
        index: Number,
        isLoading: Boolean,
        isHmlr: Boolean,
        isScottish: Boolean,
        source: Object,
    })

    const isExpanded = ref<boolean>(false)
    const hasOwnerName = computed<boolean>(() => !isNullOrWhitespace(props.owner?.name))
    const hasCorrespondenceAddress = computed<boolean>(() => !isNullOrWhitespace(props.owner?.correspondanceAddress))
    const hasDateAdded = computed<boolean>(() => !isNullOrWhitespace(props.owner?.dateAdded))
    const hasProprietorship = computed<boolean>((): boolean => !isNullOrWhitespace(props.owner?.proprietorship))

    watch(() => props.index, (newVal: number) => {
        // If only 1 owner, then show details open when panel loads
        if (newVal === -1) {
            isExpanded.value = true
        }
    }, { immediate: true })
</script>

<style lang="scss" scoped>
@import './ownership-private-owner';
</style>

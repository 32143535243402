<template>
    <ow-lock v-model="showLock"
             :feature-id="featureId"
             :offset-x="offsetX ?? ''"
             class="pa-6 full-width full-height"
             is-card>
        <slot v-if="showLock !== null"
              v-bind="{ $attrs }" />
    </ow-lock>
</template>

<script lang="ts" setup>
    import { watch } from "vue"

    import OwLock from "@/components/core/ow-lock.vue"
    import { FeatureId } from "@/composables/use-licence-controller"
    import useUser from "@/composables/use-user"

    const props = defineProps<{
        featureId?: FeatureId
        offsetX?: string,
    }>()

    const showLock = defineModel<boolean | null>({
        default: null,
    })
    const { hasAccessToFeature } = useUser()

    watch(() => props.featureId, async (featureId) => {
        if (featureId) {
            const hasAccess = await hasAccessToFeature(featureId)
            showLock.value = !hasAccess
            return
        }
        showLock.value = false
    }, {
        immediate: true,
    })
</script>

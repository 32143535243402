<template>
    <ow-card :loading="!selectedTitle?.record || documentAvailabilityLoading || isLoadingOverlays"
             :loading-skeleton="{ rows: 0, columns: 1, expandRows: false, reverse: true, ctas: 1 }"
             :has-hmlr-badge="true"
             class="order-title-plan"
             title="Title Plan">
        <div class="order-title-plan__tab-card-content">
            <div class="order-title-plan__title-plan-details">
                <ow-icon-title-plan />
                <div class="order-title-plan__title-plan-text">
                    <ow-document-availability-label v-if="showDocumentAvailabilityText"
                                                    :hmlr-availability-code="documentAvailabilityCode"
                                                    :is-ordered="Boolean(orderedTitlePlanDate)" />
                    <div v-if="titlePlanViewable"
                         class="order-title-plan__date body-copy--greyout hide-in-percy"
                         data-test="order-title-plan-label-issued-on-date">
                        {{ $t('documents.issuedOn', { date: format(new Date(titlePlanAvailability.responseDateValue), 'dd-MM-yyyy')}) }}
                    </div>
                </div>
            </div>
            <div class="order-title-plan__actions"
                 :class="{'order-title-plan__actions-overflow': hasOfficialCopies && showBackdatedViewButton}">
                <ow-split-button v-if="titlePlanViewable"
                                 :is-menu-item-loading="isMenuItemLoading"
                                 :label="showBackdatedViewButton ? 'View (Backdated)' : 'View'"
                                 :menu-items="menuItems"
                                 button-data-test="order-title-plan-button-view"
                                 @button-click="viewDocument"
                                 @menu-item-click="onMenuItemClick" />
                <div v-else
                     class="order-title-plan__actions--order-btn">
                    <ow-button :disabled="disableOrderButton || !isOrderingAllowed"
                               :is-loading="isOrderTitlePlanLoading"
                               color="primary"
                               is-primary
                               data-test="order-title-plan-button-order"
                               data-track="TITLE-DETAILS-PANEL - Order plan"
                               @click="orderDocument()">
                        {{ showBackDatedOrderButton ? $t('action.orderBackdatedPlan') : $t('action.orderPlan') }} (£3)
                    </ow-button>
                    <ow-tooltip activator="parent"
                                :disabled="isOrderingAllowed"
                                :position="OwTooltipPosition.Top">
                        {{ $t('documents.message.addTitleToMatter') }}
                    </ow-tooltip>
                </div>
                <div>
                    <ow-button v-if="titlePlanViewable && currentMatterId"
                               outlined
                               :data-test="existingOverlay ? 'order-title-plan-button-view-overlay' : 'order-title-plan-button-create-overlay'"
                               :data-track="existingOverlay ? 'TITLE-DETAILS-PANEL - Overlay Title Plan' : 'TITLE-DETAILS-PANEL - View Overlay of Title Plan'"
                               @click="goToOverlays">
                        <ow-overlay-map-icon is-primary
                                             class="mr-1" />
                        {{ existingOverlay ? $t('orderTitlePlan.viewOverlay') : $t('orderTitlePlan.overlayPlan') }}
                    </ow-button>
                </div>
            </div>
            <ow-icon-text v-if="sentInPost"
                          class="order-title-plan__info-text"
                          data-test="order-title-plan-label-sent-in-post"
                          icon="$info"
                          text="This document has been sent in post" />
            <ow-icon-text v-if="isDocumentUnderInvestigation"
                          class="order-title-plan__info-text"
                          icon="$info"
                          text="This document is currently under investigation by HMLR" />
        </div>
    </ow-card>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import {
        mapActions,
        mapMutations,
    } from 'vuex'

    import DocumentsApi from '@/api/documents.api'
    import OverlaysApi from '@/api/overlays.api'
    import OwOverlayMapIcon from '@/components/core/icons/ow-icon-overlay-map.vue'
    import OwIconTitlePlan from '@/components/core/icons/ow-icon-title-plan.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import OwDocumentAvailabilityLabel from '@/components/core/ow-document-availability-label.vue'
    import OwIconText from '@/components/core/ow-icon-text.vue'
    import OwSplitButton from '@/components/core/ow-split-button.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import {
        DocumentOrderStatus,
        isTitleDocumentAvailableToView,
    } from '@/consts/document-order-status'
    import { getDataProviderText } from '@/consts/document-source'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { Route } from '@/enums/route.enum'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
    import { ORDER_DOCUMENTS } from '@/store/modules/document-ordering/types'
    import {
        ADD_DOCUMENT_TO_MATTER,
    } from '@/store/modules/documents/documents-types'
    import { MATTER_ADD_TITLE } from '@/store/modules/matter/types'
    import {
        TITLE_CLEAR,
        TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_BACKDATED_TITLE_PLAN,
        TITLE_MUTATE_TITLE_PLAN_ORDER_LOADING,
    } from '@/store/modules/titles/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { format } from '@/utils/date-utils'

    const menuItemsText = {
        download: 'Download',
        refresh: 'Refresh (£3)',
    }

    export default {
        name: 'OrderTitlePlan',

        components: {
            OwButton,
            OwCard,
            OwDocumentAvailabilityLabel,
            OwIconText,
            OwIconTitlePlan,
            OwSplitButton,
            OwTooltip,
            OwOverlayMapIcon,
        },

        mixins: [flagsMixin],

        props: {
            selectedTitle: {
                type: Object,
                required: true,
            },

            selectedTitleNumber: {
                type: null as PropType<string | null>,
                required: true,
                default: '',
            },

            currentMatterId: {
                type: Number,
                required: false,
                default: undefined,
            },

            isOrderingAllowed: {
                type: Boolean,
                required: false,
                default: true,
            },
            officialCopies: {
                type: Object,
                required: false,
                default: null,
            },
        },

        data() {
            return {
                isExportPdfLoading: false,
                isLoadingOverlays: false,
                existingOverlay: null,
                isMenuItemLoading: false,
                menuItems: [
                    {
                        text: menuItemsText.download,
                        dataTest: 'order-title-plan-export-as-pdf',
                    },
                    {
                        text: menuItemsText.refresh,
                        dataTest: 'order-title-plan-button-refresh',
                        disabled: !this.isOrderingAllowed,
                        tooltipText: !this.isOrderingAllowed ? 'Please add this title to a matter in order to purchase documents' : null,
                    },
                ],
                OwTooltipPosition,
            }
        },

        computed: {
            showBackDatedOrderButton() {
                return this.titlePlanAvailability.hmlrAvailableVersionIsBackdated
            },

            showBackdatedViewButton() {
                return this.titlePlanAvailability?.currentVersionIsBackdated
            },

            orderedTitlePlanDate() {
                return this.titlePlanAvailability?.responseDateValue
            },

            documentAvailabilityCode() {
                return this.titlePlanAvailability?.hmlrAvailabilityCode ?? null
            },

            sentInPost() {
                return Boolean(this.titlePlanAvailability?.currentVersionHasBeenSentByPost)
            },

            showDocumentAvailabilityText() {
                return this.isDocumentUnderInvestigation ||
                    (this.documentAvailabilityCode !== null &&
                        this.selectedTitle?.officialCopiesAvailability?.loading === false &&
                        !this.orderedTitlePlanDate &&
                        !this.sentInPost)
            },

            isDocumentUnderInvestigation() {
                return this.titlePlanAvailability.orderStatus === DocumentOrderStatus.UNDER_INVESTIGATION
            },

            disableOrderButton() {
                return this.documentAvailabilityCode === HmlrDocumentAvailabilityCode.Unavailable || this.isDocumentUnderInvestigation
            },

            titlePlanAvailability() {
                return this.selectedTitle?.officialCopiesAvailability?.results?.titlePlan
            },

            isOrderTitlePlanLoading() {
                return Boolean(this.titlePlanAvailability?.loadingOrder)
            },

            isFailedOrder() {
                return Boolean(this.titlePlanAvailability?.failedOrder)
            },

            titlePlanViewable() {
                return isTitleDocumentAvailableToView(this.titlePlanAvailability?.orderStatus)
            },

            documentAvailabilityLoading() {
                return this.selectedTitle.officialCopiesAvailability.loading
            },

            isRegisterAvailable() {
                return this.selectedTitle.officialCopiesAvailability.results?.titleRegister?.documentId ||
                    this.registerAvailability?.editionDateValue
            },

            hasOfficialCopies() {
                return Boolean(this.officialCopies)
            },
        },

        async mounted() {
            if (this.currentMatterId) {
                await this.loadOverlays()
            }
        },

        methods: {
            format,

            ...mapActions('documentOrdering', {
                orderDocuments: ORDER_DOCUMENTS,
            }),

            ...mapActions({
                titleClear: TITLE_CLEAR,
                addDocumentToMatter: ADD_DOCUMENT_TO_MATTER,
                addTitleToMatter: MATTER_ADD_TITLE,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            ...mapMutations({
                setOrderTitlePlanLoading: TITLE_MUTATE_TITLE_PLAN_ORDER_LOADING,
                mutateBackdatedTitlePlan: TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_BACKDATED_TITLE_PLAN,
            }),

            async onMenuItemClick(item) {
                if (item.text === menuItemsText.download) {
                    this.isMenuItemLoading = true
                    await this.exportAsPdf()
                    this.isMenuItemLoading = false
                } else if (item.text === menuItemsText.refresh) {
                    this.isMenuItemLoading = true
                    await this.orderDocument(true)
                    this.isMenuItemLoading = false
                }
            },

            async exportAsPdf() {
                this.isExportPdfLoading = true
                const documentId = this.titlePlanAvailability.documentId
                await DocumentsApi.downloadDocumentByTypeAndId(HighLevelDocumentType.TitlePlan, documentId)
                this.logHeapEvent({
                    type: 'Title Plan - Export document',
                    metadata: {
                        titleNumber: this.selectedTitleNumber,
                        matterId: this.currentMatterId,
                        docFormat: '.pdf',
                        documentSource: getDataProviderText(this.selectedTitle?.record?.source),
                    },
                })
                this.isExportPdfLoading = false
            },

            async viewDocument() {
                const documentId = this.titlePlanAvailability.documentId
                if (documentId) {
                    await this.addDocumentsToMatter(documentId)
                }
                if (this.isReviewAssistantEnabled &&
                    this.currentMatterId &&
                    this.isRegisterAvailable) {
                    await this.$router.push({
                        name: Route.ReviewAssistant,
                        params: {
                            matterId: this.currentMatterId,
                            titleNumber: this.selectedTitleNumber,
                        },
                        hash: '#plan',
                    })
                } else {
                    if (documentId !== null) {
                        await this.$router.push({
                            name: Route.DocumentViewer,
                            params: {
                                documentType: HighLevelDocumentType.TitlePlan,
                                documentId,
                            },
                            query: {
                                fromMatterId: this.currentMatterId,
                            },
                        })
                    }
                }
            },

            async addDocumentsToMatter(documentId) {
                await this.addTitleToMatter({
                    titleNumber: this.selectedTitleNumber,
                    show: true,
                })
                await this.addDocumentToMatter({
                    documentType: HighLevelDocumentType.TitlePlan,
                    documentId,
                    matterId: this.currentMatterId,
                })
            },

            async loadOverlays() {
                this.isLoadingOverlays = true
                const documentId = this.titlePlanAvailability?.documentId
                if (documentId) {
                    const overlays = await OverlaysApi.getOverlays(this.currentMatterId)
                    this.existingOverlay = overlays.data.find(x =>
                        (x.bgOc2DocumentId === documentId ||
                            x.bgTitlePlanId === documentId ||
                            x.documentUploadId === documentId))
                }
                this.isLoadingOverlays = false
            },

            async goToOverlays() {
                const documentId = this.titlePlanAvailability.documentId
                if (documentId) {
                    await this.addDocumentsToMatter(documentId)
                    await this.titleClear()
                    // Not sure how else to do this. The clear title above updates the selected title number in the store
                    // to null. The map view watches this and redirects to the map page. Without waiting, then the below
                    // comes before that redirect to the map page and the user just stays where they are.
                    setTimeout(() => {
                        if (this.existingOverlay) {
                            this.$router.push({
                                name: Route.OverlaysList,
                                params: { matterId: this.currentMatterId },
                                query: { overlayId: this.existingOverlay.overlayId },
                            })
                        } else {
                            this.$router.push({
                                name: Route.OverlaysCreate,
                                params: { matterId: this.currentMatterId },
                                query: { documentId },
                            })
                        }
                    }, 500)
                }
            },

            logHeapOrderEvent() {
                this.logHeapEvent({
                    type: 'Title Plan Ordered',
                    metadata: {
                        titleNumber: this.selectedTitleNumber,
                        matterId: this.currentMatterId,
                    },
                })
            },

            async orderDocument(forceRefresh = false) {
                this.addTitleToMatter({
                    titleNumber: this.selectedTitleNumber,
                    show: true,
                })
                this.setOrderTitlePlanLoading(true)
                const force = this.isFailedOrder || forceRefresh
                const request = DocumentOrderRequest.createTitlePlanRequest(
                    this.selectedTitleNumber,
                    this.currentMatterId,
                    force,
                    this.titlePlanAvailability.hmlrAvailableVersionIsBackdated)
                await this.orderDocuments([request])
                this.logHeapOrderEvent()
                this.mutateBackdatedTitlePlan(this.titlePlanAvailability.hmlrAvailableVersionIsBackdated)
            },
        },
    }
</script>

<style lang="scss">
    @import './order-title-plan-card';
</style>

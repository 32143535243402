<template>
    <day-list-card :class="{ 'day-list-section__container--card': showTable }"
                   :selected-title="selectedTitle"
                   :show-view-link="false">
        <div v-if="showTable"
             id="day-list-data"
             class="d-flex pt-4 pb-0 flex-column day-list-section-data">
            <div class="day-list-section__table--header-labels-wrapper">
                <div class="day-list-section__table--header-labels">
                    <div class="label-caps-small">
                        Application ID
                    </div>
                    <div class="label-caps-small ml-4">
                        Type
                    </div>
                    <div class="label-caps-small">
                        Priority Date
                    </div>
                </div>
                <div class="empty-item" />
            </div>

            <v-expansion-panels v-model="activePanel"
                                variant="accordion"
                                multiple
                                class="day-list-section__table--expansion-panels">
                <v-expansion-panel v-for="(enquiry, index) in daylistApplications"
                                   :key="index"
                                   elevation="0"
                                   class=" title-panel-subtitle mb-0 day-list-section__table--expansion-panel"
                                   data-track="TITLE-DETAILS-PANEL - Expand daylist application"
                                   style="margin-bottom:10px; flex-direction: column;"
                                   xs12>
                    <v-expansion-panel-title expand-icon=""
                                             collapse-icon="">
                        <div class="day-list-section__table--expansion-panel-header">
                            <div v-if="enquiry.applicationReference"
                                 class="day-list-section__table--expansion-panel-header--id">
                                <strong>{{ enquiry.applicationReference }}
                                    <v-icon>{{ activePanel.includes(index) ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon></strong>
                            </div>
                            <div v-if="enquiry.applicationType"
                                 class="daylist-enquiry__application-type ml-4">
                                {{ enquiry.applicationType }}
                            </div>
                            <div v-if="enquiry.priorityDate">
                                {{ enquiry.priorityDate }}
                            </div>
                        </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <div v-if="documentSource !== DOCUMENT_SOURCE.SCOTLAND"
                             class="d-flex flex-column day-list-section__table--expanded-content">
                            <div v-for="item in getEnquiryItems(enquiry)"
                                 :key="item.title"
                                 class="day-list-section__table--expanded-content-item">
                                <span class="label-caps-small">{{ item.title }}</span>
                                <p class="ml-4">
                                    {{ item.value }}
                                </p>
                            </div>
                        </div>
                        <div v-else
                             class="flex-column day-list-section__table--expanded-content-warning">
                            <span class="body-copy mb-3">{{ $t('titlePanel.cards.daylist.scottishOrderingWarning') }}</span>
                            <div>
                                <ow-button-ds is-secondary
                                              small
                                              data-track="TITLE PANEL - Scottish Pending Application registered interest">
                                    Register Interest
                                </ow-button-ds>
                            </div>
                        </div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <ow-icon-renderer hide-attribution-text
                              :record-source="selectedTitle?.record?.source" />
        </div>
    </day-list-card>
</template>

<script setup lang="ts">
    import {
        computed,
        ref,
    } from "vue"
    import { useStore } from 'vuex'

    import OwIconRenderer from "@/components/core/icons/ow-icon-renderer.vue"
    import OwButtonDs from "@/components/core/ow-button-ds.vue"
    import DayListCard from '@/components/title-panel/v2/daylist/day-list-card.vue'
    import { useSelectedTitle } from "@/composables/use-selected-title"
    import { DOCUMENT_SOURCE } from '@/consts/document-source'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { isNullOrWhitespace } from "@/utils/string-utils"

    const { selectedTitle, daylist, loadingDaylist } = useSelectedTitle()

    const hmlrFields = [
        { key: 'priorityDate', title: 'Priority Date' },
        { key: 'priorityTime', title: 'Priority Time' },
        { key: 'lodgedBy', title: 'Logged By' },
        { key: 'applicant', title: 'Applicant' },
        { key: 'propertyDescription', title: 'Property Description' },
        { key: 'customerReference', title: 'Customer Reference' },
        { key: 'depositReason', title: 'Deposit Reason' },
        { key: 'searchCertificateNumber', title: 'Search Certificate Number' },
        { key: 'searchInterest', title: 'Search Interest' },
        { key: 'oLALodgedFor', title: 'OLA Logged For' },
    ]
    // TODO: Integrate backend properties
    const scotlandFields = [
        { key: 'mockField', title: 'Mock until integration' },
    ]
    let activePanel = ref<any>([])
    const store = useStore()
    const daylistApplications = computed(() => {
        if (daylist?.value?.application) {
            return daylist.value.data
        }

        return []
    })
    const showTable = computed(() => !loadingDaylist.value && !isNullOrEmpty(daylist.value.data))
    const documentSource = computed(() => selectedTitle.value?.record?.source)
    const getScotlandEnquiryItems = (enquiry: any) => {
        return scotlandFields
            .filter((field: any) => !isNullOrWhitespace(enquiry[field.key]) && enquiry.hasOwnProperty(field.key))
            .map((field: any) => ({
                title: field.title,
                value: enquiry[field.key],
            }))
    }
    const getHmlrEnquiryItems = (enquiry: any) => {
        const rows = hmlrFields
            .filter((field: any) => !isNullOrWhitespace(enquiry[field.key]) && enquiry.hasOwnProperty(field.key))
            .map((field: any) => ({
                title: field.title,
                value: enquiry[field.key],
            }))
        // NOTE: Treat the only deep property differently
        if (enquiry.applicationProgress && enquiry.applicationProgress.description) {
            rows.push({
                title: 'Application Progress - Description',
                value: enquiry.applicationProgress.description,
            })
        }
        return rows
    }
    const getEnquiryItems = (enquiry: any) => {
        switch (documentSource.value) {
            case DOCUMENT_SOURCE.SCOTLAND:
                return getScotlandEnquiryItems(enquiry)
            default:
                return getHmlrEnquiryItems(enquiry)
        }
    }
</script>

<style lang="scss">
    @import './daylist-enquiry';
</style>
